import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../constants/organization';

export const libraryRoutes = [
    {
        path: '/library',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.LibraryPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
        },
    },
    {
        path: '/library/shared',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.LibraryPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
        },
    },
    {
        path: '/library/prompt/:id',
        indexPath: '/library',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.PromptViewPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
        },
    },
    {
        path: '/library/prompt/:id/view',
        indexPath: '/library',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ViewOnlyPromptPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
        },
    },
    {
        path: '/library/prompt/:id/history',
        indexPath: '/library',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.TaskHistoryIndexPage, // history page for both prompt and non-prompt tasks
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
        },
    },
    {
        path: '/library/prompt/:promptId/run/:resultId',
        indexPath: '/library',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ViewHistoricalRunPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
        },
    },
    {
        path: '/library/prompt/:promptId/version/:versionId',
        indexPath: '/library',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.VersionHistoryDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
        },
    },
    {
        path: '/library/task/:id',
        indexPath: '/library',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.TaskPlaygroundPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/library/task/:id/history',
        indexPath: '/library',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.TaskHistoryIndexPage, // history page for both prompt and non-prompt tasks
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/library/task/:taskId/run/:resultId',
        indexPath: '/library',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ViewHistoricalRunPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/library/prompt/new',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.CreateNewPromptTaskPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
        },
    },
    {
        path: '/library/task/new',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.CreateNewTaskPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
];
