import { createContext, useState } from 'react';

export const WrongOrgOrViewTypeNavBlockerContext = createContext();

export const WrongOrgOrViewTypeNavBlockerProvider = ({ children }) => {
    const [isWrongOrg, setIsWrongOrg] = useState(false);
    const [isWrongViewType, setIsWrongViewType] = useState(false);
    const [redirectPath, setRedirectPath] = useState(null);

    return (
        <WrongOrgOrViewTypeNavBlockerContext.Provider
            value={{
                isWrongViewType,
                setIsWrongViewType,
                isWrongOrg,
                setIsWrongOrg,
                redirectPath,
                setRedirectPath,
            }}
        >
            {children}
        </WrongOrgOrViewTypeNavBlockerContext.Provider>
    );
};
