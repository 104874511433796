import React, { useState } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/organization-api';
import knowledgeClient from '../../../services/knowledge-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { ROUTE, CONTENT_TYPE, SENT_FOLDER_OR_DOC_DATA_LS_KEY } from '../../../constants/docs';
import { mutateDataAfterDeletingItem } from '../../../helpers/swrMutateUtils';
import { updateSidePanelStateAfterRemovingFolders } from '../../../helpers/docsSidePanelUtils';

import useUser from '../../../hooks/useUser';
import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import Modal from '../../../design-system/Modal/Modal';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';
import OrganizationListOption from '../OrganizationListOption/OrganizationListOption';
import ClientSideFilteredSearchBar from '../../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';

const SendToOrgModal = ({
    data,
    contentType,
    requestKeysToMutate,
    setSuccessAlert,
    navigateToDocsIndex,
    onClose,
}) => {
    const { user } = useUser();
    const currentOrgId = user?.organization?.id;

    const formatResponseToOptions = (results) => {
        return results?.map(({ id, organization }) => ({
            id: organization.id,
            name: organization.name,
            membershipId: id,
        }));
    };

    const {
        options: organizations,
        optionsLoading: organizationsLoading,
        canLoadMoreOptions,
        setPage,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.organization.organizationMembership,
        searchParams: { include_child: true },
        formatResponseToOptions,
    });

    const [filteredOrganizations, setFilteredOrganizations] = useState(organizations);

    const [loadingOrgId, setLoadingOrgId] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const { location, setSidePanelFolders } = useDocsPageContext() || {};

    const { isMobile } = useResponsiveBreakpoints();

    const handleSendToOrg = async ({ id: orgId, name: orgName, membershipId }) => {
        const isCurrentOrg = orgId === currentOrgId;
        if (isCurrentOrg) return;

        try {
            setLoadingOrgId(orgId);

            const docOrFolderId = data.id;
            const route = ROUTE[contentType] + docOrFolderId + '/';
            await knowledgeClient.patch(route, { organization: orgId });

            mutateDataAfterDeletingItem({ requestKeysToMutate, id: docOrFolderId });

            if (contentType === CONTENT_TYPE.folder && setSidePanelFolders) {
                updateSidePanelStateAfterRemovingFolders({
                    removedFoldersIds: [docOrFolderId],
                    parentLocation: location,
                    setSidePanelFolders,
                });
            }

            const successAlertData = {
                data: {
                    contentType,
                    membershipId,
                    organizationName: orgName,
                    folderId: contentType === CONTENT_TYPE.folder ? docOrFolderId : null,
                },
            };

            if (setSuccessAlert) {
                setSuccessAlert(successAlertData);
            }

            if (navigateToDocsIndex) {
                localStorage.setItem(
                    SENT_FOLDER_OR_DOC_DATA_LS_KEY,
                    JSON.stringify(successAlertData)
                );
                navigateToDocsIndex();
            }

            onClose();
        } catch (e) {
            setLoadingOrgId(null);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const topFixedContent = (
        <div className="flex flex-col">
            <ModalHeader
                title="Send To Organization"
                outlined
                withEscapeButton={!isMobile}
                customPaddings="px-5 py-3"
                titleStyles="font-heading-bold text-heading-bold-s text-neutral-500"
                onClose={onClose}
            />

            <div className="pt-5 px-5">
                <ClientSideFilteredSearchBar
                    size="md"
                    list={organizations}
                    setFilteredList={setFilteredOrganizations}
                    keyToFilter={['name']}
                />
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );

    const orgsListClassName = classNames('flex-1 pt-3 mt-2 px-5 overflow-y-auto', {
        'md:pb-3': !!filteredOrganizations?.length,
        'min-h-[38px]': !filteredOrganizations?.length && !organizationsLoading,
        'min-h-[70px]': organizationsLoading,
    });

    const orgsList = (
        <div className={orgsListClassName}>
            <InfiniteScrollList
                handleFetch={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                items={filteredOrganizations}
                loading={organizationsLoading}
                showLoader={!loadingOrgId}
                gap={0}
            >
                <div className="flex flex-col gap-1">
                    {filteredOrganizations?.map((organization) => {
                        const { id, name } = organization;

                        const isLoading = loadingOrgId === id;
                        const isCurrentOrg = id === currentOrgId;

                        const label = isCurrentOrg ? name + ' (current)' : name;

                        return (
                            <OrganizationListOption
                                key={id}
                                label={label}
                                isLoading={isLoading}
                                isCurrent={isCurrentOrg}
                                handleSelect={() => handleSendToOrg(organization)}
                            />
                        );
                    })}
                </div>
            </InfiniteScrollList>
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                closeButtonText="Cancel"
                minHeight="90vh"
                maxHeight="90vh"
                containerCustomPaddings="pb-4"
                contentCustomPaddings="pt-0"
                bottomButtonsCustomPaddings="px-5 pt-3"
                topFixedContent={topFixedContent}
                customGap={8}
            >
                <div className="flex flex-col bg-white">{orgsList}</div>
            </MobilePopup>
        );
    }

    return (
        <Modal size="semiMedium" resetPadding onClose={onClose}>
            <div className="flex flex-col overflow-y-auto max-h-[382px]">
                {topFixedContent}
                {orgsList}
            </div>
        </Modal>
    );
};

export default SendToOrgModal;
