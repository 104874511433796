import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { API } from 'constants';
import useCustomPagination from '../../../hooks/useCustomPagination';
import client from '../../../services/library-api';
import reportClient from '../../../services/report-api';
import inboxClient from '../../../services/inbox-api';

import { STATUS } from '../../../constants/statuses';
import { SORT_TYPES } from '../../../constants/sort';
import { SETTING_TYPE } from '../../../constants/settingsForm';
import { handlePageDataLoadError } from '../../../helpers/handlePageDataLoadError';
import { useWrongOrgOrViewTypeNavBlocker } from '../../../hooks/useWrongOrgOrViewTypeNavBlocker';
import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';

import Loading from '../../../components/Loading';
import TitleBlock from '../../../components/TitleBlock/TitleBlock';
import AboutTabSection from './AboutTabSection/AboutTabSection';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import JobHistoryTabSection from './JobHistoryTabSection/JobHistoryTabSection';
import DetailPageTopNavBlock from '../../../components/DetailPageTopNavBlock/DetailPageTopNavBlock';
import AgentSettingsTabSection from '../../../components/AgentSettingsTabSection/AgentSettingsTabSection';
import PendingSuggestionsBlock from './PendingSuggestionsBlock/PendingSuggestionsBlock';
import { Tabs } from '../../../design-system';
import { FlashlightFillIcon } from '../../../design-system/Icons';

const tabs = [{ name: 'Settings' }, { name: 'Job History' }, { name: 'About' }];
export const jobsLimit = 10;

const ClientProcessDetailPage = () => {
    const { processId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from ?? '/playbooks';

    const [processDetails, setProcessDetails] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const [jobsPage, setJobsPage] = useState(1); // current page in paginated request for jobs for Job History Tab

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    const [refreshFormsState, setRefreshFormsState] = useState(null);

    // const isUpgradeStatus = processDetails?.default_version?.upgrade_status?.is_upgrade;

    useEffect(() => {
        const handleFetch = async () => {
            try {
                await fetchProcessData();
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    setIsWrongOrg,
                    setRedirectPath,
                    redirectPath: '/dashboard',
                    generalErrorHandler: () => navigate(backLinkHref),
                });
            }
        };

        setProcessDetails(null);
        handleFetch();
    }, [processId]);

    const fetchProcessData = async () => {
        const { data } = await client.get(
            `${API.ROUTES.library.clientProcess}${processId}/?shared=True`
        );
        setProcessDetails(data);
    };

    const jobsUseCustomPaginationResponse = useCustomPagination({
        client: reportClient,
        route: API.ROUTES.report.job,
        pageIndex: jobsPage - 1,
        searchParams: { ordering: SORT_TYPES.created, process: processId },
        limit: jobsLimit,
        autoFetchNextPage: true,
    });

    const suggestionHookResponse = useFetchOptionsForPaginatedSelect({
        client: inboxClient,
        route: API.ROUTES.inbox.suggestion,
        searchParams: { status: STATUS.pending, process: processId },
    });

    useDocumentTitle(processDetails?.name);

    const refreshSettingsForms = async ({ type, inputData }) => {
        if (type === SETTING_TYPE.client) {
            await fetchProcessData();
        }

        setRefreshFormsState({ type, inputData });
    };

    const handleTabChanged = (tab, index) => {
        setActiveTabIndex(index);
    };

    // const upgradeNowBannerText = (
    //     <>
    //         You are on outdated version ({processDetails?.default_version?.name}).{' '}
    //         <span className="underline">
    //             Click here to view the improvements and migrate to{' '}
    //             {processDetails?.default_version?.upgrade_status?.latest_version}.
    //         </span>
    //     </>
    // );
    //
    // const onUpgradeNowBannerClick = () => {
    //     const latestVersionId = processDetails.default_version?.upgrade_status?.latest_version_id;
    //     navigate(`/agent/${processId}/upgrade/${latestVersionId}`, { state: { from: location } });
    // };

    const settingsTabContainerClassName = classNames('flex flex-col gap-5', {
        'h-0 max-h-0 overflow-hidden': activeTabIndex !== 0,
    });

    const handleStartNewJob = () =>
        navigate(`/agent/${processId}/start`, { state: { from: location } });

    return (
        <div className="page-position overflow-y-auto pb-8 hide-scrollbar">
            {processDetails ? (
                <>
                    <DetailPageTopNavBlock
                        backButtonText="Back to Playbooks"
                        breadcrumbs="Agents / Agent Overview"
                        backLinkHref="/playbooks"
                        rightAlignedButton={{
                            text: 'Start New Job',
                            trailingIcon: FlashlightFillIcon,
                            onClick: handleStartNewJob,
                        }}
                    />

                    <div className="w-full flex flex-col gap-5 p-5 xs:p-6 sm:p-8">
                        <TitleBlock
                            title={processDetails.name}
                            withSharedIcon={processDetails.is_shared}
                        />

                        <div className="max-w-max">
                            <Tabs
                                tabs={tabs}
                                tabIndex={activeTabIndex}
                                onTabChanged={handleTabChanged}
                            />
                        </div>

                        <PendingSuggestionsBlock
                            suggestionHookResponse={suggestionHookResponse}
                            refreshSettingsForms={refreshSettingsForms}
                        />

                        {/*{isUpgradeStatus && (*/}
                        {/*    <Banner*/}
                        {/*        title="Upgrade Today!"*/}
                        {/*        text={upgradeNowBannerText}*/}
                        {/*        color="peach"*/}
                        {/*        onBannerClick={onUpgradeNowBannerClick}*/}
                        {/*    />*/}
                        {/*)}*/}

                        <div>
                            <div className={settingsTabContainerClassName}>
                                <AgentSettingsTabSection
                                    processDetail={processDetails}
                                    setProcessDetail={setProcessDetails}
                                    shouldShowHiddenSettings={false}
                                    refreshFormsState={refreshFormsState}
                                />
                            </div>

                            {activeTabIndex === 1 && (
                                <JobHistoryTabSection
                                    jobsUseCustomPaginationResponse={
                                        jobsUseCustomPaginationResponse
                                    }
                                    jobsPage={jobsPage}
                                    setJobsPage={setJobsPage}
                                />
                            )}

                            <div className={activeTabIndex === 2 ? 'block' : 'hidden'}>
                                <AboutTabSection
                                    processDetails={processDetails}
                                    setProcessDetails={setProcessDetails}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="w-full h-full flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default ClientProcessDetailPage;
