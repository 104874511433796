import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/organization-api';

import useUser from '../../../hooks/useUser';
import { useClickOutside } from '../../../hooks';
import { useClientSideFilterBar } from '../../../hooks/useClientSideFilterBar';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import InfiniteScrollList from '../../InfiniteScrollList/InfiniteScrollList';
import OrganizationListOption from '../../../pages/DocsPage/OrganizationListOption/OrganizationListOption';
import ClientSideFilteredSearchBar from '../../ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import { SvgIcon } from '../../../design-system';
import { ArrowDownSLineIcon } from '../../../design-system/Icons';

const OrganizationSelect = ({
    selectedOrgData,
    handleChange,
    placeholder = 'Select different organization...',
}) => {
    const { user } = useUser();
    const [isOpened, setIsOpened] = useState(false);

    const dropdownRef = useRef();

    const { isMobile } = useResponsiveBreakpoints({ maxMobileWidth: 480 });

    const formatResponseToOptions = (results) => {
        return results?.map(({ id, organization }) => ({
            id: organization.id,
            name: organization.name,
            membershipId: id,
        }));
    };

    const {
        options: organizations,
        optionsLoading: organizationsLoading,
        canLoadMoreOptions,
        totalOptions,
        setPage,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.organization.organizationMembership,
        searchParams: { include_child: true },
        formatResponseToOptions,
    });

    useClickOutside(dropdownRef, (e) => {
        e?.stopPropagation();
        setIsOpened(false);
    });

    const showClientSideFilterBar = totalOptions >= 6;
    const { filteredOptions: filteredOrganizations, setFilteredOptions: setFilteredOrganizations } =
        useClientSideFilterBar({
            options: organizations,
            showClientSideFilterBar,
        });

    const isSelectedOrg = !!selectedOrgData;
    const buttonText = isSelectedOrg ? selectedOrgData.name : placeholder;

    const buttonClassName = classNames(
        'w-full max-w-full flex items-center gap-2.5 px-3 py-2.5 border-1 border-neutral-300 rounded-2',
        {
            'cursor-pointer': !organizationsLoading,
        }
    );
    const buttonTextClassName = classNames(
        'flex-1 font-body text-body-regular-m truncate text-start',
        {
            'text-neutral-300': !isSelectedOrg,
            'text-neutral-500': isSelectedOrg,
        }
    );

    const toggleIsOpened = () => setIsOpened((prev) => !prev);

    let increaseHeightBy = 4;

    if (totalOptions > 3) increaseHeightBy = 176;
    if (totalOptions > 0 && totalOptions <= 3) increaseHeightBy = 132;
    if (totalOptions === 0 && organizationsLoading) increaseHeightBy = 48;

    if (isMobile) increaseHeightBy = Math.max(0, increaseHeightBy - 48);

    const listClassName = classNames('p-2 overflow-y-auto', {
        'max-h-[142px]': totalOptions > 0,
        'max-h-[50px]': totalOptions === 0 && organizationsLoading,
    });

    return (
        <div
            className="relative flex-1 transition-all"
            style={{ marginBottom: isOpened ? `${increaseHeightBy}px` : 0 }}
        >
            <button className={buttonClassName} onClick={toggleIsOpened}>
                <p className={buttonTextClassName}>{buttonText}</p>
                <SvgIcon color="#5E6470" size="medium" icon={ArrowDownSLineIcon} />
            </button>

            {isOpened && (
                <div
                    className="absolute top-[46px] left-0 right-0 z-10 rounded-2 shadow-lg ring-1 ring-black ring-opacity-5 bg-white"
                    ref={dropdownRef}
                >
                    {showClientSideFilterBar && (
                        <div className="px-3 pt-2">
                            <ClientSideFilteredSearchBar
                                list={organizations || []}
                                setFilteredList={setFilteredOrganizations}
                                size="xs"
                            />
                        </div>
                    )}

                    <InfiniteScrollList
                        handleFetch={() => setPage((page) => page + 1)}
                        canLoadMore={canLoadMoreOptions}
                        items={filteredOrganizations}
                        loading={organizationsLoading}
                        gap={0}
                        customStyles={listClassName}
                    >
                        {filteredOrganizations?.map((organization) => {
                            const { id, name } = organization;

                            const isCurrent = id === user?.organization?.id;

                            const label = isCurrent ? name + ' (current)' : name;

                            const handleOrgChange = () => {
                                handleChange(organization);
                                setIsOpened(false);
                            };

                            return (
                                <OrganizationListOption
                                    key={id}
                                    label={label}
                                    isLoading={false}
                                    isCurrent={isCurrent}
                                    handleSelect={handleOrgChange}
                                />
                            );
                        })}
                    </InfiniteScrollList>
                </div>
            )}
        </div>
    );
};

export default OrganizationSelect;
