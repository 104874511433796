import { useState } from 'react';

export const useOverlayState = () => {
    const [isOpened, setIsOpened] = useState(false);

    const open = (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        setIsOpened(true);
    };
    const close = (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        setIsOpened(false);
    };

    const toggle = (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        setIsOpened((prevState) => !prevState);
    };

    return { isOpened, open, close, toggle };
};
