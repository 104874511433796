import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../constants/organization';

export const agentRoutes = [
    {
        path: '/agent/:processId',
        indexPath: '/dashboard',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ClientProcessDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
    {
        path: '/agent/:processId/start',
        indexPath: '/dashboard',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.StartNewJobPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
    {
        path: '/agent/:processId/upgrade/:versionId', // latestVersionId
        indexPath: '/dashboard',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ProcessSetupOrUpgradePage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
];
