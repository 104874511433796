import React from 'react';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { useViewType } from '../../hooks/useViewType';

import Modal from '../../design-system/Modal/Modal';
import ModalHeader from '../../design-system/ModalHeader/ModalHeader';
import { Button } from '../../design-system';

const WrongViewTypeBlockerModal = ({ onClose, onCloseAndRedirect }) => {
    const { viewType, toggleViewType } = useViewType();

    const handleSwitchViewType = () => {
        toggleViewType();
        onClose();
    };

    const oppositeViewTypeLabel = `${viewType === VIEW_TYPES.admin ? 'Client' : 'Admin'} Mode`;

    const title = `Switch to ${oppositeViewTypeLabel} to View Link`;

    const hightlightedTextStyles = 'font-body-bold text-body-bold-m';

    return (
        <Modal size="semiMedium" onClose={onCloseAndRedirect} resetPadding>
            <div className="p-5 md:p-6 flex flex-col gap-5 md:gap-6">
                <ModalHeader onClose={onCloseAndRedirect} title={title} />

                <p className="font-body text-body-regular-m text-neutral-500">
                    To view this link, you will have to switch to{' '}
                    <span className={hightlightedTextStyles}>{oppositeViewTypeLabel}</span>. This
                    will switch everywhere,{' '}
                    <span className={hightlightedTextStyles}>you will lose unsaved work!</span>
                </p>

                <div className="flex items-center justify-between gap-2">
                    <Button type="neutral" size="sm" text="Cancel" onClick={onCloseAndRedirect} />
                    <Button
                        type="secondary"
                        size="sm"
                        text={`Switch to ${oppositeViewTypeLabel}`}
                        onClick={handleSwitchViewType}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default WrongViewTypeBlockerModal;
