import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../design-system';
import classNames from 'classnames';

const BlockWrapper = ({
    title,
    description,
    viewAllNavPath,
    isViewButtonDisplayed = false,
    size = 'md',
    children,
}) => {
    const navigate = useNavigate();

    const containerClassName = classNames(
        'px-4 py-5 md:p-6 lg:py-6 rounded-2xl bg-white flex flex-col gap-5',
        {
            'lg:px-6': size === 'sm',
            'lg:px-8': size === 'md',
        }
    );

    return (
        <div className={containerClassName}>
            <div className="flex flex-col xs:flex-row gap-y-2 gap-x-5 justify-between">
                <div>
                    <h3 className="font-heading-bold text-heading-bold-m text-black mb-1.5">
                        {title}
                    </h3>
                    <p className="font-body text-body-regular-s text-neutral-300">{description}</p>
                </div>
                {isViewButtonDisplayed && (
                    <div className="min-w-fit">
                        <Button
                            type="neutral"
                            size="sm"
                            text="View all"
                            onClick={() => navigate(viewAllNavPath)}
                        />
                    </div>
                )}
            </div>

            {children}
        </div>
    );
};

export default BlockWrapper;
