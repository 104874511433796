import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/knowledge-api';

import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { handleExpandSidePanelFolder } from '../../../helpers/docsSidePanelUtils';

import ArrowRightSLineIcon from '../../../design-system/Icons/ArrowRightSLineIcon';
import FolderBreadcrumbItem from '../FolderBreadcrumbItem/FolderBreadcrumbItem';
import { useWrongOrgOrViewTypeNavBlocker } from '../../../hooks/useWrongOrgOrViewTypeNavBlocker';
import { handlePageDataLoadError } from '../../../helpers/handlePageDataLoadError';

const FolderBreadcrumbs = ({ folderId }) => {
    const navigate = useNavigate();

    const { location, setLocation, setExpandedFolders } = useDocsPageContext();

    const controller = useRef(new AbortController());

    const { isDesktop } = useResponsiveBreakpoints();
    const [isFullLocationDisplayed, setIsFullLocationDisplayed] = useState(isDesktop);

    const containerRef = useRef(null);

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    const fetchFolderDetails = async () => {
        try {
            controller.current?.abort();

            const newController = new AbortController();
            controller.current = newController;

            const { data } = await client.get(API.ROUTES.knowledge.folder + folderId + '/', {
                signal: newController.signal,
            });
            const { location: validLocation } = data;
            const isLocationCorrect = JSON.stringify(location) === JSON.stringify(validLocation);
            if (!isLocationCorrect) {
                handleExpandSidePanelFolder({
                    location: validLocation?.map((loc) => loc.id),
                    setExpandedFolders,
                });
                setLocation(validLocation);
            }
        } catch (e) {
            handlePageDataLoadError({
                e,
                setIsWrongOrg,
                setRedirectPath,
                redirectPath: '/docs',
                generalErrorHandler: () => navigate('/docs'),
            });
        }
    };

    useEffect(() => {
        if (folderId) {
            fetchFolderDetails();
        }

        if (!folderId) {
            setLocation([]);
        }
    }, [folderId]);

    useEffect(() => {
        if (!location && folderId) {
            fetchFolderDetails();
        }

        if (!location && !folderId) {
            setLocation([]);
        }
    }, [location]);

    useEffect(() => {
        if (!isDesktop) {
            setIsFullLocationDisplayed(false);
        }
    }, [location]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollLeft = containerRef.current.scrollWidth;
        }
    }, [isFullLocationDisplayed, location]);

    if (!folderId) {
        return (
            <div
                onClick={() => setLocation([])}
                className="flex items-center gap-1 font-body-medium text-body-regular-s text-neutral-500"
            >
                <p>Docs</p>
                <ArrowRightSLineIcon width={10} height={10} color="#5E6470" />
            </div>
        );
    }

    if (!location) {
        return <></>;
    }

    const handleNavigateToIndex = () => {
        navigate('/docs');
        setLocation([]);
    };

    const handleItemClick = (id, index) => {
        const slicedLocation = location.slice(0, index + 1);
        handleExpandSidePanelFolder({
            location: slicedLocation?.map((loc) => loc.id),
            setExpandedFolders,
        });

        if (id === folderId) {
            return;
        }

        setLocation(slicedLocation || []);
        navigate(`/docs/folder/${id}`);
    };

    return (
        <div ref={containerRef} className="flex items-center gap-1 max-w-full overflow-x-auto pb-1">
            <button
                onClick={handleNavigateToIndex}
                className="flex items-center gap-1 font-body-medium text-body-regular-xs text-neutral-300"
            >
                <p>Docs</p>
                <ArrowRightSLineIcon width={10} height={10} color="#5E6470" />
            </button>

            {location?.map((item, index) => {
                const isHidden =
                    !isDesktop && index < location.length - 1 && !isFullLocationDisplayed;

                return (
                    <FolderBreadcrumbItem
                        key={item.id}
                        item={item}
                        index={index}
                        folderId={folderId}
                        isHidden={isHidden}
                        setIsFullLocationDisplayed={setIsFullLocationDisplayed}
                        handleItemClick={handleItemClick}
                    />
                );
            })}
        </div>
    );
};

export default FolderBreadcrumbs;
