import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { handleCopyFormattedText } from '../../helpers/handleCopyFormattedText';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';

import Alert from '../Alert/Alert';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import NewRichTextEditor from './NewRichTextEditor/NewRichTextEditor';
import { SvgIcon } from '../index';
import { ErrorWarningLineIcon, FileCopyLineIcon } from '../Icons';

const NewRichTextArea = ({
    value,
    setValue,
    state = 'default',
    label,
    isRequired = false,
    errorMessage,
    tipText = '',
    withCopyButton = false,
    labelToCopyAlert,
    placeholder = 'Write here',
    additionalButtons = null,
    minHeight = 120,
    autoExpand = false,
    withDeleteButton = false,
    handleDelete,
    darkBorderColor = false,
    onBlur = () => {},
    useInternalMobileViewState = true,
    isMobileViewActive = false,
}) => {
    const { isMobile } = useResponsiveBreakpoints({ maxMobileWidth: 640 });

    const [copyAlert, setCopyAlert] = useState(null);

    const copyFormattedTextToClipboard = () => {
        const node = document.querySelector('.mdxeditor-rich-text-editor');
        if (node) {
            node.classList.add('custom-markdown-editor');
            handleCopyFormattedText({
                node,
                setCopyAlert,
                successMessage: `${labelToCopyAlert || label || ''} copied to clipboard!`,
            });
            node.classList.remove('custom-markdown-editor');
        }
    };

    const withActionButtons = withCopyButton || !!additionalButtons;

    const isMobileView = useInternalMobileViewState ? isMobile : isMobileViewActive;

    return (
        <div className="flex-grow flex flex-col gap-1.5 h-full">
            {(label || withActionButtons) && (
                <div
                    className={classNames(
                        'flex items-center',
                        label ? 'justify-between' : 'justify-end'
                    )}
                >
                    {label && (
                        <p className="font-body-bold text-body-bold-s text-neutral-500">
                            {label}
                            {isRequired && <sup className="text-red-500 leading-1 ml-0.5">*</sup>}
                        </p>
                    )}
                    {withActionButtons && (
                        <div className="flex items-center gap-2">
                            {additionalButtons}
                            {withCopyButton && (
                                <ButtonIcon
                                    type="link"
                                    size="xs"
                                    state={!value ? 'disabled' : 'default'}
                                    icon={FileCopyLineIcon}
                                    onClick={copyFormattedTextToClipboard}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}

            <NewRichTextEditor
                value={value}
                setValue={setValue}
                state={state}
                placeholder={placeholder}
                autoExpand={autoExpand}
                minHeight={minHeight}
                withDeleteButton={withDeleteButton}
                handleDelete={handleDelete}
                darkBorderColor={darkBorderColor}
                onBlur={onBlur}
                isMobileViewActive={isMobileView}
            />

            {state === 'error' && errorMessage && <ErrorMessage message={errorMessage} />}

            {tipText !== '' && state !== 'error' && (
                <div className="flex items-center gap-1">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                    <p className="text-extraSmall text-neutral-300 leading-1.25">{tipText}</p>
                </div>
            )}

            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </div>
    );
};

NewRichTextArea.propTypes = {
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func,
    state: PropTypes.oneOf(['default', 'disabled', 'error']),
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    withCopyButton: PropTypes.bool,
    labelToCopyAlert: PropTypes.string, // if you don't pass label, but want to use copy button in copySuccessAlert labelToCopyAlert will be displayed
    placeholder: PropTypes.string,
    additionalButtons: PropTypes.node,
    minHeight: PropTypes.number,
    autoExpand: PropTypes.bool,
    withDeleteButton: PropTypes.bool,
    handleDelete: PropTypes.func,
    darkBorderColor: PropTypes.bool,
    onBlur: PropTypes.func,
    useInternalMobileViewState: PropTypes.bool,
    isMobileViewActive: PropTypes.bool,
};

export default NewRichTextArea;
