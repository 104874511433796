import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/assistant-api';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { handlePageDataLoadError } from '../../../helpers/handlePageDataLoadError';
import { useWrongOrgOrViewTypeNavBlocker } from '../../../hooks/useWrongOrgOrViewTypeNavBlocker';
import { useSwrRequestKeysToMutateFromContext } from '../../../hooks/useSwrRequestKeysToMutateFromContext';

import BotDetailHeader from './BotDetailHeader/BotDetailHeader';
import Loading from '../../../components/Loading';
import BotActionModal from '../BotActionModal/BotActionModal';
import DeleteBotModal from '../DeleteBotModal/DeleteBotModal';
import BotDetailMainContent from './BotDetailMainContent/BotDetailMainContent';

const BotDetailPage = () => {
    const { botId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const backlinkHref = location.state?.from ?? '/bots';

    const [botDetail, setBotDetail] = useState(null);

    const [actionModal, setActionModal] = useState({ action: null });
    const {
        requestKeysToMutate: { bots: requestKeysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    useEffect(() => {
        const fetchDocDetail = async () => {
            try {
                const { data } = await client.get(`${API.ROUTES.assistant.customBot}${botId}/`);
                setBotDetail(data);
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    setIsWrongOrg,
                    setRedirectPath,
                    redirectPath: '/bots',
                    generalErrorHandler: () => navigate(backlinkHref),
                });
            }
        };

        fetchDocDetail();
    }, [botId]);

    useDocumentTitle(botDetail?.name);

    return (
        <div className="page-position bg-neutral-50 flex flex-col overflow-y-auto pb-8 lg:pb-0">
            {botDetail && (
                <>
                    <BotDetailHeader
                        botDetail={botDetail}
                        backlinkHref={backlinkHref}
                        setActionModal={setActionModal}
                    />

                    <BotDetailMainContent botDetail={botDetail} setBotDetail={setBotDetail} />

                    {actionModal.action === 'edit' && (
                        <BotActionModal
                            id={botId}
                            mode="edit"
                            botData={{ name: botDetail.name, description: botDetail.description }}
                            requestKeysToMutate={requestKeysToMutate}
                            setBotDetail={setBotDetail}
                            onClose={() => setActionModal({ action: null })}
                        />
                    )}

                    {actionModal.action === 'delete' && (
                        <DeleteBotModal
                            id={botId}
                            botName={botDetail.name}
                            requestKeysToMutate={requestKeysToMutate}
                            onDeleteSuccess={() => navigate(backlinkHref)}
                            onClose={() => setActionModal({ action: null })}
                        />
                    )}
                </>
            )}

            {!botDetail && (
                <div className="flex-1 flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default BotDetailPage;
