import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../Alert/Alert';
import CheckLineIcon from '../Icons/CheckLineIcon';
import ErrorWarningLineIcon from '../Icons/ErrorWarningLineIcon';

const CopyAlert = ({ copyAlert, setCopyAlert, autoCloseInMS = 3000 }) => {
    const IconFallback = {
        positive: CheckLineIcon,
        critical: ErrorWarningLineIcon,
    }[copyAlert?.status];

    return (
        <>
            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon || IconFallback}
                    autoCloseInMS={autoCloseInMS}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
        </>
    );
};

CopyAlert.propTypes = {
    copyAlert: PropTypes.shape({
        status: PropTypes.oneOf(['positive', 'critical']).isRequired,
        message: PropTypes.string.isRequired,
        icon: PropTypes.elementType,
    }),
    setCopyAlert: PropTypes.func.isRequired,
    autoCloseInMS: PropTypes.number,
};

export default CopyAlert;
