import React from 'react';
import { DateTime } from 'luxon';

import UserBadge from '../../../../design-system/UserBadge/UserBadge';
import RichTextArea from '../../../../design-system/RichTextArea/RichTextArea';

const FeedbackCard = ({ feedback }) => {
    const { created_by, created_at, value } = feedback;

    const name = created_by?.first_name || created_by?.email;
    const formattedTime = DateTime.fromISO(created_at).toFormat('MM/dd/yyyy - hh:mm a');

    return (
        <div className="flex flex-col gap-1">
            <UserBadge name={name} />

            <div className="pl-6 flex flex-col gap-1">
                <p className="font-body text-body-regular-xs text-neutral-300">{formattedTime}</p>

                {!!value && <RichTextArea value={value} previewOnly autoExpand isBorderHidden />}

                {!value && (
                    <p className="font-body text-body-regular-s text-neutral-200 italic">
                        No content
                    </p>
                )}
            </div>
        </div>
    );
};

export default FeedbackCard;
