import React from 'react';

const Message2LineIcon = ({ width, height, color }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
            <path
                d="M4.30295 12.6667L1.33325 15V2.66667C1.33325 2.29848 1.63173 2 1.99992 2H13.9999C14.3681 2 14.6666 2.29848 14.6666 2.66667V12C14.6666 12.3682 14.3681 12.6667 13.9999 12.6667H4.30295ZM3.8418 11.3333H13.3333V3.33333H2.66659V12.2567L3.8418 11.3333ZM7.33325 5.33333H8.66659V9.33333H7.33325V5.33333ZM5.66659 6.66667H7.33325V7.33333V8H5.66659V6.66667ZM8.66659 6.66667H10.3333V8H8.66659V6.66667Z"
                fill={color}
            />
        </svg>
    );
};

export default Message2LineIcon;
