import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';
import App from './App';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ViewTypeProvider } from './contexts/viewTypeContext';
import { SwrRequestKeysToMutateProvider } from './contexts/swrRequestKeysToMutate';
import { NavigationRestrictionProvider } from './contexts/navigationRestrictionContext';
import { WrongOrgOrViewTypeNavBlockerProvider } from './contexts/wrongOrgOrViewTypeNavBlockerContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <ViewTypeProvider>
        <WrongOrgOrViewTypeNavBlockerProvider>
            <SwrRequestKeysToMutateProvider>
                <NavigationRestrictionProvider>
                    <DndProvider backend={HTML5Backend}>
                        <App />
                    </DndProvider>
                </NavigationRestrictionProvider>
            </SwrRequestKeysToMutateProvider>
        </WrongOrgOrViewTypeNavBlockerProvider>
    </ViewTypeProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
