import React from 'react';

import MilestonesBoardRowView from '../MilestonesBoardRowView/MilestonesBoardRowView';
import MilestonesBoardColumnView from '../MilestonesBoardColumnView/MilestonesBoardColumnView';

const MilestonesBoard = ({ milestones, view, entityName, pageType }) => {
    const commonProps = { milestones, entityName, pageType };

    if (view === 'row') {
        return <MilestonesBoardRowView {...commonProps} />;
    }

    if (view === 'column') {
        return (
            <div className="relative flex-grow">
                <MilestonesBoardColumnView {...commonProps} />
            </div>
        );
    }

    return <></>;
};

export default MilestonesBoard;
