import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';

export const settingsRoutes = [
    {
        path: '/settings',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.SettingsAndOrganizationsPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
        },
    },
    {
        path: '/settings/organization/:organizationId',
        indexPath: '/settings',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.SettingsAndOrganizationsPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
        },
    },
];
