import React from 'react';
import classNames from 'classnames';

import Loading from '../../../components/Loading';
import OrganizationTag from '../../../design-system/OrganizationTag/OrganizationTag';

const OrganizationListOption = ({ label, handleSelect, isCurrent, isLoading }) => {
    const className = classNames('max-w-full p-2 rounded-2 hover:bg-neutral-50 transition-colors', {
        'cursor-pointer': !isLoading && !isCurrent,
        'bg-neutral-50': isLoading,
    });

    return (
        <button disabled={isCurrent} className={className} onClick={handleSelect}>
            {!isLoading && (
                <OrganizationTag name={label} size="sm" color={isCurrent ? 'neutral' : 'purple'} />
            )}
            {isLoading && (
                <div className="flex items-center">
                    <div className="w-[26px] h-[26px] min-w-[26px] flex items-center justify-center rounded-2 bg-purple-500 border-1 border-neutral-200 font-body text-body-bold-m text-white uppercase">
                        {label?.[0]}
                    </div>
                    <div className="flex-1 flex items-center justify-center">
                        <Loading size="small" withText={false} />
                    </div>
                </div>
            )}
        </button>
    );
};

export default OrganizationListOption;
