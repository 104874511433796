import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../constants/organization';

export const configureRoutes = [
    {
        path: '/configure',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ConfigurePage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/configure/process/new',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.CreateNewProcessPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/configure/process/:processId',
        indexPath: '/configure',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ProcessDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/configure/process/:processId/goal/new',
        indexPath: '/configure',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.GoalDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/configure/process/:processId/goal/:goalId',
        indexPath: '/configure',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.GoalDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/configure/process/:processId/goal/template/:goalTemplateId',
        indexPath: '/configure',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.CustomizeGoalPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/configure/scenario/:scenarioId',
        indexPath: `/configure`,
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ScenarioDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
];
