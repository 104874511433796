import React, { useState } from 'react';

import OutputCard from '../OutputCard/OutputCard';
import ProjectVariableLayout from '../ProjectVariableLayout/ProjectVariableLayout';

const ProjectOutputsBlock = ({ outputs }) => {
    const [activeOutput, setActiveOutput] = useState(null);

    const areOutputs = !!outputs?.length;

    return (
        <ProjectVariableLayout
            title="Project Outputs"
            description="Review outputs as they’re filled in real-time"
            activeVariable={activeOutput}
            setActiveVariable={setActiveOutput}
        >
            {areOutputs && (
                <div className="flex flex-col gap-3 overflow-y-auto">
                    {outputs.map((output) => (
                        <OutputCard
                            key={output.id}
                            output={output}
                            isActive={activeOutput?.id === output.id}
                            setOutputValuePanel={setActiveOutput}
                        />
                    ))}
                </div>
            )}

            {!areOutputs && (
                <p className="font-body text-body-regular-m text-neutral-500">
                    No project outputs found
                </p>
            )}
        </ProjectVariableLayout>
    );
};

export default ProjectOutputsBlock;
