import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { useOverlayState } from '../../../hooks/useOverlayState';

import { ButtonIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import BaseMoreOptionsPopup from '../BaseMoreOptionsPopup/BaseMoreOptionsPopup';
import EditBaseModal from '../EditBaseModal/EditBaseModal';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import HighlightedIcon from '../../../design-system/HighlightedIcon/HighlightedIcon';
import ArchiveLineIcon from '../../../design-system/Icons/ArchiveLineIcon';
import ClockIcon from '../../../design-system/Icons/ClockIcon';
import TimestampDisplay from '../../../design-system/TimestampDisplay/TimestampDisplay';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';

const BaseCard = ({ base, hasEditAccess, requestKeysToMutate }) => {
    const { id, name, description, records, updated_at } = base;

    const location = useLocation();

    const { isOpened: isPopupOpened, open: openPopup, close: closePopup } = useOverlayState();
    const { isOpened: isEditModalOpened, open: openModal, close: closeModal } = useOverlayState();

    const formattedDescription = description || `This is a knowledge base for ${name}`;

    const nameContainerClassName = classNames(
        'max-w-[calc(100%-40px)] max-w-[calc(100%-40px)]',
        hasEditAccess && 'pr-1'
    );

    return (
        <li className="content-stretch">
            <Link
                to={`/base/${id}`}
                state={{ from: location }}
                className="h-full flex flex-col gap-4 justify-between p-5 rounded-[6px] border-1 border-neutral-100 bg-white relative max-w-full"
            >
                {hasEditAccess && (
                    <div className="absolute top-[6px] right-1 z-10">
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={More2FillIcon}
                            onClick={openPopup}
                        />
                        {isPopupOpened && (
                            <BaseMoreOptionsPopup
                                baseId={id}
                                openEditModal={openModal}
                                onClose={closePopup}
                            />
                        )}
                    </div>
                )}

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                        <HighlightedIcon
                            icon={ArchiveLineIcon}
                            variant="neutral"
                            customIconColor="#754DCF"
                        />

                        <div className={nameContainerClassName}>
                            <ToolTip
                                text={name}
                                position="top-left"
                                shift="1px"
                                useInternalHoverStateToShowToolTip
                            >
                                <p className="max-w-full font-body-bold text-body-bold-s text-black truncate">
                                    {name}
                                </p>
                            </ToolTip>
                        </div>
                    </div>

                    <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-1">
                        {formattedDescription}
                    </p>
                </div>

                <div className="flex items-center justify-between gap-2 overflow-hidden">
                    <div className="flex items-center gap-1 flex-grow overflow-hidden">
                        <div className="min-w-[14px]">
                            <ClockIcon width={14} height={14} color="#754DCF" />
                        </div>

                        <TimestampDisplay
                            label="Last Updated"
                            timestamp={updated_at}
                            dateTimeFormat="MM/dd/yyyy"
                            customLabelStyles="text-neutral-500"
                        />
                    </div>

                    <div className="flex items-center gap-1 font-body text-body-regular-xs text-neutral-500">
                        <FileTextLineIcon width={14} height={14} color="#754DCF" />
                        {records?.toLocaleString()}
                    </div>
                </div>
            </Link>

            {isEditModalOpened && (
                <EditBaseModal
                    id={id}
                    name={name}
                    description={description || ''}
                    requestKeysToMutate={requestKeysToMutate}
                    onClose={closeModal}
                />
            )}
        </li>
    );
};

export default memo(BaseCard);
