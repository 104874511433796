import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../../services/knowledge-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { useClickOutside } from '../../../hooks';
import { useDocsPageContext } from '../../../hooks/useDocsPageContext';
import { useEscapeKeyListener } from '../../../hooks/useEscapeKeyListener';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';
import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';

import { CONTENT_TYPE, ROUTE } from '../../../constants/docs';
import {
    getFoldersDataFromPromiseAllSettledResponse,
    mutateCashedDataAfterMoveAction,
    showErrorAlertIfRequestError,
} from '../../../helpers/docsUtils';
import {
    handleExpandSidePanelFolder,
    updateSidePanelStateAfterFoldersInsert,
    updateSidePanelStateAfterRemovingFolders,
} from '../../../helpers/docsSidePanelUtils';

import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import MobilePopup from '../../../design-system/MobilePopup/MobilePopup';
import InfiniteScrollList from '../../../components/InfiniteScrollList/InfiniteScrollList';
import ClientSideFilteredSearchBar from '../../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import MoveToFolderItem from '../MoveToFolderItem/MoveToFolderItem';
import Modal from '../../../design-system/Modal/Modal';

const DOCS_OPTION = {
    id: 'root-folder',
    label: 'Docs',
    location: [],
    isRootFolder: true,
};

const MoveToPanel = ({
    itemsToMove,
    clearAllSelectedItems,
    requestKeysToMutate,
    currentRequestKeyToMutate,
    viewType = 'modal', // 'modal' | 'popup'
    onClose,
}) => {
    const panelRef = useRef();

    useClickOutside(panelRef, handleClose);
    useEscapeKeyListener(onClose);

    const [loadingFolderId, setLoadingFolderId] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const {
        options: folders,
        optionsLoading,
        canLoadMoreOptions,
        isFirstRequestCompleted,
        setPage,
    } = useFetchOptionsForPaginatedSelect({
        client,
        route: API.ROUTES.knowledge.folder,
        formatResponseToOptions: (results) =>
            results.map(({ id, label, location }) => ({ id, label, location })),
    });

    const foldersWithRootFolder = useMemo(() => {
        const foldersToMoveIds = Object.values(itemsToMove || {})
            .filter(({ content_type }) => content_type === CONTENT_TYPE.folder)
            .map(({ id }) => id);
        const foldersOptionsWithoutFoldersToMove =
            folders?.filter(({ id }) => !foldersToMoveIds.includes(id)) || [];
        return [DOCS_OPTION, ...foldersOptionsWithoutFoldersToMove];
    }, [folders, itemsToMove]);
    const [filteredFolders, setFilteredFolders] = useState(folders);

    const {
        location: currentPageLocation,
        setSidePanelFolders,
        setExpandedFolders,
    } = useDocsPageContext() || {};

    const handleMove = async (targetFolder) => {
        if (!itemsToMove) return;

        try {
            // on [] location we are the root folder and search results
            const isSameFolder =
                !!currentPageLocation?.length &&
                JSON.stringify(targetFolder.location) === JSON.stringify(currentPageLocation);

            if (isSameFolder) {
                onClose();
                return;
            }

            setLoadingFolderId(targetFolder.id);
            const itemsToMoveArray = Object.values(itemsToMove) || [];

            const data = await Promise.allSettled(
                itemsToMoveArray?.map(({ id, content_type }) => {
                    const route = ROUTE[content_type];
                    const targetFolderId = targetFolder.isRootFolder ? null : targetFolder.id;
                    const requestBody =
                        content_type === CONTENT_TYPE.folder
                            ? { parent: targetFolderId }
                            : { folder: targetFolderId };
                    return client.patch(`${route}${id}/`, requestBody);
                })
            );

            showErrorAlertIfRequestError({ data, setErrorAlert });

            await mutateCashedDataAfterMoveAction({
                requestKeysToMutate,
                currentRequestKeyToMutate,
                currentPageLocation,
                targetFolderLocation: targetFolder.location,
            });

            if (setSidePanelFolders) {
                const movedFolders = getFoldersDataFromPromiseAllSettledResponse({
                    data,
                    selectedItemsArray: itemsToMoveArray,
                });

                const movedFoldersIds = movedFolders.map(({ id }) => id);

                updateSidePanelStateAfterRemovingFolders({
                    removedFoldersIds: movedFoldersIds,
                    parentLocation: currentPageLocation,
                    setSidePanelFolders,
                });

                updateSidePanelStateAfterFoldersInsert({
                    insertedFolders: movedFolders,
                    parentLocation: targetFolder.location,
                    setSidePanelFolders,
                });

                handleExpandSidePanelFolder({
                    setExpandedFolders,
                    location: targetFolder.location?.map(({ id }) => id),
                });
            }

            if (clearAllSelectedItems) {
                clearAllSelectedItems();
            }
            onClose();
        } catch (e) {
            setLoadingFolderId(null);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    const { isMobile } = useResponsiveBreakpoints();

    function handleClose(e) {
        e?.stopPropagation();
        e?.preventDefault();
        onClose();
    }

    const topFixedContent = (
        <div className="flex flex-col">
            <ModalHeader
                onClose={onClose}
                title="Move To"
                outlined
                withEscapeButton={!isMobile}
                titleStyles="font-heading-bold text-heading-bold-s text-neutral-500"
                customPaddings="py-3 px-5"
            />

            <div className="pt-5 px-5">
                <ClientSideFilteredSearchBar
                    size="xs"
                    list={foldersWithRootFolder}
                    setFilteredList={setFilteredFolders}
                    keyToFilter={['label']}
                />
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );

    const foldersListClassName = classNames('flex-1 pt-4 mt-2 px-5 overflow-y-auto', {
        'md:pb-3': !!filteredFolders?.length,
        'min-h-[38px]': !filteredFolders?.length && !optionsLoading,
        'min-h-[110px]': !isFirstRequestCompleted && optionsLoading,
        'min-h-[70px]': !!isFirstRequestCompleted && optionsLoading,
    });

    const foldersList = (
        <div className={foldersListClassName}>
            <InfiniteScrollList
                handleFetch={() => setPage((page) => page + 1)}
                canLoadMore={canLoadMoreOptions}
                items={filteredFolders}
                loading={optionsLoading}
                showLoader={!loadingFolderId}
                gap={0}
            >
                <div className="flex flex-col gap-1">
                    {filteredFolders?.map((folder) => {
                        const { id } = folder;
                        const isLoading = loadingFolderId === id;

                        return (
                            <MoveToFolderItem
                                key={id}
                                folder={folder}
                                isLoading={isLoading}
                                isDisabled={!!loadingFolderId}
                                handleMove={() => handleMove(folder)}
                            />
                        );
                    })}
                </div>
            </InfiniteScrollList>
        </div>
    );

    if (isMobile) {
        return (
            <MobilePopup
                onClose={onClose}
                closeButtonText="Cancel"
                minHeight="90vh"
                maxHeight="90vh"
                containerCustomPaddings="pb-4"
                contentCustomPaddings="pt-0"
                bottomButtonsCustomPaddings="px-5 pt-3"
                topFixedContent={topFixedContent}
                customGap={8}
            >
                <div className="flex flex-col bg-white">{foldersList}</div>
            </MobilePopup>
        );
    }

    if (viewType === 'popup') {
        return (
            <div className="absolute top-[36px] right-0 z-50">
                <div
                    ref={panelRef}
                    className="w-[548px] flex flex-col bg-white rounded-2 border-1 border-neutral-200 shadow-l1 overflow-y-auto max-h-[416px]"
                >
                    {topFixedContent}
                    {foldersList}
                </div>
            </div>
        );
    }

    return (
        <Modal size="semiMedium" onClose={onClose} resetPadding>
            <div className="flex flex-col overflow-y-auto max-h-[369px]">
                {topFixedContent}
                {foldersList}
            </div>
        </Modal>
    );
};

export default MoveToPanel;
