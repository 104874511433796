import { ORGANIZATION_PLAN } from '../constants/organization';
import { resolvePathAndPermissionsForSpecificPath } from '../router/routes/routes';

export const checkRouteViewTypeAccess = (viewType, availableViewTypes = []) => {
    return availableViewTypes.some((availableViewType) => viewType === availableViewType);
};

export const checkOrgPlanAndUserRolePermissions = (
    orgPlan,
    userRole,
    allowedPlansAndRoles,
    needsSpecialPermission
) => {
    return needsSpecialPermission && orgPlan && userRole
        ? !!allowedPlansAndRoles?.find(
              (item) => item.plan === orgPlan && item.roles?.includes(userRole)
          )
        : true;
};

export const isViewTypeSwitchAllowed = ({ orgPlan, user }) => {
    return orgPlan === ORGANIZATION_PLAN.agency && user?.is_staff;
};

export function isRouteAvailableForOrganization({
    orgPlan,
    userRole,
    isStaff,
    path,
    user,
    viewType,
}) {
    const { dynamicPath, permissionRules } = resolvePathAndPermissionsForSpecificPath(path);

    if (!dynamicPath) return { hasPageAccess: false, hasViewTypesAccess: true };

    const hasSpecialPermission = checkOrgPlanAndUserRolePermissions(
        orgPlan,
        userRole,
        permissionRules.allowedPlansAndRoles,
        permissionRules.needsSpecialPermission
    );
    const hasStaffAccess = permissionRules.availableForStaffOnly && user ? isStaff : true;
    const hasViewTypesAccess = checkRouteViewTypeAccess(
        viewType,
        permissionRules.availableViewTypes
    );

    const hasPageAccess = hasSpecialPermission && hasStaffAccess && hasViewTypesAccess;
    return { hasPageAccess, hasViewTypesAccess };
}
