import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SvgIcon } from '../../design-system';
import DiscussLineIcon from '../../design-system/Icons/DiscussLineIcon';

const FeedbackBadge = ({
    count = 0,
    type = 'badge',
    onClick = () => {},
    highlighted = false,
    withHover = false,
}) => {
    const wrapperClassName = classNames(
        'relative flex items-center justify-center w-[24px] min-w-[24px] h-[24px] rounded-2 transition-colors outline-0 focus:outline-none',
        highlighted ? 'bg-purple-100 text-purple-500' : 'bg-transparent text-neutral-500',
        withHover && 'hover:bg-purple-100 hover:text-purple-500'
    );

    const content = (
        <>
            <SvgIcon color="currentColor" icon={DiscussLineIcon} size="medium" />

            {!!count && (
                <span className="absolute top-[1px] right-[-3px] min-w-[10px] w-fit h-[10px] p-0.5 rounded-full bg-red-500 text-[8px] text-white font-medium font-body inline-flex justify-center items-center">
                    {count}
                </span>
            )}
        </>
    );

    if (type === 'button') {
        return (
            <button className={wrapperClassName} onClick={onClick}>
                {content}
            </button>
        );
    }

    return <div className={wrapperClassName}>{content}</div>;
};

FeedbackBadge.propTypes = {
    count: PropTypes.number,
    type: PropTypes.oneOf(['badge', 'button']),
    onClick: PropTypes.func,
    highlighted: PropTypes.bool,
    withHover: PropTypes.bool,
};

export default FeedbackBadge;
