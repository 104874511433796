import React, { memo } from 'react';

import { integerRegex } from '../../../../constants/regex_patterns';
import {
    SETTING_TYPE,
    SHARING_STATE,
    SETTING_ACTION_TYPE,
    ACTION_TYPE_PLACEHOLDER,
    EMPTY_FIELD_ERROR_MESSAGE,
    NEW_SUGGESTION_ACCESS_STATE,
    DEFAULT_HANDLING_ACTION_TYPES,
} from '../../../../constants/settingsForm';

import Radio from '../../../../design-system/Radio/Radio';
import Toggle from '../../../../design-system/Toggle/Toggle';
import Checkbox from '../../../../design-system/Checkbox/Checkbox';
import MultiSelect from '../../../../design-system/MultiSelect/MultiSelect';
import OptionsInput from '../../../../design-system/OptionsInput/OptionsInput';
import NewRichTextArea from '../../../../design-system/NewRichTextArea/NewRichTextArea';
import JsonTextareaBox from '../../../../design-system/JsonTextareaBox/JsonTextareaBox';
import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import MultiDatabaseInput from '../../../../design-system/MultiDatabaseInput/MultiDatabaseInput';
import NewSuggestionBlock from '../NewSuggestionBlock/NewSuggestionBlock';
import SingleDatabaseInput from '../../../../design-system/SingleDatabaseInput/SingleDatabaseInput';
import SingleFileInputType from '../../../../components/SingleFileInputType/SingleFileInputType';
import SettingItemSharingBlock from '../SettingItemSharingBlock/SettingItemSharingBlock';
import NestedInput from '../NestedInput/NestedInput';
import JsonEditorDisabledState from '../../../../design-system/JsonEditor/JsonEditorDisabledState/JsonEditorDisabledState';
import { Input, Select, TextArea } from '../../../../design-system';

const SettingFormField = ({
    setting,
    isFormDisabled,
    setFormData,
    sharingState,
    newSuggestionAccessState = NEW_SUGGESTION_ACCESS_STATE.off,
    processId, // is used for new suggestion logic
    settingType = SETTING_TYPE.agent, // is used for new suggestion logic
    shouldShowHiddenSettings,
}) => {
    const {
        name,
        label,
        description,
        type,
        action,
        is_required,
        options,
        value,
        state: _state,
        errorMessage: _errorMessage,
        is_hidden = false,
        details,
        is_shared,
        placeholder,
        constraints,
    } = setting;

    if (!shouldShowHiddenSettings && is_hidden) {
        return <></>;
    }

    const formattedOptions =
        options &&
        Object.keys(options)?.map((item) => ({
            id: options[item],
            name: item,
        }));

    const state = isFormDisabled ? 'disabled' : _state;
    const errorMessage = _errorMessage || EMPTY_FIELD_ERROR_MESSAGE[action];

    const defaultPlaceholder = ACTION_TYPE_PLACEHOLDER[action] || `Fill this input`;
    const displayedPlaceholder = placeholder || defaultPlaceholder;

    let infoTag = null;
    if (action === SETTING_ACTION_TYPE.single_file && (constraints?.max || constraints?.min)) {
        const min = constraints?.min ? `Min: ${constraints.min} MB` : '';
        const max = constraints?.max ? `Max: ${constraints.max} MB` : '';
        infoTag = (
            <p className="font-body text-body-regular-xs text-neutral-300">
                {min}
                {!!min && !!max && ','} {max}
            </p>
        );
    }

    const handleFormItemChange = (cb) => {
        setFormData((prevData) =>
            prevData.map((item) => {
                if (item.name === name) {
                    const updatedItem = typeof cb === 'function' ? cb(item) : cb;
                    return { ...updatedItem, state: 'default', errorMessage: null };
                }

                return item;
            })
        );
    };

    const handleInputChange = (value) => {
        handleFormItemChange((setting) => ({ ...setting, value }));
    };

    const handleIntegerInputChange = (event) => {
        const inputValue = event.target.value;

        if (integerRegex.test(inputValue)) {
            handleFormItemChange((setting) => ({ ...setting, value: +inputValue }));
        }
    };

    const handleAddNewOption = (newOption) => {
        handleFormItemChange((setting) => {
            const normalizedValue = Array.isArray(setting.value) ? setting.value : [];
            const value = [...normalizedValue, newOption];
            return { ...setting, value };
        });
    };

    const handleRemoveOption = (optionToRemove) => {
        if (state === 'disabled') {
            return;
        }

        handleFormItemChange((setting) => ({
            ...setting,
            value: setting.value?.filter((option) => option !== optionToRemove),
        }));
    };

    const handleRadioButtonChange = (optionId) => {
        handleFormItemChange((setting) => ({ ...setting, value: optionId }));
    };

    const handleMultiSelectChange = (optionId) => {
        handleFormItemChange((setting) => {
            const newValue = setting.value?.includes(optionId)
                ? setting.value.filter((el) => el !== optionId)
                : [...(setting.value || []), optionId];
            return { ...setting, value: newValue };
        });
    };

    const isSharingStatusDisplayed = sharingState !== SHARING_STATE.unavailable;
    const isSharedPlaceholderDisplayed = is_shared && isSharingStatusDisplayed && placeholder;

    const sharingBlock = isSharingStatusDisplayed && (
        <SettingItemSharingBlock
            setting={setting}
            setFormData={setFormData}
            sharingState={sharingState}
        />
    );

    const isSuggestionBlockDisplayed =
        newSuggestionAccessState === NEW_SUGGESTION_ACCESS_STATE.on ||
        (newSuggestionAccessState === NEW_SUGGESTION_ACCESS_STATE.lockedOnly && !is_shared);
    const newSuggestionBlock = isSuggestionBlockDisplayed && (
        <NewSuggestionBlock setting={setting} settingType={settingType} processId={processId} />
    );

    const actionsBarRightContent = (
        <>
            {newSuggestionBlock} {sharingBlock}
        </>
    );

    return (
        <>
            {DEFAULT_HANDLING_ACTION_TYPES.includes(action) && (
                <FormFieldWrapper
                    label={label}
                    isRequired={is_required}
                    state={state}
                    errorMessage={action !== SETTING_ACTION_TYPE.multi_db ? errorMessage : null}
                    tipText={action !== SETTING_ACTION_TYPE.multi_db ? description : ''}
                    gap={0}
                    bottomSpace={4}
                    labelMarginBottom={7}
                    actionsBarRightContent={actionsBarRightContent}
                    actionsBarLeftContent={infoTag}
                >
                    {action === SETTING_ACTION_TYPE.single_input && type === 'string' && (
                        <Input
                            size="sm"
                            name={name}
                            value={value || ''}
                            placeholder={displayedPlaceholder}
                            state={state}
                            type="text"
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    )}
                    {action === SETTING_ACTION_TYPE.single_input && type === 'integer' && (
                        <Input
                            size="sm"
                            name={name}
                            value={value}
                            placeholder={placeholder || '0'}
                            state={state}
                            onChange={handleIntegerInputChange}
                        />
                    )}
                    {action === SETTING_ACTION_TYPE.single_input && type === 'number' && (
                        <Input
                            size="sm"
                            name={name}
                            value={value}
                            placeholder={placeholder || '0.1'}
                            type="number"
                            step="0.1"
                            state={state}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    )}
                    {action === SETTING_ACTION_TYPE.json_input && (
                        <div className="flex flex-col gap-4 min-h-[200px]">
                            {!isSharedPlaceholderDisplayed && (
                                <JsonTextareaBox
                                    mode="code"
                                    value={value}
                                    state={state}
                                    containerMinHeight={200}
                                    setValue={handleInputChange}
                                />
                            )}

                            {isSharedPlaceholderDisplayed && (
                                <div className="h-[200px]">
                                    <JsonEditorDisabledState autoExpand placeholder={placeholder} />
                                </div>
                            )}
                        </div>
                    )}
                    {action === SETTING_ACTION_TYPE.textarea_input && (
                        <TextArea
                            name={name}
                            value={value || ''}
                            placeholder={displayedPlaceholder}
                            state={state}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    )}
                    {action === SETTING_ACTION_TYPE.richtext_input && (
                        <NewRichTextArea
                            name={name}
                            value={value || ''}
                            placeholder={displayedPlaceholder}
                            state={state}
                            setValue={handleInputChange}
                            autoExpand
                            minHeight={170}
                            darkBorderColor
                        />
                    )}
                    {action === SETTING_ACTION_TYPE.multi_input && (
                        <OptionsInput
                            name={name}
                            options={Array.isArray(value) ? value : []}
                            placeholder={displayedPlaceholder}
                            state={state}
                            handleAddNewOption={handleAddNewOption}
                            handleRemoveOption={handleRemoveOption}
                            containerGap={16}
                            optionsBadgeColor="neutral"
                            optionsBadgeFontStyle="text-body-regular-xs"
                        />
                    )}
                    {action === SETTING_ACTION_TYPE.single_select && (
                        <ul className="flex flex-col gap-1 pt-0.5">
                            {formattedOptions.map((option) => (
                                <li key={option.id} className="py-1 px-2">
                                    <Radio
                                        isSelected={option.id === value}
                                        name={option.id}
                                        label={option.name}
                                        state={state}
                                        onChange={() => handleRadioButtonChange(option.id)}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                    {action === SETTING_ACTION_TYPE.multi_select && (
                        <ul className="flex flex-col gap-1">
                            {formattedOptions.map((option) => (
                                <li key={option.id} className="py-1 px-2">
                                    <Checkbox
                                        name={option.id}
                                        label={option.name}
                                        isChecked={!!value?.includes(option.id)}
                                        state={state}
                                        onChange={() => handleMultiSelectChange(option.id)}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                    {action === SETTING_ACTION_TYPE.single_dropdown && (
                        <Select
                            size="sm"
                            name={name}
                            value={value}
                            options={formattedOptions || []}
                            placeholder={displayedPlaceholder}
                            state={state}
                            onChange={handleInputChange}
                            includeClientSideFiltering
                        />
                    )}
                    {action === SETTING_ACTION_TYPE.multi_dropdown && (
                        <MultiSelect
                            name={name}
                            addedOptions={Array.isArray(value) ? value : []}
                            optionsForSelect={formattedOptions || []}
                            placeholder={displayedPlaceholder}
                            state={state}
                            handleAddNewOption={handleAddNewOption}
                            handleRemoveOption={handleRemoveOption}
                            includeClientSideFiltering
                        />
                    )}
                    {action === SETTING_ACTION_TYPE.single_db && (
                        <SingleDatabaseInput
                            name={name}
                            valueObj={value}
                            details={details}
                            state={state}
                            placeholder={displayedPlaceholder}
                            handleChange={handleFormItemChange}
                        />
                    )}
                    {action === SETTING_ACTION_TYPE.multi_db && (
                        <MultiDatabaseInput
                            name={name}
                            addedOptions={Array.isArray(value) ? value : []}
                            details={details}
                            state={state}
                            tipText={description}
                            errorMessage={errorMessage}
                            handleChange={handleFormItemChange}
                            placeholder={displayedPlaceholder}
                            shouldHideOptionsInfo={isSharedPlaceholderDisplayed}
                        />
                    )}
                    {action === SETTING_ACTION_TYPE.single_file && (
                        <SingleFileInputType
                            value={value}
                            constraints={constraints}
                            state={state}
                            placeholder={displayedPlaceholder}
                            handleChange={handleFormItemChange}
                        />
                    )}
                </FormFieldWrapper>
            )}

            {action === SETTING_ACTION_TYPE.toggle && (
                <FormFieldWrapper
                    state={state}
                    errorMessage={errorMessage}
                    tipText={description}
                    actionsBarRightContent={sharingBlock}
                >
                    <Toggle
                        name={name}
                        label={label}
                        isRequired={is_required}
                        isDisabled={state === 'disabled'}
                        isSelected={value === true}
                        size="sm"
                        onChange={handleInputChange}
                    />
                </FormFieldWrapper>
            )}

            {action === SETTING_ACTION_TYPE.nested_input && (
                <NestedInput
                    setting={{ ...setting, state, errorMessage }}
                    handleNestedInputChange={handleFormItemChange}
                    shouldShowHiddenSettings={shouldShowHiddenSettings}
                    actionsBarRightContent={actionsBarRightContent}
                    isSharedPlaceholderDisplayed={isSharedPlaceholderDisplayed}
                />
            )}
        </>
    );
};

export default memo(SettingFormField);
