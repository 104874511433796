import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import executeClient from '../../services/execute-api';
import organizationClient from '../../services/organization-api';
import templateClient from '../../services/template-api';

import useUser from '../../hooks/useUser';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useWrongOrgOrViewTypeNavBlocker } from '../../hooks/useWrongOrgOrViewTypeNavBlocker';
import { useFetchOptionsForPaginatedSelect } from '../../hooks/useFetchOptionsForPaginatedSelect';

import { SORT_TYPES } from '../../constants/sort';
import { defaultErrorMessage } from '../../constants/errorMessages';
import { handlePageDataLoadError } from '../../helpers/handlePageDataLoadError';

import { ArrowGoBackLineIcon, ErrorWarningLineIcon } from '../../design-system/Icons';
import { Input, TextArea } from '../../design-system';
import Button from '../../design-system/Button/Button';
import PaginatedSelect from '../../design-system/PaginatedSelect/PaginatedSelect';
import OptionsInput from '../../design-system/OptionsInput/OptionsInput';
import FormFieldWrapper from '../../design-system/FormFieldWrapper/FormFieldWrapper';
import FileInput from '../../design-system/FileInput/FileInput';
import DatabaseIcon from '../../design-system/Icons/DatabaseIcon';
import Alert from '../../design-system/Alert/Alert';

const formFieldsName = [
    'organization',
    'name',
    'description',
    'input_keys',
    'output_keys',
    'dataset_file',
];

const CreateNewDatasetPage = () => {
    const { goalTemplateId } = useParams();
    const [goalTemplateData, setGoalTemplateData] = useState(null);

    const { user } = useUser();
    const navigate = useNavigate();

    const [inputData, setInputData] = useState({
        organization: null,
        name: '',
        description: '',
        input_keys: [],
        // output_keys: '',
        output_keys: [],
        dataset_file: null,
    });
    const [errorFieldsMessages, setErrorFieldsMessages] = useState({
        organization: null,
        name: null,
        description: null,
        input_keys: null,
        output_keys: null,
        dataset_file: null,
    });
    const [errorAlert, setErrorAlert] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    const {
        options: organizationOptions,
        optionsLoading,
        canLoadMoreOptions,
        setPage,
        totalOptions: totalOrganizationOptions,
    } = useFetchOptionsForPaginatedSelect({
        client: organizationClient,
        route: API.ROUTES.organization.organizationMembership,
        searchParams: { user: user?.id },
        startRequest: !!user,
        formatResponseToOptions: (results) =>
            results.map(({ organization }) => ({ id: organization.id, name: organization.name })),
    });

    useDocumentTitle('Create Dataset');

    useEffect(() => {
        const fetchGoalTemplateData = async () => {
            try {
                const { data } = await templateClient.get(
                    `${API.ROUTES.template.goalTemplate}${goalTemplateId}/`
                );
                setGoalTemplateData(data);
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    setIsWrongOrg,
                    setRedirectPath,
                    redirectPath: '/templates/goals',
                    generalErrorHandler: () => navigate('/templates/goals'),
                });
            }
        };

        fetchGoalTemplateData();
    }, []);

    const createDataset = async () => {
        const areEmptyFields = formFieldsName.some((key) =>
            // key === 'input_keys' ? !inputData.input_keys?.length : !inputData[key],
            key === 'input_keys' || key === 'output_keys'
                ? !inputData[key]?.length
                : !inputData[key]
        );

        if (areEmptyFields) {
            setErrorFieldsMessages({
                organization: !inputData.organization ? 'Please Select an Organization' : null,
                name: !inputData.name ? 'Please fill in this field.' : null,
                description: !inputData.description ? 'Please fill in this field.' : null,
                input_keys: !inputData.input_keys?.length ? 'Please add Input Keys.' : null,
                output_keys: !inputData.output_keys?.length ? 'Please add Output Keys.' : null,
                // output_keys: !inputData.output_keys ? 'Please fill in this field.' : null,
                dataset_file: !inputData.dataset_file ? 'Please upload a dataset CSV file' : null,
            });
            return;
        }

        try {
            setErrorFieldsMessages({});
            setIsLoading(true);
            const formData = new FormData();

            const { input_keys, output_keys, ...restInputData } = inputData;

            Object.entries(restInputData).forEach(([key, value]) => {
                formData.append(key, value);
            });
            formData.append('input_keys', input_keys.join(','));
            formData.append('output_keys', output_keys.join(','));
            formData.append('goal_template', goalTemplateId);

            await executeClient.post(API.ROUTES.execute.dataset, formData);

            // go to GoalTemplateDatasetsPage with sort == created so user can see the created dataset at the top
            navigate(
                `/templates/goal/${goalTemplateId}/datasets?sort=${SORT_TYPES.created}&page=1`
            );
        } catch (error) {
            const errorObject = error?.response?.data;

            if (errorObject) {
                const errorMessages = {};
                Object.keys(errorObject)?.map((key) => {
                    if (formFieldsName.includes(key)) {
                        // show error message related to a specific field
                        errorMessages[key] = errorObject[key]?.[0];
                    } else if (key === 'error' && typeof errorObject[key] === 'string') {
                        // show error message that not related to any specific field
                        errorMessages.dataset_file = errorObject.error;
                        setErrorAlert({ message: defaultErrorMessage });
                    } else {
                        setErrorAlert({ message: defaultErrorMessage });
                    }
                });

                setErrorFieldsMessages(errorMessages);
            } else {
                setErrorAlert({ message: defaultErrorMessage });
            }
            setIsLoading(false);
        }
    };

    const handleInputChange = (name, value) => {
        setInputData((prevData) => ({ ...prevData, [name]: value }));
        if (errorFieldsMessages[name]) {
            setErrorFieldsMessages((prevMessages) => ({ ...prevMessages, [name]: null }));
        }
    };

    const handleAddInputKey = (newInputKey) => {
        setInputData((prevData) => ({
            ...prevData,
            input_keys: [...prevData.input_keys, newInputKey],
        }));
        if (errorFieldsMessages.input_keys) {
            setErrorFieldsMessages((prevMessages) => ({ ...prevMessages, input_keys: null }));
        }
    };

    const handleRemoveInputKey = (inputKey) => {
        setInputData((prevData) => ({
            ...prevData,
            input_keys: prevData.input_keys?.filter((option) => option !== inputKey),
        }));
    };

    const handleAddOutputKey = (newOutputKey) => {
        setInputData((prevData) => ({
            ...prevData,
            output_keys: [...prevData.output_keys, newOutputKey],
        }));
        if (errorFieldsMessages.output_keys) {
            setErrorFieldsMessages((prevMessages) => ({ ...prevMessages, output_keys: null }));
        }
    };

    const handleRemoveOutputKey = (outputKey) => {
        setInputData((prevData) => ({
            ...prevData,
            output_keys: prevData.output_keys?.filter((option) => option !== outputKey),
        }));
    };

    return (
        <div className="bg-white w-full flex justify-center h-full px-[14px] pb-[40px] min-h-[calc(100vh-64px)]">
            <div className="w-full max-w-[800px] py-5">
                <div className="mb-5">
                    <Button
                        type="link"
                        size="sm"
                        // add the template name to the text
                        text={`Back to ${goalTemplateData?.name || 'Goal Template'}`}
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={() => navigate(`/templates/goal/${goalTemplateId}`)}
                    />
                </div>
                <h1 className="font-heading-bold text-heading-bold-m text-neutral-500 mb-8">
                    Create New Dataset
                </h1>
                <div className="flex flex-col gap-5 mb-8">
                    <PaginatedSelect
                        size="sm"
                        name="organization"
                        options={organizationOptions}
                        optionsLoading={optionsLoading}
                        value={inputData.organization}
                        label="Select an Organization"
                        isRequired
                        placeholder="Select the organization this dataset belongs to"
                        onChange={(value) => handleInputChange('organization', value)}
                        state={errorFieldsMessages.organization ? 'error' : 'default'}
                        errorMessage={errorFieldsMessages.organization}
                        fetchOptions={() => setPage((page) => page + 1)}
                        canLoadMore={canLoadMoreOptions}
                        includeClientSideFiltering
                        totalOptionsCount={totalOrganizationOptions}
                    />
                    <Input
                        size="sm"
                        name="name"
                        value={inputData.name}
                        label="Dataset Name"
                        isRequired
                        placeholder="Give this dataset a name"
                        onChange={(e) => handleInputChange('name', e.target.value)}
                        state={errorFieldsMessages.name ? 'error' : 'default'}
                        errorMessage={errorFieldsMessages.name}
                    />
                    <TextArea
                        name="description"
                        value={inputData.description}
                        label="Dataset Description"
                        isRequired
                        state={errorFieldsMessages.description ? 'error' : 'default'}
                        errorMessage={errorFieldsMessages.description}
                        placeholder="Give this dataset a helpful description"
                        onChange={(e) => handleInputChange('description', e.target.value)}
                        height="80px"
                    />
                    <OptionsInput
                        options={inputData.input_keys}
                        label="Dataset Input Keys"
                        isRequired
                        state={errorFieldsMessages.input_keys ? 'error' : 'default'}
                        errorMessage={errorFieldsMessages.input_keys}
                        placeholder="Add your input key and click + to add them"
                        handleAddNewOption={handleAddInputKey}
                        handleRemoveOption={handleRemoveInputKey}
                        tipText="These must match column names in your CSV file"
                        optionsBadgeColor="neutral"
                        optionsBadgeFontStyle="text-body-regular-xs"
                        parentGap={16}
                    />
                    <OptionsInput
                        options={inputData.output_keys}
                        label="Dataset Output Keys"
                        isRequired
                        state={errorFieldsMessages.output_keys ? 'error' : 'default'}
                        errorMessage={errorFieldsMessages.output_keys}
                        placeholder="Add your output key and click + to add them"
                        handleAddNewOption={handleAddOutputKey}
                        handleRemoveOption={handleRemoveOutputKey}
                        tipText="These must match column names in your CSV file"
                        optionsBadgeColor="neutral"
                        optionsBadgeFontStyle="text-body-regular-xs"
                        parentGap={16}
                    />
                    {/* <Input
                        size="sm"
                        name="output_keys"
                        value={inputData.output_keys}
                        label="Dataset Output Key"
                        isRequired
                        placeholder="Add your output key"
                        onChange={(e) => handleInputChange('output_keys', e.target.value)}
                        state={errorFieldsMessages.output_keys ? 'error' : 'default'}
                        errorMessage={errorFieldsMessages.output_keys}
                        tipText="This must match a column name in your CSV file"
                    /> */}
                    <FormFieldWrapper
                        label="Dataset CSV file"
                        isRequired
                        state={errorFieldsMessages.dataset_file ? 'error' : 'default'}
                        errorMessage={errorFieldsMessages.dataset_file}
                    >
                        <FileInput
                            file={inputData.dataset_file}
                            handleFile={(file) => handleInputChange('dataset_file', file)}
                            formatsArray={['.csv']}
                            placeholder="Drag & drop a file to upload or click to browse"
                        />
                    </FormFieldWrapper>
                </div>
                <Button
                    type="primary"
                    size="sm"
                    text="Create Dataset"
                    leadingIcon={DatabaseIcon}
                    state={isLoading ? 'loading' : 'default'}
                    onClick={createDataset}
                />
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default CreateNewDatasetPage;
