import React, { memo } from 'react';

import { STATUS } from '../../../../constants/statuses';
import { JOB_TASK_BUCKET_STATUS } from '../../../../constants/jobs';

import StatusBuckets from '../StatusBuckets/StatusBuckets';

const MilestonesBoardColumnView = ({ milestones: _milestones, entityName, pageType }) => {
    const milestones = _milestones || [];

    const { pendingMilestones, processingMilestones, completedMilestones } =
        getSortedMilestonesByStatus();

    function getSortedMilestonesByStatus() {
        const { pendingMilestones, processingMilestones, completedMilestones } =
            groupMilestonesByStatusHeader(milestones);

        const allMilestonesLength = milestones.length;
        return {
            pendingMilestones: sortMilestonesByOrder(pendingMilestones, allMilestonesLength),
            processingMilestones: sortMilestonesByOrder(processingMilestones, allMilestonesLength),
            completedMilestones: sortMilestonesByOrder(completedMilestones, allMilestonesLength),
        };
    }

    function groupMilestonesByStatusHeader(milestones) {
        return milestones.reduce(
            (acc, milestone) => {
                switch (milestone.status) {
                    case STATUS.pending:
                        acc.pendingMilestones.push(milestone);
                        break;
                    case STATUS.started:
                        acc.processingMilestones.push(milestone);
                        break;
                    case STATUS.failure:
                        acc.processingMilestones.push(milestone);
                        break;
                    case STATUS.success:
                        acc.completedMilestones.push(milestone);
                        break;
                    default:
                        break;
                }
                return acc;
            },
            {
                pendingMilestones: [],
                processingMilestones: [],
                completedMilestones: [],
            }
        );
    }

    function sortMilestonesByOrder(milestones, allMilestonesLength) {
        return milestones.sort((a, b) => {
            // if order === null consider it as arrayLength to place this item at the bottom of the list
            return (a.order || allMilestonesLength) - (b.order || allMilestonesLength);
        });
    }

    const commonProps = { view: 'column', entityName, pageType };

    return (
        <div className="flex gap-x-5 overflow-x-auto overflow-y-hidden absolute inset-0">
            <StatusBuckets
                bucketStatus={JOB_TASK_BUCKET_STATUS.pending}
                milestones={pendingMilestones || []}
                {...commonProps}
            />
            <StatusBuckets
                bucketStatus={JOB_TASK_BUCKET_STATUS.processing}
                milestones={processingMilestones || []}
                {...commonProps}
            />
            <StatusBuckets
                bucketStatus={JOB_TASK_BUCKET_STATUS.completed}
                milestones={completedMilestones || []}
                {...commonProps}
            />
        </div>
    );
};

export default memo(MilestonesBoardColumnView);
