import React from 'react';

import PendingSuggestionsBanner from '../PendingSuggestionsBanner/PendingSuggestionsBanner';

const PendingSuggestionsBlock = ({ suggestionHookResponse, refreshSettingsForms }) => {
    const { options: suggestions } = suggestionHookResponse;

    const areSuggestions = suggestions?.length > 0;

    return (
        <>
            {areSuggestions && (
                <PendingSuggestionsBanner
                    suggestionHookResponse={suggestionHookResponse}
                    refreshSettingsForms={refreshSettingsForms}
                />
            )}
        </>
    );
};

export default PendingSuggestionsBlock;
