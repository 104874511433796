import { VIEW_TYPES } from './viewTypes';
import { ORGANIZATION_PLAN, USER_ROLE } from './organization';
import HomeIcon from '../design-system/Icons/HomeIcon';
import TerminalBoxLineIcon from '../design-system/Icons/TerminalBoxLineIcon';
import GitBranchLineIcon from '../design-system/Icons/GitBranchLineIcon';
import PlayProcessIcon from '../design-system/Icons/PlayProcessIcon';
import InboxIndicatorIcon from '../design-system/Icons/InboxIndicatorIcon';
import ArchiveLineIcon from '../design-system/Icons/ArchiveLineIcon';
import Share2 from '../design-system/Icons/Share2';
import Settings3LineIcon from '../design-system/Icons/Settings3LineIcon';
import LogoutBoxRLineIcon from '../design-system/Icons/LogoutBoxRLineIcon';
import Search2LineIcon from '../design-system/Icons/Search2LineIcon';
import GlobeIcon from '../design-system/Icons/GlobeIcon';
import FileTextLineIcon from '../design-system/Icons/FileTextLineIcon';
import {
    FlashlightFillIcon,
    FlowChartIcon,
    Robot2LineIcon,
    Sparkling2FillIcon,
} from '../design-system/Icons';
import BoxIcon from '../design-system/Icons/BoxIcon';

export const SIDEBAR_ITEMS = {
    dashboard: {
        path: '/dashboard',
        title: 'Dashboard',
        toolTipLabel: 'Dashboard',
        icon: HomeIcon,
        availableViewTypes: [VIEW_TYPES.client],
        activePathPrefixes: ['dashboard'],
    },
    library: {
        path: '/library',
        title: 'Library',
        toolTipLabel: 'Library',
        icon: TerminalBoxLineIcon,
        availableViewTypes: [VIEW_TYPES.admin],
        activePathPrefixes: ['library'],
    },
    assistant: {
        path: '/assistant',
        title: 'Assistant',
        toolTipLabel: 'Assistant',
        icon: Robot2LineIcon,
        availableViewTypes: [VIEW_TYPES.client],
        activePathPrefixes: ['assistant'],
    },
    configure: {
        path: '/configure',
        title: 'Configure',
        toolTipLabel: 'Configure',
        icon: FlowChartIcon,
        availableViewTypes: [VIEW_TYPES.admin],
        needsSpecialPermission: true,
        allowedPlansAndRoles: [
            {
                plan: ORGANIZATION_PLAN.agency,
                roles: [USER_ROLE.owner, USER_ROLE.admin],
            },
        ],
        activePathPrefixes: ['configure'],
    },
    threads: {
        path: '/threads',
        title: 'Threads',
        toolTipLabel: 'Threads',
        icon: GitBranchLineIcon,
        availableViewTypes: [VIEW_TYPES.admin],
        needsSpecialPermission: true,
        allowedPlansAndRoles: [
            {
                plan: ORGANIZATION_PLAN.agency,
                roles: [USER_ROLE.owner, USER_ROLE.admin],
            },
        ],
        activePathPrefixes: ['thread', 'threads'],
    },
    playbooks: {
        path: '/playbooks',
        title: 'Playbooks',
        toolTipLabel: 'Playbooks',
        icon: PlayProcessIcon,
        availableViewTypes: [VIEW_TYPES.client],
        activePathPrefixes: ['playbook', 'playbooks'],
    },
    inbox: {
        path: '/inbox',
        title: 'Inbox',
        toolTipLabel: 'Inbox',
        icon: InboxIndicatorIcon,
        availableViewTypes: [VIEW_TYPES.client],
        activePathPrefixes: ['inbox'],
    },
    jobs: {
        path: '/jobs',
        title: 'Jobs',
        toolTipLabel: 'Jobs',
        icon: FlashlightFillIcon,
        availableViewTypes: [VIEW_TYPES.client],
        activePathPrefixes: ['job', 'jobs', 'project', 'projects'],
    },
    docs: {
        path: '/docs',
        title: 'Docs',
        toolTipLabel: 'Docs',
        icon: FileTextLineIcon,
        availableViewTypes: [VIEW_TYPES.client],
        activePathPrefixes: ['doc', 'docs'],
    },
    discover: {
        path: '/discover',
        title: 'Discover',
        toolTipLabel: 'Discover',
        icon: GlobeIcon,
        availableViewTypes: [VIEW_TYPES.client],
        activePathPrefixes: ['discover'],
    },
    bases: {
        path: '/bases',
        title: 'Knowledge Sources',
        toolTipLabel: 'Knowledge Sources',
        icon: ArchiveLineIcon,
        availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
        needsSpecialPermission: true,
        allowedPlansAndRoles: [
            {
                plan: ORGANIZATION_PLAN.agency,
                roles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.member],
            },
        ],
        activePathPrefixes: ['bases', 'base'],
    },
    bots: {
        path: '/bots',
        title: 'Bots',
        toolTipLabel: 'Bots',
        icon: BoxIcon,
        availableViewTypes: [VIEW_TYPES.admin],
        needsSpecialPermission: true,
        allowedPlansAndRoles: [
            {
                plan: ORGANIZATION_PLAN.agency,
                roles: [USER_ROLE.owner, USER_ROLE.admin],
            },
        ],
        activePathPrefixes: ['bot', 'bots'],
    },
    templates: {
        path: '/templates/goals',
        title: 'Templates',
        toolTipLabel: 'Templates',
        icon: Sparkling2FillIcon,
        availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
        availableForStaffOnly: true,
        activePathPrefixes: ['templates'],
    },
    settings: {
        path: '/settings',
        title: 'Settings',
        toolTipLabel: 'Settings',
        icon: Settings3LineIcon,
        availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
        activePathPrefixes: ['settings'],
    },
    logout: {
        path: '/logout',
        title: 'Log out',
        toolTipLabel: 'Log out',
        icon: LogoutBoxRLineIcon,
        availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
        activePathPrefixes: ['logout'],
    },
    adminSearch: {
        path: '/search',
        title: 'Search ⌘K',
        toolTipLabel: 'Search ⌘K',
        icon: Search2LineIcon,
        availableViewTypes: [VIEW_TYPES.admin],
        activePathPrefixes: ['search'],
    },
};

export const sideBarBg = {
    [VIEW_TYPES.admin]: 'bg-neutral-500',
    [VIEW_TYPES.client]: 'bg-white',
};

export const navItemIconColor = {
    [VIEW_TYPES.admin]: '#C6B8E7',
    [VIEW_TYPES.client]: '#33373D',
};

export const navActiveItemIconColor = {
    [VIEW_TYPES.admin]: '#C6B8E7',
    [VIEW_TYPES.client]: '#754DCF',
};

export const navItemLabelColor = {
    [VIEW_TYPES.admin]: 'text-purple-300',
    [VIEW_TYPES.client]: 'text-neutral-400',
};

export const mobMenuBgColor = {
    [VIEW_TYPES.admin]: 'bg-neutral-400',
    [VIEW_TYPES.client]: 'bg-neutral-50',
};

export const itemHoverColor = {
    [VIEW_TYPES.admin]: 'hover:bg-neutral-400 ',
    [VIEW_TYPES.client]: 'hover:bg-purple-100',
};

export const logoIconColor = {
    [VIEW_TYPES.admin]: '#D9E56E',
    [VIEW_TYPES.client]: '#754DCF',
};

export const decorationLineColor = {
    [VIEW_TYPES.admin]: 'border-neutral-300',
    [VIEW_TYPES.client]: 'border-neutral-200',
};

export const orgBlockStyles = {
    [VIEW_TYPES.admin]: 'bg-white text-purple-500',
    [VIEW_TYPES.client]: 'bg-purple-500 text-white',
};

export const multiOrgStyles = {
    [VIEW_TYPES.admin]: 'bg-neutral-400 before:bg-neutral-300',
    [VIEW_TYPES.client]: 'bg-neutral-100 before:bg-neutral-200',
};
