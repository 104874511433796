import React from 'react';

import { openLinkInNewTab } from '../../../../helpers/openLinkInNewTab';
import { PLAYBOOK_BUILDER_MODE } from '../../../../constants/playbookBuilder';

import { Badge } from '../../../../design-system';
import { FlowChartIcon } from '../../../../design-system/Icons';
import UserBadge from '../../../../design-system/UserBadge/UserBadge';
import DetailContainer from '../../../../design-system/DetailContainer/DetailContainer';
import PlayProcessIcon from '../../../../design-system/Icons/PlayProcessIcon';
import PlayCircleFillIcon from '../../../../design-system/Icons/PlayCircleFillIcon';
import ArrowRightUpLineIcon from '../../../../design-system/Icons/ArrowRightUpLineIcon';

const JobOrProjectMainDetail = ({ type, process, playbook, scenario, created_by }) => {
    const createdBy = created_by.first_name || created_by.email;

    const handleOpenLink = (e, url) => {
        e?.stopPropagation();
        openLinkInNewTab(url);
    };

    return (
        <>
            {type === 'job' && (
                <DetailContainer label="Agent" maxWidth="60%" desktopStartPixel={1024}>
                    <Badge
                        text={process?.name}
                        color="neutral"
                        leadingIcon={PlayCircleFillIcon}
                        leadingIconColor="#1F2125"
                        trailingIcon={ArrowRightUpLineIcon}
                        trailingIconColor="#5E6470"
                        onBadgeClick={(e) => handleOpenLink(e, `/agent/${process?.id}`)}
                    />
                </DetailContainer>
            )}

            {type === 'project' && (
                <>
                    <DetailContainer label="Playbook" maxWidth="32%" desktopStartPixel={1024}>
                        <Badge
                            text={playbook?.name}
                            color="neutral"
                            leadingIcon={PlayProcessIcon}
                            leadingIconColor="#000000"
                            trailingIcon={ArrowRightUpLineIcon}
                            trailingIconColor="#5E6470"
                            onBadgeClick={(e) =>
                                handleOpenLink(
                                    e,
                                    `/playbook/${playbook?.id}?mode=${PLAYBOOK_BUILDER_MODE.view}`
                                )
                            }
                        />
                    </DetailContainer>
                    <DetailContainer
                        label="Project Scenario"
                        maxWidth="38%"
                        desktopStartPixel={1024}
                    >
                        <Badge
                            text={scenario?.name}
                            color="neutral"
                            leadingIcon={FlowChartIcon}
                            leadingIconColor="#000000"
                            trailingIcon={ArrowRightUpLineIcon}
                            trailingIconColor="#5E6470"
                            onBadgeClick={(e) => handleOpenLink(e, `/scenario/${scenario?.id}`)}
                        />
                    </DetailContainer>
                </>
            )}

            <DetailContainer
                label="Started by"
                maxWidth={type === 'job' ? '30%' : '25%'}
                desktopStartPixel={1024}
            >
                <UserBadge name={createdBy} />
            </DetailContainer>
        </>
    );
};

export default JobOrProjectMainDetail;
