import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';

export const templatesRoutes = [
    {
        path: '/templates/goals',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.TemplatesLibraryPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/processes',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.TemplatesLibraryPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/goal/new',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.CreateNewGoalTemplatePage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/process/new',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.CreateNewProcessTemplatePage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/goal/:goalTemplateId',
        indexPath: '/templates/goals',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.TemplateDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/goal/:goalTemplateId/branch/:versionId',
        indexPath: '/templates/goals',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.TemplateDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/goal/:goalTemplateId/branch/new',
        indexPath: '/templates/goals',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.CreateNewTemplateBranchPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/process/:processTemplateId',
        indexPath: '/templates/processes',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.TemplateDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/process/:processTemplateId/branch/:versionId',
        indexPath: '/templates/processes',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.TemplateDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/process/:processTemplateId/branch/new',
        indexPath: '/templates/processes',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.CreateNewTemplateBranchPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/process/:processTemplateId/publish',
        indexPath: '/templates/processes',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.PublishProcessTemplatePage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/process/:processTemplateId/test',
        indexPath: '/templates/processes',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.RunIntegrationTestPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/goal/:goalTemplateId/datasets',
        indexPath: '/templates/goals',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.GoalTemplateDatasetsAndTestRunsPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/goal/:goalTemplateId/tests',
        indexPath: '/templates/goals',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.GoalTemplateDatasetsAndTestRunsPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/goal/:goalTemplateId/dataset/new',
        indexPath: '/templates/goals',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.CreateNewDatasetPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
    {
        path: '/templates/goal/:goalTemplateId/test',
        indexPath: '/templates/goals',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.CreateUnitTestPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            availableForStaffOnly: true,
        },
    },
];
