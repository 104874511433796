import React, { memo, useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/library-api';

// import CreditsCountBadge from '../../../../components/CreditsCountBadge/CreditsCountBadge';
import { SvgIcon } from '../../../../design-system';
import RichTextArea from '../../../../design-system/RichTextArea/RichTextArea';
import EditLineIcon from '../../../../design-system/Icons/EditLineIcon';
import BlockContainerWithHeader from '../../../../components/BlockContainerWithHeader/BlockContainerWithHeader';
import RichTextFieldEditingState from '../../../../components/RichTextFieldEditingState/RichTextFieldEditingState';

const AboutTabSection = ({ processDetails, setProcessDetails }) => {
    const { id, description, is_shared, default_version } = processDetails;
    // const { cost_per_run } = default_version;

    const isDescriptionEditable = !is_shared;
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        if (isDescriptionEditable && !isEditing) {
            setIsEditing(true);
        }
    };

    const saveDescription = async (description) => {
        if (!isDescriptionEditable) return;

        const { data } = await client.patch(`${API.ROUTES.library.process}${id}/`, {
            description,
        });
        setProcessDetails((prevData) => ({ ...prevData, description: data.description }));
    };

    const topRightContent = (
        <>
            {isDescriptionEditable && (
                <button onClick={handleEditClick}>
                    <SvgIcon color="#1F2125" icon={EditLineIcon} size="large" />
                </button>
            )}
            {/*<CreditsCountBadge cost={cost_per_run} label="job" />*/}
        </>
    );

    return (
        <BlockContainerWithHeader
            title="Agent Overview"
            containerGap={8}
            topRightContent={topRightContent}
        >
            {!isEditing && (
                <RichTextArea
                    value={description || ''}
                    previewOnly
                    autoExpand
                    isBorderHidden
                    minHeight={32}
                />
            )}

            {isEditing && (
                <RichTextFieldEditingState
                    value={description}
                    handleSave={saveDescription}
                    stopEditing={() => setIsEditing(false)}
                />
            )}
        </BlockContainerWithHeader>
    );
};

export default memo(AboutTabSection);
