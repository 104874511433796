import React, { memo, useState } from 'react';

import { ACCESS_TYPE } from '../../constants/accessType';
import { SETTING_TYPE } from '../../constants/settingsForm';

import { WsMultipleLoadingsProvider } from '../../contexts/websoketListeningMultipleLoadingsContext';

import ClientSettingsBlock from './ClientSettingsBlock/ClientSettingsBlock';
import BlockContainerWithHeader from '../BlockContainerWithHeader/BlockContainerWithHeader';
import ProcessOverviewSettingsTabSection from './ProcessOverviewSettingsTabSection/ProcessOverviewSettingsTabSection';

const EMPTY_CONTEXT_MESSAGE = 'No client settings found. Unlock settings above to edit here.';
const SHARED_AGENT_EMPTY_CONTEXT_MESSAGE =
    'No client settings found. Unlock settings in the parent organization to edit here.';

const AgentSettingsTabSection = ({
    processDetail,
    setProcessDetail,
    shouldShowHiddenSettings = false,
    refreshFormsState = null,
}) => {
    const { id, is_shared, access_type, default_version } = processDetail;

    const [contextChangesCount, setContextChangesCount] = useState(0);

    const triggerContextFormStateRefresh = () => {
        setContextChangesCount((prev) => prev + 1);
    };

    const shouldRefreshClientSettings =
        refreshFormsState?.type === SETTING_TYPE.client ? refreshFormsState : null;
    const shouldRefreshAgentSettings =
        refreshFormsState?.type === SETTING_TYPE.agent ? refreshFormsState : null;

    if (!is_shared) {
        return (
            <>
                <WsMultipleLoadingsProvider messageType="update_process">
                    <ProcessOverviewSettingsTabSection
                        processDetails={processDetail}
                        setProcessDetails={setProcessDetail}
                        shouldShowHiddenSettings={shouldShowHiddenSettings}
                        allowSharing={access_type === ACCESS_TYPE.shared}
                        triggerContextFormStateRefresh={triggerContextFormStateRefresh}
                        refreshFormDataDependencyArray={[shouldRefreshAgentSettings]}
                    />
                </WsMultipleLoadingsProvider>

                {access_type === ACCESS_TYPE.shared && (
                    <ClientSettingsBlock
                        context={default_version.context?.context}
                        contextId={default_version.context?.id}
                        shouldShowHiddenSettings={shouldShowHiddenSettings}
                        emptyContextMessage={EMPTY_CONTEXT_MESSAGE}
                        processId={id}
                        setProcessDetail={setProcessDetail}
                        refreshFormDataDependencyArray={[
                            contextChangesCount,
                            shouldRefreshClientSettings,
                        ]}
                    />
                )}
            </>
        );
    }

    if (is_shared) {
        return (
            <>
                <BlockContainerWithHeader
                    title="Agent Settings"
                    description="Go to your parent organization to view and edit the agent settings."
                />

                <ClientSettingsBlock
                    context={default_version.context?.context}
                    contextId={default_version.context?.id}
                    shouldShowHiddenSettings={shouldShowHiddenSettings}
                    emptyContextMessage={SHARED_AGENT_EMPTY_CONTEXT_MESSAGE}
                    processId={id}
                    setProcessDetail={setProcessDetail}
                    refreshFormDataDependencyArray={[
                        contextChangesCount,
                        shouldRefreshClientSettings,
                    ]}
                />
            </>
        );
    }
};

export default memo(AgentSettingsTabSection);
