import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useOverlayState } from '../../hooks/useOverlayState';

import ToolTip from '../../design-system/ToolTip/ToolTip';
import SuccessAlert from '../../design-system/SuccessAlert/SuccessAlert';
import DiscussLineIcon from '../../design-system/Icons/DiscussLineIcon';
import ShareFeedbackModal from '../ShareFeedbackModal/ShareFeedbackModal';
import { Button, ButtonIcon } from '../../design-system';

const LogFeedbackTrigger = ({
    target,
    targetId,
    onSuccess,
    type = 'button',
    customSize = null,
}) => {
    const { isOpened, open: openModal, close: closeModal } = useOverlayState();
    const [successAlert, setSuccessAlert] = useState(null);

    return (
        <>
            {type === 'button' && (
                <Button
                    type="link"
                    size={customSize || 'sm'}
                    text="Log Feedback"
                    leadingIcon={DiscussLineIcon}
                    onClick={openModal}
                />
            )}

            {type === 'button_icon' && (
                <ToolTip
                    text="Log Feedback"
                    position="top-right"
                    shift="-3px"
                    useInternalHoverStateToShowToolTip
                >
                    <ButtonIcon
                        type="link"
                        size={customSize || 'xs'}
                        icon={DiscussLineIcon}
                        onClick={openModal}
                    />
                </ToolTip>
            )}

            {isOpened && (
                <ShareFeedbackModal
                    target={target}
                    targetId={targetId}
                    state="empty"
                    variant="primary"
                    setSuccessAlert={setSuccessAlert}
                    onSuccessFeedbackShare={onSuccess}
                    onClose={closeModal}
                />
            )}

            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
        </>
    );
};

LogFeedbackTrigger.propTypes = {
    target: PropTypes.oneOf(['inbox_message', 'thread']).isRequired,
    targetId: PropTypes.string.isRequired, // message id or thread id
    type: PropTypes.oneOf(['button', 'button_icon']),
    onSuccess: PropTypes.func,
    customSize: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
};

export default LogFeedbackTrigger;
