import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom';

import { routes } from './routes/routes';

import * as Pages from '../pages';
import PageContainer from '../components/PageContainer/PageContainer';

import { getRouteAccessComponent } from '../helpers/getRouteAccessComponent';

import HomePage from './HomePage';

export default function Router() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<PageContainer />}>
                <Route index element={<HomePage />} />

                {routes.map((route) => {
                    const {
                        path,
                        route_access_type,
                        component: Component,
                        permissionRules,
                    } = route;
                    const RouteAccessComponent = getRouteAccessComponent(route_access_type);

                    return (
                        <Route
                            key={path}
                            path={path}
                            element={
                                <RouteAccessComponent
                                    component={<Component />}
                                    {...permissionRules}
                                />
                            }
                        />
                    );
                })}
                <Route path="*" element={<Pages.NotFoundPage />} />
            </Route>
        )
    );
    return <RouterProvider router={router} />;
}
