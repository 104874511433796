import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../services/assistant-api';

import useUser from './useUser';
import { useWrongOrgOrViewTypeNavBlocker } from './useWrongOrgOrViewTypeNavBlocker';

import { CHAT_TYPE } from '../constants/assistant';
import { CHAT_GPT4o_MODEL } from '../pages/AssistantPage/AIModelSelector/AIModelSelector';

import { handlePageDataLoadError } from '../helpers/handlePageDataLoadError';

export const useFetchChatThreadData = ({ chatThreadId }) => {
    const { user } = useUser();
    const navigate = useNavigate();

    const defaultChatType = useMemo(() => {
        if (!user) {
            return CHAT_TYPE.divibot;
        }

        const isChatGpt4oAvailable = !!user.organization.available_models?.find(
            ({ model }) => model === CHAT_GPT4o_MODEL
        );

        return isChatGpt4oAvailable ? CHAT_TYPE.divibot : CHAT_TYPE.generic;
    }, [user]);
    const defaultSelectedModel = defaultChatType === CHAT_TYPE.divibot ? CHAT_GPT4o_MODEL : null;

    const [chatThreadData, setChatThreadData] = useState(null);
    const [controller, setController] = useState(new AbortController());

    const [chatType, setChatType] = useState(defaultChatType);
    const [selectedAiModel, setSelectedAiModel] = useState(null);
    const [selectedCustomBot, setSelectedCustomBot] = useState(null);

    const isFirstRender = useRef(true);

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    useEffect(() => {
        const fetchChatThreadData = async () => {
            try {
                setChatThreadData(null);
                setSelectedAiModel(null);
                setSelectedCustomBot(null);

                controller.abort();

                const newController = new AbortController();
                setController(newController);

                const { data } = await client.get(
                    `${API.ROUTES.assistant.chatThread}${chatThreadId}/`,
                    { signal: newController.signal }
                );

                if (data.type === CHAT_TYPE.custom) {
                    const isCustomBotDataReceived = data.custom_bot?.id && data.custom_bot?.name;

                    if (isCustomBotDataReceived) {
                        setChatType(CHAT_TYPE.custom);
                        setSelectedCustomBot({
                            id: data.custom_bot.id,
                            name: data.custom_bot.name,
                        });
                    }

                    if (!isCustomBotDataReceived) {
                        setChatType(CHAT_TYPE.generic);
                        setSelectedAiModel(data.settings.model);
                    }
                }

                if (data.type !== CHAT_TYPE.custom) {
                    setChatType(data.type);
                    setSelectedAiModel(data.settings.model);
                }

                setChatThreadData(data);
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    setIsWrongOrg,
                    setRedirectPath,
                    redirectPath: '/assistant',
                    generalErrorHandler: () => navigate('/assistant'),
                });
            }
        };

        const resetChatThreadData = () => {
            controller.abort();

            setChatThreadData(null);
            setChatType(defaultChatType);
            setSelectedAiModel(defaultSelectedModel);
            setSelectedCustomBot(null);
        };

        if (chatThreadId && chatThreadId !== chatThreadData?.id) {
            fetchChatThreadData();
        }

        if (!chatThreadId && !isFirstRender.current) {
            resetChatThreadData();
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    }, [chatThreadId]);

    return {
        chatThreadData,
        setChatThreadData,
        chatType,
        setChatType,
        selectedAiModel,
        setSelectedAiModel,
        selectedCustomBot,
        setSelectedCustomBot,
    };
};
