import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonIcon } from '../index';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../Icons';

const MultiActionButton = ({
    type = 'secondary',
    size = 'sm',
    state = 'default',
    text,
    onClick,
    isPopupOpened = false,
    togglePopup,
}) => {
    const Icon = isPopupOpened ? ArrowUpSLineIcon : ArrowDownSLineIcon;

    return (
        <div className="flex gap-[1px]">
            <Button
                type={type}
                size={size}
                state={state}
                text={text}
                noRoundedRight
                onClick={onClick}
            />
            <ButtonIcon
                type={type}
                size={size}
                state={state}
                icon={Icon}
                noRoundedLeft
                onClick={togglePopup}
            />
        </div>
    );
};

MultiActionButton.propTypes = {
    type: Button.propTypes.type,
    size: Button.propTypes.size,
    state: Button.propTypes.state,
    text: PropTypes.string,
    onClick: PropTypes.func,
    isPopupOpened: PropTypes.bool,
    togglePopup: PropTypes.func,
};

export default MultiActionButton;
