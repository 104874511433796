import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { generalSortOptions } from '../../constants/sort';
import { handleSortOptionSelect as _handleSortOptionSelect } from '../../helpers/handleUpdateSearchParams';

import SortDropdown from '../SortDropdown/SortDropdown';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../design-system/Icons';
import { Button } from '../../design-system';

const SortControls = ({
    sortOptions = generalSortOptions,
    dropdownPosition = { top: '120%', left: 0 },
    buttonContainerClassName = 'min-[680px]:relative',
    usePagination = true,
    usePageState = false,
    resetPageState = () => {},
    variant = 'primary',
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const sortValue = searchParams.get('sort') || '';

    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const closeSortDropdown = (e) => {
        e?.stopPropagation();
        e?.preventDefault();
        setIsDropdownOpened(false);
    };

    const handleSortOptionSelect = (sortOption) => {
        _handleSortOptionSelect({
            sortOption,
            setSearchParams,
            searchParams,
            usePagination,
            usePageState,
            resetPageState,
            onSearchDropdownClose: closeSortDropdown,
        });
    };

    return (
        <div className={buttonContainerClassName}>
            {variant === 'primary' && (
                <Button
                    type="link"
                    size="xs"
                    text="Sort"
                    trailingIcon={isDropdownOpened ? ArrowUpSLineIcon : ArrowDownSLineIcon}
                    onClick={() => setIsDropdownOpened(true)}
                />
            )}
            {variant === 'secondary' && (
                <button
                    className="border-0 outline-0 bg-none text-[14px] font-bold underline underline-offset-[3px]"
                    onClick={() => setIsDropdownOpened(true)}
                >
                    Sort
                </button>
            )}
            {isDropdownOpened && (
                <SortDropdown
                    options={sortOptions}
                    selectedOption={sortValue}
                    handleOptionSelect={handleSortOptionSelect}
                    dropdownPosition={dropdownPosition}
                    onClose={closeSortDropdown}
                />
            )}
        </div>
    );
};

SortControls.propTypes = {
    sortOptions: PropTypes.arrayOf(
        PropTypes.shape({ text: PropTypes.string, value: PropTypes.string })
    ),
    dropdownPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttonContainerClassName: PropTypes.string,
    usePagination: PropTypes.bool,
    usePageState: PropTypes.bool,
    resetPageState: PropTypes.func,
    variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default SortControls;
