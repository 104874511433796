import React, { useState } from 'react';
import { API } from 'constants';
import organizationClient from '../../../services/organization-api';

import useUser from '../../../hooks/useUser';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';

import { Button } from '../../../design-system';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';
import Modal from '../../../design-system/Modal/Modal';
import Alert from '../../../design-system/Alert/Alert';
import PaginatedSelect from '../../../design-system/PaginatedSelect/PaginatedSelect';

const ReassignModal = ({
    onClose,
    onReassign,
    onMultiActionOptionsPanelClose,
    disabledUserId: _disabledUserId,
}) => {
    const { user } = useUser();
    const disabledUserId = _disabledUserId ?? user.id;

    const [selectedMember, setSelectedMember] = useState(null);
    const [errorSelectedMemberField, setErrorSelectedMemberField] = useState(false);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isReassignLoading, setIsReassignLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState({ show: false, statusCode: null });

    const {
        options: organizationMembersOptions,
        optionsLoading,
        canLoadMoreOptions,
        setPage,
        totalOptions,
        isFirstRequestCompleted,
    } = useFetchOptionsForPaginatedSelect({
        client: organizationClient,
        route: API.ROUTES.organization.organizationMembership,
        searchParams: { organization: user?.organization?.id },
        startRequest: !!user,
        formatResponseToOptions: (results) =>
            results.map(({ user }) => ({ id: user.pk, name: user.email })),
    });

    const handleReassign = async () => {
        if (!selectedMember) {
            setErrorSelectedMemberField(true);
            return;
        }

        try {
            setIsReassignLoading(true);
            const selectedMemberData = organizationMembersOptions?.find(
                (member) => member.id === selectedMember
            );

            await onReassign(selectedMember, selectedMemberData);

            setIsReassignLoading(false);
            onMultiActionOptionsPanelClose();
        } catch (error) {
            setIsReassignLoading(false);
            setErrorAlert({ show: true, statusCode: error.response?.status });
        }
    };

    const handleSelectChange = (id) => {
        setSelectedMember(id);

        if (errorSelectedMemberField) {
            setErrorSelectedMemberField(false);
        }
    };

    const submitButtonState =
        disabledUserId === selectedMember ? 'disabled' : isReassignLoading ? 'loading' : 'default';

    const dropdownOptionsLength = organizationMembersOptions.length;
    const showDropdownClientFiltering = dropdownOptionsLength > 5;

    // used to increase the height of the modal so that the dropdown fits entirely on the screen
    const selectMarginBottom =
        dropdownOpen && optionsLoading && !isFirstRequestCompleted
            ? 60
            : dropdownOpen && !optionsLoading
            ? Math.min(
                  34 * (dropdownOptionsLength || 1) + (showDropdownClientFiltering ? 45 : 0) - 10,
                  190
              )
            : 24;

    return (
        <Modal onClose={onClose} size="medium">
            <p className="font-heading-bold text-heading-bold-m text-neutral-500 mb-4">
                Reassign To
            </p>
            <div
                style={{
                    marginBottom: `${selectMarginBottom}px`,
                    transition: 'margin 0.2s ease-in-out',
                }}
            >
                <PaginatedSelect
                    size="md"
                    name="assigned_to"
                    value={selectedMember}
                    options={organizationMembersOptions}
                    optionsLoading={optionsLoading}
                    label="Select a member to send to"
                    isRequired
                    placeholder="Select a member"
                    onChange={handleSelectChange}
                    state={errorSelectedMemberField ? 'error' : 'default'}
                    errorMessage="Please select a member"
                    fetchOptions={() => setPage((page) => page + 1)}
                    canLoadMore={canLoadMoreOptions}
                    includeClientSideFiltering
                    dropdownHeight={192}
                    totalOptionsCount={totalOptions}
                    useExternalDropdownState
                    dropdownOpen={dropdownOpen}
                    setDropdownOpen={setDropdownOpen}
                />
            </div>
            <div className="flex items-center justify-between">
                <Button type="neutral" size="xs" text="Cancel" onClick={onClose} />
                <Button
                    type="primary"
                    size="xs"
                    text="Confirm"
                    state={submitButtonState}
                    onClick={handleReassign}
                />
            </div>
            {errorAlert.show && (
                <Alert
                    status="critical"
                    message={defaultErrorMessage}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={3000}
                    handleClose={() => setErrorAlert({ show: false, statusCode: null })}
                />
            )}
        </Modal>
    );
};

export default ReassignModal;
