import React, { useState } from 'react';

import ToolTip from '../../../../design-system/ToolTip/ToolTip';
import SuccessAlert from '../../../../design-system/SuccessAlert/SuccessAlert';
import Message2LineIcon from '../../../../design-system/Icons/Message2LineIcon';
import CreateNewSuggestionPanel from '../CreateNewSuggestionPanel/CreateNewSuggestionPanel';

const NewSuggestionBlock = ({ setting, settingType, processId }) => {
    const [isPanelOpened, setIsPanelOpened] = useState(false);
    const [successAlert, setSuccessAlert] = useState(null);

    return (
        <>
            <ToolTip
                text="New suggestion"
                position="top-right"
                shift="0px"
                useInternalHoverStateToShowToolTip
            >
                <button
                    onClick={() => setIsPanelOpened(true)}
                    className="block px-1.5 pt-1 pb-0.5 focus:outline-none"
                >
                    <Message2LineIcon width={16} height={16} color="#000000" />
                </button>
            </ToolTip>

            {isPanelOpened && (
                <CreateNewSuggestionPanel
                    setting={setting}
                    settingType={settingType}
                    processId={processId}
                    setSuccessAlert={setSuccessAlert}
                    onClose={() => setIsPanelOpened(false)}
                />
            )}

            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
        </>
    );
};

export default NewSuggestionBlock;
