import React from 'react';

import {
    SETTING_TYPE,
    SHARING_STATE,
    NEW_SUGGESTION_ACCESS_STATE,
} from '../../../constants/settingsForm';

import SettingFormField from './SettingFormField/SettingFormField';

const SettingsForm = ({
    formData,
    setFormData,
    containerGap = 28,
    shouldShowHiddenSettings = true,
    isFormDisabled = false,
    sharingState = SHARING_STATE.unavailable, // "enabled" | "disabled" | "unavailable"
    newSuggestionAccessState = NEW_SUGGESTION_ACCESS_STATE.off, // "on" | "off" | "lockedOnly
    processId, // is used for new suggestion logic
    settingType = SETTING_TYPE.agent, // is used for new suggestion logic
}) => {
    // if sharingState is "unavailable" - sharing status buttons are hidden
    return (
        <>
            {!!formData?.length && (
                <div className="flex flex-col" style={{ gap: `${containerGap}px` }}>
                    {formData.map((setting) => (
                        <SettingFormField
                            key={setting.name}
                            setting={setting}
                            setFormData={setFormData}
                            isFormDisabled={isFormDisabled}
                            sharingState={sharingState}
                            settingType={settingType}
                            newSuggestionAccessState={newSuggestionAccessState}
                            processId={processId}
                            shouldShowHiddenSettings={shouldShowHiddenSettings}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default SettingsForm;
