import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../constants/organization';

export const discoverRoutes = [
    {
        path: '/discover/agent/:processTemplateId/setup/:versionId',
        indexPath: '/dashboard',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ProcessSetupOrUpgradePage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    // {
    //     path: '/discover',
    //     route_access_type: ROUTE_ACCESS_TYPE.private,
    //     component: Pages.DiscoverPage,
    //     permissionRules: {
    //         availableViewTypes: [VIEW_TYPES.client],
    //     },
    // },
];
