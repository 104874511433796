import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../../services/inbox-api';

import { mutateMilestonesDataAfterReassignInboxMessage } from '../../../../helpers/milestoneUtils';

import ReassignModal from '../../../InboxPage/ReassignModal/ReassignModal';
import InboxRecordItem from '../InboxRecordItem/InboxRecordItem';
import ViewInboxMessageModal from '../ViewInboxMessageModal/ViewInboxMessageModal';
import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';

const InboxContentPiece = ({ content, milestoneId, jobLogFeedbackTrigger }) => {
    const { id: contentId, label, data } = content;

    const { jobId, projectId } = useParams();

    const inboxRecordItems = Array.isArray(data) ? data : [data];
    const [savedInboxRecordItem, setSavedInboxRecordItem] = useState(null);

    const [actionModal, setActionModal] = useState({
        action: null,
        messageId: null,
        disabledUserId: null,
    });
    const [errorAlert, setErrorAlert] = useState(null);

    const openActionModal = (action, messageId, disabledUserId = null) =>
        setActionModal({ action, messageId, disabledUserId });

    const closeActionModal = () =>
        setActionModal({ action: null, messageId: null, disabledUserId: null });

    useEffect(() => {
        if (savedInboxRecordItem) {
            const isDataUpToDate = inboxRecordItems.find(
                (item) =>
                    item.message_id === savedInboxRecordItem.message_id &&
                    item.assigned_to_id === savedInboxRecordItem.assigned_to_id &&
                    item.assigned_to_email === savedInboxRecordItem.assigned_to_email
            );

            if (isDataUpToDate) {
                setSavedInboxRecordItem(null);
            }
        }
    }, [data, savedInboxRecordItem]);

    const handleReassign = async (memberId, user) => {
        const email = user?.name;
        await client.patch(`${API.ROUTES.inbox.message}${actionModal.messageId}/`, {
            assigned_to: memberId,
        });

        if (!email) return;

        const key = projectId
            ? `${API.ROUTES.report.project}${projectId}/`
            : `${API.ROUTES.report.job}${jobId}/`;

        const currentInboxRecordItem = inboxRecordItems.find(
            (item) => item.message_id === actionModal.messageId
        );
        if (currentInboxRecordItem) {
            setSavedInboxRecordItem({
                ...currentInboxRecordItem,
                assigned_to_email: email,
                assigned_to_id: memberId,
            });
        }

        await mutateMilestonesDataAfterReassignInboxMessage({
            key,
            milestoneId,
            contentId,
            memberId,
            email,
            messageId: actionModal.messageId,
        });
    };

    const displayedInboxRecordItems = useMemo(() => {
        if (!savedInboxRecordItem) {
            return inboxRecordItems;
        }

        return inboxRecordItems.map((item) => {
            if (item.message_id === savedInboxRecordItem.message_id) {
                return savedInboxRecordItem;
            }

            return item;
        });
    }, [savedInboxRecordItem, data]);

    return (
        <FormFieldWrapper label={label} actionsBarRightContent={jobLogFeedbackTrigger} gap={6}>
            <ul className="flex flex-col gap-1">
                {displayedInboxRecordItems.map((item, index) => {
                    return (
                        <InboxRecordItem
                            key={index}
                            item={item}
                            openActionModal={openActionModal}
                        />
                    );
                })}
            </ul>
            {actionModal.action === 'view' && (
                <ViewInboxMessageModal
                    messageId={actionModal.messageId}
                    setErrorAlert={setErrorAlert}
                    onClose={closeActionModal}
                />
            )}

            {actionModal.action === 'reassign' && (
                <ReassignModal
                    onClose={closeActionModal}
                    onReassign={handleReassign}
                    onMultiActionOptionsPanelClose={closeActionModal}
                    disabledUserId={actionModal.disabledUserId}
                />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </FormFieldWrapper>
    );
};

export default InboxContentPiece;
