import React from 'react';

import { formatKeyToLabel } from '../../../../helpers/formatKeyToLabel';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import MarkdownPreviewWithAdditionalActions from '../../../../design-system/MarkdownPreviewWithAdditionalActions/MarkdownPreviewWithAdditionalActions';

const VariableValuePanel = ({ variable, onClose }) => {
    const { label, key, value } = variable;

    const formattedLabel = label || formatKeyToLabel(key);

    const { isDesktop } = useResponsiveBreakpoints();

    if (isDesktop) {
        return (
            <div className="w-2/3 max-w-2/3 bg-white pt-5 pb-[40px] overflow-y-auto h-full min-h-full">
                <div className="pl-6 pr-[40px] xl:pr-[60px]">
                    <MarkdownPreviewWithAdditionalActions value={value} label={formattedLabel} />
                </div>
            </div>
        );
    }

    return (
        <MarkdownPreviewWithAdditionalActions
            value={value}
            label={formattedLabel}
            showAsMobilePopup
            onPopupClose={onClose}
        />
    );
};

export default VariableValuePanel;
