import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';

export const docsRoutes = [
    {
        path: '/docs',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.DocsPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
    {
        path: '/docs/folder/:folderId',
        indexPath: '/docs',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.DocsPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
    {
        path: '/doc/:docId',
        indexPath: '/docs',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.DocDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
];
