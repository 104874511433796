import React, { memo, useMemo, useState } from 'react';

import { useResponsiveRange } from '../../../../hooks/useResponsiveRange';

import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import NestedInputValueCard from '../NestedInputValueCard/NestedInputValueCard';
import NestedItemModal from '../NestedItemModal/NestedItemModal';
import { Button } from '../../../../design-system';
import { AddLineIcon } from '../../../../design-system/Icons';

const NestedInput = ({
    setting,
    handleNestedInputChange,
    shouldShowHiddenSettings,
    actionsBarRightContent,
    isSharedPlaceholderDisplayed = false,
}) => {
    const {
        label,
        is_required,
        value,
        details: { item_name, properties },
        description,
        constraints,
        state,
        errorMessage,
        placeholder,
    } = setting;
    const [inputRef, columnNumber] = useResponsiveRange([0, 450, 790, 1100, 1500, 1900]);

    const [itemModal, setItemModal] = useState(null);
    const itemName = item_name || label;

    const maxItemsCount = constraints?.max;
    const maxItemsMessage = maxItemsCount ? `Max: ${maxItemsCount}` : '';

    const isEmptyValue = !value || !value?.length;

    const firstStringPropertyName = useMemo(
        () => properties?.find(({ type }) => type === 'string')?.name,
        [properties]
    );

    const isMaxValueItems = !!maxItemsCount && value?.length >= maxItemsCount;
    const addItemButtonState = isMaxValueItems || state === 'disabled' ? 'disabled' : 'default';

    const handleValueChange = (cb) => {
        handleNestedInputChange((prevData) => ({
            ...prevData,
            value: cb(prevData.value),
        }));
    };

    const emptyValueMessage = `No ${label} added yet. Click + Add ${itemName} to start adding ${label}.`;

    return (
        <div className="flex flex-col gap-1.5" ref={inputRef}>
            <FormFieldWrapper
                label={label}
                isRequired={is_required}
                tipText={description}
                topRightTipText={maxItemsMessage}
                state={state}
                errorMessage={errorMessage}
                actionsBarRightContent={actionsBarRightContent}
            >
                {!isEmptyValue && columnNumber !== null && (
                    <div
                        className="grid gap-2"
                        style={{ gridTemplateColumns: `repeat(${columnNumber}, minmax(0, 1fr))` }}
                    >
                        {value.map((item) => (
                            <NestedInputValueCard
                                key={item.temporaryId}
                                item={item}
                                itemName={itemName}
                                setItemModal={setItemModal}
                                handleValueChange={handleValueChange}
                                firstStringPropertyName={firstStringPropertyName}
                                isDisabled={state === 'disabled'}
                                columnNumber={columnNumber}
                                isMaxValueItems={isMaxValueItems}
                            />
                        ))}
                    </div>
                )}

                {isEmptyValue && (
                    <div className="w-full h-[140px] min-h-[140px] p-4 overflow-hidden rounded-[10px] bg-purple-100 border-1 border-dashed border-purple-500 flex items-center justify-center">
                        <p className="font-body text-body-regular-s text-neutral-300 text-center">
                            {isSharedPlaceholderDisplayed ? placeholder : emptyValueMessage}
                        </p>
                    </div>
                )}
            </FormFieldWrapper>

            <div className="flex justify-end">
                <Button
                    type="link"
                    size="sm"
                    text={`Add ${itemName}`}
                    state={addItemButtonState}
                    leadingIcon={AddLineIcon}
                    onClick={() => setItemModal({ mode: 'add' })}
                />
            </div>

            {itemModal && (
                <NestedItemModal
                    mode={itemModal.mode}
                    itemName={itemName}
                    item={itemModal.item}
                    properties={properties || []}
                    handleNestedInputChange={handleNestedInputChange}
                    shouldShowHiddenSettings={shouldShowHiddenSettings}
                    onClose={() => setItemModal(null)}
                />
            )}
        </div>
    );
};

export default memo(NestedInput);
