import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { API } from 'constants';
import client from '../../services/library-api';
import { PLAYBOOK_BUILDER_MODE } from '../../constants/playbookBuilder';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { handlePageDataLoadError } from '../../helpers/handlePageDataLoadError';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';
import { useWrongOrgOrViewTypeNavBlocker } from '../../hooks/useWrongOrgOrViewTypeNavBlocker';

import Loading from '../../components/Loading';
import ErrorAlert from '../../design-system/ErrorAlert/ErrorAlert';
import PlaybookDetailsModal from './PlaybookDetailsModal/PlaybookDetailsModal';
import PlaybookBuilderIndex from './PlaybookBuilderIndex/PlaybookBuilderIndex';
import PlaybookHeaderMobileView from './PlaybookHeaderMobileView/PlaybookHeaderMobileView';
import PlaybookBuilderTopControls from './PlaybookBuilderTopControls/PlaybookBuilderTopControls';

const PlaybookBuilderPage = () => {
    const { playbookId } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const mode = searchParams.get('mode');

    const navigate = useNavigate();
    const location = useLocation();
    const backLinkHref = location.state?.from || '/playbooks';

    const [playbookDetail, setPlaybookDetail] = useState(null);

    const [playbookContentData, setPlaybookContentData] = useState(null); // changing this state leads to refreshing playbookContentFormData state
    const playbookContentChangesState = useState(false);

    const [pageTopControlsHeight, setPageTopControlsHeight] = useState(36);

    const [isPlaybookEditModalOpened, setIsPlaybookEditModalOpened] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    const { isDesktop } = useResponsiveBreakpoints();

    const builderIndexHeight =
        pageTopControlsHeight && isDesktop ? `calc(100% - ${pageTopControlsHeight}px)` : 'auto';

    useEffect(() => {
        const fetchPlaybookData = async () => {
            try {
                await refreshPlaybookData();
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    setIsWrongOrg,
                    setRedirectPath,
                    redirectPath: backLinkHref,
                    generalErrorHandler: () => navigate(backLinkHref),
                });
            }
        };

        fetchPlaybookData();
    }, []);

    const refreshPlaybookData = async () => {
        const { data } = await client.get(
            `${API.ROUTES.library.playbook}${playbookId}/?shared=True`
        );
        setPlaybookDetail(data);
        setPlaybookContentData(data.playbook_content);
    };

    const isPlaybookShared = playbookDetail?.is_shared;

    useEffect(() => {
        const isWrongViewMode =
            (isPlaybookShared || !isDesktop) && mode !== PLAYBOOK_BUILDER_MODE.view;
        if (isWrongViewMode) {
            changeMode(PLAYBOOK_BUILDER_MODE.view, true);
            return;
        }

        const isParsedModeCorrect = Object.values(PLAYBOOK_BUILDER_MODE).includes(mode);

        if (!isParsedModeCorrect) {
            const defaultMode = PLAYBOOK_BUILDER_MODE.view;
            changeMode(defaultMode, true);
        }
    }, [mode, isDesktop, isPlaybookShared]);

    const changeMode = (newMode, shouldReplace = false) => {
        if (mode === newMode) {
            return;
        }

        // do not allow user to change mode to edit on mobile
        if (!isDesktop && newMode === PLAYBOOK_BUILDER_MODE.edit) {
            return;
        }

        if (isPlaybookShared && newMode === PLAYBOOK_BUILDER_MODE.edit) {
            return;
        }

        const urlSearchParams = new URLSearchParams(searchParams);
        urlSearchParams.set('mode', newMode);
        setSearchParams(urlSearchParams, { replace: shouldReplace, state: location.state });
    };

    const openPlaybookEditModal = useCallback(
        () => setIsPlaybookEditModalOpened(true),
        [setIsPlaybookEditModalOpened]
    );

    useDocumentTitle(playbookDetail?.name);

    const pageContainerClassName = classNames(
        'page-position lg:pt-8 xl:pt-[40px] lg:px-5 min-[1124px]:px-[40px] xl:px-[60px] flex flex-col transition-colors hide-scrollbar overflow-y-auto lg:overflow-hidden',
        {
            'bg-white': mode === PLAYBOOK_BUILDER_MODE.edit,
            'bg-neutral-50': mode === PLAYBOOK_BUILDER_MODE.view,
        }
    );

    return (
        <div className={pageContainerClassName}>
            {playbookDetail ? (
                <>
                    <div className={isDesktop ? 'block' : 'hidden'}>
                        <PlaybookBuilderTopControls
                            backLinkHref={backLinkHref}
                            setPageTopControlsHeight={setPageTopControlsHeight}
                            changeMode={changeMode}
                        />
                    </div>

                    {!isDesktop && (
                        <PlaybookHeaderMobileView
                            mode={mode}
                            backLinkHref={backLinkHref}
                            playbookDetail={playbookDetail}
                            openPlaybookEditModal={openPlaybookEditModal}
                        />
                    )}

                    <PlaybookBuilderIndex
                        mode={mode}
                        playbookDetail={playbookDetail}
                        setPlaybookDetail={setPlaybookDetail}
                        playbookContentData={playbookContentData}
                        playbookContentChangesState={playbookContentChangesState}
                        height={builderIndexHeight}
                        isDesktop={isDesktop}
                        openPlaybookEditModal={openPlaybookEditModal}
                        changeMode={changeMode}
                    />
                </>
            ) : (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}

            {isPlaybookEditModalOpened && (
                <PlaybookDetailsModal
                    mode="edit"
                    playbookData={playbookDetail}
                    setUpdatedPlaybookData={setPlaybookDetail}
                    onClose={() => setIsPlaybookEditModalOpened(false)}
                />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default PlaybookBuilderPage;
