import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';

export const authRoutes = [
    { path: '/login', route_access_type: ROUTE_ACCESS_TYPE.restricted, component: Pages.Login },
    {
        path: '/register',
        route_access_type: ROUTE_ACCESS_TYPE.restricted,
        component: Pages.Register,
    },
    {
        path: '/logout',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.Logout,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
        },
    },
    {
        path: '/password-reset',
        route_access_type: ROUTE_ACCESS_TYPE.public,
        component: Pages.ResetPassword,
    },
    {
        path: '/password-reset/confirm/:id/:confirmationId',
        route_access_type: ROUTE_ACCESS_TYPE.public,
        component: Pages.ResetPasswordConfirmation,
    },
    {
        path: '/email/confirm/:confirmationId',
        route_access_type: ROUTE_ACCESS_TYPE.restricted,
        component: Pages.ConfirmEmail,
    },
    {
        path: '/email/verify/',
        route_access_type: ROUTE_ACCESS_TYPE.restricted,
        component: Pages.VerifyEmail,
    },
    {
        path: '/email/verify/:email',
        route_access_type: ROUTE_ACCESS_TYPE.restricted,
        component: Pages.VerifyEmail,
    },
];
