import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { threadStatusBadge } from '../../../constants/threads';

import { Badge } from '../../../design-system';
import FlowChartIcon from '../../../design-system/Icons/FlowChartIcon';
import TimestampDisplay from '../../../design-system/TimestampDisplay/TimestampDisplay';

const ExecutionCard = ({ data }) => {
    const { id, name, scenario, status, updated_at } = data;
    const location = useLocation();

    const statusBadge = threadStatusBadge[status];

    const cardContainerClassName = classNames(
        'px-4 py-3 max-w-full w-full rounded-2 bg-white',
        'grid grid-rows-[auto_auto] min-[910px]:grid-rows-1 grid-cols-[minmax(100px,_1fr)] min-[690px]:grid-cols-[minmax(100px,_1fr)_90px] min-[910px]:grid-cols-[minmax(100px,_1fr)_90px_222px] min-[1400px]:grid-cols-[minmax(100px,_1fr)_90px_222px] gap-x-4 min-[1400px]:gap-x-8 gap-y-2 items-start min-[690px]:items-center'
    );

    return (
        <Link
            key={id}
            to={`/execution/${id}`}
            state={{ from: location }}
            className={cardContainerClassName}
        >
            <div className="grid grid-cols-1 min-[1100px]:grid-cols-[minmax(100px,_1.2fr)_minmax(100px,_1fr)] gap-x-4 min-[1400px]:gap-x-8 gap-y-1 items-center">
                <p className="font-body-bold text-body-bold-s text-black truncate max-w-full row-start-2 row-end-2 min-[1100px]:row-start-1 min-[1100px]:row-end-1">
                    {name}
                </p>
                <div className="max-w-full row-start-1 row-end-1 flex items-center">
                    <Badge
                        text={scenario?.name}
                        color="neutral"
                        leadingIcon={FlowChartIcon}
                        leadingIconColor="#000000"
                    />
                </div>
            </div>

            <div className="flex min-[690px]:justify-end w-full row-start-2 row-end-2 col-span-2 min-[690px]:row-start-auto min-[690px]:row-end-auto min-[690px]:col-span-1">
                <Badge text={statusBadge.text} color={statusBadge.color} />
            </div>

            <div className="pt-2 min-[910px]:p-0 border-t-1 border-neutral-200 min-[910px]:border-none row-start-3 row-end-3 min-[690px]:row-start-2 min-[690px]:row-end-2 col-span-full min-[910px]:row-start-auto min-[910px]:row-end-auto min-[910px]:col-span-1">
                <TimestampDisplay
                    label="Last Updated"
                    timestamp={updated_at}
                    dateTimeFormat="MM/dd/yyyy - hh:mm a"
                />
            </div>
        </Link>
    );
};

export default memo(ExecutionCard);
