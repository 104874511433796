import React from 'react';
import classNames from 'classnames';

import { formatKeyToLabel } from '../../../../helpers/formatKeyToLabel';

import CheckboxCircleFillLineIcon from '../../../../design-system/Icons/CheckboxCircleFillLineIcon';

const OutputCard = ({ output, isActive, setOutputValuePanel }) => {
    const { label, key, description, is_filled } = output;

    const formattedLabel = label || formatKeyToLabel(key);

    const isClickable = is_filled;

    const handleCardClick = () => {
        if (!isClickable) return;

        if (isActive) {
            setOutputValuePanel(null);
            return;
        }
        setOutputValuePanel(output);
    };

    const cardClassName = classNames(
        'p-4 rounded-2 bg-white border-2 flex gap-2 items-start justify-between transition-colors',
        isActive ? 'border-purple-300' : 'border-neutral-100',
        isClickable && 'cursor-pointer'
    );

    return (
        <div className={cardClassName} onClick={handleCardClick}>
            <div className="min-h-[24px] flex items-center">
                <div className="flex flex-col gap-1">
                    <p className="font-body-bold text-body-bold-s text-neutral-500">
                        {formattedLabel}
                    </p>
                    {description && (
                        <p className="font-body text-body-regular-s text-neutral-300 line-clamp-3">
                            {description}
                        </p>
                    )}
                </div>
            </div>

            <div>
                {is_filled && <CheckboxCircleFillLineIcon width={24} height={24} color="#754DCF" />}
                {!is_filled && (
                    <div className="w-6 h-6 min-w-[24px] flex items-center justify-center">
                        <div className="w-[18px] h-[18px] border-1 rounded-full border-neutral-300"></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OutputCard;
