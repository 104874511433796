import React, { useState } from 'react';
import classNames from 'classnames';

import Switcher from '../../../../design-system/Switcher/Switcher';
import MilestonesBoard from '../../JobDetailPage/MilestonesBoard/MilestonesBoard';
import ProjectInputsBlock from '../ProjectInputsBlock/ProjectInputsBlock';
import ProjectOutputsBlock from '../ProjectOutputsBlock/ProjectOutputsBlock';

const TABS = [{ name: 'Project History' }, { name: 'Project Inputs' }, { name: 'Project Outputs' }];
export const containerXPaddings = 'px-4 xs:px-5 sm:px-8 lg:px-[40px] xl:px-[60px]';

const ProjectDetailMainPanel = ({ projectDetail }) => {
    const { milestones, inputs, outputs } = projectDetail;

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const containerClassName = classNames(
        'pt-2.5 flex flex-col flex-1 bg-neutral-50/70 overflow-hidden',
        activeTabIndex === 0 && `${containerXPaddings} pb-4 lg:pb-7`
    );
    const switcherContainerClassName = classNames(
        activeTabIndex === 0 && 'mb-5',
        activeTabIndex !== 0 && `${containerXPaddings}`
    );

    return (
        <div className={containerClassName}>
            <div className="relative flex flex-col h-full max-h-full flex-grow">
                <div className={switcherContainerClassName}>
                    <div className="px-5 sm:px-4 border-b-1 border-neutral-200 flex flex-col">
                        <Switcher
                            tabs={TABS}
                            tabIndex={activeTabIndex}
                            onTabChanged={(_, idx) => setActiveTabIndex(idx)}
                        />
                    </div>
                </div>

                {activeTabIndex === 0 && (
                    <MilestonesBoard
                        milestones={milestones}
                        view="column"
                        pageType="project"
                        entityName="Connected Jobs"
                    />
                )}

                {activeTabIndex === 1 && <ProjectInputsBlock inputs={inputs} />}

                {activeTabIndex === 2 && <ProjectOutputsBlock outputs={outputs} />}
            </div>
        </div>
    );
};

export default ProjectDetailMainPanel;
