// history page for both prompt and non-prompt tasks
import React, { useEffect, useState } from 'react';
import { API } from 'constants';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'design-system/Button/Button';
import { ArrowGoBackLineIcon, TerminalBoxLineIcon } from 'design-system/Icons';
import client from '../../services/library-api';
import { Tabs } from '../../design-system';
import { HISTORY_PAGE_TYPES, HISTORY_PAGE_TYPES_LABELS } from '../../constants/promptHistory';
import PromptVersionHistory from './PromptVersionHistory/PromptVersionHistory';
import TaskRunHistory from './TaskRunHistory/TaskRunHistory';
import Loading from '../../components/Loading';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { capitalizeFirstLetter } from '../../services/strings';
import { SIMPLIFIED_TASK_TYPES } from '../../constants/library';
import { useWrongOrgOrViewTypeNavBlocker } from '../../hooks/useWrongOrgOrViewTypeNavBlocker';
import { handlePageDataLoadError } from '../../helpers/handlePageDataLoadError';

const tabs = [
    { name: HISTORY_PAGE_TYPES_LABELS.runs },
    { name: HISTORY_PAGE_TYPES_LABELS.versions },
];

export const taskTypeLabel = {
    [SIMPLIFIED_TASK_TYPES.prompt]: 'prompt',
    [SIMPLIFIED_TASK_TYPES.nonPrompt]: 'task',
};

const TaskHistoryIndexPage = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const libraryLocation = location.state?.libraryLocation || '/library';

    // prompt or task
    const taskType = location.pathname?.includes('/prompt/')
        ? SIMPLIFIED_TASK_TYPES.prompt
        : SIMPLIFIED_TASK_TYPES.nonPrompt;

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedPageType = searchParams.get('page');
    const tabIndex = parsedPageType === HISTORY_PAGE_TYPES.versions ? 1 : 0;

    const requestEndpoint = {
        [SIMPLIFIED_TASK_TYPES.prompt]: `${API.ROUTES.library.prompt}${id}/`,
        [SIMPLIFIED_TASK_TYPES.nonPrompt]: `${API.ROUTES.library.task}${id}/`,
    };

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    useDocumentTitle(
        `${capitalizeFirstLetter(taskTypeLabel[taskType])} ${capitalizeFirstLetter(
            parsedPageType
        )} History`
    );

    const handleBackPressed = () => {
        navigate(`/library/${taskTypeLabel[taskType]}/${id}`, { state: { libraryLocation } });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const { data } = await client.get(requestEndpoint[taskType]);
                setData(data);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                handlePageDataLoadError({
                    e,
                    setIsWrongOrg,
                    setRedirectPath,
                    redirectPath: '/library',
                    generalErrorHandler: handleBackPressed,
                });
            }
        };

        if (!parsedPageType) {
            setSearchParams({ page: HISTORY_PAGE_TYPES.runs });
        }
        fetchData();
    }, []);

    const onTabChanged = () => {
        // now we don't version history for non-prompt tasks
        if (taskType === SIMPLIFIED_TASK_TYPES.nonPrompt) {
            return;
        }

        if (parsedPageType === HISTORY_PAGE_TYPES.versions) {
            setSearchParams({ page: HISTORY_PAGE_TYPES.runs });
        } else {
            setSearchParams({ page: HISTORY_PAGE_TYPES.versions });
        }
    };

    return (
        <>
            {!isLoading && data && (
                <div className="w-full sm:w-[calc(100%-68px)] h-full min-h-[calc(100vh-64px)] px-4 sm:px-8 pt-4 pb-[48px] bg-white flex flex-col gap-4 sm:gap-8 items-start">
                    <Button
                        type="link"
                        size="sm"
                        text={`Back to ${capitalizeFirstLetter(
                            taskTypeLabel[taskType]
                        )} Playground`}
                        onClick={handleBackPressed}
                        leadingIcon={ArrowGoBackLineIcon}
                        trailingIcon={TerminalBoxLineIcon}
                    />
                    <p className="font-body text-body-bold-xl">
                        History:{' '}
                        <span className="font-body text-body-regular-xl">
                            {taskType === SIMPLIFIED_TASK_TYPES.prompt
                                ? data.task?.name
                                : data.name}
                        </span>
                    </p>
                    <Tabs onTabChanged={onTabChanged} tabIndex={tabIndex} tabs={tabs} />
                    {parsedPageType === HISTORY_PAGE_TYPES.runs && <TaskRunHistory />}
                    {parsedPageType === HISTORY_PAGE_TYPES.versions && <PromptVersionHistory />}
                </div>
            )}
            {isLoading && (
                <div className="fixed inset-0 flex justify-center items-center">
                    <div className="z-6 p-4 rounded-lg">
                        <Loading text="" />
                    </div>
                </div>
            )}
        </>
    );
};

export default TaskHistoryIndexPage;
