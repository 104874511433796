import React from 'react';

import RenameJobModal from '../JobDetailPage/RenameJobModal/RenameJobModal';
import RestartJobSidePanel from '../JobDetailPage/RestartJobSidePanel/RestartJobSidePanel';
import ConfirmArchiveJobModal from '../JobDetailPage/ConfirmArchiveJobModal/ConfirmArchiveJobModal';

const JobCardActions = ({
    id,
    name,
    setJobs,
    actionModal,
    setActionModal,
    requestKeysToMutate,
}) => {
    return (
        <>
            {actionModal.action === 'rename' && (
                <RenameJobModal
                    jobId={id}
                    jobName={name}
                    setJobs={setJobs}
                    requestKeysToMutate={requestKeysToMutate || []}
                    onClose={() => setActionModal({ action: null })}
                />
            )}
            {actionModal.action === 'restart' && (
                <RestartJobSidePanel
                    jobId={id}
                    requestKeysToMutate={requestKeysToMutate || []}
                    onClose={() => setActionModal({ action: null })}
                />
            )}
            {actionModal.action === 'archive' && (
                <ConfirmArchiveJobModal
                    jobId={id}
                    jobName={name}
                    setJobs={setJobs}
                    requestKeysToMutate={requestKeysToMutate || []}
                    onClose={() => setActionModal({ action: null })}
                />
            )}
        </>
    );
};

export default JobCardActions;
