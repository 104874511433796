import React, { useEffect, useMemo, useRef, useState } from 'react';

import { API } from 'constants';
import client from '../../../services/library-api';

import { defaultErrorMessage } from '../../../constants/errorMessages';
import { VIEW_TYPES } from '../../../constants/viewTypes';
import {
    ALLOWED_SETTING_ACTION_TYPES,
    NEW_SUGGESTION_ACCESS_STATE,
    SETTING_TYPE,
    SHARING_STATE,
} from '../../../constants/settingsForm';

import { useViewType } from '../../../hooks/useViewType';
import {
    checkIfDisplayedRequiredFieldsFilled,
    checkIsFormDataValid,
    formatSettingsForRequestBody,
    getSettingsFormDataInitialState,
    replaceFilesInFormData,
} from '../../../helpers/settingsFormUtils';
import { checkIfFormDataChanged } from '../../../helpers/checkIfFormDataChanged';

import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import SuccessAlert from '../../../design-system/SuccessAlert/SuccessAlert';
import SettingsForm from '../../../pages/CustomizeGoalPage/SettingsForm/SettingsForm';
import BlockContainerWithHeader from '../../BlockContainerWithHeader/BlockContainerWithHeader';
import { Button } from '../../../design-system';

const ClientSettingsBlock = ({
    context,
    contextId,
    shouldShowHiddenSettings = false,
    emptyContextMessage,
    setProcessDetail,
    processId,
    refreshFormDataDependencyArray = [],
}) => {
    const [formData, setFormData] = useState(() => {
        if (context) {
            return getSettingsFormDataInitialState(context) || [];
        }
        return [];
    });
    const originalFormData = useRef(formData);

    const { viewType } = useViewType();

    const [areChangesToSave, setAreChangesToSave] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);

    useEffect(() => {
        const isFormChanged = checkIfFormDataChanged(originalFormData.current, formData);

        if (isFormChanged !== areChangesToSave) {
            setAreChangesToSave(isFormChanged);
        }
    }, [formData]);

    useEffect(() => {
        const shouldRefreshFormState = refreshFormDataDependencyArray.some(Boolean);

        if (shouldRefreshFormState) {
            const updatedFormData = context ? getSettingsFormDataInitialState(context) || [] : [];
            setFormData(updatedFormData);
            originalFormData.current = updatedFormData;
        }
    }, [...refreshFormDataDependencyArray]);

    const handleUpdateClientSettings = async () => {
        try {
            const isFormDataValid = checkIsFormDataValid({
                settingsFormData: formData,
                setSettingsFormData: setFormData,
                skipCheckingHiddenSettings: !shouldShowHiddenSettings,
                skipCheckingSharedSettings: false,
            });
            if (!isFormDataValid) {
                return;
            }

            setIsLoading(true);

            const updatedFormData = await replaceFilesInFormData(formData);
            const requestBody = { context: formatSettingsForRequestBody(updatedFormData) };

            const { data } = await client.patch(
                API.ROUTES.library.processContext + contextId + '/',
                requestBody
            );

            setFormData((data) => [...data]);
            originalFormData.current = formData;

            const newContextId = data.id;
            setProcessDetail((prevData) => ({
                ...prevData,
                default_version: {
                    ...prevData.default_version,
                    context: { ...prevData.default_version?.context, id: newContextId },
                },
            }));

            setSuccessAlert({ message: 'Client Settings successfully updated!' });
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        } finally {
            setIsLoading(false);
        }
    };

    const allRequiredFieldsFilled = useMemo(
        () =>
            checkIfDisplayedRequiredFieldsFilled({
                formData,
                areHiddenSettingsDisplayed: shouldShowHiddenSettings,
                skipSharedFields: false,
            }),
        [formData]
    );

    const isContextEmpty =
        !formData?.length ||
        !formData?.some((setting) => ALLOWED_SETTING_ACTION_TYPES.includes(setting.action));

    const isSubmitEnabled = areChangesToSave && allRequiredFieldsFilled;
    const submitButtonState = isLoading ? 'loading' : isSubmitEnabled ? 'default' : 'disabled';

    const newSuggestionAccessState =
        viewType === VIEW_TYPES.admin
            ? NEW_SUGGESTION_ACCESS_STATE.on
            : NEW_SUGGESTION_ACCESS_STATE.off;

    return (
        <BlockContainerWithHeader
            title="Client Settings"
            description="View and update settings specific to this client."
        >
            {!isContextEmpty && (
                <SettingsForm
                    formData={formData}
                    setFormData={setFormData}
                    shouldShowHiddenSettings={shouldShowHiddenSettings}
                    containerGap={20}
                    sharingState={SHARING_STATE.unavailable}
                    newSuggestionAccessState={newSuggestionAccessState}
                    processId={processId}
                    settingType={SETTING_TYPE.client}
                />
            )}

            {isContextEmpty && (
                <p className="font-body-bold text-body-bold-s text-black">{emptyContextMessage}</p>
            )}

            <div className="flex justify-end pt-5 border-t-1 border-neutral-200">
                <Button
                    size="xs"
                    type="secondary"
                    text="Update Client Settings"
                    state={submitButtonState}
                    onClick={handleUpdateClientSettings}
                />
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
        </BlockContainerWithHeader>
    );
};

export default ClientSettingsBlock;
