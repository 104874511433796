import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useTextareaAutoResize } from '../../hooks/useTextareaAutoResize';

import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper';

const TextAreaWithAutoResize = ({
    name,
    value,
    state = 'default',
    label,
    isRequired = false,
    placeholder,
    errorMessage,
    tipText = '',
    onChange,
    withCopyButton = false,
    minHeight: wrapperMinHeight = 96,
}) => {
    const minHeight = Math.max(wrapperMinHeight - 36, 20);

    const { textareaRef, textareaContainerRef } = useTextareaAutoResize({
        text: value,
        minHeight,
    });

    const wrapperClassName = classNames(
        'w-full py-17 px-11 rounded-2 focus-within:placeholder-transparent caret-neutral-500 border-1 focus-within:border-1 focus-within:outline-0 focus-within:shadow-l3 transition-all ease-in',
        {
            'bg-white': state !== 'disabled',
            'bg-neutral-50': state === 'disabled',
            'border-neutral-300 focus:shadow-neutral-300': state !== 'error',
            'border-red-500 focus:shadow-red-500': state === 'error',
        }
    );

    const textAreaClassName =
        'resize-none font-body text-body-regular-m text-neutral-500 disabled:text-neutral-300 placeholder-neutral-300 focus:placeholder-transparent caret-neutral-500 focus:outline-none bg-transparent';

    return (
        <FormFieldWrapper
            value={value}
            label={label}
            isRequired={isRequired}
            state={state}
            errorMessage={errorMessage}
            tipText={tipText}
            withCopyButton={withCopyButton}
            gap={4}
        >
            <div className={wrapperClassName}>
                <div
                    ref={textareaContainerRef}
                    className="flex flex-col max-w-full"
                    style={{ minHeight: `${minHeight}px` }}
                >
                    <textarea
                        name={name}
                        value={value}
                        rows={1}
                        ref={textareaRef}
                        placeholder={placeholder}
                        disabled={state === 'disabled'}
                        onChange={onChange}
                        className={textAreaClassName}
                    />
                </div>
            </div>
        </FormFieldWrapper>
    );
};

TextAreaWithAutoResize.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    onChange: PropTypes.func,
    withCopyButton: PropTypes.bool,
    minHeight: PropTypes.number,
};

export default TextAreaWithAutoResize;
