import React from 'react';
import PropTypes from 'prop-types';

import { ButtonIcon } from '../index';
import ThumbsUpIcon from '../Icons/ThumbsUpIcon';
import ThumbsDownIcon from '../Icons/ThumbsDownIcon';
import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper';

const RatingControls = ({
    rating,
    label,
    isRequired = false,
    onChange,
    state = 'default',
    errorMessage,
    tipText,
}) => {
    return (
        <FormFieldWrapper
            label={label}
            isRequired={isRequired}
            gap={8}
            state={state}
            tipText={tipText}
            errorMessage={errorMessage}
        >
            <div className="flex items-center gap-2">
                <ButtonIcon
                    type={rating === 1 ? 'secondary' : 'neutral'}
                    size="sm"
                    state={state === 'disabled' ? 'disabled' : 'default'}
                    icon={ThumbsUpIcon}
                    onClick={() => onChange(1)}
                />
                <ButtonIcon
                    type={rating === -1 ? 'secondary' : 'neutral'}
                    size="sm"
                    state={state === 'disabled' ? 'disabled' : 'default'}
                    icon={ThumbsDownIcon}
                    onClick={() => onChange(-1)}
                />
            </div>
        </FormFieldWrapper>
    );
};

RatingControls.propTypes = {
    rating: PropTypes.oneOf([1, 0, -1]).isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    state: PropTypes.oneOf(['default', 'disabled', 'error']),
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
};

export default RatingControls;
