import React from 'react';
import classNames from 'classnames';

import { useOverlayState } from '../../../../hooks/useOverlayState';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import TimestampDisplay from '../../../../design-system/TimestampDisplay/TimestampDisplay';
import ViewSuggestionModal from '../ViewSuggestionModal/ViewSuggestionModal';

const PendingSuggestionCard = ({ suggestion, onSuccessSuggestionAction }) => {
    const { id, title, created_at } = suggestion;

    const { isOpened, open: openModal, close: closeModal } = useOverlayState();
    const { isMobile } = useResponsiveBreakpoints();

    const containerClassName = classNames(
        'px-4 py-3 rounded-2 bg-white overflow-hidden cursor-default',
        'grid grid-rows-[auto_auto] min-[916px]:grid-rows-1 grid-cols-[24px_minmax(60px,_1fr)_44px] md:grid-cols-[24px_minmax(60px,_1fr)_115px] min-[916px]:grid-cols-[24px_minmax(60px,_1fr)_204px_115px] gap-y-2 gap-x-2.5 md:gap-x-3 xl:gap-x-4 items-center'
    );

    return (
        <>
            <div className={containerClassName} onClick={(e) => e.stopPropagation()}>
                <div className="w-6 h-6 min-w-[24px] flex items-center justify-center">
                    <div className="w-[18px] h-[18px] border-1 rounded-full border-neutral-300"></div>
                </div>

                <p className="font-body text-body-regular-s text-black truncate">{title}</p>

                <div className="pt-2 min-[916px]:p-0 border-t-1 border-neutral-200 min-[916px]:border-none row-start-2 row-end-2 col-span-full min-[916px]:row-start-auto min-[916px]:row-end-auto min-[916px]:col-span-1">
                    <TimestampDisplay
                        label="Created At"
                        timestamp={created_at}
                        variant="secondary"
                    />
                </div>

                <button
                    className="py-0.5 font-body-bold text-body-bold-s text-neutral-500 underline underline-offset-2 focus:outline-0"
                    onClick={openModal}
                >
                    {isMobile ? 'View' : 'View suggestion'}
                </button>

                {isOpened && (
                    <ViewSuggestionModal
                        id={id}
                        onSuccess={onSuccessSuggestionAction}
                        onClose={closeModal}
                    />
                )}
            </div>
        </>
    );
};

export default PendingSuggestionCard;
