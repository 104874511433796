export const buildQueryParams = (params) => {
    if (typeof params !== 'object' || params === null) {
        return '';
    }

    return Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
};

export const handlePreviousPageClick = ({ searchParams, setSearchParams, currentPage }) => {
    if (currentPage <= 1) {
        return;
    }

    const urlSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.set('page', `${currentPage - 1}`);
    setSearchParams(urlSearchParams);
};

export const handleNextPageClick = ({ searchParams, setSearchParams, currentPage }) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.set('page', `${currentPage + 1}`);
    setSearchParams(urlSearchParams);
};

export const handleSortOptionSelect = ({
    searchParams,
    setSearchParams,
    sortOption,
    onSearchDropdownClose,
    usePagination = true,
    usePageState = false, // if, in addition to search params, the page state should also be handled
    resetPageState = () => {}, // should be passed if usePageState = true
}) => {
    const urlSearchParams = new URLSearchParams(searchParams);

    urlSearchParams.set('sort', sortOption);
    if (usePagination) {
        urlSearchParams.set('page', '1');
    }
    setSearchParams(urlSearchParams);

    if (usePageState) {
        resetPageState();
    }
    onSearchDropdownClose();
};

export const handleSearch = ({
    searchParams,
    setSearchParams,
    searchValue,
    usePagination = true,
    usePageState = false, // if, in addition to search params, the page state should also be handled
    resetPageState = () => {}, // should be passed if usePageState = true
}) => {
    const urlSearchParams = new URLSearchParams(searchParams);

    urlSearchParams.set('search', searchValue);
    if (usePagination) {
        urlSearchParams.set('page', '1');
    }
    setSearchParams(urlSearchParams);

    if (usePageState) {
        resetPageState();
    }
};

export const handleClearSearchBar = ({
    searchParams,
    setSearchParams,
    setSearchValue,
    usePagination = true,
    usePageState = false, // if, in addition to search params, the page state should also be handled
    resetPageState = () => {}, // should be passed if usePageState = true
}) => {
    const urlSearchParams = new URLSearchParams(searchParams);

    urlSearchParams.delete('search');
    if (usePagination) {
        urlSearchParams.set('page', '1');
    }
    setSearchParams(urlSearchParams);

    setSearchValue('');
    if (usePageState) {
        resetPageState();
    }
};

export const handleAddNewFilterOption = ({
    searchParams,
    setSearchParams,
    currentFilters,
    newFilter,
    usePagination = true,
    usePageState = false, // if, in addition to search params, the page state should also be handled
    resetPageState = () => {}, // should be passed if usePageState = true
    searchParamKey = 'filter',
    shouldSortFilters = false,
}) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    const updatedFilterArray = [...(currentFilters || []), newFilter];

    const updatedFilterValue = shouldSortFilters
        ? updatedFilterArray.sort((a, b) => a - b).join(',')
        : updatedFilterArray.join(',');

    urlSearchParams.set(searchParamKey, updatedFilterValue);
    if (usePagination) {
        urlSearchParams.set('page', '1');
    }
    setSearchParams(urlSearchParams);

    if (usePageState) {
        resetPageState();
    }
};

export const handleRemoveFilterOption = ({
    searchParams,
    setSearchParams,
    currentFilters,
    filterToDelete,
    usePagination = true,
    usePageState = false, // if, in addition to search params, the page state should also be handled
    resetPageState = () => {}, // should be passed if usePageState = true
    searchParamKey = 'filter',
}) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    const updatedFilter = currentFilters?.filter((item) => item !== filterToDelete)?.join(',');
    if (updatedFilter) {
        urlSearchParams.set(searchParamKey, updatedFilter);
    }
    if (!updatedFilter) {
        urlSearchParams.delete(searchParamKey);
    }
    if (usePagination) {
        urlSearchParams.set('page', '1');
    }
    setSearchParams(urlSearchParams);

    if (usePageState) {
        resetPageState();
    }
};

export const handleSelectAndDeselectSearchParamValue = ({
    searchParamKey,
    searchParams,
    setSearchParams,
    currentValue,
    newValue,
    usePagination = true,
    usePageState = false, // if, in addition to search params, the page state should also be handled
    resetPageState = () => {}, // should be passed if usePageState = true
    onDropdownClose = () => {},
}) => {
    const urlSearchParams = new URLSearchParams(searchParams);

    if (currentValue !== newValue) {
        urlSearchParams.set(searchParamKey, newValue);
    }
    if (currentValue === newValue) {
        urlSearchParams.delete(searchParamKey);
    }
    if (usePagination) {
        urlSearchParams.set('page', '1');
    }
    setSearchParams(urlSearchParams);

    if (usePageState) {
        resetPageState();
    }

    onDropdownClose();
};

export const handleChangeTab = ({
    searchParams,
    setSearchParams,
    tab,
    usePagination = true,
    usePageState = false, // if, in addition to search params, the page state should also be handled
    resetPageState = () => {}, // should be passed if usePageState = true
    searchParamKeysToRemove = [],
}) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.set('tab', tab.key);

    if (usePagination) {
        urlSearchParams.set('page', '1');
    }

    searchParamKeysToRemove?.forEach((param) => {
        urlSearchParams.delete(param);
    });
    setSearchParams(urlSearchParams);

    if (usePageState) {
        resetPageState();
    }
};
