import React from 'react';
import RichTextArea from '../../../../design-system/RichTextArea/RichTextArea';

const StaticContentPiece = ({ content, jobLogFeedbackTrigger }) => {
    const { value } = content;

    return (
        <div className="flex gap-2 items-start">
            <div className="flex-1">
                <RichTextArea value={value} previewOnly autoExpand isBorderHidden />
            </div>

            {jobLogFeedbackTrigger}
        </div>
    );
};

export default StaticContentPiece;
