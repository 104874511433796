import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import useUser from '../../hooks/useUser';
import { useClickOutside } from 'hooks';

import { ORGANIZATION_PLAN } from '../../constants/organization';
import { defaultErrorMessage } from '../../constants/errorMessages';

import { copyTaskConfig } from '../../helpers/copyTaskConfig';
import { copyCompiledPrompt } from '../../helpers/copyCompiledPrompt';

import { ErrorWarningLineIcon } from '../../design-system/Icons';
import CheckLineIcon from '../../design-system/Icons/CheckLineIcon';
import ListOption from '../../design-system/ListOption/ListOption';

TaskOptions.propTypes = {
    mode: PropTypes.oneOf(['regular', 'viewOnly']),
    promptId: PropTypes.string.isRequired,
    onModalClose: PropTypes.func.isRequired,
    handleTaskDeleteSelected: PropTypes.func,
    handleTaskDuplicate: PropTypes.func,
    isDuplicateTaskLoading: PropTypes.func,
};

function TaskOptions({
    mode = 'regular',
    onModalClose,
    handleTaskDeleteSelected,
    handleTaskDuplicate,
    isDuplicateTaskLoading,
    promptId,
    libraryLocation,
    task,
    inputs = [],
    outputs = [],
    promptText = '',
    setAlert,
}) {
    const { orgPlan } = useUser();
    const moreOptionRef = useRef(null);
    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const resultId = urlParams.get('result') || null;
    const fillId = urlParams.get('fill') || null;

    const handleIntegrateWithMakeSelected = () => {
        navigate('/integrations/make/prompt-setup');
    };

    const handleIntegrateWithZapierSelected = () => {
        navigate('/integrations/zapier/prompt-setup');
    };

    const handleViewRunHistory = () => {
        if (promptId) {
            navigate(`/library/prompt/${promptId}/history?page=runs`, {
                state: { libraryLocation },
            });
        }
    };

    const handleCopyConfig = () => {
        const currentTask = mode === 'viewOnly' ? task : { ...task, inputs, outputs };

        copyTaskConfig(
            currentTask,
            () =>
                setAlert({
                    status: 'positive',
                    message: `Config copied to clipboard!`,
                    icon: CheckLineIcon,
                }),
            () =>
                setAlert({
                    status: 'critical',
                    message: defaultErrorMessage,
                    icon: ErrorWarningLineIcon,
                })
        );
        onModalClose();
    };

    const handleCopyCompiledPrompt = () => {
        copyCompiledPrompt({ promptText, inputs, setAlert });
        onModalClose();
    };

    const handleViewerModeClick = () => {
        if (resultId) {
            navigate(`/library/prompt/${promptId}/view?result=${resultId}`, {
                state: { libraryLocation },
            });
        } else if (fillId) {
            navigate(`/library/prompt/${promptId}/view?fill=${fillId}`, {
                state: { libraryLocation },
            });
        } else {
            navigate(`/library/prompt/${promptId}/view`, { state: { libraryLocation } });
        }
    };

    useClickOutside(moreOptionRef, onModalClose);

    const modalPosition = {
        regular: 'left-0 bottom-[calc(100%+8px)]',
        viewOnly:
            'bottom-[calc(100%+8px)] max-[500px]:left-[50%] max-[500px]:transform max-[500px]:translate-x-[-50%] min-[500px]:right-0',
    };

    return (
        <div
            ref={moreOptionRef}
            className={`
                bg-white rounded-2 p-[16px] shadow-l1 border-1 border-neutral-200 absolute w-[278px] xs:w-[297px] flex flex-col gap-1 ${modalPosition[mode]}
            `}
        >
            {mode === 'regular' && (
                <ListOption
                    text="Viewer Mode"
                    leadingIconName="eyeIcon"
                    onClick={handleViewerModeClick}
                />
            )}
            <ListOption
                text="View History"
                leadingIconName="historyIcon"
                onClick={handleViewRunHistory}
            />
            <ListOption
                text="Integrate with Zapier"
                leadingIconName="share2"
                onClick={handleIntegrateWithZapierSelected}
            />
            <ListOption
                text="Integrate with Make.com"
                leadingIconName="share2"
                onClick={handleIntegrateWithMakeSelected}
            />
            {orgPlan === ORGANIZATION_PLAN.agency && (
                <ListOption
                    text="Copy Config"
                    leadingIconName="copyIcon"
                    onClick={handleCopyConfig}
                />
            )}
            {mode === 'regular' && (
                <>
                    <ListOption
                        text="Duplicate Prompt"
                        leadingIconName="addToLibrary"
                        isLoading={isDuplicateTaskLoading}
                        onClick={handleTaskDuplicate}
                    />
                    <ListOption
                        text="Copy Compiled Prompt"
                        leadingIconName="fileList3LineIcon"
                        onClick={handleCopyCompiledPrompt}
                    />
                    <ListOption
                        text="Delete Prompt"
                        leadingIconName="deleteBin4LineIcon"
                        iconColor="#E95B69"
                        textColor="#E95B69"
                        onClick={handleTaskDeleteSelected}
                    />
                </>
            )}
        </div>
    );
}

export default TaskOptions;
