import React from 'react';
import PropTypes from 'prop-types';

import { ButtonIcon } from '../../design-system';
import { CloseLineIcon } from '../../design-system/Icons';

const PanelHeader = ({ title, onClose }) => {
    return (
        <div className="flex items-center justify-between gap-3">
            <h2 className="font-body-bold text-body-bold-l text-black">{title}</h2>

            <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
        </div>
    );
};

PanelHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PanelHeader;
