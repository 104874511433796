import React, { useState } from 'react';

import InputCard from '../InputCard/InputCard';
import ProjectVariableLayout from '../ProjectVariableLayout/ProjectVariableLayout';

const ProjectInputsBlock = ({ inputs }) => {
    const [activeInput, setActiveInput] = useState(null);

    const areInputs = !!inputs?.length;

    return (
        <ProjectVariableLayout
            title="Project Inputs"
            description="These are the inputs submitted at project start"
            activeVariable={activeInput}
            setActiveVariable={setActiveInput}
        >
            {areInputs && (
                <div className="flex flex-col gap-3 overflow-y-auto">
                    {inputs.map((input) => {
                        const id = input.id;

                        return (
                            <InputCard
                                key={id}
                                input={input}
                                isActive={activeInput?.id === id}
                                setValuePanel={setActiveInput}
                            />
                        );
                    })}
                </div>
            )}

            {!areInputs && (
                <p className="font-body text-body-regular-m text-neutral-500">
                    No project inputs found
                </p>
            )}
        </ProjectVariableLayout>
    );
};

export default ProjectInputsBlock;
