import React from 'react';

import { SUGGESTION_ACTION } from '../../../../constants/settingsForm';

import SquareIcon from '../../../../design-system/Icons/SquareIcon';
import ActionOption from '../../../../design-system/ActionOption/ActionOption';
import RefreshCcwIcon from '../../../../design-system/Icons/RefreshCcwIcon';
import MultiActionPopup from '../../../../design-system/MultiActionPopup/MultiActionPopup';
import CheckboxLineIcon from '../../../../design-system/Icons/CheckboxLineIcon';

const SuggestionMultiActionPopup = ({ setAction, onClose }) => {
    return (
        <MultiActionPopup
            actionOptions={
                <>
                    <ActionOption
                        label="Approve"
                        description="This will accept the suggestion and implement it"
                        icon={CheckboxLineIcon}
                        onClose={onClose}
                        handleAction={() => setAction(SUGGESTION_ACTION.approve)}
                    />
                    <ActionOption
                        label="Reject"
                        description="This will reject the suggestion and archive the feedback"
                        icon={SquareIcon}
                        onClose={onClose}
                        handleAction={() => setAction(SUGGESTION_ACTION.reject)}
                    />
                    <ActionOption
                        label="Resubmit"
                        description="This will resubmit the feedback for an alternative suggestion "
                        icon={RefreshCcwIcon}
                        onClose={onClose}
                        handleAction={() => setAction(SUGGESTION_ACTION.resubmit)}
                    />
                </>
            }
            onClose={onClose}
        />
    );
};
export default SuggestionMultiActionPopup;
