import { API } from 'constants';
import organizationClient from '../services/organization-api';

import { ORGANIZATION_DATA_LS_KEY } from '../constants/organization';
import { VIEW_TYPES_REDIRECT_PATH } from '../constants/viewTypes';
import { allRoutesPathname, DYNAMIC_TO_INDEX_ROUTES_MAPPING } from '../router/routes/routes';

import { isRouteMatching } from './routesUtils';
import { isRouteAvailableForOrganization } from './routesPermissionsUtils';

export const makeOrganizationChangeRequestAndUpdateLS = async ({ membershipId }) => {
    const { data } = await organizationClient.patch(
        `${API.ROUTES.organization.organizationMembership}${membershipId}/`,
        { is_default: true }
    );

    const newOrgData = {
        id: data.organization.id,
        userRole: data.role,
        orgPlan: data.organization.plan,
        isStaff: data.user.is_staff,
    };

    localStorage.setItem(ORGANIZATION_DATA_LS_KEY, JSON.stringify(newOrgData));

    return { newOrgData };
};

export const navigateUserAfterOrganizationChange = ({
    newOrgData,
    user,
    viewType,
    oppositeViewType,
    toggleViewType,
    isMainTab = false,
}) => {
    if (!newOrgData) {
        window.location.reload();
        return;
    }

    const currentPathName = allRoutesPathname.find((pathname) =>
        isRouteMatching(pathname, window.location.pathname)
    );
    const indexPath = DYNAMIC_TO_INDEX_ROUTES_MAPPING[currentPathName] || currentPathName;

    const { hasPageAccess, hasViewTypesAccess } = isRouteAvailableForOrganization({
        orgPlan: newOrgData.orgPlan,
        userRole: newOrgData.userRole,
        isStaff: newOrgData.isStaff,
        path: indexPath,
        user,
        viewType,
    });

    if (hasPageAccess) {
        if (indexPath === currentPathName) window.location.reload();
        if (indexPath !== currentPathName) window.location.href = indexPath;
    }

    if (!hasPageAccess) {
        if (hasViewTypesAccess) window.location.href = VIEW_TYPES_REDIRECT_PATH[viewType];
        if (!hasViewTypesAccess) {
            if (isMainTab) {
                toggleViewType();
            }
            window.location.href = VIEW_TYPES_REDIRECT_PATH[oppositeViewType];
        }
    }
};
