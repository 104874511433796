export const ROOT_FOLDER_INITIAL_DATA = {
    pagination: {
        page: 1,
        canLoadMore: false,
        isFetched: false,
    },
    results: null,
};

export const EMPTY_FOLDER_INITIAL_DATA = {
    pagination: {
        page: 1,
        canLoadMore: false,
        isFetched: false,
    },
    isExpanded: false,
    results: null,
};
