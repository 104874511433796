import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ToolTipArrowIcon from 'design-system/Icons/ToolTipArrowIcon';

ToolTip.propTypes = {
    position: PropTypes.oneOf([
        'top-left',
        'top-center',
        'top-right',
        'center-right',
        'center-left',
        'bottom-left',
        'bottom-center',
        'bottom-right',
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    isShown: PropTypes.bool,
    shift: PropTypes.string,
    useInternalHoverStateToShowToolTip: PropTypes.bool,
};

function ToolTip(props) {
    const {
        position = 'top-center',
        text,
        children,
        isShown = true,
        shift,
        useInternalHoverStateToShowToolTip = false,
    } = props;

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        if (useInternalHoverStateToShowToolTip) {
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        if (useInternalHoverStateToShowToolTip) {
            setIsHovered(false);
        }
    };

    const arrowStyle = {
        'top-left': 'top-0 left-[10px] translate-y-[-100%]',
        'top-center': 'top-0 left-1/2 translate-x-[-50%] translate-y-[-100%]',
        'top-right': 'top-0 right-[10px] translate-y-[-100%]',
        'center-right': 'rotate-90 top-1/2 right-[-9px] translate-y-[-50%]',
        'center-left': 'rotate-[-90deg] top-1/2 left-[-9px] translate-y-[-50%]',
        'bottom-left': 'rotate-180 bottom-0 left-[10px] translate-y-[100%]',
        'bottom-center': 'rotate-180 bottom-0 left-1/2 translate-x-[-50%] translate-y-[100%]',
        'bottom-right': 'rotate-180 bottom-0 right-[10px] translate-y-[100%]',
    };

    const toolTipStyle = {
        'top-left': `top-full translate-y-[8px]`,
        'top-center': 'top-full translate-y-[8px] translate-x-[-50%]',
        'top-right': `top-full translate-y-[8px]`,
        'center-right': 'top-1/2 translate-y-[-50%] translate-x-[-8px]',
        'center-left': 'top-1/2 translate-y-[-50%] translate-x-[8px]',
        'bottom-left': `bottom-full translate-y-[-8px]`,
        'bottom-center': 'bottom-full translate-y-[-8px] translate-x-[-50%]',
        'bottom-right': `bottom-full translate-y-[-8px]`,
    };

    const toolTipPositionStyle = {
        'top-left': { left: shift ? shift : '70%' },
        'top-center': { left: '50%' },
        'top-right': { right: shift ? shift : '70%' },
        'center-right': { right: '100%' },
        'center-left': { left: shift ? shift : '100%' },
        'bottom-left': { left: shift ? shift : '70%' },
        'bottom-center': { left: '50%' },
        'bottom-right': { right: shift ? shift : '70%' },
    };

    const toolTipShow = {
        true: 'opacity-100',
        false: 'opacity-0',
    };

    const arrowPosition = arrowStyle[position];
    const toolTipPosition = toolTipStyle[position];

    const isToolTipShown = useInternalHoverStateToShowToolTip ? isHovered : isShown;
    const toolTipVisibility = toolTipShow[isToolTipShown];

    return (
        <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {children}
            <div
                className={classNames(
                    'absolute z-30 block bg-neutral-300 py-1.5 px-2.5 rounded-1 transition-opacity duration-300 max-w-[290px]',
                    'font-body text-body-regular-xs text-white max-xs:max-w-[50vw]',
                    toolTipPosition,
                    toolTipVisibility
                )}
                style={{
                    width: 'max-content',
                    pointerEvents: 'none',
                    ...toolTipPositionStyle[position],
                }}
            >
                {text}
                <span className={classNames('absolute', arrowPosition)}>
                    <ToolTipArrowIcon width={12} height={7} color="#5E6470" />
                </span>
            </div>
        </div>
    );
}

export default ToolTip;
