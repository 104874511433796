import React, { useState } from 'react';

import { SUGGESTION_ACTION } from '../../../../constants/settingsForm';

import Loading from '../../../../components/Loading';
import Message2LineIcon from '../../../../design-system/Icons/Message2LineIcon';
import PendingSuggestionCard from '../PendingSuggestionCard/PendingSuggestionCard';
import { Button, ButtonIcon, SvgIcon } from '../../../../design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from '../../../../design-system/Icons';

const PendingSuggestionsBanner = ({ suggestionHookResponse, refreshSettingsForms }) => {
    const {
        options: suggestions,
        setOptions: setSuggestions,
        totalOptions: total,
        setTotalOptions: setTotal,
        refreshCurrentPageData,
        canLoadMoreOptions: hasNextPage,
        optionsLoading: loading,
        setPage,
    } = suggestionHookResponse;

    const [isExpanded, setIsExpanded] = useState(false);

    const onSuccessSuggestionAction = async ({ id, action, type, inputData }) => {
        if (action === SUGGESTION_ACTION.approve) {
            await refreshSettingsForms({ type, inputData });
        }

        setSuggestions((prev) => prev?.filter((suggestion) => suggestion.id !== id));
        setTotal((total) => total - 1);

        refreshCurrentPageData();
    };

    const ExpandIcon = isExpanded ? ArrowUpSLineIcon : ArrowDownSLineIcon;

    return (
        <div
            className="w-full p-4 rounded-2 bg-blue-300/20 border-1 border-blue-300 cursor-pointer"
            onClick={() => setIsExpanded((prev) => !prev)}
        >
            <div className="flex items-start gap-2 md:gap-3">
                <div className="w-8 h-8 min-w-[32px] rounded-2 bg-white flex items-center justify-center">
                    <SvgIcon color="#63D6FA" icon={Message2LineIcon} size="large" />
                </div>

                <div className="flex-1 flex items-center justify-between gap-2 md:gap-3">
                    <div className="flex flex-col gap-2">
                        <h3 className="font-body-bold text-body-bold-m text-black">
                            {total} New Suggestion{total === 1 ? '' : 's'}
                        </h3>
                        <p className="font-body text-body-regular-s text-neutral-400">
                            Review and approve suggestions based on your submitted feedback
                        </p>
                    </div>

                    <ButtonIcon type="link" size="sm" icon={ExpandIcon} />
                </div>
            </div>

            {isExpanded && (
                <div className="flex flex-col gap-2 md:ml-[44px] mt-3">
                    {suggestions.map((suggestion) => (
                        <PendingSuggestionCard
                            key={suggestion.id}
                            suggestion={suggestion}
                            onSuccessSuggestionAction={onSuccessSuggestionAction}
                        />
                    ))}

                    {hasNextPage && !loading && (
                        <div className="flex justify-end">
                            <Button
                                type="link"
                                size="sm"
                                text="View More"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPage((page) => page + 1);
                                }}
                            />
                        </div>
                    )}

                    {loading && (
                        <div className="min-h-[40px]">
                            <Loading withText={false} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PendingSuggestionsBanner;
