import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { defaultErrorMessage } from '../../constants/errorMessages';

import ErrorWarningLineIcon from '../Icons/ErrorWarningLineIcon';
import Alert from '../Alert/Alert';
import CheckLineIcon from '../Icons/CheckLineIcon';
import AddToLibraryIcon from '../Icons/AddToLibraryIcon';
import EditLineIcon from '../Icons/EditLineIcon';
import Trash2Icon from '../Icons/Trash2Icon';
import FilePlusIcon from '../Icons/FilePlusIcon';
import FormFieldFooter from '../FormFieldFooter/FormFieldFooter';
import { Badge, ButtonIcon } from '../index';
import { FileCopyLineIcon } from '../Icons';
import classNames from 'classnames';

FormFieldWrapper.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    showLabelAsBadge: PropTypes.bool,
    labelBadgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
    isRequired: PropTypes.bool,
    state: PropTypes.oneOf(['default', 'disabled', 'error']),
    errorMessage: PropTypes.string,
    tipText: PropTypes.string,
    children: PropTypes.node,
    withCopyButton: PropTypes.bool,
    withDeleteButton: PropTypes.bool,
    withEditButton: PropTypes.bool,
    withDuplicateButton: PropTypes.bool,
    withAddFileButton: PropTypes.bool,
    onDeleteButtonClick: PropTypes.func,
    onEditButtonClick: PropTypes.func,
    onDuplicateButtonClick: PropTypes.func,
    onAddFileButtonClick: PropTypes.func,
    value: PropTypes.string,
    labelToCopyAlert: PropTypes.string,
    gap: PropTypes.number,
    bottomSpace: PropTypes.number,
    labelMarginBottom: PropTypes.number,
    topRightTipText: PropTypes.string,
    actionsBarLeftContent: PropTypes.node,
    actionsBarRightContent: PropTypes.node,
    actionsBarCustomGap: PropTypes.number,
};

function FormFieldWrapper({
    children,
    state = 'default',
    label,
    showLabelAsBadge = false,
    labelBadgeColor = 'peach',
    isRequired,
    errorMessage,
    tipText,
    withCopyButton = false,
    withDeleteButton = false,
    withEditButton = false,
    withDuplicateButton = false,
    withAddFileButton = false,
    onDeleteButtonClick = () => {},
    onEditButtonClick = () => {},
    onDuplicateButtonClick = () => {},
    onAddFileButtonClick = () => {},
    value, // if you set withCopyButton = true then this value will be copied to clipboard
    labelToCopyAlert, // if label is a React element, pass here label as a string to display in Success Copy Alert
    gap = 4, // (in px)
    bottomSpace = 0, // additional space between {children} and errorMessage or tipText
    labelMarginBottom = 0,
    topRightTipText = '',
    actionsBarLeftContent = null,
    actionsBarRightContent = null,
    actionsBarCustomGap = null,
}) {
    const [copyStatusAlert, setCopyStatusAlert] = useState({
        status: '',
        message: '',
    });

    const handleCopy = () => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                setCopyStatusAlert({
                    status: 'positive',
                    message: `${labelToCopyAlert || label} copied to clipboard!`,
                });
            })
            .catch(() => {
                setCopyStatusAlert({ status: 'critical', message: defaultErrorMessage });
            });
    };

    const includesActionButton =
        withCopyButton ||
        withDeleteButton ||
        withEditButton ||
        withDuplicateButton ||
        withAddFileButton;

    const withRightCornerElements =
        includesActionButton || topRightTipText || actionsBarLeftContent || actionsBarRightContent;

    const actionBarClassName = classNames(
        'flex items-center',
        !actionsBarCustomGap && 'gap-1 sm:gap-2'
    );
    const actionBarStyle = actionsBarCustomGap ? { gap: `${actionsBarCustomGap}px` } : {};

    return (
        <>
            <div className="flex flex-col w-full" style={{ gap: `${gap}px` }}>
                {(label || withRightCornerElements) && (
                    <div
                        className={`flex items-center gap-2 ${
                            label ? 'justify-between' : 'justify-end'
                        }`}
                        style={{ marginBottom: `${labelMarginBottom}px` }}
                    >
                        {label && (
                            <div className="font-body-bold text-body-bold-s text-neutral-500">
                                {showLabelAsBadge ? (
                                    <Badge text={label} color={labelBadgeColor} />
                                ) : (
                                    label
                                )}
                                {isRequired && (
                                    <sup className="text-red-500 leading-1 ml-0.5">*</sup>
                                )}
                            </div>
                        )}
                        {withRightCornerElements && (
                            <div className={actionBarClassName} style={actionBarStyle}>
                                {actionsBarLeftContent}
                                {withCopyButton && (
                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        state={!value ? 'disabled' : 'default'}
                                        icon={FileCopyLineIcon}
                                        onClick={handleCopy}
                                    />
                                )}
                                {withDuplicateButton && (
                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        state="default"
                                        icon={AddToLibraryIcon}
                                        onClick={onDuplicateButtonClick}
                                    />
                                )}
                                {withEditButton && (
                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        state="default"
                                        icon={EditLineIcon}
                                        onClick={onEditButtonClick}
                                    />
                                )}
                                {withDeleteButton && (
                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        state="default"
                                        icon={Trash2Icon}
                                        onClick={onDeleteButtonClick}
                                    />
                                )}
                                {withAddFileButton && (
                                    <ButtonIcon
                                        type="link"
                                        size="xs"
                                        state="default"
                                        icon={FilePlusIcon}
                                        onClick={onAddFileButtonClick}
                                    />
                                )}
                                {!!topRightTipText && (
                                    <p className="font-body text-body-regular-xs text-neutral-300">
                                        {topRightTipText}
                                    </p>
                                )}
                                {actionsBarRightContent}
                            </div>
                        )}
                    </div>
                )}

                {children}

                <FormFieldFooter
                    state={state}
                    errorMessage={errorMessage}
                    tipText={tipText}
                    wrapperStyle={{ marginTop: `${bottomSpace}px` }}
                />
            </div>

            {copyStatusAlert.message && (
                <Alert
                    status={copyStatusAlert.status}
                    message={copyStatusAlert.message}
                    icon={
                        copyStatusAlert.status === 'positive' ? CheckLineIcon : ErrorWarningLineIcon
                    }
                    autoCloseInMS={2000}
                    handleClose={() => setCopyStatusAlert({ status: '', message: '' })}
                />
            )}
        </>
    );
}

export default FormFieldWrapper;
