import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';

export const scenarioRoutes = [
    {
        path: '/scenario/:scenarioId',
        indexPath: '/playbooks',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ClientScenarioDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
];
