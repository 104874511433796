import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { API } from 'constants';
import operateClient from '../../services/operate-api';

import useUser from '../../hooks/useUser';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useWrongOrgOrViewTypeNavBlocker } from '../../hooks/useWrongOrgOrViewTypeNavBlocker';

import { handlePageDataLoadError } from '../../helpers/handlePageDataLoadError';
import { setNewFeedbackToThreadFeedbacks } from '../../helpers/threadsUtils';

import Loading from '../../components/Loading';
import ThreadDetailPageHeader from './ThreadDetailPageHeader/ThreadDetailPageHeader';
import GoalCard from './GoalCard/GoalCard';
import RestartSidePanel from './RestartSidePanel/RestartSidePanel';
import CancelConfirmationModal from './CancelConfirmationModal/CancelConfirmationModal';
import FeedbackPanel from './FeedbackPanel/FeedbackPanel';
import ConfirmReloadReportModal from './ConfirmReloadReportModal/ConfirmReloadReportModal';
import ConfirmStatusChangeModal from './ConfirmStatusChangeModal/ConfirmStatusChangeModal';
import ShareFeedbackModal from '../../components/ShareFeedbackModal/ShareFeedbackModal';
import SuccessAlert from '../../design-system/SuccessAlert/SuccessAlert';

const fetcher = (url) => operateClient.get(url).then((res) => res.data);

const ThreadDetailPage = () => {
    const { threadId } = useParams();

    const { user } = useUser();

    const [searchParams] = useSearchParams();
    const withArchivedWorkers = Boolean(searchParams.get('archived'));

    const navigate = useNavigate();
    const location = useLocation();
    const backlinkHref = location.state?.from ?? '/threads';
    const { width: screenWidth } = useWindowSize();

    const [shouldRefresh, setShouldRefresh] = useState(true); // thread.status === 'started'
    const requestEndpoint = withArchivedWorkers
        ? `${API.ROUTES.operate.thread}${threadId}/?archived=true`
        : `${API.ROUTES.operate.thread}${threadId}/`;

    const { data, error, isLoading, mutate } = useSWR(requestEndpoint, fetcher, {
        refreshInterval: shouldRefresh ? 5000 : 0,
    });

    const [thread, setThread] = useState(null);

    const [feedbackPanelOpened, setFeedbackPanelOpened] = useState(false);
    const [restartSidePanel, setRestartSidePanel] = useState({ opened: false });
    const [cancelConfirmationModal, setCancelConfirmationModal] = useState({ opened: false });
    const [isReloadReportModalOpened, setIsReloadReportModalOpened] = useState(false);
    const [isConfirmStatusChangeModalOpened, setIsConfirmStatusChangeModalOpened] = useState(false);

    const [feedbackModal, setFeedbackModal] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);

    const [pageHeaderHeight, setPageHeaderHeight] = useState(0);
    const pageContentHeight =
        feedbackPanelOpened && screenWidth >= 970 ? `calc(100% - ${pageHeaderHeight}px)` : 'auto'; // height of the page without header

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    useDocumentTitle(thread && thread.name);

    useEffect(() => {
        if (data) {
            setShouldRefresh(data.status === 'started');
            setThread(data); // We use the 'thread' state instead of immediately showing data from useSWR. This helps display the loader only in the section with workers, not the whole page, when toggling the display of archived workers. The header remains unchanged from the previous request.
        }
    }, [data]);

    if (error) {
        handlePageDataLoadError({
            e: error,
            setIsWrongOrg,
            setRedirectPath,
            redirectPath: '/threads',
            generalErrorHandler: () => navigate(backlinkHref),
        });
    }

    const onSuccessFeedbackShare = (data) => {
        setNewFeedbackToThreadFeedbacks({ setThread, newFeedback: data, user });
    };

    const mainContainerRef = useRef(null);
    const contentContainerRef = useRef(null);

    const scrollableContainerRef =
        !feedbackPanelOpened || screenWidth < 970 ? mainContainerRef : contentContainerRef;

    return (
        <div
            className={`page-position bg-neutral-50 flex flex-col ${
                (!feedbackPanelOpened || screenWidth < 970) && 'overflow-y-auto'
            }`}
            ref={mainContainerRef}
        >
            {thread ? (
                <>
                    <ThreadDetailPageHeader
                        thread={thread}
                        setThread={setThread}
                        setRestartSidePanel={setRestartSidePanel}
                        setCancelConfirmationModal={setCancelConfirmationModal}
                        setFeedbackPanelOpened={setFeedbackPanelOpened}
                        setIsReloadReportModalOpened={setIsReloadReportModalOpened}
                        setIsConfirmStatusChangeModalOpened={setIsConfirmStatusChangeModalOpened}
                        feedbackCount={thread.feedback?.length}
                        setPageHeaderHeight={setPageHeaderHeight}
                    />
                    <div
                        className="flex flex-grow"
                        style={{
                            height: pageContentHeight,
                        }}
                    >
                        {(!feedbackPanelOpened || screenWidth >= 970) && (
                            <div
                                className={`px-5 pt-4 pb-[90px] sm:px-8 sm:pt-8 sm:pb-[40px] flex flex-col gap-2.5 flex-grow h-full ${
                                    feedbackPanelOpened && 'overflow-y-auto'
                                }`}
                                ref={contentContainerRef}
                            >
                                {isLoading && !data ? (
                                    <div className="flex justify-center items-center pt-4">
                                        <Loading />
                                    </div>
                                ) : (
                                    <>
                                        {thread.workers?.map((worker) => (
                                            <GoalCard
                                                key={worker.id}
                                                worker={worker}
                                                setThread={setThread}
                                                processId={thread.process?.id}
                                                setRestartSidePanel={setRestartSidePanel}
                                                setCancelConfirmationModal={
                                                    setCancelConfirmationModal
                                                }
                                                setFeedbackModal={setFeedbackModal}
                                                smallerWidth={feedbackPanelOpened}
                                                scrollableContainerRef={scrollableContainerRef}
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        )}
                        {feedbackPanelOpened && (
                            <FeedbackPanel
                                threadId={threadId}
                                feedback={thread.feedback}
                                onFeedbackSuccess={onSuccessFeedbackShare}
                                onClose={() => setFeedbackPanelOpened(false)}
                            />
                        )}
                    </div>
                </>
            ) : (
                <div className="flex-grow h-full flex justify-center items-center">
                    <Loading />
                </div>
            )}
            {restartSidePanel.opened && (
                <RestartSidePanel
                    restartTarget={restartSidePanel.restartTarget}
                    workerId={restartSidePanel.workerId}
                    threadId={threadId}
                    mutate={mutate}
                    onClose={() => setRestartSidePanel({ opened: false })}
                />
            )}
            {cancelConfirmationModal.opened && (
                <CancelConfirmationModal
                    cancelTarget={cancelConfirmationModal.cancelTarget}
                    workerId={cancelConfirmationModal.workerId}
                    goalName={cancelConfirmationModal.goalName}
                    threadId={threadId}
                    threadName={thread?.name}
                    setThread={setThread}
                    mutate={mutate}
                    onClose={() => setCancelConfirmationModal({ opened: false })}
                />
            )}
            {feedbackModal && (
                <ShareFeedbackModal
                    target="thread"
                    targetId={threadId}
                    state="filled"
                    variant="secondary"
                    initialRating={feedbackModal.initialRating}
                    setSuccessAlert={setSuccessAlert}
                    onSuccessFeedbackShare={onSuccessFeedbackShare}
                    onClose={() => setFeedbackModal(null)}
                />
            )}
            {isReloadReportModalOpened && (
                <ConfirmReloadReportModal
                    threadId={threadId}
                    threadName={thread?.name}
                    setSuccessAlert={setSuccessAlert}
                    onClose={() => setIsReloadReportModalOpened(false)}
                />
            )}
            {isConfirmStatusChangeModalOpened && (
                <ConfirmStatusChangeModal
                    threadId={threadId}
                    threadName={thread?.name}
                    mutate={mutate}
                    onClose={() => setIsConfirmStatusChangeModalOpened(false)}
                />
            )}

            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
        </div>
    );
};

export default ThreadDetailPage;
