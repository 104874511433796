import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { handleCopyFormattedText } from '../../helpers/handleCopyFormattedText';
import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';

import CopyAlert from '../CopyAlert/CopyAlert';
import MobilePopup from '../MobilePopup/MobilePopup';
import RichTextArea from '../RichTextArea/RichTextArea';
import SuccessAlert from '../SuccessAlert/SuccessAlert';
import FileTextLineIcon from '../Icons/FileTextLineIcon';
import LogFeedbackTrigger from '../../components/LogFeedbackTrigger/LogFeedbackTrigger';
import QuickAddToDocModal from '../../components/QuickAddToDocModal/QuickAddToDocModal';
import CollapseDiagonalLine from '../Icons/CollapseDiagonalLine';
import { ButtonIcon } from '../index';
import { FileCopyLineIcon } from '../Icons';

const MarkdownPreviewWithAdditionalActions = ({
    value,
    label,
    placeholder,
    showAsMobilePopup = false,
    withLogFeedbackButton = false, // implemented just for desktop view
    threadId, // is used for Log Feedback Trigger
    onPopupClose = () => {},
}) => {
    const containerRef = useRef(null);

    const [isAddToDocModalOpened, setIsAddToDocModalOpened] = useState(false);
    const [successAlert, setSuccessAlert] = useState(null);
    const [copyAlert, setCopyAlert] = useState(null);

    const openAddToDocModal = () => setIsAddToDocModalOpened(true);

    const closeAddToDocModal = () => setIsAddToDocModalOpened(false);

    const isFilled = !!value;

    const { isMobile } = useResponsiveBreakpoints();

    const formattedValue = typeof value === 'string' ? value : JSON.stringify(value, null, 4);

    const modalAndAlertsMarkup = (
        <>
            {isAddToDocModalOpened && (
                <QuickAddToDocModal
                    content={value}
                    setSuccessAlert={setSuccessAlert}
                    useCustomIsMobileState
                    isMobile={showAsMobilePopup || isMobile}
                    onClose={closeAddToDocModal}
                />
            )}

            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
            <CopyAlert copyAlert={copyAlert} setCopyAlert={setCopyAlert} />
        </>
    );

    if (showAsMobilePopup) {
        const copyFormattedMessage = () => {
            const node = containerRef.current?.querySelector('.wmde-markdown');
            if (node) {
                handleCopyFormattedText({
                    node,
                    setCopyAlert,
                });
            }
        };

        const bottomButtonsGroup = (
            <div className="px-3 flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <ButtonIcon
                        type="link"
                        size="sm"
                        icon={FileTextLineIcon}
                        onClick={openAddToDocModal}
                    />
                    <ButtonIcon
                        type="link"
                        size="sm"
                        icon={FileCopyLineIcon}
                        onClick={copyFormattedMessage}
                    />
                </div>

                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={CollapseDiagonalLine}
                    onClick={onPopupClose}
                />
            </div>
        );

        return (
            <MobilePopup
                onClose={onPopupClose}
                withCloseButton={false}
                bottomButtonsGroup={bottomButtonsGroup}
                containerCustomPaddings="px-1 pb-4"
                minHeight={216}
                customGap={16}
                maxHeight="94vh"
                closeOnClickOutside={onPopupClose}
            >
                <div
                    className="px-3 flex-1 h-full min-h-full flex flex-col gap-2"
                    ref={containerRef}
                >
                    <p className="font-body-bold text-body-bold-xl text-neutral-500">{label}</p>

                    <RichTextArea
                        value={isFilled ? formattedValue : placeholder}
                        previewOnly
                        autoExpand
                        darkBorderColor
                        customPadding="px-3 py-4"
                        previewMinHeight={45}
                        markdownColor={isFilled ? '#1F2125' : '#CFD6E5'}
                    />
                </div>
                {modalAndAlertsMarkup}
            </MobilePopup>
        );
    }

    const additionalButtons = (
        <>
            {withLogFeedbackButton && (
                <LogFeedbackTrigger target="thread" targetId={threadId} type="button_icon" />
            )}
            <ButtonIcon type="link" size="xs" icon={FileTextLineIcon} onClick={openAddToDocModal} />
        </>
    );

    return (
        <>
            <RichTextArea
                value={isFilled ? formattedValue : placeholder}
                label={label}
                previewOnly
                autoExpand
                darkBorderColor
                customPadding="px-3 py-4"
                withCopyButton={isFilled}
                markdownColor={isFilled ? '#1F2125' : '#CFD6E5'}
                previewMinHeight={45}
                additionalButtons={isFilled ? additionalButtons : null}
            />

            {modalAndAlertsMarkup}
        </>
    );
};

MarkdownPreviewWithAdditionalActions.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    showAsMobilePopup: PropTypes.bool,
    withLogFeedbackButton: PropTypes.bool,
    threadId: PropTypes.string,
    onPopupClose: PropTypes.func,
};

export default MarkdownPreviewWithAdditionalActions;
