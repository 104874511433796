import React from 'react';

import MarkdownPreviewWithAdditionalActions from '../../../../design-system/MarkdownPreviewWithAdditionalActions/MarkdownPreviewWithAdditionalActions';

const notFilledStateMessage = '*Not filled yet*';

const InfoContentPiece = ({ content, pageType, jobId }) => {
    const { value, is_filled, label } = content;

    const withLogFeedbackButton = !!(is_filled && pageType === 'job' && jobId);

    return (
        <MarkdownPreviewWithAdditionalActions
            value={is_filled ? value : ''}
            label={label}
            withLogFeedbackButton={withLogFeedbackButton}
            threadId={jobId}
            placeholder={notFilledStateMessage}
        />
    );
};

export default InfoContentPiece;
