import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { TASK_TYPES_ICON } from '../../../constants/library';
import FileIcon from '../../../design-system/Icons/FileIcon';
import ClockIcon from '../../../design-system/Icons/ClockIcon';
import GitPullRequest from '../../../design-system/Icons/GitPullRequest';
import GoalTemplateMoreOptionsPopup from '../GoalTemplateMoreOptionsPopup/GoalTemplateMoreOptionsPopup';
import ConfirmGoalTemplateDeletionModal from '../TemplateDetailPage/ConfirmGoalTemplateDeletionModal/ConfirmGoalTemplateDeletionModal';
import { More2FillIcon } from '../../../design-system/Icons';
import { Badge, ButtonIcon } from '../../../design-system';
import TimestampDisplay from '../../../design-system/TimestampDisplay/TimestampDisplay';
import HighlightedIcon from '../../../design-system/HighlightedIcon/HighlightedIcon';

const GoalTemplateCard = ({ item, requestKeysToMutate, setAlert }) => {
    const location = useLocation();
    const { id, name, description, is_live, current_version, tasks, updated_at, is_editing } = item;

    const [moreOptionsPopupOpened, setMoreOptionsPopupOpened] = useState(false);
    const [confirmDeletionModalOpened, setConfirmDeletionModalOpened] = useState(false);

    const taskCount = tasks?.length;

    const toggleMoreOptionsPopup = (e) => {
        e?.preventDefault();
        setMoreOptionsPopupOpened(true);
    };

    return (
        <>
            <Link
                to={`/templates/goal/${id}`}
                state={{ from: location }}
                className="w-full h-full relative rounded-2 bg-white border-1 border-neutral-100 p-5 flex flex-col gap-4 justify-between self-stretch"
            >
                <div className="absolute top-1.5 right-1">
                    <ButtonIcon
                        type="link"
                        size="xs"
                        icon={More2FillIcon}
                        onClick={toggleMoreOptionsPopup}
                    />
                    {moreOptionsPopupOpened && (
                        <GoalTemplateMoreOptionsPopup
                            goalTemplateData={item}
                            setCopyAlert={setAlert}
                            openConfirmDeletionModal={() => {
                                setMoreOptionsPopupOpened(false);
                                setConfirmDeletionModalOpened(true);
                            }}
                            popupLocation="libraryPage"
                            onClose={() => setMoreOptionsPopupOpened(false)}
                        />
                    )}
                </div>
                <div>
                    <div className="flex items-center gap-2 mb-2 w-[calc(100%-6px)]">
                        <HighlightedIcon icon={FileIcon} variant="neutral" />

                        <p className="font-body-bold text-body-bold-s text-black line-clamp-2">
                            {name}
                        </p>
                    </div>
                    <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-2 ">
                        {description}
                    </p>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-1">
                        {is_live ? (
                            <Badge text={current_version} color="neutral" />
                        ) : (
                            <div className="px-2 py-0.5 border-1 border-neutral-200 uppercase font-body text-body-regular-xs text-neutral-300 rounded-full">
                                Draft
                            </div>
                        )}
                        <Badge
                            text={`${taskCount || 'No'} ${taskCount === 1 ? 'Task' : 'Tasks'}`}
                            color="peach"
                        />
                        <div className="flex items-center gap-2 flex-wrap">
                            {tasks?.map((task, index) => {
                                const Icon = TASK_TYPES_ICON[task.type];
                                return Icon ? (
                                    <Icon key={index} width={14} height={14} color="#754DCF" />
                                ) : (
                                    <></>
                                );
                            })}
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-1">
                            <ClockIcon width={14} height={14} color="#754DCF" />
                            <TimestampDisplay
                                label="Last Updated"
                                timestamp={updated_at}
                                dateTimeFormat="MM/dd/yyyy"
                                customLabelStyles="text-neutral-500"
                            />
                        </div>
                        <GitPullRequest
                            width={14}
                            height={14}
                            color={is_editing ? '#754DCF' : '#CFD6E5'}
                        />
                    </div>
                </div>
            </Link>
            {confirmDeletionModalOpened && (
                <ConfirmGoalTemplateDeletionModal
                    goalTemplateId={id}
                    goalTemplateName={name}
                    requestKeysToMutate={requestKeysToMutate}
                    setSuccessAlert={setAlert}
                    onClose={() => setConfirmDeletionModalOpened(false)}
                />
            )}
        </>
    );
};

export default GoalTemplateCard;
