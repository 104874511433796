import React from 'react';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useDefaultSearchParams } from '../../hooks/useDefaultSearchParams';

import BasesIndex from './BasesIndex/BasesIndex';
import BasesPageHeader from './BasesPageHeader/BasesPageHeader';

const BasesPage = () => {
    useDefaultSearchParams({ checkPage: true, checkSort: true });

    useDocumentTitle('Knowledge Sources');

    return (
        <div className="w-full sm:w-[calc(100%-68px)] p-4 sm:p-0 flex flex-col gap-5">
            <BasesPageHeader />

            <BasesIndex />
        </div>
    );
};

export default BasesPage;
