import React, { useState } from 'react';

import { defaultErrorMessage } from '../../constants/errorMessages';
import { makeOrganizationChangeRequestAndUpdateLS } from '../../helpers/changeOrganizationUtils';

import Modal from '../../design-system/Modal/Modal';
import ErrorAlert from '../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../design-system/ModalHeader/ModalHeader';
import OrganizationSelect from './OrganizationSelect/OrganizationSelect';
import { Button } from '../../design-system';

const WrongOrganizationBlockerModal = ({ onClose, onCloseAndRedirect }) => {
    const [selectedOrganization, setSelectedOrganization] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleChangeOrg = async () => {
        try {
            if (!selectedOrganization) return;

            const { membershipId } = selectedOrganization;
            setIsLoading(true);

            await makeOrganizationChangeRequestAndUpdateLS({ membershipId });
            setIsLoading(false);

            window.location.reload();
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <Modal size="semiMedium" onClose={onCloseAndRedirect} resetPadding>
            <div className="p-5 md:p-6 flex flex-col gap-5 md:gap-6 w-full">
                <ModalHeader
                    title="Switch Organizations to View Link"
                    onClose={onCloseAndRedirect}
                />

                <p className="font-body text-body-regular-m text-neutral-500">
                    This organization doesn’t have access to this link. Select the organization to
                    switch to below. This will switch everywhere,{' '}
                    <span className="font-body-bold text-body-bold-m">
                        you will lose unsaved work!
                    </span>
                </p>

                <div className="flex flex-col xs:flex-row xs:items-start justify-between gap-2 max-w-full">
                    <OrganizationSelect
                        selectedOrgData={selectedOrganization}
                        handleChange={setSelectedOrganization}
                    />
                    <Button
                        type="secondary"
                        size="sm"
                        text="Switch"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleChangeOrg}
                    />
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default WrongOrganizationBlockerModal;
