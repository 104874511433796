import React from 'react';
import classNames from 'classnames';

import VariableValuePanel from '../VariableValuePanel/VariableValuePanel';

const ProjectVariableLayout = ({
    title,
    description,
    activeVariable,
    setActiveVariable,
    children,
}) => {
    const isPanelOpened = !!activeVariable;

    const listClassName = classNames(
        'overflow-y-auto h-full max-h-full px-4 xs:px-5 sm:px-8 pb-[40px] lg:pl-[40px] xl:pl-[60px] pt-4 sm:pt-5',
        {
            'w-full lg:pr-[40px] xl:pr-[60px]': !isPanelOpened,
            'w-full lg:w-1/3 lg:pr-6': isPanelOpened,
        }
    );

    return (
        <div className="relative flex-1">
            <div className="overflow-hidden absolute inset-0 flex flex-col lg:flex-row">
                <div className={listClassName}>
                    <div className="mb-5">
                        <h3 className="font-body-bold text-body-bold-m text-neutral-500">
                            {title}
                        </h3>
                        <p className="font-body text-body-regular-s text-neutral-300">
                            {description}
                        </p>
                    </div>

                    {children}
                </div>

                {activeVariable && (
                    <VariableValuePanel
                        variable={activeVariable}
                        onClose={() => setActiveVariable(null)}
                    />
                )}
            </div>
        </div>
    );
};

export default ProjectVariableLayout;
