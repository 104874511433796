import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CardIndicatorLineLayout = ({
    indicatorColor = '#CFD6E5',
    indicatorPaddings = 'pr-1.5 pl-0 md:px-2.5',
    gap = 8,
    children,
}) => {
    const statusIndicatorClassName = classNames(
        'pt-2.5 flex flex-col gap-0.5 items-center',
        indicatorPaddings
    );
    const statusCircleClassName = 'w-[7px] h-[7px] rounded-full';

    return (
        <div className="w-full max-w-full flex" style={{ gap }}>
            <div className={statusIndicatorClassName}>
                <div
                    className={statusCircleClassName}
                    style={{ backgroundColor: indicatorColor }}
                ></div>
                <div className="flex-grow w-[1px] bg-neutral-200"></div>
            </div>

            <div className="flex-1 overflow-hidden">{children}</div>
        </div>
    );
};

CardIndicatorLineLayout.propTypes = {
    indicatorColor: PropTypes.string,
    indicatorPaddings: PropTypes.string,
    gap: PropTypes.number,
    children: PropTypes.node,
};

export default CardIndicatorLineLayout;
