import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Plot from 'react-plotly.js';
import Plotly from 'plotly.js/dist/plotly';

import { formatPlotData, formatPlotLayout, getLayoutSizes } from '../../helpers/plotUtils';

import { ButtonIcon } from '../index';
import DownloadIcon from '../Icons/DownloadIcon';
import FormFieldHeader from '../FormFieldHeader/FormFieldHeader';
import FullScreenPlotModal from './FullScreenPlotModal/FullScreenPlotModal';
import ExpandDiagonalLineIcon from '../Icons/ExpandDiagonalLineIcon';

const padding = 8;

const PlotView = ({
    value,
    outlined = false,
    label,
    withExpandButton = false,
    withDownloadButton = false,
    actionsBarActions = null,
}) => {
    const plotlyRef = useRef(null);
    const containerRef = useRef(null);
    const resizableContainerRef = useRef(null);

    const [containerWidth, setContainerWidth] = useState(null);
    const [plotHeight, setPlotHeight] = useState(null);
    const plotHeightRef = useRef(null);
    const diff = useRef(-1);

    const [isExpanded, setIsExpanded] = useState(false);

    let parsedValue = value;
    if (typeof value === 'string') {
        try {
            parsedValue = JSON.parse(value);
        } catch (e) {}
    }

    const updatePlotSizeWhileScreenResize = () => {
        if (containerRef.current) {
            const width = containerRef.current.offsetWidth;
            setContainerWidth(width);

            const { height } = countPlotSizes(width);
            setPlotHeight(height);
            plotHeightRef.current = height;

            if (resizableContainerRef.current) {
                resizableContainerRef.current.style.height = `${height + 2 * padding}px`;
            }
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updatePlotSizeWhileScreenResize);

        return () => {
            window.removeEventListener('resize', updatePlotSizeWhileScreenResize);
        };
    }, []);

    const updatePlotSizeOnHeightChange = (entries) => {
        if (!containerRef.current) return;

        const plotContainerHeight = containerRef.current.offsetHeight;
        const plotHeight = containerRef.current.children?.[0]?.offsetHeight;
        if (diff.current === -1 && plotHeight > 0) {
            diff.current = plotContainerHeight - plotHeight;
        }

        const entry = entries[0];
        const updatedPlotContainerHeight = entry.contentRect.height;
        if (diff.current === -1) return;
        const height = updatedPlotContainerHeight - (diff.current ?? 6); // 6px is from library
        setPlotHeight(height);
        plotHeightRef.current = height;
    };

    useEffect(() => {
        const observer = new ResizeObserver(updatePlotSizeOnHeightChange);

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        updatePlotSizeWhileScreenResize();
    }, []);

    function countPlotSizes(containerWidth) {
        if (typeof parsedValue !== 'object' || parsedValue === null) {
            return { width: null, height: null };
        }

        const { layoutWidth, layoutHeight } = getLayoutSizes({ layout: parsedValue.layout });

        const width = containerWidth;
        const height = (layoutHeight / layoutWidth) * width;

        return { width, height, layoutWidth, layoutHeight };
    }

    if (typeof parsedValue === 'object' && parsedValue !== null) {
        const { data, layout } = parsedValue;
        const { width, height, layoutWidth, layoutHeight } = countPlotSizes(containerWidth);

        const formattedData = formatPlotData({ data });

        const formattedLayout = formatPlotLayout({ layout, width, height: plotHeight });

        const downloadPlotAsPng = async () => {
            if (plotlyRef.current) {
                try {
                    const plotTitle = layout?.title?.text;
                    const filename = plotTitle ? `${plotTitle} Plot` : 'Plot';
                    Plotly.downloadImage(plotlyRef.current.el, {
                        format: 'png',
                        width: 1000,
                        height: (layoutHeight / layoutWidth) * 1000,
                        filename,
                    });
                } catch (error) {
                    console.error('Error downloading image:', error);
                }
            }
        };

        const isLoaded = !!width;

        const containerClassName = classNames(
            'px-2 overflow-hidden',
            outlined && isLoaded && 'rounded-2 border-1 border-neutral-300',
            outlined ? 'md:resize-y' : ''
        );
        const backgroundColor = layout?.paper_bgcolor || '#fff';

        const withHeader = label || withExpandButton || withDownloadButton || !!actionsBarActions;
        let actionsBarRightContent = null;
        if (withExpandButton || withDownloadButton || actionsBarActions) {
            actionsBarRightContent = (
                <>
                    {actionsBarActions}
                    {withDownloadButton && (
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={DownloadIcon}
                            onClick={downloadPlotAsPng}
                        />
                    )}
                    {withExpandButton && (
                        <ButtonIcon
                            type="link"
                            size="xs"
                            icon={ExpandDiagonalLineIcon}
                            onClick={() => setIsExpanded((prev) => !prev)}
                        />
                    )}
                </>
            );
        }

        return (
            <div className="flex flex-col gap-1">
                {withHeader && (
                    <FormFieldHeader
                        label={label}
                        actionsBarRightContent={actionsBarRightContent}
                    />
                )}
                <div
                    className={containerClassName}
                    ref={resizableContainerRef}
                    style={{
                        backgroundColor,
                        minHeight: height + 2 * padding,
                        paddingTop: padding,
                        paddingBottom: padding,
                    }}
                >
                    <div ref={containerRef} className="h-full">
                        {isLoaded && (
                            <Plot
                                ref={plotlyRef}
                                data={formattedData}
                                layout={formattedLayout}
                                config={{ displayModeBar: false }}
                                onError={(err) => console.log('Error in PlotView', err)}
                            />
                        )}
                    </div>
                </div>

                {isExpanded && (
                    <FullScreenPlotModal value={value} onClose={() => setIsExpanded(false)} />
                )}
            </div>
        );
    }

    return <></>;
};

PlotView.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    outlined: PropTypes.bool,
    label: PropTypes.string,
    withExpandButton: PropTypes.bool,
    withDownloadButton: PropTypes.bool,
    actionsBarActions: PropTypes.node,
};

export default memo(PlotView);
