import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/library-api';

import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { handlePageDataLoadError } from '../../../helpers/handlePageDataLoadError';
import { useWrongOrgOrViewTypeNavBlocker } from '../../../hooks/useWrongOrgOrViewTypeNavBlocker';
import { getStartNewJobFormDataInitialState } from '../../../helpers/startNewJobFormUtils';

import Button from '../../../design-system/Button/Button';
import Loading from '../../../components/Loading';
import StartNewProjectFormBlock from './StartNewProjectFormBlock/StartNewProjectFormBlock';
import StartNewProjectSuccessState from './StartNewProjectSuccessState/StartNewProjectSuccessState';
import { ArrowGoBackLineIcon } from '../../../design-system/Icons';

const StartNewProjectPage = () => {
    const navigate = useNavigate();
    const { playbookId, scenarioId } = useParams();

    const [scenarioData, setScenarioData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [startNewProjectState, setStartNewProjectState] = useState('default'); // states are "default", "loading", "success"

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    useDocumentTitle('Start New Project');

    useEffect(() => {
        if (!scenarioId) return navigate(`/playbook/${playbookId}`);

        const fetchScenarioData = async () => {
            try {
                const { data } = await client.get(
                    `${API.ROUTES.library.scenario}${scenarioId}/?shared=True`
                );
                setScenarioData(data);
                setFormData(getStartNewJobFormDataInitialState(data.default_version.inputs || []));
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    setIsWrongOrg,
                    setRedirectPath,
                    redirectPath: '/playbooks',
                    generalErrorHandler: () => navigate(`/playbook/${playbookId}`),
                });
            }
        };

        fetchScenarioData();
    }, []);

    return (
        <div className="page-position flex flex-col bg-neutral-50 overflow-y-auto hide-scrollbar">
            {scenarioData && (
                <>
                    <div className="py-3 px-4 sm:px-6 bg-white border-b-1 border-neutral-200 flex flex-col items-start min-[380px]:flex-row min-[380px]:items-center gap-x-5 gap-y-0.5">
                        <Button
                            type="link"
                            size="xs"
                            text="Back"
                            leadingIcon={ArrowGoBackLineIcon}
                            onClick={() => navigate(`/playbook/${playbookId}`)}
                        />
                        <p className="font-body text-body-regular-s text-neutral-300">
                            Playbook / {scenarioData.playbook.name}
                        </p>
                    </div>

                    {startNewProjectState !== 'success' && (
                        <StartNewProjectFormBlock
                            scenarioData={scenarioData}
                            formData={formData}
                            setFormData={setFormData}
                            startNewProjectState={startNewProjectState}
                            setStartNewProjectState={setStartNewProjectState}
                        />
                    )}

                    {startNewProjectState === 'success' && (
                        <StartNewProjectSuccessState scenarioName={scenarioData.name} />
                    )}
                </>
            )}
            {!scenarioData && (
                <div className="w-full h-full flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default StartNewProjectPage;
