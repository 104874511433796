import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useTextareaAutoResize } from '../../hooks/useTextareaAutoResize';
import { defaultErrorMessage } from '../../constants/errorMessages';

import ErrorAlert from '../ErrorAlert/ErrorAlert';
import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper';

export const emptyFieldError = 'empty field';

const TextFieldWithCustomTextStyles = ({
    value,
    isSaveOnBlur = false,
    isSaveOnEnterPress = false,
    handleSave: _handleSave = () => {}, // if isSaveOnBlur = true this function will be executed on blur
    handleChange = () => {}, // if isSaveOnBlur = false this function will handle textarea change
    isDisabled = false,
    minHeight = 64,
    placeholder,
    textStyle = 'font-body text-body-regular-s text-neutral-500',
    customTextFieldStyles = '',
}) => {
    const [stagedText, setStagedText] = useState(value); // this state is used when isSaveOnBlur = true (main state is updated on blur)
    const valueToDisplay = isSaveOnBlur ? stagedText : value;

    const [emptyFieldErrorMessage, setEmptyFieldErrorMessage] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const { textareaRef, textareaContainerRef } = useTextareaAutoResize({
        text: valueToDisplay,
        minHeight,
    });

    useEffect(() => {
        if (stagedText !== value && isSaveOnBlur) {
            setStagedText(value);
            setEmptyFieldErrorMessage(null);
        }
    }, [value, isSaveOnBlur]);

    const handleSave = async () => {
        if (value === stagedText) {
            return;
        }

        try {
            await _handleSave(stagedText);
        } catch (e) {
            if (e?.message === emptyFieldError) {
                textareaRef.current.focus();
                setEmptyFieldErrorMessage('Please fill in this field.');
                return;
            }

            setStagedText(value);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.statusCode });
        }
    };

    const handleBlur = () => {
        if (isSaveOnBlur) {
            handleSave(stagedText);
        }
    };

    const handleTextAreaChange = (e) => {
        if (isSaveOnBlur) {
            setStagedText(e.target.value);
        }

        if (!isSaveOnBlur) {
            handleChange(e);
        }

        setEmptyFieldErrorMessage(null);
    };

    const handleKeyDown = (e) => {
        if (!isSaveOnEnterPress) {
            return;
        }

        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!isSaveOnBlur) {
                handleSave(stagedText);
            }
            textareaRef.current.blur();
        }
    };

    const textAreaClassName = classNames(
        'max-w-full w-full focus:outline-0 resize-none bg-transparent placeholder-neutral-300',
        textStyle,
        customTextFieldStyles,
        emptyFieldErrorMessage && 'focus:!shadow-red-500'
    );

    return (
        <>
            <FormFieldWrapper
                state={emptyFieldErrorMessage ? 'error' : 'default'}
                errorMessage={emptyFieldErrorMessage}
                gap={4}
            >
                <div
                    ref={textareaContainerRef}
                    className="flex flex-col max-w-full"
                    style={{ minHeight: `${minHeight}px` }}
                >
                    <textarea
                        value={valueToDisplay}
                        rows={1}
                        ref={textareaRef}
                        placeholder={placeholder}
                        readOnly={isDisabled}
                        onChange={handleTextAreaChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        className={textAreaClassName}
                    />
                </div>
            </FormFieldWrapper>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </>
    );
};

TextFieldWithCustomTextStyles.propTypes = {
    value: PropTypes.string,
    isSaveOnBlur: PropTypes.bool,
    isSaveOnEnterPress: PropTypes.bool,
    handleSave: PropTypes.func,
    handleChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    minHeight: PropTypes.number,
    placeholder: PropTypes.string,
    textStyle: PropTypes.string,
    customTextFieldStyles: PropTypes.string,
};

export default TextFieldWithCustomTextStyles;
