import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../constants/organization';

export const botsRoutes = [
    {
        path: '/bots',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.BotsPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/bot/:botId',
        indexPath: '/bots',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.BotDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
];
