import React, { useState } from 'react';

import * as API from '../../../constants/api';
import client from '../../../services/knowledge-api';

import { useFormState } from '../../../hooks/useFormState';

import { mutateListDataAfterUpdatingItem } from '../../../helpers/swrMutateUtils';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../constants/errorMessages';

import Modal from '../../../design-system/Modal/Modal';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import ModalHeader from '../../../design-system/ModalHeader/ModalHeader';
import { Button, Input, TextArea } from '../../../design-system';

const EditBaseModal = ({ id, name, description, requestKeysToMutate, onSuccess, onClose }) => {
    const { formData, fieldErrorMessages, setFieldErrorMessages, handleInputChange } = useFormState(
        { name, description }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const handleRename = async () => {
        const { name, description } = formData;

        const areEmptyFields = !name || !description;
        if (areEmptyFields) {
            setFieldErrorMessages({
                name: !name ? emptyFieldErrorMessage : null,
                description: !description ? emptyFieldErrorMessage : null,
            });
            return;
        }

        try {
            setIsLoading(true);

            const requestBody = { name, description };
            const { data } = await client.patch(`${API.ROUTES.knowledge.base}${id}/`, requestBody);
            const updatedPart = { name: data.name, description: data.description };

            mutateListDataAfterUpdatingItem({ requestKeysToMutate, id, updatedPart });
            onSuccess && onSuccess(data);

            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        }
    };

    return (
        <Modal onClose={onClose} size="medium" resetPadding>
            <ModalHeader onClose={onClose} title="Edit Source" outlined />

            <div className="px-5 sm:px-6 md:px-8 py-5 flex flex-col gap-4">
                <Input
                    size="sm"
                    value={formData.name}
                    name="source_name"
                    label="Source Name"
                    isRequired
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    state={fieldErrorMessages.name ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.name}
                    placeholder={name}
                />

                <TextArea
                    name="source_description"
                    value={formData.description}
                    label="Source Description"
                    isRequired
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    state={fieldErrorMessages.description ? 'error' : 'default'}
                    errorMessage={fieldErrorMessages.description}
                    placeholder="Enter a description for the source"
                />

                <div className="flex items-center justify-between">
                    <Button type="neutral" size="md" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="md"
                        text="Save"
                        state={isLoading ? 'loading' : 'default'}
                        onClick={handleRename}
                    />
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Modal>
    );
};

export default EditBaseModal;
