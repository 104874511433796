import { CONTENT_TYPE } from '../constants/docs';
import { EMPTY_FOLDER_INITIAL_DATA } from '../constants/selectDocModal';

import { mergeNewDataIntoCurrent } from './mergeNewDataIntoCurrent';

export const updateFolderDataInState = ({ cb, location, setFoldersDocumentsData }) => {
    if (!location) return;

    const isRoot = location.length === 0;
    if (isRoot) {
        setFoldersDocumentsData(cb);
        return;
    }

    setFoldersDocumentsData((prevData) => {
        const recursiveUpdateState = (state, index) => {
            const currentFolderId = location[index];

            if (!state) {
                return null;
            }

            if (index === location.length - 1) {
                const updatedState = state?.map((item) => {
                    const isTargetFolder =
                        item.id === currentFolderId && item.content_type === CONTENT_TYPE.folder;

                    if (isTargetFolder) {
                        return cb(item);
                    }

                    return item;
                });
                return updatedState;
            }

            return state?.map((item) => {
                const isCurrentFolder =
                    item.id === currentFolderId && item.content_type === CONTENT_TYPE.folder;
                if (isCurrentFolder) {
                    return {
                        ...item,
                        results: recursiveUpdateState(item.results, index + 1),
                    };
                }

                return item;
            });
        };

        return { ...prevData, results: recursiveUpdateState(prevData.results, 0) };
    });
};

export const setDocsAndFoldersDataOnFetch = ({
    newResults,
    canLoadMore,
    location,
    setFoldersDocumentsData,
}) => {
    const newFormattedResults = newResults.map((item) => {
        const { content_type } = item;

        if (content_type === CONTENT_TYPE.folder) {
            return { ...item, ...EMPTY_FOLDER_INITIAL_DATA };
        }

        return item;
    });

    updateFolderDataInState({
        cb: (folder) => ({
            ...folder,
            pagination: {
                ...folder.pagination,
                isFetched: true,
                canLoadMore,
            },
            results: mergeNewDataIntoCurrent(folder.results || [], newFormattedResults),
        }),
        location,
        setFoldersDocumentsData,
    });
};

// should set in main state
const collapseFolderAndChildren = (folder) => {
    if (!folder.results) return { ...folder, isExpanded: false };

    const children = folder.results?.map((item) => {
        if (item.content_type === CONTENT_TYPE.folder) {
            return collapseFolderAndChildren(item);
        }
        return item;
    });
    return { ...folder, isExpanded: false, results: children };
};

export const toggleFolderExpand = ({ location, setFoldersDocumentsData }) => {
    const expandFolder = (folder) => ({ ...folder, isExpanded: true });

    updateFolderDataInState({
        cb: (folder) =>
            folder.isExpanded ? collapseFolderAndChildren(folder) : expandFolder(folder),
        location,
        setFoldersDocumentsData,
    });
};

export const collapseAllFolders = ({ setFoldersDocumentsData }) => {
    updateFolderDataInState({
        cb: (root) => ({
            ...root,
            results: root.results?.map(collapseFolderAndChildren) || root.results,
        }),
        location: [],
        setFoldersDocumentsData,
    });
};

export const expandFoldersWithSelectedDocs = ({ selectedDocs, setFoldersDocumentsData }) => {
    if (!selectedDocs?.length) return;

    const containsSelectedDocs = (results) => {
        if (!results || !results?.length) return false;

        const hasSelectedInCurrentLevel = results.some(({ id }) => selectedDocs.includes(id));
        if (hasSelectedInCurrentLevel) return true;

        return results.some((item) => {
            if (item.content_type === CONTENT_TYPE.folder) {
                return containsSelectedDocs(item.results);
            }
            return false;
        });
    };

    const expandFolderIfHasSelectedDocs = (folder) => {
        const isExpanded = containsSelectedDocs(folder.results);

        return {
            ...folder,
            isExpanded,
            results:
                folder.results?.map((item) => {
                    if (item.content_type === CONTENT_TYPE.folder && isExpanded) {
                        return expandFolderIfHasSelectedDocs(item);
                    }
                    return item;
                }) || folder.results,
        };
    };

    setFoldersDocumentsData((prevData) => ({
        ...prevData,
        results:
            prevData.results?.map((item) => {
                if (item.content_type === CONTENT_TYPE.folder) {
                    return expandFolderIfHasSelectedDocs(item);
                }
                return item;
            }) || prevData.results,
    }));
};
