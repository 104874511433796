import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { API } from 'constants';
import organizationClient from '../../../services/organization-api';

import {
    makeOrganizationChangeRequestAndUpdateLS,
    navigateUserAfterOrganizationChange,
} from '../../../helpers/changeOrganizationUtils';

import useUser from '../../../hooks/useUser';
import { useViewType } from '../../../hooks/useViewType';
import { useClickOutside } from '../../../hooks';
import { usePreActionNavigationGuard } from '../../../hooks/usePreActionNavigationGuard';
import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';

import Loading from '../../Loading';
import ErrorAlert from '../../../design-system/ErrorAlert/ErrorAlert';
import OrganizationCard from '../OrganizationCard/OrganizationCard';
import InfiniteScrollList from '../../InfiniteScrollList/InfiniteScrollList';
import ClientSideFilteredSearchBar from '../../ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';
import { ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';

const OrganizationSwitcher = ({ mobileVersion = false, onClose }) => {
    const orgSwitcherRef = useRef(null);
    const { user } = useUser();

    const [filteredOrganizations, setFilteredOrganizations] = useState([]);

    const [orgChangeLoadingId, setOrgChangeLoadingId] = useState(null);
    const [errorAlert, setErrorAlert] = useState(null);

    const [targetOrganizationData, setTargetOrganizationData] = useState(null); // used when user want to change organization in BlockingModal

    const { viewType, oppositeViewType, toggleViewType } = useViewType();

    const {
        options: organizations,
        optionsLoading,
        canLoadMoreOptions,
        setPage,
    } = useFetchOptionsForPaginatedSelect({
        client: organizationClient,
        route: API.ROUTES.organization.organizationMembership,
        searchParams: { user: user.id },
    });

    const {
        handleConfirmNavigation,
        handleCancelNavigation,
        ConfirmModalComponent,
        isConfirmNavigationModalShown,
        isNavigationRestricted,
        setIsConfirmNavigationModalShown,
        promptMessage,
        isPromptViewPageOpened,
    } = usePreActionNavigationGuard({
        onConfirmNavigation: changeOrganizationInBlockingModal,
        onCancelNavigation: () => setTargetOrganizationData(null),
    });

    const closeOrganizationSwitcher = (e) => {
        if (isConfirmNavigationModalShown) {
            return;
        }
        onClose(e);
    };

    useClickOutside(orgSwitcherRef, closeOrganizationSwitcher);

    const changeTheDefaultOrganization = async (
        membershipId,
        isDefault,
        skipBlockingCheck = false
    ) => {
        if (isNavigationRestricted && !skipBlockingCheck) {
            setIsConfirmNavigationModalShown(true);
            setTargetOrganizationData({ membershipId, isDefault });
            return;
        }

        if (isDefault) {
            return;
        }

        try {
            setOrgChangeLoadingId(membershipId);

            const { newOrgData } = await makeOrganizationChangeRequestAndUpdateLS({ membershipId });

            setOrgChangeLoadingId(null);
            setTargetOrganizationData(null);

            navigateUserAfterOrganizationChange({
                newOrgData,
                user,
                viewType,
                oppositeViewType,
                toggleViewType,
                isMainTab: true,
            });

            onClose();
        } catch (e) {
            setOrgChangeLoadingId(null);
            setErrorAlert({
                message:
                    'Oops! Something went wrong while changing organization. Please try again.',
                statusCode: e.response?.status,
            });
        }
    };

    async function changeOrganizationInBlockingModal() {
        try {
            if (targetOrganizationData) {
                await changeTheDefaultOrganization(
                    targetOrganizationData.membershipId,
                    targetOrganizationData.isDefault,
                    true
                );
                setTargetOrganizationData(null);
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    const confirmNavigationModalText = {
        true: `You have unsaved changes. If you change the organization now, all your changes will be lost.`,
        false: `Are you sure you want to change the organization? ${promptMessage || ''}`,
    };

    const backdropClassName = classNames(
        mobileVersion && 'fixed top-0 left-0 right-0 bottom-0 bg-black/50'
    );
    const containerClassName = classNames('bg-white z-[32] flex flex-col pb-3', {
        'fixed bottom-0 top-[40px] left-0 right-0 rounded-t-2 px-5 pt-6 gap-2': mobileVersion,
        'absolute top-1/2 transform translate-y-[-50%] left-[78px] w-[360px] h-[calc(100vh-40px)] gap-1 shadow-l1 rounded-2 px-4 pt-4 border-1 border-neutral-200':
            !mobileVersion,
    });

    const listClassName = classNames('overflow-y-auto', {
        'pt-2': !filteredOrganizations?.length && optionsLoading,
        '-mr-5 pr-5': mobileVersion,
        '-mr-4 pr-4': !mobileVersion,
    });

    return (
        <div ref={orgSwitcherRef} className={backdropClassName}>
            <div className={containerClassName}>
                <div className="absolute top-[8px] right-[8px]">
                    <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                </div>
                <p className="font-body-bold text-body-bold-m text-black py-2">Organizations</p>

                {!!organizations.length && (
                    <>
                        <ClientSideFilteredSearchBar
                            list={organizations}
                            setFilteredList={setFilteredOrganizations}
                            size="md"
                            keyToFilter={['organization', 'name']}
                        />
                        <InfiniteScrollList
                            handleFetch={() => setPage((page) => page + 1)}
                            canLoadMore={canLoadMoreOptions}
                            items={filteredOrganizations}
                            loading={optionsLoading}
                            customStyles={listClassName}
                        >
                            <ul className="flex flex-col gap-1">
                                {filteredOrganizations.map((membership) => {
                                    return (
                                        <OrganizationCard
                                            key={membership.id}
                                            membership={membership}
                                            orgChangeLoadingId={orgChangeLoadingId}
                                            changeTheDefaultOrganization={
                                                changeTheDefaultOrganization
                                            }
                                        />
                                    );
                                })}
                            </ul>
                        </InfiniteScrollList>
                    </>
                )}

                {!organizations.length && (
                    <div className="pt-4">
                        <Loading />
                    </div>
                )}
            </div>

            {isConfirmNavigationModalShown && (
                <ConfirmModalComponent
                    text={confirmNavigationModalText[isPromptViewPageOpened]}
                    onCancel={handleCancelNavigation}
                    onConfirm={handleConfirmNavigation}
                    confirmButtonText={
                        isPromptViewPageOpened ? 'Change organization' : 'Yes, change organization'
                    }
                    cancelButtonText={isPromptViewPageOpened ? 'Stay on Page' : 'No, Stay on Page'}
                    buttonsTheme={isPromptViewPageOpened ? 'colored' : 'dark'}
                />
            )}

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default OrganizationSwitcher;
