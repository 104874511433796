import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useOverlayState } from '../../../../hooks/useOverlayState';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';
import { useSwrRequestKeysToMutateFromContext } from '../../../../hooks/useSwrRequestKeysToMutateFromContext';

import { baseEntriesSortOptions } from '../../../../constants/sort';

import { Button, ButtonIcon } from '../../../../design-system';
import { ArrowGoBackLineIcon, More2FillIcon } from '../../../../design-system/Icons';
import SortControls from '../../../../components/SortControls/SortControls';
import EditBaseModal from '../../EditBaseModal/EditBaseModal';
import HighlightedIcon from '../../../../design-system/HighlightedIcon/HighlightedIcon';
import ArchiveLineIcon from '../../../../design-system/Icons/ArchiveLineIcon';
import BaseMoreOptionsPopup from '../../BaseMoreOptionsPopup/BaseMoreOptionsPopup';
import SearchBarWithUrlParamsHandling from '../../../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';

const BaseDetailPageHeader = ({ baseDetail, backLinkHref, setBaseDetail, hasEditAccess }) => {
    const { id, name, description } = baseDetail;

    const navigate = useNavigate();
    const location = useLocation();

    const {
        requestKeysToMutate: { bases: requestKeysToMutate },
    } = useSwrRequestKeysToMutateFromContext();

    const { isOpened: isPopupOpened, open: openPopup, close: closePopup } = useOverlayState();
    const { isOpened: isEditModalOpened, open: openModal, close: closeModal } = useOverlayState();

    const { isMobile } = useResponsiveBreakpoints();

    const onSuccessEdit = (data) => {
        const { name, description } = data;
        setBaseDetail((prevData) => ({ ...prevData, name, description }));
    };

    const sortDropdownPosition = isMobile ? { top: '34px', right: 0 } : { top: '120%', left: 0 };

    return (
        <div className="px-5 sm:px-6 py-5 bg-white">
            <div className="flex items-center sm:items-start justify-between min-h-[40px] mb-1">
                <Button
                    type="link"
                    size="xs"
                    text="Back to Knowledge Bases"
                    leadingIcon={ArrowGoBackLineIcon}
                    onClick={() => navigate(backLinkHref, { state: { from: location } })}
                />

                {hasEditAccess && (
                    <div className="relative">
                        <ButtonIcon
                            type="link"
                            size="sm"
                            icon={More2FillIcon}
                            onClick={openPopup}
                        />

                        {isPopupOpened && (
                            <BaseMoreOptionsPopup
                                baseId={id}
                                openEditModal={openModal}
                                onClose={closePopup}
                            />
                        )}
                    </div>
                )}
            </div>

            <div className="flex flex-col gap-1 w-full max-w-[764px] mb-2">
                <div className="flex gap-3">
                    <HighlightedIcon icon={ArchiveLineIcon} variant="dark_purple" />

                    <div className="flex-grow my-auto">
                        <h1 className="font-body-bold text-body-bold-l text-black">{name}</h1>
                    </div>
                </div>

                {description && (
                    <p className="font-body text-body-regular-s text-neutral-400">{description}</p>
                )}
            </div>

            <div className="flex items-center gap-4">
                <SearchBarWithUrlParamsHandling
                    size="xs"
                    maxWidth={300}
                    placeholder="Search by keyword"
                />

                <SortControls
                    sortOptions={baseEntriesSortOptions}
                    variant="secondary"
                    dropdownPosition={sortDropdownPosition}
                    buttonContainerClassName="relative"
                />
            </div>

            {isEditModalOpened && (
                <EditBaseModal
                    id={id}
                    name={name}
                    description={description || ''}
                    requestKeysToMutate={requestKeysToMutate}
                    onSuccess={onSuccessEdit}
                    onClose={closeModal}
                />
            )}
        </div>
    );
};

export default memo(BaseDetailPageHeader);
