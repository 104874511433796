export const VIEW_TYPE_LS_KEY = 'view_type';

export const VIEW_TYPES = {
    admin: 'admin',
    client: 'client',
};

export const DEFAULT_VIEW_TYPE = VIEW_TYPES.client;

export const VIEW_TYPES_ARRAY = [VIEW_TYPES.admin, VIEW_TYPES.client];

export const VIEW_TYPES_REDIRECT_PATH = {
    admin: '/library',
    client: '/dashboard',
};
