import React from 'react';
import PropTypes from 'prop-types';

import ModalHeader from '../ModalHeader/ModalHeader';
import ClientSideFilteredSearchBar from '../../components/ClientSideFilteredSearchBar/ClientSideFilteredSearchBar';

const ModalHeaderWithSearch = ({
    onClose,
    onCancelClick,
    title,
    options,
    setFilteredOptions,
    listLabel,
    useExternalQuery = false,
    query = '',
    setQuery = () => {},
}) => {
    return (
        <div className="flex flex-col">
            <ModalHeader
                onClose={onClose}
                title={title}
                outlined
                titleStyles="font-heading-bold text-heading-bold-s text-neutral-500"
                customPaddings="py-[11px] px-5"
            />

            <div className="pt-5 flex-1 flex flex-col gap-5">
                <div className="flex flex-col gap-4 md:gap-5 px-5">
                    <div className="flex items-center gap-3 l:gap-5">
                        <ClientSideFilteredSearchBar
                            setFilteredList={setFilteredOptions}
                            list={options}
                            withClearIcon
                            size="md"
                            keyToFilter={['label']}
                            useExternalQuery={useExternalQuery}
                            query={query}
                            setQuery={setQuery}
                        />
                        <button
                            type="button"
                            className="font-body text-body-regular-m text-neutral-500 underline"
                            onClick={onCancelClick}
                        >
                            Cancel
                        </button>
                    </div>

                    <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase">
                        {listLabel}
                    </p>
                </div>
            </div>
        </div>
    );
};

ModalHeaderWithSearch.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    setFilteredOptions: PropTypes.func.isRequired,
    keyToFilter: PropTypes.array,
    listLabel: PropTypes.string.isRequired,
    useExternalQuery: PropTypes.bool,
    query: PropTypes.string,
    setQuery: PropTypes.func,
};

export default ModalHeaderWithSearch;
