import React, { useMemo } from 'react';

import { STATUS } from '../../../../constants/statuses';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import ModalHeader from '../../../../design-system/ModalHeader/ModalHeader';
import MobilePopup from '../../../../design-system/MobilePopup/MobilePopup';
import FeedbackCard from '../FeedbackCard/FeedbackCard';

const FeedbackSidePanel = ({ feedback, onClose }) => {
    const { pendingFeedback, resolvedFeedback } = useMemo(() => {
        if (!Array.isArray(feedback)) return { pendingFeedback: [], resolvedFeedback: [] };

        return feedback.reduce(
            (acc, item) => {
                if (item.status === STATUS.pending) {
                    return { ...acc, pendingFeedback: [...acc.pendingFeedback, item] };
                }
                return { ...acc, resolvedFeedback: [...acc.resolvedFeedback, item] };
            },
            { pendingFeedback: [], resolvedFeedback: [] }
        );
    }, [feedback]);

    const areFeedback = !!feedback?.length;

    const panelContent = (
        <>
            {areFeedback && (
                <>
                    {pendingFeedback.map((feedback, index) => (
                        <FeedbackCard key={index} feedback={feedback} />
                    ))}

                    {!!resolvedFeedback.length && (
                        <div className="flex flex-col gap-4 opacity-50">
                            <p className="font-body text-body-bold-xs text-neutral-500 uppercase">
                                Resolved
                            </p>

                            {resolvedFeedback.map((feedback, index) => (
                                <FeedbackCard key={index} feedback={feedback} />
                            ))}
                        </div>
                    )}
                </>
            )}

            {!areFeedback && (
                <p className="font-body text-body-regular-s text-neutral-500">
                    No feedback submitted for job
                </p>
            )}
        </>
    );

    const { isDesktop } = useResponsiveBreakpoints();

    if (!isDesktop) {
        return (
            <MobilePopup
                onClose={onClose}
                containerCustomPaddings="px-4 pb-4"
                minHeight={178}
                customGap={16}
                maxHeight="94vh"
                closeOnClickOutside={onClose}
            >
                <p className="font-body-bold text-body-bold-m text-neutral-500 mb-1">Feedback</p>

                {panelContent}
            </MobilePopup>
        );
    }

    return (
        <div className="w-full lg:w-[350px] xl:w-[400px] 2xl:w-[430px] lg:overflow-y-auto flex-grow h-full">
            <div className="pt-3 md:pt-5 px-5 pb-4 lg:pb-7 bg-white min-h-full rounded-2 lg:rounded-none flex flex-col gap-4">
                <ModalHeader
                    title="Feedback"
                    onClose={onClose}
                    size="xs"
                    withEscapeButton={false}
                    titleStyles="font-body-bold text-body-bold-m text-neutral-500"
                />

                {panelContent}
            </div>
        </div>
    );
};

export default FeedbackSidePanel;
