import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Loading from '../../../components/Loading';
import { Button, SvgIcon } from '../../../design-system';
import { FlashlightFillIcon } from '../../../design-system/Icons';

const NewAgentSuggestionsBlock = ({ agentsWithSuggestions, setNextPage, hasNextPage, loading }) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (!agentsWithSuggestions?.length) return <></>;

    return (
        <div className="py-5 px-4 md:px-5 rounded-2 flex flex-col gap-5 bg-blue-100">
            <div className="flex flex-col gap-1">
                <h3 className="font-body-bold text-body-bold-l text-black">
                    New Agent Suggestions
                </h3>
                <p className="font-body text-body-regular-s text-neutral-500">
                    Continually improve your agents by reviewing new suggestions
                </p>
            </div>

            <ul className="flex flex-col gap-3">
                {agentsWithSuggestions.map((agent) => {
                    const { id, name, suggestions } = agent;

                    const count = suggestions?.length || 0;
                    const suggestionsCountLabel = `${count} new agent suggestion${
                        count === 1 ? '' : 's'
                    }`;

                    return (
                        <li
                            key={id}
                            className="max-w-full rounded-2 bg-white px-4 py-3 flex items-center justify-between gap-3"
                        >
                            <div className="flex flex-col gap-0.5 overflow-hidden">
                                <div className="flex gap-2 items-center">
                                    <SvgIcon
                                        color="#1F2125"
                                        icon={FlashlightFillIcon}
                                        size="medium"
                                    />
                                    <p className="font-body-bold text-body-bold-xs text-black truncate">
                                        AGENT: {name}
                                    </p>
                                </div>
                                <p className="font-body text-body-regular-xs text-neutral-500 truncate">
                                    {suggestionsCountLabel} ready for review
                                </p>
                            </div>

                            <Button
                                size="xs"
                                type="link"
                                text="View"
                                onClick={() =>
                                    navigate(`/agent/${id}`, { state: { from: location } })
                                }
                            />
                        </li>
                    );
                })}
            </ul>

            {hasNextPage && !loading && (
                <Button
                    type="link"
                    size="sm"
                    text="View More"
                    onClick={() => setNextPage((page) => page + 1)}
                />
            )}

            {loading && (
                <div className="min-h-[40px]">
                    <Loading withText={false} />
                </div>
            )}
        </div>
    );
};

export default NewAgentSuggestionsBlock;
