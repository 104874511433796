import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/execute-api';

import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';
import { useWindowSize } from '../../../hooks/useWindowSize';

import CollapsableContainer from '../../../components/CollapsableContainer/CollapsableContainer';
import PaginatedSelect from '../../../design-system/PaginatedSelect/PaginatedSelect';
import Loading from '../../../components/Loading';
import FormFieldWrapper from '../../../design-system/FormFieldWrapper/FormFieldWrapper';
import { Input, Select } from '../../../design-system';
import { arePrimitiveArraysEqualUnordered } from '../../../helpers/arePrimitiveArraysEqualUnordered';
import Alert from '../../../design-system/Alert/Alert';
import errorWarningLineIcon from '../../../design-system/Icons/ErrorWarningLineIcon';

const DatasetSettingsBlock = ({
    isExpanded,
    toggleExpand,
    isBlockOpenable,
    goalTemplateId,
    goalInputKeys,
    goalOutputKeys,
    formData,
    setDatasetSettingsFormData,
    fieldsErrorMessage,
    setFieldsErrorMessage,
    isDatasetPreselectLoading,
    datasetSettingsFromPreviousTestRun,
    stopPreselecting,
}) => {
    const {
        datasetSettings: { dataset, inputs, outputs },
        unitTestSettings: { organization, version },
    } = formData;
    const shouldPreviousTestRunDataBePreselected = !!datasetSettingsFromPreviousTestRun.dataset;

    const [searchParams] = useSearchParams();
    const datasetFromUrl = searchParams.get('dataset') || null;
    const datasetIdToPreselect = datasetFromUrl || datasetSettingsFromPreviousTestRun.dataset;

    const [datasetInputKeysOptions, setDatasetInputKeysOptions] = useState(null);
    const [datasetOutputKeysOptions, setDatasetOutputKeysOptions] = useState(null);

    const [errorAlert, setErrorAlert] = useState(null);

    const { width: screenWidth } = useWindowSize();

    const formatDatasetOptions = (results) => {
        return results.map((item) => ({
            id: item.id,
            name: item.name,
            inputKeys: item.input_keys,
            outputKeys: item.output_keys,
        }));
    };

    const {
        options: datasetOptions,
        optionsLoading,
        canLoadMoreOptions,
        setPage,
        totalOptions: totalDatasetOptions,
        isFirstRequestCompleted: areFirstPageDatasetOptionsLoaded,
    } = useFetchOptionsForPaginatedSelect({
        client,
        route: API.ROUTES.execute.dataset,
        searchParams: {
            goal_template: goalTemplateId,
            organization,
        },
        startRequest: !!organization,
        formatResponseToOptions: formatDatasetOptions,
    });

    useEffect(() => {
        const shouldPreselectDataset =
            isDatasetPreselectLoading &&
            areFirstPageDatasetOptionsLoaded &&
            !dataset &&
            datasetIdToPreselect;
        if (shouldPreselectDataset) {
            // find the dataset among all datasets (first pagination page) with the ID from URL and set it in the dataset select
            const datasetToPreselect = datasetOptions.find(
                (dataset) => dataset.id === datasetIdToPreselect
            )?.id;
            if (datasetToPreselect) {
                handleDatasetSelectChange(datasetToPreselect);

                if (!shouldPreviousTestRunDataBePreselected) {
                    // all fields to preselect are already filled (organization and dataset) so turn off the loader
                    stopPreselecting();
                }
                // if isPreviousTestRunDataPreselecting continue preselecting
            } else {
                stopPreselecting();
            }
        }
    }, [
        datasetOptions,
        isDatasetPreselectLoading,
        areFirstPageDatasetOptionsLoaded,
        datasetIdToPreselect,
    ]);

    useEffect(() => {
        const shouldInputsAndOutputsBePreselected =
            shouldPreviousTestRunDataBePreselected &&
            isDatasetPreselectLoading &&
            goalInputKeys &&
            goalOutputKeys;

        if (shouldInputsAndOutputsBePreselected) {
            const isInputsDataMatch = checkPreviousTestRunInputsCompatibility();
            const isOutputsDataMatch = checkPreviousTestRunOutputsCompatibility();
            const isPreviousTestRunDataValid = isInputsDataMatch && isOutputsDataMatch;

            if (isPreviousTestRunDataValid) {
                // set inputs and outputs from previous Test Run
                const { inputs, outputs } = datasetSettingsFromPreviousTestRun;
                setDatasetSettingsFormData((prevData) => ({ ...prevData, inputs, outputs }));
            }
            if (!isPreviousTestRunDataValid) {
                stopPreselecting();
                setErrorAlert({
                    message:
                        "Oops, this Test Run's settings could not be loaded! Please try again.",
                });
            }
        }
    }, [isDatasetPreselectLoading, goalInputKeys, goalOutputKeys]);

    const handleDatasetSelectChange = (value) => {
        // set new dataset and clear inputs mapping
        setDatasetSettingsFormData((prevData) => {
            const clearedInputs = prevData.inputs
                ? Object.fromEntries(Object.keys(prevData.inputs)?.map((key) => [key, null]))
                : null;
            const clearedOutputs = prevData.outputs
                ? Object.fromEntries(Object.keys(prevData.outputs)?.map((key) => [key, null]))
                : null;
            return { dataset: value, inputs: clearedInputs, outputs: clearedOutputs };
        });
        if (fieldsErrorMessage.dataset) {
            setFieldsErrorMessage((prevErrorMessages) => ({ ...prevErrorMessages, dataset: null }));
        }

        // set new Dataset Input Keys Options
        const selectedDatasetInputsKeys = datasetOptions.find(
            (option) => option.id === value
        )?.inputKeys;
        setDatasetInputKeysOptions(
            selectedDatasetInputsKeys?.map((input) => ({ id: input, name: input })) || []
        );

        // set new Dataset Output Keys Options
        const selectedDatasetOutputsKeys = datasetOptions.find(
            (option) => option.id === value
        )?.outputKeys;
        setDatasetOutputKeysOptions(
            selectedDatasetOutputsKeys?.map((output) => ({ id: output, name: output })) || []
        );
    };

    useEffect(() => {
        setDatasetInputKeysOptions(null);
        setDatasetOutputKeysOptions(null);
    }, [organization, version]);

    const checkPreviousTestRunInputsCompatibility = () => {
        const { inputs: previousTestRunInputs } = datasetSettingsFromPreviousTestRun;

        const previousTestRunInputKeysArray = Object.keys(previousTestRunInputs);
        const previousTestRunInputValuesArray = Object.values(previousTestRunInputs);
        const goalInputKeysArray = goalInputKeys.map((input) => input.name);
        const datasetInputKeysOptionsArray =
            datasetInputKeysOptions?.map((input) => input.name) || [];

        const arePreviousTestRunInputsMatchGoalInputKeys = arePrimitiveArraysEqualUnordered(
            previousTestRunInputKeysArray,
            goalInputKeysArray
        );

        const arePreviousTestRunInputValuesInDatasetInputKeys =
            previousTestRunInputValuesArray.every((item) =>
                datasetInputKeysOptionsArray.includes(item)
            );

        return (
            arePreviousTestRunInputsMatchGoalInputKeys &&
            arePreviousTestRunInputValuesInDatasetInputKeys
        );
    };

    const checkPreviousTestRunOutputsCompatibility = () => {
        const { outputs: previousTestRunOutputs } = datasetSettingsFromPreviousTestRun;

        const previousTestRunOutputKeysArray = Object.keys(previousTestRunOutputs);
        const previousTestRunOutputValuesArray = Object.values(previousTestRunOutputs);
        const goalOutputKeysArray = goalOutputKeys.map((output) => output.name);
        const datasetOutputKeysOptionsArray =
            datasetOutputKeysOptions?.map((output) => output.name) || [];

        const arePreviousTestRunOutputsMatchGoalOutputKeys = arePrimitiveArraysEqualUnordered(
            previousTestRunOutputKeysArray,
            goalOutputKeysArray
        );

        const arePreviousTestRunOutputValuesInDatasetOutputKeys =
            previousTestRunOutputValuesArray.every((item) =>
                datasetOutputKeysOptionsArray.includes(item)
            );

        return (
            arePreviousTestRunOutputsMatchGoalOutputKeys &&
            arePreviousTestRunOutputValuesInDatasetOutputKeys
        );
    };

    const handleInputsMappingSelectChange = (goalInputKey, datasetInputKey) => {
        setDatasetSettingsFormData((prevData) => ({
            ...prevData,
            inputs: { ...prevData.inputs, [goalInputKey]: datasetInputKey },
        }));
        if (fieldsErrorMessage.inputs?.[goalInputKey]) {
            setFieldsErrorMessage((prevMessages) => ({
                ...prevMessages,
                inputs: { ...(prevMessages?.inputs || {}), [goalInputKey]: null },
            }));
        }
    };

    const handleOutputsMappingSelectChange = (goalOutputKey, datasetOutputKey) => {
        setDatasetSettingsFormData((prevData) => ({
            ...prevData,
            outputs: { ...prevData.outputs, [goalOutputKey]: datasetOutputKey },
        }));
        if (fieldsErrorMessage.outputs?.[goalOutputKey]) {
            setFieldsErrorMessage((prevMessages) => ({
                ...prevMessages,
                outputs: { ...(prevMessages?.outputs || {}), [goalOutputKey]: null },
            }));
        }
    };

    return (
        <CollapsableContainer
            title="DATASET SETTINGS"
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
            isDisabled={!isBlockOpenable}
        >
            <div className="flex flex-col gap-4">
                <PaginatedSelect
                    size="sm"
                    name="dataset"
                    value={dataset}
                    options={datasetOptions}
                    optionsLoading={optionsLoading}
                    label="Select a Dataset"
                    isRequired
                    state={
                        !organization
                            ? 'disabled'
                            : fieldsErrorMessage.dataset
                            ? 'error'
                            : 'default'
                    }
                    errorMessage={fieldsErrorMessage.dataset}
                    placeholder="Select a dataset for this organization to run the test on"
                    onChange={handleDatasetSelectChange}
                    fetchOptions={() => setPage((page) => page + 1)}
                    canLoadMore={canLoadMoreOptions}
                    includeClientSideFiltering
                    totalOptionsCount={totalDatasetOptions}
                    info={
                        <>
                            Or create{' '}
                            <Link
                                to={`/templates/goal/${goalTemplateId}/dataset/new`}
                                className="underline"
                            >
                                new dataset
                            </Link>
                        </>
                    }
                />

                {(datasetInputKeysOptions || datasetOutputKeysOptions) &&
                    (goalInputKeys || goalOutputKeys ? (
                        <>
                            {datasetInputKeysOptions && !!goalInputKeys?.length && (
                                <div className="flex flex-col gap-2">
                                    {goalInputKeys.map((goalInput, index) => (
                                        <FormFieldWrapper
                                            tipText={
                                                // show tip text just for the last element
                                                index === goalInputKeys.length - 1 &&
                                                'Map each required goal input key to an input within the dataset'
                                            }
                                            state={
                                                fieldsErrorMessage.inputs?.[goalInput.name]
                                                    ? 'error'
                                                    : 'default'
                                            }
                                            errorMessage={
                                                fieldsErrorMessage.inputs?.[goalInput.name]
                                            }
                                            key={goalInput.name}
                                        >
                                            <div className="flex flex-col xs:flex-row xs:items-center gap-3">
                                                <div className="xs:w-[calc((100%-12px)*0.47)]">
                                                    <Input
                                                        size="xs"
                                                        state="disabled"
                                                        value={goalInput.name}
                                                        name="goalInputKey"
                                                        label={
                                                            (index === 0 || screenWidth < 480) &&
                                                            'Goal input key'
                                                        }
                                                        isRequired={
                                                            index === 0 || screenWidth < 480
                                                        }
                                                        onChange={() => {}}
                                                    />
                                                </div>
                                                <div className="xs:w-[calc((100%-12px)*0.53)]">
                                                    <Select
                                                        options={datasetInputKeysOptions}
                                                        value={inputs?.[goalInput.name]}
                                                        label={
                                                            (index === 0 || screenWidth < 480) &&
                                                            'Dataset input key'
                                                        }
                                                        isRequired={
                                                            index === 0 || screenWidth < 480
                                                        }
                                                        onChange={(datasetInputKey) =>
                                                            handleInputsMappingSelectChange(
                                                                goalInput.name,
                                                                datasetInputKey
                                                            )
                                                        }
                                                        size="xs"
                                                        name="datasetInputKey"
                                                    />
                                                </div>
                                            </div>
                                        </FormFieldWrapper>
                                    ))}
                                </div>
                            )}
                            {datasetInputKeysOptions && !!goalOutputKeys.length && (
                                <div className="flex flex-col gap-2">
                                    {goalOutputKeys.map((goalOutput, index) => (
                                        <FormFieldWrapper
                                            tipText={
                                                // show tip text just for the last element
                                                index === goalOutputKeys.length - 1 &&
                                                'Map each required goal output key to an output within the dataset'
                                            }
                                            state={
                                                fieldsErrorMessage.outputs?.[goalOutput.name]
                                                    ? 'error'
                                                    : 'default'
                                            }
                                            errorMessage={
                                                fieldsErrorMessage.outputs?.[goalOutput.name]
                                            }
                                            key={goalOutput.name}
                                        >
                                            <div className="flex flex-col xs:flex-row xs:items-center gap-3">
                                                <div className="xs:w-[calc((100%-12px)*0.47)]">
                                                    <Input
                                                        size="xs"
                                                        state="disabled"
                                                        value={goalOutput.name}
                                                        name="goalOutputKey"
                                                        label={
                                                            (index === 0 || screenWidth < 480) &&
                                                            'Goal output key'
                                                        }
                                                        isRequired={
                                                            index === 0 || screenWidth < 480
                                                        }
                                                        onChange={() => {}}
                                                    />
                                                </div>
                                                <div className="xs:w-[calc((100%-12px)*0.53)]">
                                                    <Select
                                                        options={datasetOutputKeysOptions}
                                                        value={outputs?.[goalOutput.name]}
                                                        label={
                                                            (index === 0 || screenWidth < 480) &&
                                                            'Dataset output key'
                                                        }
                                                        isRequired={
                                                            index === 0 || screenWidth < 480
                                                        }
                                                        onChange={(datasetOutputKey) =>
                                                            handleOutputsMappingSelectChange(
                                                                goalOutput.name,
                                                                datasetOutputKey
                                                            )
                                                        }
                                                        size="xs"
                                                        name="datasetOutputKey"
                                                    />
                                                </div>
                                            </div>
                                        </FormFieldWrapper>
                                    ))}
                                </div>
                            )}
                        </>
                    ) : (
                        <Loading withText={false} />
                    ))}
            </div>
            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={errorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </CollapsableContainer>
    );
};

export default DatasetSettingsBlock;
