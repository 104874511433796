import { useEffect, useState } from 'react';

import { buildQueryParams } from '../helpers/handleUpdateSearchParams';

export const useFetchDataOnLoad = ({ client, route, searchParams, onError }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = buildQueryParams(searchParams);
                const url = route + (queryParams ? `?${queryParams}` : '');

                const { data: response } = await client.get(url);
                setData(response);
            } catch (e) {
                onError && onError();
            }
        };

        fetchData();
    }, []);

    return { data, setData };
};
