import React from 'react';

import * as API from '../../../../constants/api';
import client from '../../../../services/knowledge-api';

import {
    setDocsAndFoldersDataOnFetch,
    toggleFolderExpand,
    updateFolderDataInState,
} from '../../../../helpers/selectDocModalUtils';
import { useFetchPaginatedDataWithExternalStates } from '../../../../hooks/useFetchPaginatedDataWithExternalStates';

import { SvgIcon } from '../../../../design-system';
import { ArrowDownSLineIcon } from '../../../../design-system/Icons';
import FolderFillIcon from '../../../../design-system/Icons/FolderFillIcon';
import DocsAndFoldersList from '../DocsAndFoldersList/DocsAndFoldersList';
import ArrowRightSLineIcon from '../../../../design-system/Icons/ArrowRightSLineIcon';

const FolderListItem = ({
    folder,
    selectedDocs,
    handleChange,
    isTopLevel = false,
    setFoldersDocumentsData,
    parentFolderLocation,
}) => {
    const { id, label, pagination, isExpanded } = folder;

    const Icon = isExpanded ? ArrowDownSLineIcon : ArrowRightSLineIcon;
    const folderIconColor = isTopLevel ? '#754DCF' : '#C6B8E7';

    const location = [...parentFolderLocation, id];

    const setItems = ({ newResults, canLoadMore }) =>
        setDocsAndFoldersDataOnFetch({
            newResults,
            canLoadMore,
            location,
            setFoldersDocumentsData,
        });

    const { isLoading } = useFetchPaginatedDataWithExternalStates({
        client,
        route: API.ROUTES.knowledge.folderDocument,
        searchParams: { parent: id },
        page: pagination.page,
        shouldFetch: !pagination.isFetched && isExpanded,
        updateStateAfterFetch: setItems,
    });

    const toggleExpand = () => toggleFolderExpand({ location, setFoldersDocumentsData });

    const setPage = (cb) =>
        updateFolderDataInState({
            cb: (folder) => {
                const { pagination } = folder;
                return {
                    ...folder,
                    pagination: { ...pagination, page: cb(pagination.page), isFetched: false },
                };
            },
            location,
            setFoldersDocumentsData,
        });

    return (
        <div>
            <button
                onClick={toggleExpand}
                className="py-2 px-3 w-full flex items-center rounded-2 bg-white hover:bg-neutral-50 transition-colors focus:outline-none"
            >
                <div className="block" onClick={() => {}}>
                    <SvgIcon icon={Icon} size="medium" color="#000000" />
                </div>
                <FolderFillIcon width={20} height={20} color={folderIconColor} />
                <p className="flex-1 pl-2 truncate font-body-bold text-body-bold-s text-neutral-500 text-start">
                    {label}
                </p>
            </button>

            {isExpanded && (
                <>
                    {(isLoading || !!folder.results?.length) && (
                        <div className="ml-4">
                            <DocsAndFoldersList
                                setFoldersDocumentsData={setFoldersDocumentsData}
                                data={folder}
                                parentFolderLocation={location}
                                isLoading={isLoading}
                                selectedDocs={selectedDocs}
                                handleChange={handleChange}
                                setPage={setPage}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default FolderListItem;
