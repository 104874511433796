import React, { useState } from 'react';
import classNames from 'classnames';

import { modelsNameMapping } from '../../../constants/modelsNameMapping';

import { handleCopyFormattedText } from '../../../helpers/handleCopyFormattedText';
import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import Alert from '../../../design-system/Alert/Alert';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import CopyIcon from '../../../design-system/Icons/CopyIcon';
import SuccessAlert from '../../../design-system/SuccessAlert/SuccessAlert';
import RefreshCwIcon from '../../../design-system/Icons/RefreshCwIcon';
import FileTextLineIcon from '../../../design-system/Icons/FileTextLineIcon';
import QuickAddToDocModal from '../../../components/QuickAddToDocModal/QuickAddToDocModal';
import AssistantMessageVersionSwitcher from '../AssistantMessageVersionSwitcher/AssistantMessageVersionSwitcher';
import { Sparkling2FillIcon } from '../../../design-system/Icons';
import { ButtonIcon, SvgIcon } from '../../../design-system';

const AssistantMessageActionButtons = ({
    message,
    setChatMessages,
    onRegenerateMessageClick,
    isRegenerateDisabled,
    containerRef,
}) => {
    const {
        default_version: { id: currentVersionId, content, model },
        versions,
    } = message;

    const [copyAlert, setCopyAlert] = useState(null);
    const [successAlert, setSuccessAlert] = useState(null);
    const [isAddToDocModalOpened, setIsAddToDocModalOpened] = useState(false);

    const { isDesktop } = useResponsiveBreakpoints();

    const hasMultipleVersions = versions?.length > 1;

    const currentModel = modelsNameMapping[model];

    const copyFormattedMessage = () => {
        const node = containerRef.current?.querySelector('.wmde-markdown');
        if (node) {
            handleCopyFormattedText({
                node,
                setCopyAlert,
            });
        }
    };

    return (
        <>
            <div className="flex items-center gap-2">
                <ToolTip
                    text="Copy Message"
                    position="top-left"
                    shift="-2px"
                    isShown={!isDesktop && false}
                    useInternalHoverStateToShowToolTip={isDesktop}
                >
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={CopyIcon}
                        onClick={copyFormattedMessage}
                    />
                </ToolTip>

                <ToolTip
                    text="Regenerate"
                    position="top-left"
                    shift="-2px"
                    isShown={!isDesktop && false}
                    useInternalHoverStateToShowToolTip={isDesktop}
                >
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={RefreshCwIcon}
                        state={isRegenerateDisabled ? 'disabled' : 'default'}
                        onClick={onRegenerateMessageClick}
                    />
                </ToolTip>

                <ToolTip
                    text="Quick Add"
                    position="top-left"
                    shift="-2px"
                    isShown={!isDesktop && false}
                    useInternalHoverStateToShowToolTip={isDesktop}
                >
                    <ButtonIcon
                        type="neutral"
                        size="xs"
                        icon={FileTextLineIcon}
                        onClick={() => setIsAddToDocModalOpened(true)}
                    />
                </ToolTip>

                <div className={classNames(!isDesktop && 'flex-1 truncate')}>
                    <ToolTip
                        text={currentModel}
                        position="top-left"
                        shift="-2px"
                        isShown={!isDesktop && false}
                        useInternalHoverStateToShowToolTip={isDesktop}
                    >
                        <div className="flex items-center truncate max-w-full">
                            <div className="w-[28px] min-w-[28px] h-[28px] flex items-center justify-center">
                                <SvgIcon color="#1F2125" icon={Sparkling2FillIcon} size="medium" />
                            </div>
                            {!isDesktop && (
                                <p className="font-body text-body-regular-xs text-black truncate">
                                    {currentModel}
                                </p>
                            )}
                        </div>
                    </ToolTip>
                </div>

                {hasMultipleVersions && (
                    <AssistantMessageVersionSwitcher
                        currentVersionId={currentVersionId}
                        versions={versions || []}
                        setChatMessages={setChatMessages}
                    />
                )}
            </div>

            {isAddToDocModalOpened && (
                <QuickAddToDocModal
                    content={content}
                    setSuccessAlert={setSuccessAlert}
                    onClose={() => setIsAddToDocModalOpened(false)}
                />
            )}

            {copyAlert && (
                <Alert
                    status={copyAlert.status}
                    message={copyAlert.message}
                    icon={copyAlert.icon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopyAlert(null)}
                />
            )}
            <SuccessAlert successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
        </>
    );
};

export default AssistantMessageActionButtons;
