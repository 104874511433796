import React from 'react';
import CheckboxListItem from '../../../../design-system/CheckboxListItem/CheckboxListItem';
import FileTextLineIcon from '../../../../design-system/Icons/FileTextLineIcon';
import InfiniteScrollList from '../../../../components/InfiniteScrollList/InfiniteScrollList';

const DocsListWithSearch = ({
    filteredOptions,
    selectedDocs,
    docsOptionsHookResponse,
    handleChange,
}) => {
    const { optionsLoading, setPage, canLoadMoreOptions } = docsOptionsHookResponse;

    return (
        <InfiniteScrollList
            handleFetch={() => setPage((page) => page + 1)}
            canLoadMore={canLoadMoreOptions}
            items={filteredOptions}
            loading={optionsLoading}
            gap={0}
        >
            {filteredOptions.map((doc) => {
                const { id } = doc;
                const isChecked = !!selectedDocs[id];

                return (
                    <CheckboxListItem
                        key={id}
                        item={doc}
                        isChecked={isChecked}
                        handleChange={handleChange}
                        icon={FileTextLineIcon}
                    />
                );
            })}
        </InfiniteScrollList>
    );
};

export default DocsListWithSearch;
