export const formatKeyToLabel = (string) => {
    if (!string) return '';

    const words = string.split(/[-_]+/);
    const capitalizedWords = words.map((word) =>
        !word ? '' : word[0]?.toUpperCase() + word?.slice(1)
    );

    return capitalizedWords.join(' ');
};
