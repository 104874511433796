import { useEffect, useState } from 'react';

import { DEFAULT_LIMIT } from './useCustomPagination';
import { buildQueryParams } from '../helpers/handleUpdateSearchParams';

export const useFetchPaginatedDataWithExternalStates = ({
    client,
    route,
    searchParams = {},
    page,
    limit = DEFAULT_LIMIT,
    shouldFetch = true,
    updateStateAfterFetch,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const searchParamsQuery = buildQueryParams(searchParams);

                const endpoint = `${route}?limit=${limit}&offset=${(page - 1) * limit}${
                    searchParamsQuery && `&${searchParamsQuery}`
                }`;
                const { data } = await client.get(endpoint);

                const canLoadMore = !!data.next;
                updateStateAfterFetch({ newResults: data.results || [], canLoadMore });

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };

        if (shouldFetch && !isLoading) {
            fetchData();
        }
    }, [page, shouldFetch]);

    return { isLoading };
};
