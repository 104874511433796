import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import * as API from '../../../constants/api';
import client from '../../../services/organization-api';
import userClient from '../../../services/user-api';
import { useGetRequest } from '../../../hooks/request';

import ClientSettings from './ClientSettings/ClientSettings';
import ParentOrganizationSettings from './ParentOrganizationSettings/ParentOrganizationSettings';

import { handlePageDataLoadError } from '../../../helpers/handlePageDataLoadError';
import { useWrongOrgOrViewTypeNavBlocker } from '../../../hooks/useWrongOrgOrViewTypeNavBlocker';
import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';

const OrganizationDetailPage = ({ ...organizationsListProps }) => {
    const { organizationId: parentOrgId } = useParams();

    const [searchParams] = useSearchParams();
    const childOrgId = searchParams.get('client');

    const navigate = useNavigate();

    const controller = useRef(new AbortController());
    const [parentOrganizationData, setParentOrganizationData] = useState(null);

    const getParentOrganizationData = useGetRequest(
        { url: API.ROUTES.organization.organization + parentOrgId + '/' },
        client
    );

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    const {
        options: childOrganizations,
        setOptions: setChildOrganizations,
        page: childOrganizationsPage,
        setPage: setChildOrganizationsPage,
        totalOptions: totalChildOrganizations,
        isFirstRequestCompleted: isChildOrganizationsFirstDataFetched,
    } = useFetchOptionsForPaginatedSelect({
        client,
        route: `${API.ROUTES.organization.organization}${parentOrgId}/child-organization/`,
        triggerRefetch: [parentOrgId],
    });

    const refetchParentOrganizationData = async () => {
        controller.current?.abort();

        const newController = new AbortController();
        controller.current = newController;

        const { response, success, error } = await getParentOrganizationData.request({
            signal: newController.signal,
        });
        if (success) {
            setParentOrganizationData(response.organization_data);
        }
        if (error) {
            handlePageDataLoadError({
                e: error,
                setIsWrongOrg,
                setRedirectPath,
                redirectPath: '/settings',
                generalErrorHandler: () => navigate('/settings'),
            });
        }
    };

    useEffect(() => {
        const fetchParentOrganizationData = async () => {
            setParentOrganizationData(null);

            await refetchParentOrganizationData();
        };

        fetchParentOrganizationData();
    }, [parentOrgId]);

    const parentOrganizationMembers = useMemo(
        () => parentOrganizationData?.organization_members?.map(({ user }) => ({ user })),
        [parentOrganizationData]
    );

    const paginationProps = {
        page: childOrganizationsPage,
        total: totalChildOrganizations,
        setPage: setChildOrganizationsPage,
    };

    useEffect(() => {
        // Handle OAuth callback (only slack for now)
        const handleOAuthCallback = async () => {
            const type = searchParams.get('type');
            const code = searchParams.get('code');
            if (type === 'slack_oauth_callback' && code) {
                try {
                    // Send the OAuth callback request
                    const { data } = await userClient.get(
                        `oauth/callback/?organization=${parentOrgId}&type=${type}&code=${code}`
                    );
                    console.log('OAuth callback response:', data);

                    // Refresh the organization data after a successful response
                    await refetchParentOrganizationData();

                    // Optional: Clean up the URL query params after handling the callback
                    const newSearchParams = new URLSearchParams(searchParams);
                    newSearchParams.delete('type');
                    newSearchParams.delete('code');
                    navigate(`?${newSearchParams.toString()}`, { replace: true });
                } catch (error) {
                    console.error('Error handling OAuth callback:', error);
                }
            }
            // Add other providers here
        };

        handleOAuthCallback();
    }, [searchParams]);

    return (
        <>
            {!childOrgId && (
                <ParentOrganizationSettings
                    key={`parent-organization-details-${parentOrgId}`}
                    organizationData={parentOrganizationData}
                    refetchOrganizationData={refetchParentOrganizationData}
                    childOrganizations={childOrganizations}
                    paginationProps={paginationProps}
                    {...organizationsListProps}
                />
            )}

            {childOrgId && (
                <ClientSettings
                    key={`client-details-${childOrgId}`}
                    clientOrganizations={childOrganizations}
                    setChildOrganizations={setChildOrganizations}
                    isFirstDataFetched={isChildOrganizationsFirstDataFetched}
                    parentOrganizationMembers={parentOrganizationMembers}
                    parentOrganizationName={parentOrganizationData?.organization?.name}
                    parentOrganizationPlan={parentOrganizationData?.billing_account?.plan}
                    {...organizationsListProps}
                />
            )}
        </>
    );
};

export default OrganizationDetailPage;
