import { CROSS_VIEW_TYPE_ROUTES } from '../router/routes/routes';

export const isRouteMatching = (routePattern, currentRoute) => {
    if (routePattern === currentRoute) return true;

    // Convert the route pattern into a regex
    const regexPattern = routePattern
        .replace(/:[^/]+/g, '[^/]+') // Replace dynamic segments like ":id" with a regex to match anything except "/"
        .replace(/\//g, '\\/'); // Escape "/"

    const regex = new RegExp(`^${regexPattern}$`);
    return regex.test(currentRoute);
};

export const isCurrentRouteCrossView = (currentRoute) => {
    return CROSS_VIEW_TYPE_ROUTES.some((routePattern) =>
        isRouteMatching(routePattern, currentRoute)
    );
};
