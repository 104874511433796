let baseUrl;
if (process.env.NODE_ENV === 'production') {
    // TODO: replace with production API URL
    baseUrl = '/api/user/';
} else {
    baseUrl = 'http://127.0.0.1:8000/api/user/';
}

export const PROVIDERS = {
    openai: 'openai',
    anthropic: 'anthropic',
    perplexity: 'perplexity',
    // weaviate: 'weaviate',
    hubspot: 'hubspot',
    serper: 'serper',
    fireflies: 'fireflies',
    notion: 'notion',
    airtable: 'airtable',
    ayrshare: 'ayrshare',
    // asana: 'asana',
    apify: 'apify',
    slack: 'slack',
    google: 'google',
    github: 'github',
    rapidapi: 'rapidapi',
    firecrawl: 'firecrawl',
    smartscout: 'smartscout',
    clickup: 'clickup',
};

export const PROVIDERS_LABEL = {
    openai: 'OpenAI',
    anthropic: 'Anthropic',
    // weaviate: 'Weaviate',
    perplexity: 'Perplexity',
    hubspot: 'HubSpot',
    serper: 'Serper API',
    fireflies: 'Fireflies',
    notion: 'Notion',
    airtable: 'Airtable',
    ayrshare: 'Ayrshare',
    // asana: 'Asana',
    apify: 'Apify',
    slack: 'Slack',
    google: 'Google',
    github: 'GitHub',
    rapidapi: 'Rapid API',
    firecrawl: 'Firecrawl',
    smartscout: 'SmartScout',
    clickup: 'ClickUp',
};

export const PROVIDERS_LOGO = {
    openai: '/images/providers-logo/openai-logo.png',
    anthropic: '/images/providers-logo/anthropic-logo.png',
    perplexity: '/images/providers-logo/perplexity-logo.png',
    // weaviate: '/images/providers-logo/weaviate-logo.png',
    hubspot: '/images/providers-logo/hubspot-logo.png',
    serper: '/images/providers-logo/serper-logo.png',
    fireflies: '/images/providers-logo/fireflies-logo.png',
    notion: '/images/providers-logo/notion-logo.png',
    airtable: '/images/providers-logo/airtable-logo.png',
    ayrshare: '/images/providers-logo/ayrshare-logo.png',
    // asana: '/images/providers-logo/asana-logo.png',
    apify: '/images/providers-logo/apify-logo.png',
    slack: '/images/providers-logo/slack-logo.png',
    google: '/images/providers-logo/google-logo.png',
    github: '/images/providers-logo/github-logo.png',
    rapidapi: '/images/providers-logo/rapidapi-logo.png',
    firecrawl: '/images/providers-logo/firecrawl-logo.png',
    smartscout: '/images/providers-logo/smartscout-logo.png',
    clickup: '/images/providers-logo/clickup-logo.png',
};

export const providersCardData = {
    [PROVIDERS.openai]: {
        title: PROVIDERS_LABEL.openai,
        description: 'Connect your OpenAI API key to run your prompts using their models.',
        logoPath: PROVIDERS_LOGO.openai,
        authUrl: null
    },
    [PROVIDERS.anthropic]: {
        title: PROVIDERS_LABEL.anthropic,
        description: 'Connect your Anthropic API key to run your prompts using their models.',
        logoPath: PROVIDERS_LOGO.anthropic,
        authUrl: null
    },
    [PROVIDERS.perplexity]: {
        title: PROVIDERS_LABEL.perplexity,
        description: 'Connect your Perplexity API key to run your prompts using their models.',
        logoPath: PROVIDERS_LOGO.perplexity,
        authUrl: null
    },
    // [PROVIDERS.weaviate]: {
    //     title: PROVIDERS_LABEL.weaviate,
    //     description:
    //         'Connect your Weaviate cluster to start embedding your business knowledge into a vector store.',
    //     logoPath: PROVIDERS_LOGO.weaviate,
    // },
    [PROVIDERS.hubspot]: {
        title: PROVIDERS_LABEL.hubspot,
        description: 'Connect your HubSpot account to reimagine your sales and marketing efforts.',
        logoPath: PROVIDERS_LOGO.hubspot,
        authUrl: null
    },
    [PROVIDERS.serper]: {
        title: PROVIDERS_LABEL.serper,
        description:
            'Connect your Serper API account to supercharge your processes with real-time web search capabilities.',
        logoPath: PROVIDERS_LOGO.serper,
        authUrl: null
    },
    [PROVIDERS.fireflies]: {
        title: PROVIDERS_LABEL.fireflies,
        description: 'Connect your Fireflies account to enhance your meeting productivity.',
        logoPath: PROVIDERS_LOGO.fireflies,
        authUrl: null
    },
    [PROVIDERS.notion]: {
        title: PROVIDERS_LABEL.notion,
        description:
            'Connect your Notion account to amplify your collaboration and organization capabilities.',
        logoPath: PROVIDERS_LOGO.notion,
        authUrl: null
    },
    [PROVIDERS.airtable]: {
        title: PROVIDERS_LABEL.airtable,
        description:
            'Connect your Airtable account to connect your business processes to your data.',
        logoPath: PROVIDERS_LOGO.airtable,
        authUrl: null
    },
    [PROVIDERS.ayrshare]: {
        title: PROVIDERS_LABEL.ayrshare,
        description:
            'Connect your Ayrshare account to connect your social media accounts for content processes.',
        logoPath: PROVIDERS_LOGO.ayrshare,
        authUrl: null
    },
    // [PROVIDERS.asana]: {
    //     title: PROVIDERS_LABEL.asana,
    //     description:
    //         'Connect your Asana account to bring your processes into the personal productivity space.',
    //     logoPath: PROVIDERS_LOGO.asana,
    // },
    [PROVIDERS.rapidapi]: {
        title: PROVIDERS_LABEL.rapidapi,
        description:
            'Connect your Rapid API account to supercharge your processes with new APIs.',
        logoPath: PROVIDERS_LOGO.rapidapi,
        authUrl: null
    },
    [PROVIDERS.slack]: {
        title: PROVIDERS_LABEL.slack,
        description:
            'Connect your Slack account to streamline your team’s collaboration and communication.',
        logoPath: PROVIDERS_LOGO.slack,
        authUrl: `oauth/redirect/?provider=slack`
    },
    [PROVIDERS.apify]: {
        title: PROVIDERS_LABEL.apify,
        description:
            'Connect your Apify account to unlock web scraping and automation capabilities.',
        logoPath: PROVIDERS_LOGO.apify,
        authUrl: null
    },
    [PROVIDERS.google]: {
        title: PROVIDERS_LABEL.google,
        description:
            'Connect your Google Gemini API key to run your prompts using their models.',
        logoPath: PROVIDERS_LOGO.google,
        authUrl: null
    },
    [PROVIDERS.github]: {
        title: PROVIDERS_LABEL.github,
        description:
            'Connect your GitHub account to work with your repositories and automate your workflows.',
        logoPath: PROVIDERS_LOGO.github,
        authUrl: `oauth/redirect/?provider=github`
    },
    [PROVIDERS.firecrawl]: {
        title: PROVIDERS_LABEL.firecrawl,
        description:
            'Connect your Firecrawl account to unlock smarter web scraping and crawling.',
        logoPath: PROVIDERS_LOGO.firecrawl,
        authUrl: null
    },
    [PROVIDERS.smartscout]: {
        title: PROVIDERS_LABEL.smartscout,
        description:
            'Connect your SmartScout account to leverage Amazon data.',
        logoPath: PROVIDERS_LOGO.smartscout,
        authUrl: null
    },
    [PROVIDERS.clickup]: {
        title: PROVIDERS_LABEL.clickup,
        description:
            'Connect your ClickUp account to manage your tasks and projects.',
        logoPath: PROVIDERS_LOGO.clickup,
        authUrl: null
    }
};

export const providersForNonAgencyOrg = [PROVIDERS.openai, PROVIDERS.anthropic];

export const providersForAgencyOrg = [
    PROVIDERS.openai,
    PROVIDERS.anthropic,
    PROVIDERS.perplexity,
    // PROVIDERS.weaviate,
    PROVIDERS.hubspot,
    PROVIDERS.serper,
    PROVIDERS.fireflies,
    PROVIDERS.notion,
    PROVIDERS.airtable,
    PROVIDERS.ayrshare,
    // PROVIDERS.asana,
    PROVIDERS.apify,
    PROVIDERS.slack,
    PROVIDERS.google,
    PROVIDERS.rapidapi,
    PROVIDERS.firecrawl,
    PROVIDERS.smartscout,
    PROVIDERS.clickup,
];

export const comingSoonProviders = [];
