import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import EscapeIcon from '../Icons/EscapeIcon';
import { ButtonIcon } from '../index';
import { CloseLineIcon } from '../Icons';

ModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
    titleStyles: PropTypes.string,
    outlined: PropTypes.bool,
    customPaddings: PropTypes.string,
    additionalControls: PropTypes.node,
};

function ModalHeader({
    title,
    onClose,
    size = 'sm', // size of button icon
    titleStyles = 'font-heading-bold text-heading-bold-m text-neutral-500',
    outlined = false,
    customPaddings = 'px-5 sm:px-6 md:px-8 py-3',
    additionalControls = null,
    withEscapeButton = true,
}) {
    const containerClassName = classNames(
        'flex items-center justify-between gap-3',
        outlined && `border-b-1 border-neutral-200 ${customPaddings}`
    );

    return (
        <div className={containerClassName}>
            <p className={titleStyles}>{title}</p>
            <div className="flex items-center gap-1">
                {additionalControls}

                {withEscapeButton && (
                    <button onClick={onClose} className="cursor-pointer">
                        <EscapeIcon width={28} height={18} color="#5E6470" />
                    </button>
                )}

                <ButtonIcon type="link" size={size} icon={CloseLineIcon} onClick={onClose} />
            </div>
        </div>
    );
}

export default ModalHeader;
