import { authRoutes } from './authRoutes';
import { libraryRoutes } from './libraryRoutes';
import { configureRoutes } from './configureRoutes';
import { templatesRoutes } from './templatesRoutes';
import { assistantRoutes } from './assistantRoutes';
import { docsRoutes } from './docsRoutes';
import { botsRoutes } from './botsRoutes';
import { playbooksRoutes } from './playbooksRoutes';
import { settingsRoutes } from './settingsRoutes';
import { searchRoutes } from './searchRoutes';
import { basesRoutes } from './basesRoutes';
import { upgradeRoutes } from './upgradeRoutes';
import { inboxRoutes } from './inboxRoutes';
import { agentRoutes } from './agentRoutes';
import { scenarioRoutes } from './scenarioRoutes';
import { jobsRoutes } from './jobsRoutes';
import { projectsRoutes } from './projectsRoutes';
import { discoverRoutes } from './discoverRoutes';
import { threadsRoutes } from './threadsRoutes';
import { executionRoutes } from './executionRoutes';
import { dashboardRoutes } from './dashboardRoutes';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';
import { isRouteMatching } from '../../helpers/routesUtils';

export const routes = [
    ...authRoutes,
    ...libraryRoutes,
    ...configureRoutes,
    ...settingsRoutes,
    ...templatesRoutes,
    ...assistantRoutes,
    ...docsRoutes,
    ...botsRoutes,
    ...playbooksRoutes,
    ...searchRoutes,
    ...basesRoutes,
    ...upgradeRoutes,
    ...inboxRoutes,
    ...agentRoutes,
    ...scenarioRoutes,
    ...jobsRoutes,
    ...projectsRoutes,
    ...discoverRoutes,
    ...threadsRoutes,
    ...executionRoutes,
    ...dashboardRoutes,
];

export const DYNAMIC_TO_INDEX_ROUTES_MAPPING = routes.reduce(
    (acc, { path, indexPath }) => ({
        ...acc,
        [path]: indexPath || path,
    }),
    {}
);

// the following code cannot be moved to a separate file because it uses the routes array

// routes available for both view types
export const CROSS_VIEW_TYPE_ROUTES = routes
    .filter((route) => {
        const { route_access_type, permissionRules } = route;
        if (route_access_type === ROUTE_ACCESS_TYPE.public) return true;
        if (route_access_type === ROUTE_ACCESS_TYPE.private) {
            if (permissionRules && permissionRules.availableViewTypes) {
                const { availableViewTypes } = permissionRules;
                return (
                    availableViewTypes.includes(VIEW_TYPES.admin) &&
                    availableViewTypes.includes(VIEW_TYPES.client)
                );
            }
        }
        return false;
    })
    .map((route) => route.path);

export const allRoutesPathname = routes.map((route) => route.path);

// means that the pathname filled with IDs instead of dynamic parts
export const resolvePathAndPermissionsForSpecificPath = (path) => {
    const route = routes.find((route) => isRouteMatching(route.path, path));
    return { dynamicPath: route?.path, permissionRules: route?.permissionRules || {} };
};
