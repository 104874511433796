import React, { useState } from 'react';

import { API } from 'constants';
import client from '../../../../services/inbox-api';

import { useFormState } from '../../../../hooks/useFormState';
import { capitalizeStr } from '../../../../helpers/formatOrganizationDataForTable';
import { useOverlayState } from '../../../../hooks/useOverlayState';

import { SETTING_TYPE, SUGGESTION_ACTION } from '../../../../constants/settingsForm';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import RichTextArea from '../../../../design-system/RichTextArea/RichTextArea';
import TimestampDisplay from '../../../../design-system/TimestampDisplay/TimestampDisplay';
import MultiActionButton from '../../../../design-system/MultiActionButton/MultiActionButton';
import TextAreaWithAutoResize from '../../../../design-system/TextAreaWithAutoResize/TextAreaWithAutoResize';
import SuggestionMultiActionPopup from '../SuggestionMultiActionPopup/SuggestionMultiActionPopup';
import { TextArea } from '../../../../design-system';

const ViewSuggestionModalIndex = ({ suggestion, onSuccess, onClose }) => {
    const { id, title, created_at, value, feedback } = suggestion;

    const [action, setAction] = useState(SUGGESTION_ACTION.approve);

    const { isOpened: isPopupOpened, toggle: togglePopup } = useOverlayState();
    const { isOpened: isFeedbackShown, toggle: toggleFeedback } = useOverlayState();

    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { text: feedback?.value || '' }
    );

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const confirmAction = async () => {
        try {
            if (action === SUGGESTION_ACTION.resubmit && !formData.text) {
                setFieldErrorMessages({ text: emptyFieldErrorMessage });
                return;
            }

            setIsLoading(true);

            const status = {
                [SUGGESTION_ACTION.approve]: 'completed',
                [SUGGESTION_ACTION.reject]: 'archived',
                [SUGGESTION_ACTION.resubmit]: 'archived',
            }[action];
            const requestBody = { status };
            if (action === SUGGESTION_ACTION.resubmit) {
                requestBody.text = formData.text;
            }

            const { data } = await client.patch(
                API.ROUTES.inbox.suggestion + id + '/',
                requestBody
            );

            const detailType = Object.keys(data.detail || {})[0];
            const type = {
                settings: SETTING_TYPE.agent,
                context: SETTING_TYPE.client,
            }[detailType];

            await onSuccess({ id, action, type, inputData: data.detail?.[detailType]?.[0] });
            onClose();
        } catch (e) {
            setIsLoading(false);
            setErrorAlert({ message: defaultErrorMessage });
        }
    };

    const setNewAction = (action) => {
        setAction(action);
        setFieldErrorMessages({});
    };

    const feedbackButtonText = isFeedbackShown ? 'Hide Feedback' : 'View Feedback';

    const isExpanded = isFeedbackShown || action === SUGGESTION_ACTION.resubmit;

    return (
        <div className="flex flex-col gap-5 transition-all min-h-[194px]">
            <div>
                <h3 className="font-body-bold text-bodybold-l text-neutral-500 mb-1">{title}</h3>
                <TimestampDisplay label="Created At" timestamp={created_at} variant="secondary" />
            </div>

            <RichTextArea value={value} previewOnly autoExpand isBorderHidden />

            {isExpanded && (
                <div className="pt-5 border-t-1 border-neutral-200 flex flex-col gap-5">
                    {isFeedbackShown && (
                        <TextAreaWithAutoResize
                            name="current_feedback"
                            value={feedback?.value}
                            label="Feedback"
                            isRequired
                            state="disabled"
                            placeholder="No text provided in feedback"
                            minHeight={120}
                        />
                    )}

                    {action === SUGGESTION_ACTION.resubmit && (
                        <TextArea
                            name="feedback"
                            value={formData.text}
                            label="Edit your Feedback"
                            isRequired
                            state={fieldErrorMessages.text ? 'error' : 'default'}
                            errorMessage={fieldErrorMessages.text}
                            height="120px"
                            placeholder="Enter your Feedback"
                            onChange={(e) => handleInputChange('text', e.target.value)}
                        />
                    )}
                </div>
            )}

            <div className="relative flex items-center justify-end gap-3 lg:gap-8">
                {!!feedback && (
                    <button
                        onClick={toggleFeedback}
                        className="font-heading-bold text-[14px] text-neutral-400 underline underline-offset-2 focus:outline-0"
                    >
                        {feedbackButtonText}
                    </button>
                )}

                <MultiActionButton
                    type="secondary"
                    size="sm"
                    text={capitalizeStr(action)}
                    state={isLoading ? 'loading' : 'default'}
                    isPopupOpened={isPopupOpened}
                    togglePopup={togglePopup}
                    onClick={confirmAction}
                />

                {isPopupOpened && (
                    <SuggestionMultiActionPopup setAction={setNewAction} onClose={togglePopup} />
                )}
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </div>
    );
};

export default ViewSuggestionModalIndex;
