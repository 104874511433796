import React from 'react';
import DocsAndFoldersList from '../DocsAndFoldersList/DocsAndFoldersList';

const DocsListWithoutSearch = ({
    selectedDocs,
    foldersDocumentsData,
    setFoldersDocumentsData,
    handleChange,
    isRootDataLoading,
}) => {
    const setPage = (cb) => {
        setFoldersDocumentsData((prevData) => {
            const { pagination } = prevData;
            return {
                ...prevData,
                pagination: { ...pagination, page: cb(pagination.page), isFetched: false },
            };
        });
    };

    return (
        <DocsAndFoldersList
            data={foldersDocumentsData}
            setFoldersDocumentsData={setFoldersDocumentsData}
            selectedDocs={selectedDocs}
            handleChange={handleChange}
            setPage={setPage}
            isLoading={isRootDataLoading}
            isRoot
            parentFolderLocation={[]}
        />
    );
};

export default DocsListWithoutSearch;
