import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { VIEW_TYPES } from '../../../constants/viewTypes';
import { useViewType } from '../../../hooks/useViewType';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { generalSortOptions } from '../../../constants/sort';

import { Button } from '../../../design-system';
import { AddCircleLineIcon } from '../../../design-system/Icons';
import SortControls from '../../../components/SortControls/SortControls';
import SearchBarWithUrlParamsHandling from '../../../components/SearchBarWithUrlParamsHandling/SearchBarWithUrlParamsHandling';

const BasesPageHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { viewType } = useViewType();
    const withCreateButton = viewType === VIEW_TYPES.admin;

    const { width: screenWidth } = useWindowSize();
    const smallBreakpoint = withCreateButton ? 480 : 548;

    const isSmall = screenWidth < smallBreakpoint;
    const isMedium = screenWidth >= smallBreakpoint && screenWidth < 768;

    const sortDropdownPosition = (() => {
        if (isSmall) return { top: '32px', right: 0 };
        if (isMedium) return { top: '32px', left: '50%', transform: 'translateX(-50%)' };
        return { top: '32px', left: 0 };
    })();

    const searchContainerClassName = classNames('flex-grow sm:max-w-[300px]', {
        'xs:max-w-[300px]': !withCreateButton,
        'xs:max-w-[240px]': withCreateButton,
    });

    return (
        <>
            <div>
                <p className="font-heading-bold text-heading-bold-l">Knowledge Sources</p>
                <p className="font-body text-body-regular-xs text-neutral-300">
                    The context available to your chatbots and agents
                </p>
            </div>

            <div className="flex flex-col-reverse xs:items-center xs:justify-between gap-x-4 gap-y-2 xs:flex-row">
                <div className="xs:flex-grow flex items-center gap-2">
                    <div className={searchContainerClassName}>
                        <SearchBarWithUrlParamsHandling size="xs" placeholder="Search by name" />
                    </div>
                    <SortControls
                        sortOptions={generalSortOptions}
                        variant="secondary"
                        dropdownPosition={sortDropdownPosition}
                        buttonContainerClassName="relative"
                    />
                </div>

                {withCreateButton && (
                    <Button
                        type="primary"
                        size="xs"
                        text="New Base"
                        state="disabled"
                        leadingIcon={AddCircleLineIcon}
                        onClick={() => navigate('/base/new', { state: { from: location } })}
                    />
                )}
            </div>
        </>
    );
};

export default BasesPageHeader;
