import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useWindowSize } from '../../hooks/useWindowSize';

import ListOption from '../../design-system/ListOption/ListOption';
import ListOptionGroup from '../../design-system/ListOptionGroup/ListOptionGroup';

const SortDropdown = ({
    options,
    selectedOption,
    handleOptionSelect,
    onClose,
    dropdownPosition: _dropdownPosition = null,
    dropdownWidth,
}) => {
    const { width: screenWidth } = useWindowSize();

    const listOptions = options.map(({ text, value }, index) => (
        <ListOption
            key={value}
            text={text}
            index={index}
            leadingSelectIcon={true}
            selected={value === selectedOption}
            onClick={() => handleOptionSelect(value)}
        />
    ));

    const defaultDropdownWidth = screenWidth > 850 ? 355 : 320;

    const defaultPosition = {
        top: '120%',
        left: screenWidth >= 1050 ? 0 : 'auto',
        right: screenWidth < 1050 ? 0 : 'auto',
    };

    const className = classNames(
        'absolute z-20',
        typeof _dropdownPosition === 'string' ? _dropdownPosition : null
    );
    const style =
        !_dropdownPosition || typeof _dropdownPosition === 'object'
            ? { ...(_dropdownPosition || defaultPosition) }
            : null;

    return (
        <div className={className} style={style}>
            <ListOptionGroup
                listOptions={listOptions}
                fixedWidth={dropdownWidth || defaultDropdownWidth}
                handleClose={onClose}
            />
        </div>
    );
};

SortDropdown.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ).isRequired,
    selectedOption: PropTypes.string.isRequired,
    handleOptionSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    dropdownPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dropdownWidth: PropTypes.number,
};

export default SortDropdown;
