import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/library-api';

import { useFormState } from '../../../hooks/useFormState';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useWrongOrgOrViewTypeNavBlocker } from '../../../hooks/useWrongOrgOrViewTypeNavBlocker';

import { isValidJson } from '../../../helpers/isValidJson';
import { checkIfFormDataChanged } from '../../../helpers/checkIfFormDataChanged';
import { handlePageDataLoadError } from '../../../helpers/handlePageDataLoadError';

import { CONFIGURE_TAB } from '../../../constants/configure';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../constants/errorMessages';

import ScenarioHeader from './PageHeader/PageHeader';
import ScenarioDetailsHeader from './ScenarioDetailsHeader/ScenarioDetailsHeader';
import ScenarioVersionSelector from './ScenarioVersionSelector/ScenarioVersionSelector';
import Loading from '../../../components/Loading';
import ScenarioVersionDetails from './ScenarioVersionDetails/ScenarioVersionDetails';
import CreateNewVersionModal from './CreateNewVersionModal/CreateNewVersionModal';
import ScenarioActionBar from './ScenarioActionBar/ScenarioActionBar';
import Alert from '../../../design-system/Alert/Alert';
import { ErrorWarningLineIcon } from '../../../design-system/Icons';

export const SCENARIO_ROUTE = API.ROUTES.library.scenario;
export const SCENARIO_VERSION_ROUTE = API.ROUTES.library.scenarioVersion;

const ScenarioDetailPage = () => {
    const { scenarioId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const location = useLocation();

    const backLinkHref = location.state?.from ?? `/configure?tab=${CONFIGURE_TAB.scenarios}`;

    const [scenarioData, setScenarioData] = useState(null);

    const selectedVersionId = searchParams.get('version');

    const {
        formData: selectedVersionData,
        setFormData: setSelectedVersionData,
        handleInputChange,
        fieldErrorMessages,
        setFieldErrorMessages,
    } = useFormState(null);
    const originalSelectedVersionData = useRef(selectedVersionData);

    const [areChangesToSave, setAreChangesToSave] = useState(false);

    const isDefaultVersionSelected =
        selectedVersionId && selectedVersionId === scenarioData?.default_version?.id;

    const [isCreateVersionModalOpened, setIsCreateVersionModalOpened] = useState(false);

    const [isSaveChangesLoading, setIsSaveChangesLoading] = useState(false);
    const [isStatusChangeLoading, setIsStatusChangeLoading] = useState({
        is_default: false,
        is_live: false,
    });

    const [errorAlert, setErrorAlert] = useState(null);

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    useDocumentTitle(scenarioData?.name);

    useEffect(() => {
        const fetchScenarioData = async () => {
            try {
                const { data } = await client.get(`${SCENARIO_ROUTE}${scenarioId}/`);
                setScenarioData(data);

                if (selectedVersionId) {
                    const selectedVersionData =
                        data.versions?.find((version) => version.id === selectedVersionId) || null;
                    setSelectedVersionFormData(selectedVersionData);
                }
            } catch (e) {
                handlePageDataLoadError({
                    e,
                    setIsWrongOrg,
                    setRedirectPath,
                    redirectPath: `/configure?tab=${CONFIGURE_TAB.scenarios}`,
                    generalErrorHandler: () => navigate(backLinkHref),
                });
            }
        };

        fetchScenarioData();
    }, []);

    useEffect(() => {
        const versionIsNotPreselect = !selectedVersionId && scenarioData;

        if (versionIsNotPreselect) {
            const defaultVersionId = scenarioData.default_version?.id;

            if (defaultVersionId) {
                setSelectedVersionToSearchParams(defaultVersionId);
            }
        }
    }, [selectedVersionId, scenarioData]);

    useEffect(() => {
        if (selectedVersionData) {
            const isFormDataChanged = checkIfFormDataChanged(
                originalSelectedVersionData.current,
                selectedVersionData
            );

            if (areChangesToSave !== isFormDataChanged) {
                setAreChangesToSave(isFormDataChanged);
            }
        }
    }, [selectedVersionData]);

    const setSelectedVersionFormData = useCallback(
        (selectedVersionData) => {
            if (selectedVersionData) {
                const newVersionData = {
                    ...selectedVersionData,
                    config: selectedVersionData.config || {},
                };
                setSelectedVersionData(newVersionData);

                setFieldErrorMessages({});
                originalSelectedVersionData.current = newVersionData;
                setAreChangesToSave(false);
            }
        },
        [setSelectedVersionData]
    );

    const refreshSelectedVersionFormData = useCallback(
        (versionId) => {
            const selectedVersionData =
                scenarioData.versions?.find((version) => version.id === versionId) || null;
            setSelectedVersionFormData(selectedVersionData);
        },
        [scenarioData?.versions, setSelectedVersionFormData]
    );

    const setSelectedVersionToSearchParams = useCallback(
        (versionId) => {
            const urlSearchParams = new URLSearchParams(searchParams);
            urlSearchParams.set('version', versionId);
            setSearchParams(urlSearchParams, { state: location.state });

            refreshSelectedVersionFormData(versionId);
        },
        [searchParams, setSearchParams, location, refreshSelectedVersionFormData]
    );

    const onScenarioVersionCreate = (newVersion) => {
        setScenarioData((prevData) => ({
            ...prevData,
            versions: [...prevData.versions, newVersion],
        }));

        const urlSearchParams = new URLSearchParams(searchParams);
        urlSearchParams.set('version', newVersion.id);
        setSearchParams(urlSearchParams, { state: location.state });

        setSelectedVersionFormData(newVersion);
    };

    const isSaveChangesRequestBodyError = () => {
        const areEmptyFields = !selectedVersionData.name || !selectedVersionData.config;
        if (areEmptyFields) {
            setFieldErrorMessages({ name: emptyFieldErrorMessage, config: emptyFieldErrorMessage });
            return true;
        }

        if (typeof selectedVersionData.config === 'string') {
            const isJsonValid = isValidJson(selectedVersionData.config);
            if (!isJsonValid) {
                setFieldErrorMessages((prev) => ({
                    ...prev,
                    config: 'Invalid JSON format. Please check your syntax.',
                }));
                return true;
            }
        }

        return false;
    };

    const refreshVersionDataAfterUpdates = (updatedVersion) => {
        setScenarioData((prevData) => ({
            ...prevData,
            versions:
                prevData.versions?.map((version) => {
                    if (version.id === updatedVersion.id) {
                        return updatedVersion;
                    }
                    // if updated version is default, remove default flag from other versions
                    if (updatedVersion.is_default) {
                        return { ...version, is_default: false };
                    }
                    return version;
                }) || [],
            default_version: updatedVersion?.is_default ? updatedVersion : prevData.default_version,
        }));

        setSelectedVersionFormData(updatedVersion);
    };

    const saveVersionChanges = async ({ shouldErrorBeThrown } = { shouldErrorBeThrown: false }) => {
        if (!areChangesToSave) {
            return;
        }

        const isFormDataInvalid = isSaveChangesRequestBodyError();
        if (isFormDataInvalid) {
            if (shouldErrorBeThrown) {
                throw new Error('save changes error');
            }
            return;
        }

        try {
            !shouldErrorBeThrown && setIsSaveChangesLoading(true);
            const requestBody = {
                name: selectedVersionData.name,
                config:
                    typeof selectedVersionData.config === 'string'
                        ? JSON.parse(selectedVersionData.config)
                        : selectedVersionData.config,
            };
            const { data } = await client.patch(
                `${SCENARIO_VERSION_ROUTE}${selectedVersionData.id}/`,
                requestBody
            );
            refreshVersionDataAfterUpdates(data);
            setIsSaveChangesLoading(false);
        } catch (e) {
            setIsSaveChangesLoading(false);

            const errorMessage = e?.response?.data?.error;
            if (errorMessage && typeof errorMessage === 'string') {
                setFieldErrorMessages((prev) => ({ ...prev, config: errorMessage }));
            }

            setErrorAlert({ message: defaultErrorMessage, statusCode: e?.response?.status });

            if (shouldErrorBeThrown) {
                throw new Error('save changes error');
            }
        }
    };

    const changeVersionStatus = async ({ statusType }) => {
        // statusTypes: is_live, is_default
        try {
            setIsStatusChangeLoading((prevState) => ({ ...prevState, [statusType]: true }));
            if (areChangesToSave) {
                await saveVersionChanges({ shouldErrorBeThrown: true });
            }

            const { data } = await client.patch(
                `${SCENARIO_VERSION_ROUTE}${selectedVersionData.id}/`,
                { [statusType]: true }
            );

            refreshVersionDataAfterUpdates(data);

            if (statusType === 'is_live' && !scenarioData.is_live) {
                setScenarioData((prevData) => ({ ...prevData, is_live: true }));
            }
        } catch (e) {
            if (e.message === 'save changes error') {
                // do nothing, this error already shown in saveVersionChanges()
            }
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        } finally {
            setIsStatusChangeLoading((prevState) => ({ ...prevState, [statusType]: false }));
        }
    };

    const isLoading =
        isSaveChangesLoading || isStatusChangeLoading.is_live || isStatusChangeLoading.is_default;

    return (
        <div className="page-position flex flex-col overflow-y-auto bg-white">
            {scenarioData && (
                <>
                    <ScenarioHeader
                        scenarioId={scenarioId}
                        scenarioName={scenarioData.name}
                        backLinkHref={backLinkHref}
                    />
                    <div className="w-full max-w-full flex-1 px-1 md:px-6 pt-6 pb-[40px]">
                        <div className="w-full max-w-[900px] mx-auto px-4 h-full flex flex-col gap-5">
                            <ScenarioDetailsHeader
                                scenarioData={scenarioData}
                                setScenarioData={setScenarioData}
                                isEditable={isDefaultVersionSelected}
                            />

                            <ScenarioVersionSelector
                                versions={scenarioData.versions}
                                setSelectedVersion={setSelectedVersionToSearchParams}
                                isNewVersionButtonEnabled={
                                    isDefaultVersionSelected && scenarioData.is_live
                                }
                                setIsCreateVersionModalOpened={setIsCreateVersionModalOpened}
                                isVersionChangeBlocked={isLoading}
                            />

                            {selectedVersionData && (
                                <ScenarioVersionDetails
                                    key={`version-details-${selectedVersionData?.id}`}
                                    selectedVersionFormData={selectedVersionData}
                                    handleInputChange={handleInputChange}
                                    fieldErrorMessages={fieldErrorMessages}
                                    isEditable={!selectedVersionData?.is_live}
                                    setFieldErrorMessages={setFieldErrorMessages}
                                />
                            )}

                            <ScenarioActionBar
                                areChangesToSave={areChangesToSave}
                                isLiveVersionSelected={selectedVersionData?.is_live}
                                isDefaultVersionSelected={isDefaultVersionSelected}
                                handleSaveChanges={saveVersionChanges}
                                changeVersionStatus={changeVersionStatus}
                                isSaveChangesLoading={isSaveChangesLoading}
                                isStatusChangeLoading={isStatusChangeLoading}
                            />
                        </div>
                    </div>

                    {isCreateVersionModalOpened && (
                        <CreateNewVersionModal
                            scenarioId={scenarioId}
                            config={scenarioData.default_version?.config}
                            onScenarioVersionCreate={onScenarioVersionCreate}
                            onClose={() => setIsCreateVersionModalOpened(false)}
                        />
                    )}
                </>
            )}

            {!scenarioData && (
                <div className="flex-1 flex items-center justify-center">
                    <Loading />
                </div>
            )}

            {errorAlert && (
                <Alert
                    status="critical"
                    message={errorAlert.message}
                    icon={ErrorWarningLineIcon}
                    statusCode={errorAlert.statusCode}
                    autoCloseInMS={4000}
                    handleClose={() => setErrorAlert(null)}
                />
            )}
        </div>
    );
};

export default ScenarioDetailPage;
