import React from 'react';

import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TimestampDisplay = ({
    label,
    dateTimeFormat: _dateTimeFormat,
    timestamp,
    variant = 'primary',
    customLabelStyles = '',
}) => {
    const dateTimeFormat = _dateTimeFormat || 'MM/dd/yyyy, hh:mm a';
    const formattedTime = DateTime.fromISO(timestamp).toFormat(dateTimeFormat);

    const labelClassName = classNames(
        'whitespace-nowrap truncate',
        {
            'font-body text-body-regular-xs text-neutral-300': variant === 'primary',
            'font-body-bold text-body-bold-xs text-neutral-300': variant === 'secondary',
        },
        customLabelStyles
    );

    const timestampClassName = classNames({
        'font-body-bold text-body-bold-xs text-neutral-500': variant === 'primary',
        'font-body text-body-regular-xs text-neutral-300': variant === 'secondary',
    });

    return (
        <p className={labelClassName}>
            {label} <span className={timestampClassName}>{formattedTime}</span>
        </p>
    );
};

TimestampDisplay.propTypes = {
    label: PropTypes.string.isRequired,
    dateTimeFormat: PropTypes.string,
    timestamp: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default TimestampDisplay;
