import React from 'react';
import classNames from 'classnames';

import { formatKeyToLabel } from '../../../../helpers/formatKeyToLabel';

const InputCard = ({ input, isActive, setValuePanel }) => {
    const { label, key, description } = input;

    const formattedLabel = label || formatKeyToLabel(key);

    const handleCardClick = () => {
        if (isActive) {
            setValuePanel(null);
            return;
        }
        setValuePanel(input);
    };

    const cardClassName = classNames(
        'p-4 rounded-2 bg-white border-2 flex flex-col gap-1 transition-colors cursor-pointer',
        isActive ? 'border-purple-300' : 'border-neutral-100'
    );

    return (
        <div className={cardClassName} onClick={handleCardClick}>
            <p className="font-body-bold text-body-bold-s text-neutral-500">{formattedLabel}</p>
            {description && (
                <p className="font-body text-body-regular-s text-neutral-300 line-clamp-3">
                    {description}
                </p>
            )}
        </div>
    );
};

export default InputCard;
