import React, { useEffect, useState } from 'react';

import client from '../../../services/knowledge-api';
import * as API from '../../../constants/api';

import { SORT_TYPES } from '../../../constants/sort';
import { ROOT_FOLDER_INITIAL_DATA } from '../../../constants/selectDocModal';

import {
    collapseAllFolders,
    expandFoldersWithSelectedDocs,
    setDocsAndFoldersDataOnFetch,
} from '../../../helpers/selectDocModalUtils';
import { useFetchPaginatedDataWithExternalStates } from '../../../hooks/useFetchPaginatedDataWithExternalStates';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import SelectDocModal from './SelectDocModal/SelectDocModal';

const SelectDocBlock = ({
    selectedDocs,
    setSelectedTools,
    clearErrorMessage,
    textareaHeight,
    isModalOpened,
    setIsModalOpened,
}) => {
    const [foldersDocumentsData, setFoldersDocumentsData] = useState(ROOT_FOLDER_INITIAL_DATA);

    const docsOptionsHookResponse = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.knowledge.document,
        searchParams: { ordering: SORT_TYPES.alphabeticalByLabel },
        formatResponseToOptions: (results) => results?.map(({ id, label }) => ({ id, label })),
    });

    const setRootItems = ({ newResults, canLoadMore }) => {
        setDocsAndFoldersDataOnFetch({
            newResults,
            canLoadMore,
            location: [],
            setFoldersDocumentsData,
        });
    };

    const { pagination } = foldersDocumentsData;

    const { isLoading } = useFetchPaginatedDataWithExternalStates({
        client,
        route: API.ROUTES.knowledge.folderDocument,
        page: pagination.page,
        shouldFetch: !pagination.isFetched,
        updateStateAfterFetch: setRootItems,
    });

    useEffect(() => {
        if (isModalOpened) {
            const selectedDocsArray = Object.keys(selectedDocs || {});
            expandFoldersWithSelectedDocs({
                selectedDocs: selectedDocsArray,
                setFoldersDocumentsData,
            });
        }
    }, [isModalOpened]);

    const closeSelectDocModal = (e) => {
        e?.stopPropagation();
        e?.preventDefault();

        setIsModalOpened(false);
        collapseAllFolders({ setFoldersDocumentsData });
    };

    return (
        <>
            {isModalOpened && (
                <SelectDocModal
                    foldersDocumentsData={foldersDocumentsData}
                    setFoldersDocumentsData={setFoldersDocumentsData}
                    docsOptionsHookResponse={docsOptionsHookResponse}
                    selectedDocs={selectedDocs}
                    setSelectedTools={setSelectedTools}
                    clearErrorMessage={clearErrorMessage}
                    textareaHeight={textareaHeight}
                    isRootDataLoading={isLoading}
                    onClose={closeSelectDocModal}
                />
            )}
        </>
    );
};

export default SelectDocBlock;
