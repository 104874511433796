import React from 'react';
import { handleOpenStartNewJobModal } from '../../helpers/handleOpenStartNewJobModal';

import { Button } from '../../design-system';
import { FlashlightFillIcon } from '../../design-system/Icons';

const ClientPageTitleBlock = ({ title, hideButtonsOnMobile = false }) => {
    return (
        <div className="flex flex-wrap gap-x-4 gap-y-1 justify-between pb-5">
            <div className="min-h-[40px] flex items-center">
                <p className="font-heading-bold text-heading-bold-m sm:text-heading-bold-l text-black">
                    {title}
                </p>
            </div>

            <div
                className={`items-center gap-4 ${hideButtonsOnMobile ? 'hidden lg:flex' : 'flex'}`}
            >
                <Button
                    type="primary"
                    size="sm"
                    text="Start New Job"
                    helperText="⌘J"
                    trailingIcon={FlashlightFillIcon}
                    onClick={handleOpenStartNewJobModal}
                />
            </div>
        </div>
    );
};

export default ClientPageTitleBlock;
