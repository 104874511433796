import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';

import { API } from 'constants';
import client from '../../../../services/inbox-api';

import { STATUS } from '../../../../constants/statuses';
import {
    SETTING_TYPE,
    SHARING_STATE,
    NEW_SUGGESTION_ACCESS_STATE,
} from '../../../../constants/settingsForm';
import { defaultErrorMessage, emptyFieldErrorMessage } from '../../../../constants/errorMessages';

import {
    checkIsFormDataValid,
    checkIsRequiredFieldFilled,
    formatSettingsForRequestBody,
    removeRedundantFieldsFromSetting,
} from '../../../../helpers/settingsFormUtils';

import { useFormState } from '../../../../hooks/useFormState';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import Panel from '../../../../components/Panel';
import ErrorAlert from '../../../../design-system/ErrorAlert/ErrorAlert';
import PanelHeader from '../../../../components/PanelHeader/PanelHeader';
import PaginatedSelect from '../../../../design-system/PaginatedSelect/PaginatedSelect';
import NewRichTextArea from '../../../../design-system/NewRichTextArea/NewRichTextArea';
import SettingFormField from '../SettingFormField/SettingFormField';
import { Button, Input } from '../../../../design-system';

const CreateNewSuggestionPanel = ({
    setting,
    settingType,
    processId,
    setSuccessAlert,
    onClose,
}) => {
    const { formData, handleInputChange, fieldErrorMessages, setFieldErrorMessages } = useFormState(
        { title: '', value: '', feedback: null }
    );
    const [settingSuggestions, setSettingSuggestions] = useState(() => {
        const normalizedSetting = removeRedundantFieldsFromSetting(setting);
        return [{ ...normalizedSetting, state: 'default', errorMessage: null }];
    });

    const [isLoading, setIsLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(null);

    const {
        options: feedbacks,
        optionsLoading,
        totalOptions,
        setPage,
        canLoadMoreOptions,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.inbox.feedback,
        searchParams: { status: STATUS.pending },
        formatResponseToOptions: formatFeedbacksResponseToOptions,
    });

    function formatFeedbacksResponseToOptions(results) {
        return results?.map(({ id, process, created_at }) => {
            const formattedTime = DateTime.fromISO(created_at).toFormat('M/d/yy @ h:mm a');
            const name = `${process.name} - ${formattedTime} - ${id}`;
            return { id, name };
        });
    }

    const handleSubmit = async () => {
        try {
            const areEmptyFields = !checkIfAllFieldsFilled();
            if (areEmptyFields) {
                setFieldErrorMessages({
                    title: !formData.title ? emptyFieldErrorMessage : null,
                    value: !formData.value ? emptyFieldErrorMessage : null,
                    feedback: !formData.feedback ? 'Please select an option.' : null,
                });
                return;
            }

            const isDataValid = checkIsFormDataValid({
                settingsFormData: settingSuggestions,
                setSettingsFormData: setSettingSuggestions,
            });
            if (!isDataValid) return;

            setIsLoading(true);
            const { title, value, feedback } = formData;
            const settings = formatSettingsForRequestBody(settingSuggestions);

            const type = {
                [SETTING_TYPE.agent]: 'process_setting',
                [SETTING_TYPE.client]: 'process_context',
            }[settingType];

            const detail = {
                [SETTING_TYPE.agent]: { settings },
                [SETTING_TYPE.client]: { context: settings },
            }[settingType];

            const requestBody = {
                type,
                process: processId,
                feedback,
                title,
                value,
                detail,
            };
            await client.post(API.ROUTES.inbox.suggestion, requestBody);

            setSuccessAlert({ message: 'Agent suggestion successfully submitted!' });
            onClose();
        } catch (e) {
            setErrorAlert({ message: defaultErrorMessage, statusCode: e.response?.status });
        } finally {
            setIsLoading(false);
        }
    };

    function checkIfAllFieldsFilled() {
        const suggestion = { ...settingSuggestions[0], is_required: true };
        const isSuggestionFilled = checkIsRequiredFieldFilled(suggestion);
        return Object.values(formData).every(Boolean) && isSuggestionFilled;
    }

    const allFieldsFilled = useMemo(checkIfAllFieldsFilled, [formData, settingSuggestions]);
    const submitButtonState = isLoading ? 'loading' : allFieldsFilled ? 'default' : 'disabled';

    return (
        <Panel onClose={onClose} showControls={false} resetPadding>
            <div className="h-full max-h-full flex flex-col gap-3 px-5 md:px-8 pt-4 pb-5 md:pb-8">
                <div className="flex-1 flex flex-col gap-5 overflow-y-auto -mr-5 -md:mr-8 pr-5 md:pr-8">
                    <PanelHeader title="New Suggestion" onClose={onClose} />

                    <div className="flex flex-col gap-4 md:gap-5 pb-4 md:pb-5 -mt-2 border-b-1 border-neutral-200 px-[1px]">
                        <Input
                            size="sm"
                            name="title"
                            value={formData.title}
                            label="Give your suggestion a title"
                            isRequired
                            onChange={(e) => handleInputChange('title', e.target.value)}
                            placeholder="This title should be short and unique."
                            state={fieldErrorMessages.title ? 'error' : 'default'}
                            errorMessage={fieldErrorMessages.title}
                        />
                        <NewRichTextArea
                            value={formData.value}
                            label="Add a non-technical explanation of the suggestion"
                            isRequired
                            setValue={(value) => handleInputChange('value', value)}
                            placeholder="This explanation should be thorough enough to fully explain the proposed change(s)."
                            state={fieldErrorMessages.value ? 'error' : 'default'}
                            errorMessage={fieldErrorMessages.value}
                            minHeight={209}
                            darkBorderColor
                        />

                        <PaginatedSelect
                            size="sm"
                            options={feedbacks}
                            optionsLoading={optionsLoading}
                            value={formData.feedback}
                            label="Select connected feedback"
                            isRequired
                            onChange={(value) => handleInputChange('feedback', value)}
                            state={fieldErrorMessages.feedback ? 'error' : 'default'}
                            errorMessage={fieldErrorMessages.feedback}
                            fetchOptions={() => setPage((page) => page + 1)}
                            canLoadMore={canLoadMoreOptions}
                            includeClientSideFiltering
                            totalOptionsCount={totalOptions}
                        />
                    </div>

                    <div className="px-[1px]">
                        <SettingFormField
                            setting={settingSuggestions[0]}
                            sharingState={SHARING_STATE.unavailable}
                            shouldShowHiddenSettings
                            setFormData={setSettingSuggestions}
                            newSuggestionAccessState={NEW_SUGGESTION_ACCESS_STATE.off}
                        />
                    </div>
                </div>

                <div className="pt-3 flex justify-end">
                    <Button
                        type="primary"
                        size="sm"
                        state={submitButtonState}
                        text="Submit Suggestion"
                        onClick={handleSubmit}
                    />
                </div>
            </div>

            <ErrorAlert errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
        </Panel>
    );
};

export default CreateNewSuggestionPanel;
