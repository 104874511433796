import { useEffect, useRef, useState } from 'react';

import { DEFAULT_LIMIT } from './useCustomPagination';
import { mergeNewDataIntoCurrent } from '../helpers/mergeNewDataIntoCurrent';

export const useFetchOptionsForPaginatedSelect = ({
    client,
    route,
    searchParams = {},
    startRequest = true, // startRequest prop should be used just if you want to initiate a request after getting some data or any other event (not on page mount)
    triggerRefetch = [],
    formatResponseToOptions,
    limit = DEFAULT_LIMIT,
}) => {
    const [options, setOptions] = useState([]);
    const [optionsLoading, setOptionsLoading] = useState(false);
    const [totalOptions, setTotalOptions] = useState(0);

    const [page, setPage] = useState(1);
    const [canLoadMoreOptions, setCanLoadMoreOptions] = useState(false);

    const [isFirstRequestCompleted, setIsFirstRequestCompleted] = useState(false); // indicates if the first data is already received

    const [searchParamsId, setSearchParamsId] = useState(0); //the searchParams indicator (when searchParams change, searchParamsId increases by 1, triggering a refetch of options)
    const [controller, setController] = useState(new AbortController());
    const refreshCurrentPageController = useRef(new AbortController());

    const searchParamsQuery =
        Object.keys(searchParams)
            .map((key) => `${key}=${searchParams[key]}`)
            .join('&') || '';

    const setNewOptions = (results) => {
        setOptions((prevResults) => {
            if (!prevResults && !results) {
                return [];
            } else {
                let newResults = results;
                if (formatResponseToOptions) {
                    newResults = formatResponseToOptions(results || []);
                }

                return mergeNewDataIntoCurrent(prevResults || [], newResults || []);
            }
        });
    };

    const getRequestUrl = () =>
        `${route}?limit=${limit}&offset=${(page - 1) * limit}${
            searchParamsQuery && `&${searchParamsQuery}`
        }`;

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                controller.abort(); // cancel the previous request if it's still active

                // create and set the new controller as the active one
                const newController = new AbortController();
                setController(newController);

                setOptionsLoading(true);
                const url = getRequestUrl();
                const { data: newData } = await client.get(url, { signal: newController.signal });
                setNewOptions(newData.results);

                setCanLoadMoreOptions(!!newData.next);
                setOptionsLoading(false);
                if (page === 1) {
                    setTotalOptions(newData.count);
                    setIsFirstRequestCompleted(true);
                }
            } catch (error) {
                if (error.message === 'canceled') {
                    return; // the next request is loading
                }
                setOptionsLoading(false);
            }
        };

        if (startRequest && searchParamsId) {
            fetchOptions();
        }
    }, [page, startRequest, searchParamsId]);

    useEffect(() => {
        // if searchParams changed reset current options data to fetch new options
        if (startRequest) {
            resetCurrentData();
        }
    }, [searchParamsQuery, startRequest, ...triggerRefetch]);

    const refreshCurrentPageData = async () => {
        try {
            refreshCurrentPageController.current?.abort();

            const newController = new AbortController();
            refreshCurrentPageController.current = newController;

            const url = getRequestUrl();
            const { data: newData } = await client.get(url, { signal: newController.signal });

            setNewOptions(newData.results);
            setCanLoadMoreOptions(!!newData.next);
            setTotalOptions(newData.count);
        } catch (error) {}
    };

    const resetCurrentData = () => {
        setPage(1);
        setOptions([]);
        setTotalOptions(0);
        setSearchParamsId((prevData) => prevData + 1);
        setIsFirstRequestCompleted(false);
        refreshCurrentPageController.current?.abort();
    };

    return {
        options,
        setOptions,
        optionsLoading,
        canLoadMoreOptions,
        page,
        setPage,
        totalOptions,
        setTotalOptions,
        refreshCurrentPageData,
        isFirstRequestCompleted,
    };
};
