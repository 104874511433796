import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';

export const playbooksRoutes = [
    {
        path: '/playbooks',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.PlaybooksIndexPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
    {
        path: '/playbook/:playbookId',
        indexPath: '/playbooks',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.PlaybookBuilderPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
    {
        path: '/playbook/:playbookId/start/:scenarioId',
        indexPath: '/playbooks',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.StartNewProjectPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
];
