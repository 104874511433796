import React, { useState } from 'react';

import { useOverlayState } from '../../../hooks/useOverlayState';

import { ButtonIcon } from '../../../design-system';
import { More2FillIcon } from '../../../design-system/Icons';
import CopyAlert from '../../../design-system/CopyAlert/CopyAlert';
import FeedbackBadge from '../../../components/FeedbackBadge/FeedbackBadge';
import ThreadMoreOptionsPopup from '../ThreadMoreOptionsPopup/ThreadMoreOptionsPopup';

const ThreadDetailPageActionButtons = ({
    threadStatus,
    feedbackCount,
    executionId,
    setFeedbackPanelOpened,
    setRestartSidePanel,
    setThreadActionsModal,
}) => {
    const [copyAlert, setCopyAlert] = useState(null);

    const { isOpened: isPopupOpened, open: openPopup, close: closePopup } = useOverlayState();

    const toggleFeedbackPanelOpened = () => {
        setFeedbackPanelOpened((prevState) => !prevState);
    };

    return (
        <div className="flex items-center gap-2">
            {feedbackCount > 0 && (
                <FeedbackBadge
                    count={feedbackCount}
                    type="button"
                    onClick={toggleFeedbackPanelOpened}
                />
            )}

            <div className="relative">
                <ButtonIcon type="link" size="xs" icon={More2FillIcon} onClick={openPopup} />

                {isPopupOpened && (
                    <ThreadMoreOptionsPopup
                        threadStatus={threadStatus}
                        executionId={executionId}
                        setCopyAlert={setCopyAlert}
                        setRestartSidePanel={setRestartSidePanel}
                        setFeedbackPanelOpened={setFeedbackPanelOpened}
                        {...setThreadActionsModal}
                        onClose={closePopup}
                    />
                )}
            </div>

            <CopyAlert copyAlert={copyAlert} setCopyAlert={setCopyAlert} />
        </div>
    );
};

export default ThreadDetailPageActionButtons;
