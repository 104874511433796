import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useUser from '../hooks/useUser';
import { useViewType } from '../hooks/useViewType';
import { useWrongOrgOrViewTypeNavBlocker } from '../hooks/useWrongOrgOrViewTypeNavBlocker';

import { VIEW_TYPES_REDIRECT_PATH } from '../constants/viewTypes';

import {
    isViewTypeSwitchAllowed,
    checkRouteViewTypeAccess,
    isRouteAvailableForOrganization,
    checkOrgPlanAndUserRolePermissions,
} from '../helpers/routesPermissionsUtils';

import WrongViewTypeBlockerModal from '../components/WrongViewTypeBlockerModal/WrongViewTypeBlockerModal';
import WrongOrganizationBlockerModal from '../components/WrongOrganizationBlockerModal/WrongOrganizationBlockerModal';

const AccessHandler = ({
    availableViewTypes,
    availableForStaffOnly = false,
    needsSpecialPermission = false, //based on userRole and orgPlan
    allowedPlansAndRoles = [], // this should be an array structured [{plan: "agency", roles: ["owner", "admin"]}]
    children,
}) => {
    const navigate = useNavigate();
    const { user, orgPlan, userRole } = useUser();

    const { viewType } = useViewType();
    const {
        isWrongViewType,
        isWrongOrg,
        setIsWrongViewType,
        setIsWrongOrg,
        redirectPath,
        setRedirectPath,
    } = useWrongOrgOrViewTypeNavBlocker();

    const hasViewTypesAccess = checkRouteViewTypeAccess(viewType, availableViewTypes);

    const hasStaffAccess = availableForStaffOnly && user ? !!user?.is_staff : true;

    // based on userRole and orgPlan
    const hasSpecialPermission = checkOrgPlanAndUserRolePermissions(
        orgPlan,
        userRole,
        allowedPlansAndRoles,
        needsSpecialPermission
    );

    const hasAccessToOppositeViewType = isViewTypeSwitchAllowed({ orgPlan, user });

    // page available only in opposite view type and current organization does not have access to that view type
    const orgViewTypeAccessMismatch = !!user && !hasViewTypesAccess && !hasAccessToOppositeViewType;

    const hasOrgAccess = hasSpecialPermission && hasStaffAccess && !orgViewTypeAccessMismatch;

    useEffect(() => {
        if (!hasOrgAccess) {
            setIsWrongOrg(true);
        }

        if (isWrongOrg && hasOrgAccess) {
            setIsWrongOrg(false);
        }
    }, [hasOrgAccess]);

    useEffect(() => {
        if (!hasViewTypesAccess && hasOrgAccess) {
            if (hasAccessToOppositeViewType) {
                setIsWrongViewType(true);
            }
            if (!hasAccessToOppositeViewType) {
                navigate(VIEW_TYPES_REDIRECT_PATH[viewType]);
            }
        }
    }, [hasOrgAccess, hasViewTypesAccess]);

    const closeBlockingModal = () => {
        setIsWrongViewType(false);
        setIsWrongOrg(false);
        setRedirectPath(null);
    };

    const closeAndRedirect = () => {
        let path = VIEW_TYPES_REDIRECT_PATH[viewType];
        if (redirectPath) {
            const { hasPageAccess } = isRouteAvailableForOrganization({
                orgPlan,
                userRole,
                isStaff: user?.is_staff,
                path: redirectPath,
                user,
                viewType,
            });

            if (hasPageAccess) {
                path = redirectPath;
            }
        }

        navigate(path);

        closeBlockingModal();
    };

    const hasAccess = hasViewTypesAccess && hasOrgAccess;

    return (
        <>
            {hasAccess && children}

            {isWrongViewType && (
                <WrongViewTypeBlockerModal
                    onClose={closeBlockingModal}
                    onCloseAndRedirect={closeAndRedirect}
                />
            )}
            {isWrongOrg && (
                <WrongOrganizationBlockerModal
                    onClose={closeBlockingModal}
                    onCloseAndRedirect={closeAndRedirect}
                />
            )}
        </>
    );
};

export default AccessHandler;
