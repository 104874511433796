import React from 'react';

import SideBar from '../components/SideBar/SideBar';

const AuthorizedUserLayout = ({ children }) => {
    return (
        <div className="flex flex-row h-full w-full max-sm:pt-[60px]">
            <div className="sm:w-17 sm:min-w-17">
                <SideBar />
            </div>
            {children}
        </div>
    );
};

export default AuthorizedUserLayout;
