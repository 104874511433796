import React, { Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { JOB_TASK_CONTENT_TYPE } from '../../../../constants/jobs';

import KnowledgeBaseContentPiece from '../KnowledgeBaseContentPiece/KnowledgeBaseContentPiece';
import InboxContentPiece from '../InboxContentPiece/InboxContentPiece';
import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import ApiContentPiece from '../ApiContentPiece/ApiContentPiece';
import InfoContentPiece from '../InfoContentPiece/InfoContentPiece';
import AgentContentPiece from '../AgentContentPiece/AgentContentPiece';
import PlotContentPiece from '../PlotContentPiece/PlotContentPiece';
import AgentContentPieceForProject from '../AgentContentPieceForProject/AgentContentPieceForProject';
import LogFeedbackTrigger from '../../../../components/LogFeedbackTrigger/LogFeedbackTrigger';
import StaticContentPiece from '../StaticContentPiece/StaticContentPiece';

const MilestonePanelContent = ({ milestoneId, contentArray, pageType }) => {
    const { jobId } = useParams();

    const sortedContentArray = useMemo(
        () => contentArray.sort((a, b) => a.order - b.order),
        [contentArray]
    );

    const _jobLogFeedbackTrigger = pageType === 'job' && jobId && (
        <LogFeedbackTrigger target="thread" targetId={jobId} type="button_icon" />
    );

    return (
        <div className="flex flex-col gap-5">
            {sortedContentArray?.map((content) => {
                const { order, content_type, is_filled, label } = content;

                const jobLogFeedbackTrigger = is_filled && _jobLogFeedbackTrigger;

                if (content_type === JOB_TASK_CONTENT_TYPE.static) {
                    return (
                        <StaticContentPiece
                            key={order}
                            content={content}
                            jobLogFeedbackTrigger={jobLogFeedbackTrigger}
                        />
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.info) {
                    return (
                        <InfoContentPiece
                            key={order}
                            content={content}
                            pageType={pageType}
                            jobId={jobId}
                        />
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.plot) {
                    return (
                        <PlotContentPiece
                            key={order}
                            content={content}
                            jobLogFeedbackTrigger={jobLogFeedbackTrigger}
                        />
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.agent && pageType === 'project') {
                    return <AgentContentPieceForProject key={order} content={content} />;
                }

                if (!is_filled) {
                    return (
                        <FormFieldWrapper label={label} gap={6}>
                            <div className="px-4 py-8 rounded-2 border-1 border-neutral-200 border-dashed flex justify-center font-body text-body-regular-m text-neutral-200 italic text-center">
                                Not filled yet
                            </div>
                        </FormFieldWrapper>
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.kb) {
                    return (
                        <KnowledgeBaseContentPiece
                            key={order}
                            content={content}
                            jobLogFeedbackTrigger={jobLogFeedbackTrigger}
                        />
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.inbox) {
                    return (
                        <InboxContentPiece
                            key={order}
                            content={content}
                            milestoneId={milestoneId}
                            jobLogFeedbackTrigger={jobLogFeedbackTrigger}
                        />
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.api) {
                    return (
                        <ApiContentPiece
                            key={order}
                            content={content}
                            jobLogFeedbackTrigger={jobLogFeedbackTrigger}
                        />
                    );
                }

                if (content_type === JOB_TASK_CONTENT_TYPE.agent) {
                    return (
                        <AgentContentPiece
                            key={order}
                            content={content}
                            jobLogFeedbackTrigger={jobLogFeedbackTrigger}
                        />
                    );
                }

                return <Fragment key={order}></Fragment>;
            })}
        </div>
    );
};

export default MilestonePanelContent;
