import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import useUser from '../hooks/useUser';
import { checkViewTypeAccess } from '../helpers/viewTypeUtils';

import { VIEW_TYPES } from '../constants/viewTypes';
import { useViewType } from '../hooks/useViewType';

import Loading from '../components/Loading';

const HomePage = () => {
    const { user, isValidating, userRole, orgPlan } = useUser();
    const { viewType, oppositeViewType, toggleViewType } = useViewType();

    const hasViewTypeAccess = user
        ? checkViewTypeAccess({
              viewType,
              userRole,
              orgPlan,
              is_staff: user.is_staff,
          })
        : true;

    useEffect(() => {
        if (!hasViewTypeAccess) {
            toggleViewType();
        }
    }, [hasViewTypeAccess]);

    const isNotLoggedIn = !isValidating && !user;

    if (isNotLoggedIn) {
        return <Navigate to="/login" />;
    }

    if (!user) {
        return (
            <div className="fixed inset-0 flex items-center justify-center">
                <Loading />
            </div>
        );
    }

    const availableViewType = hasViewTypeAccess ? viewType : oppositeViewType;
    const homePageRoute = {
        [VIEW_TYPES.admin]: '/library',
        [VIEW_TYPES.client]: '/dashboard',
    }[availableViewType];

    return <Navigate to={homePageRoute} replace />;
};

export default HomePage;
