import React from 'react';
import Panel from '../../../../components/Panel';
import MilestonePanelHeader from '../MilestonePanelHeader/MilestonePanelHeader';
import MilestonePanelContent from '../MilestonePanelContent/MilestonePanelContent';

const MilestoneSidePanel = ({ milestone, bucketStatus, pageType, onClose }) => {
    return (
        <Panel onClose={onClose} showControls={false}>
            <MilestonePanelHeader
                milestone={milestone}
                bucketStatus={bucketStatus}
                onClose={onClose}
            />
            {!!milestone.content?.length && (
                <MilestonePanelContent
                    milestoneId={milestone.id}
                    contentArray={milestone.content}
                    pageType={pageType}
                />
            )}
        </Panel>
    );
};

export default MilestoneSidePanel;
