// open a Wrong Organization Blocker Modal if 404 returns
export const handlePageDataLoadError = ({
    e,
    setIsWrongOrg,
    redirectPath,
    setRedirectPath,
    generalErrorHandler,
}) => {
    console.log('ERR', e);

    if (e?.message === 'canceled') {
        return; // the next request is loading
    }

    if (e?.response?.status === 404 || e?.status === 404) {
        setIsWrongOrg(true);
        if (redirectPath) {
            setRedirectPath(redirectPath);
        }
        return;
    }

    generalErrorHandler();
};
