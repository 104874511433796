import React from 'react';
import { Navigate } from 'react-router-dom';

import useUser from 'hooks/useUser';

import Loading from '../components/Loading';
import AccessHandler from './AccessHandler';
import AuthorizedUserLayout from './AuthorizedUserLayout';

// route just for loggedIn users
const PrivateRoute = ({ component: Component, redirectTo = '/login', ...accessProps }) => {
    const { user, isValidating } = useUser();

    const isNotLoggedIn = !isValidating && !user;

    if (isNotLoggedIn) {
        return <Navigate to={redirectTo} />;
    }

    if (!user) {
        return (
            <div className="fixed inset-0 flex items-center justify-center">
                <Loading />
            </div>
        );
    }

    return (
        <AccessHandler {...accessProps}>
            <AuthorizedUserLayout>{Component}</AuthorizedUserLayout>
        </AccessHandler>
    );
};

export default PrivateRoute;
