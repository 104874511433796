import { defaultErrorMessage } from '../constants/errorMessages';

import CheckLineIcon from '../design-system/Icons/CheckLineIcon';
import ErrorWarningLineIcon from '../design-system/Icons/ErrorWarningLineIcon';

export const handleCopyFormattedText = ({
    node,
    setCopyAlert = () => {},
    successMessage = 'Copied to clipboard',
}) => {
    if (!node) return;

    const selection = window.getSelection();

    try {
        const range = document.createRange();
        range.selectNodeContents(node);

        selection.removeAllRanges();
        selection.addRange(range);

        const successful = document.execCommand('copy');

        if (successful) {
            setCopyAlert({
                status: 'positive',
                message: successMessage,
                icon: CheckLineIcon,
            });
        } else {
            setCopyAlert({
                status: 'critical',
                message: defaultErrorMessage,
                icon: ErrorWarningLineIcon,
            });
        }

        selection.removeAllRanges();
    } catch (err) {
        setCopyAlert({
            status: 'critical',
            message: defaultErrorMessage,
            icon: ErrorWarningLineIcon,
        });
    }
    selection.removeAllRanges();
};
