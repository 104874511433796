import { defaultErrorMessage } from '../constants/errorMessages';

import CheckLineIcon from '../design-system/Icons/CheckLineIcon';
import { ErrorWarningLineIcon } from '../design-system/Icons';

export const copyCompiledPrompt = ({ promptText = '', inputs = [], setAlert }) => {
    let compiledPrompt = promptText || '';

    inputs?.forEach((input) => {
        const inputRegex = new RegExp(`\\[${input.key}\\]`, 'g');
        compiledPrompt = compiledPrompt.replace(inputRegex, input.value || '');
    });

    navigator.clipboard
        .writeText(compiledPrompt)
        .then(() => {
            setAlert({
                status: 'positive',
                message: 'Copied full prompt to clipboard!',
                icon: CheckLineIcon,
            });
        })
        .catch(() => {
            setAlert({
                status: 'critical',
                message: defaultErrorMessage,
                icon: ErrorWarningLineIcon,
            });
        });
};
