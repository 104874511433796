import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';

export const inboxRoutes = [
    {
        path: '/inbox',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.InboxPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
];
