import React from 'react';
import useUser from '../hooks/useUser';

import AuthorizedUserLayout from './AuthorizedUserLayout';

//The route is for both logged-in and non-logged-in users. For logged-in users, a page with a sidebar will be displayed.
const PublicRoute = ({ component: Component }) => {
    const { user, isValidating } = useUser();

    const isNotLoggedIn = !isValidating && !user;

    if (isNotLoggedIn) {
        return Component;
    }

    return <AuthorizedUserLayout>{Component}</AuthorizedUserLayout>;
};

export default PublicRoute;
