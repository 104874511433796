import React, { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { statusBadge as jobsStatusBadge } from '../../../constants/statuses';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { Badge, ButtonIcon, SvgIcon } from '../../../design-system';
import { FlashlightFillIcon, FlowChartIcon, More2FillIcon } from '../../../design-system/Icons';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import JobCardActions from '../JobCardActions/JobCardActions';
import HighlightedIcon from '../../../design-system/HighlightedIcon/HighlightedIcon';
import TimestampDisplay from '../../../design-system/TimestampDisplay/TimestampDisplay';
import PlayCircleFillIcon from '../../../design-system/Icons/PlayCircleFillIcon';
import JobCardMoreOptionsPopup from '../JobCardMoreOptionsPopup/JobCardMoreOptionsPopup';

const JobCard = ({ data: job, setData: setJobs, requestKeysToMutate }) => {
    const { id, name, process, status, operation, updated_at } = job;
    const location = useLocation();

    const { isTablet } = useResponsiveBreakpoints({
        maxMobileWidth: 690,
        minDesktopWidth: 910,
    });

    const [isMoreOptionsPopupOpened, setIsMoreOptionsPopupOpened] = useState(false);
    const [actionModal, setActionModal] = useState({ action: null });

    const statusBadge = jobsStatusBadge[status];

    const openMoreOptionsPopup = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setIsMoreOptionsPopupOpened(true);
    };

    const cardContainerClassName = classNames(
        'px-4 py-3 max-w-full w-full rounded-2 bg-white',
        'grid grid-rows-[auto_auto] min-[910px]:grid-rows-1 grid-cols-[32px_minmax(100px,_1fr)_28px] min-[690px]:grid-cols-[32px_minmax(100px,_1fr)_124px_28px] min-[910px]:grid-cols-[32px_minmax(100px,_1fr)_124px_222px_28px] gap-x-4 min-[1400px]:gap-x-6 gap-y-2 items-start min-[690px]:items-center'
    );

    const tagsContainer = classNames(
        'flex flex-row-reverse items-center min-[690px]:flex-row justify-end w-full row-start-2 row-end-2 col-span-2 gap-4 min-[690px]:gap-1 min-[690px]:row-start-auto min-[690px]:row-end-auto min-[690px]:col-span-1',
        { 'min-[690px]:justify-end': !operation, 'min-[690px]:justify-between': operation }
    );

    return (
        <>
            <Link
                key={id}
                to={`/job/${id}`}
                state={{ from: location }}
                className={cardContainerClassName}
            >
                <div className="h-full place-content-center">
                    <HighlightedIcon icon={FlashlightFillIcon} variant="light_purple" />
                </div>

                <div className="grid grid-cols-1 min-[1100px]:grid-cols-[minmax(100px,_1.5fr)_minmax(100px,_1.2fr)]  gap-x-4 xl:gap-x-6 gap-y-1 items-center">
                    <p className="font-body-bold text-body-bold-s text-black truncate max-w-full row-start-2 row-end-2 min-[1100px]:row-start-1 min-[1100px]:row-end-1">
                        {name}
                    </p>
                    <div className="max-w-full row-start-1 row-end-1 flex items-center">
                        <Badge
                            text={process?.name}
                            color="neutral"
                            leadingIcon={PlayCircleFillIcon}
                            leadingIconColor={process?.icon_color}
                        />
                    </div>
                </div>

                <div className={tagsContainer}>
                    {operation && (
                        <ToolTip
                            text={operation.project?.name}
                            position={isTablet ? 'top-right' : 'top-left'}
                            shift="-4px"
                            useInternalHoverStateToShowToolTip
                        >
                            <SvgIcon color="#754DCF" icon={FlowChartIcon} size="large" />
                        </ToolTip>
                    )}
                    <Badge text={statusBadge.text} color={statusBadge.color} />
                </div>

                <div className="pt-2 min-[910px]:p-0 border-t-1 border-neutral-200 min-[910px]:border-none row-start-3 row-end-3 col-span-full min-[690px]:row-start-2 min-[690px]:row-end-2 min-[910px]:row-start-auto min-[910px]:row-end-auto min-[910px]:col-span-1">
                    <TimestampDisplay
                        label="Last Updated"
                        timestamp={updated_at}
                        dateTimeFormat="MM/dd/yyyy - hh:mm a"
                    />
                </div>

                <div className="relative">
                    <ButtonIcon
                        icon={More2FillIcon}
                        onClick={openMoreOptionsPopup}
                        type="link"
                        size="xs"
                    />

                    {isMoreOptionsPopupOpened && (
                        <JobCardMoreOptionsPopup
                            setActionModal={setActionModal}
                            onClose={() => setIsMoreOptionsPopupOpened(false)}
                        />
                    )}
                </div>
            </Link>

            <JobCardActions
                id={id}
                name={name}
                setJobs={setJobs}
                actionModal={actionModal}
                setActionModal={setActionModal}
                requestKeysToMutate={requestKeysToMutate}
            />
        </>
    );
};

export default memo(JobCard);
