import React, { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCustomPagination, { DEFAULT_LIMIT } from '../../hooks/useCustomPagination';
import client from 'services/library-api';
import { API } from 'constants';

import useDocumentTitle from 'hooks/useDocumentTitle';
import SearchBar from 'components/SearchBar/SearchBar';
import PaginationFooter from 'pages/LibraryPage/PaginationFooter/PaginationFooter';
import Loading from 'components/Loading';
import TaskCard from '../LibraryPage/TaskCard/TaskCard';
import { useFetchCollections } from '../../hooks/useFetchCollections';
import { Button } from '../../design-system';
import { ArrowGoBackLineIcon } from '../../design-system/Icons';

const SearchPage = () => {
    const navigate = useNavigate();
    const [collections, setCollections] = useState([]);
    useFetchCollections(setCollections);

    const [searchParams, setSearchParams] = useSearchParams();
    const parsedParams = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
    const { query, page = 1 } = parsedParams;
    const currentPage = Number(page) || 1;

    const { data, total, loading, key } = useCustomPagination({
        pageIndex: currentPage - 1,
        searchParams: { search: query || '', ordering: 'name' },
        client,
        route: API.ROUTES.library.task,
    });

    if (total && (currentPage <= 0 || currentPage > Math.ceil(total / DEFAULT_LIMIT))) {
        setSearchParams({ query, page: 1 });
    }

    useDocumentTitle('Search');

    return (
        <div className="fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white overflow-y-auto flex flex-col">
            <div className="w-full flex flex-col gap-2 px-4 py-3 sm:px-6 sm:py-5">
                <div>
                    <Button
                        type="link"
                        size="xs"
                        text="Back to Library"
                        leadingIcon={ArrowGoBackLineIcon}
                        onClick={() => navigate('/library?page=1&sort=name')}
                    />
                </div>
                <h1 className="font-heading-bold text-heading-bold-m text-black">Search Results</h1>
                <div className="w-[90%] ml:w-150">
                    <SearchBar />
                </div>
                {total > 0 && (
                    <p className="font-body text-body-regular-xs text-neutral-300">
                        {total} {total === 1 ? 'Result' : 'Results'}
                    </p>
                )}
                {total === 0 && !loading && (
                    <p className="font-body-bold text-body-bold-s text-neutral-500 mt-4">
                        Sorry, nothing is found.
                    </p>
                )}
                {loading && (
                    <div className="flex justify-center items-center mt-6">
                        <Loading />
                    </div>
                )}
            </div>
            {total > 0 && (
                <div className="flex-grow w-full bg-neutral-50 px-4 py-3 sm:px-6 sm:py-4">
                    <ul className="flex flex-col gap-2 w-full">
                        {data.map((item) => (
                            <TaskCard
                                key={item.id}
                                task={item}
                                libraryView="list"
                                swrKey={key}
                                collections={collections}
                                setCollections={setCollections}
                                navigateToTaskPageByCardClick
                            />
                        ))}
                    </ul>
                    <PaginationFooter
                        total={total}
                        start={(page - 1) * DEFAULT_LIMIT + 1}
                        end={page * DEFAULT_LIMIT > total ? total : page * DEFAULT_LIMIT}
                        onNextPressed={() =>
                            setSearchParams({ ...parsedParams, page: currentPage + 1 })
                        }
                        onPreviousPressed={() =>
                            setSearchParams({ ...parsedParams, page: currentPage - 1 })
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default SearchPage;
