import React from 'react';

import { handleOpenStartNewJobModal } from '../../../helpers/handleOpenStartNewJobModal';

import JobCard from '../JobCard/JobCard';
import Loading from '../../../components/Loading';
import EmptyView from '../../../design-system/EmptyView/EmptyView';
import BlockWrapper from '../BlockWrapper/BlockWrapper';
import { FlashlightFillIcon } from '../../../design-system/Icons';

const RecentJobsBlock = ({ jobs, jobsMutateKey }) => {
    const isViewButtonDisplayed = !!jobs?.length;

    return (
        <BlockWrapper
            title="Recent Jobs"
            description="Most recent jobs that you’ve run"
            viewAllNavPath="/jobs"
            size="sm"
            isViewButtonDisplayed={isViewButtonDisplayed}
        >
            {jobs && (
                <>
                    {!!jobs.length && (
                        <div className="flex flex-col gap-3">
                            {jobs.map((job) => (
                                <JobCard key={job.id} job={job} jobsMutateKey={jobsMutateKey} />
                            ))}
                        </div>
                    )}
                    {!jobs.length && (
                        <EmptyView
                            icon={FlashlightFillIcon}
                            mainText="You don’t have any Jobs yet."
                            subtext="Try creating one by clicking “New Job”."
                            buttonText="New Job"
                            handleButtonClick={handleOpenStartNewJobModal}
                            buttonLeadingIcon={FlashlightFillIcon}
                            buttonHelperText="⌘J"
                        />
                    )}
                </>
            )}

            {!jobs && <Loading withText={false} />}
        </BlockWrapper>
    );
};

export default RecentJobsBlock;
