import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from 'constants';
import client from '../../../services/template-api';
import { useFetchOptionsForPaginatedSelect } from '../../../hooks/useFetchOptionsForPaginatedSelect';

import Modal from '../../../design-system/Modal/Modal';
import EscapeIcon from '../../../design-system/Icons/EscapeIcon';
import PaginatedSelect from '../../../design-system/PaginatedSelect/PaginatedSelect';
import { Button, SvgIcon } from '../../../design-system';

const AddGoalFromTemplateModal = ({ processId, onClose }) => {
    const navigate = useNavigate();

    const [selectedGoalTemplateId, setSelectedGoalTemplateId] = useState(null);
    const [goalTemplateFieldError, setGoalTemplateFieldError] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const {
        options: goalTemplateOptions,
        optionsLoading,
        canLoadMoreOptions,
        setPage,
        totalOptions,
    } = useFetchOptionsForPaginatedSelect({
        client,
        route: API.ROUTES.template.goalTemplate,
        searchParams: { is_live: true },
        formatResponseToOptions: (results) => results.map(({ id, name }) => ({ id, name })),
    });

    const handleSelectChange = (value) => {
        setSelectedGoalTemplateId(value);
        if (goalTemplateFieldError) {
            setGoalTemplateFieldError(false);
        }
    };

    const handleContinueClick = () => {
        if (!selectedGoalTemplateId) {
            setGoalTemplateFieldError(true);
            return;
        }

        navigate(`/configure/process/${processId}/goal/template/${selectedGoalTemplateId}`);
    };

    // used to increase the height of the modal so that the dropdown fits entirely on the screen
    const selectMarginBottom = dropdownOpen ? 72 : 0;

    return (
        <Modal onClose={onClose} size="medium">
            <div className="p-2 flex flex-col gap-8">
                <div className="flex gap-3 items-start justify-between">
                    <p className="font-heading-bold text-heading-bold-m text-neutral-400">
                        Add Goal from Template
                    </p>
                    <button onClick={onClose} className="cursor-pointer py-[5px]">
                        <SvgIcon color="#1F2125" icon={EscapeIcon} size="rectangle" />
                    </button>
                </div>
                <div
                    style={{
                        marginBottom: selectMarginBottom,
                        transition: 'margin 0.2s ease-in-out',
                    }}
                >
                    <PaginatedSelect
                        size="sm"
                        name="goal_template"
                        options={goalTemplateOptions}
                        optionsLoading={optionsLoading}
                        value={selectedGoalTemplateId}
                        label="Select Goal Template"
                        isRequired
                        placeholder="Select an option"
                        onChange={handleSelectChange}
                        state={goalTemplateFieldError ? 'error' : 'default'}
                        errorMessage={'Please select a Goal Template.'}
                        fetchOptions={() => setPage((page) => page + 1)}
                        canLoadMore={canLoadMoreOptions}
                        includeClientSideFiltering
                        totalOptionsCount={totalOptions}
                        useExternalDropdownState
                        dropdownOpen={dropdownOpen}
                        setDropdownOpen={setDropdownOpen}
                        dropdownHeight={160}
                    />
                </div>
                <div className="flex items-center justify-between gap-3">
                    <Button type="ghost" size="sm" text="Cancel" onClick={onClose} />
                    <Button
                        type="secondary"
                        size="sm"
                        text="Continue"
                        onClick={handleContinueClick}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AddGoalFromTemplateModal;
