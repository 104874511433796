import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { API } from 'constants';
import client from 'services/library-api';
import operateClient from 'services/operate-api';
import WebSocketInstance from 'services/websocket';

import { USER_ROLE } from '../../../constants/organization';
import { defaultErrorMessage } from '../../../constants/errorMessages';
import { TASK_TYPES } from '../../../constants/library';
import { TemperatureOptions } from 'constants';

import { useWindowSize } from 'hooks/useWindowSize';
import { useFetchCollections } from '../../../hooks/useFetchCollections';
import { useNavigationRestrictionContext } from '../../../hooks/useNavigationRestrictionContext';
import { useWrongOrgOrViewTypeNavBlocker } from '../../../hooks/useWrongOrgOrViewTypeNavBlocker';
import useDocumentTitle from 'hooks/useDocumentTitle';
import useUser from 'hooks/useUser';

import { handlePromptLocalStorageSave } from 'helpers/handleRecentVisitedPagesLocalStorageSave';
import { formatToKey } from 'helpers/formatLabelToKey';
import { getPromptSettings } from 'helpers/getPromptSettings';
import { getModelOptions } from 'helpers/getModelOptions';
import { getOrganizationMembership } from 'helpers/getOrganizationMembership';
import { duplicateTask } from 'helpers/duplicateTask';
import { updateOutputState } from '../../../helpers/updateOutputState';
import { formatPromptVersionName } from '../../../helpers/formatPromptVersionName';
import { updateQueryParameters } from '../../../helpers/updateQueryParameters';
import { handlePageDataLoadError } from '../../../helpers/handlePageDataLoadError';
import {
    filterInputsWithFillQuerystring,
    updateInputState,
} from '../../../helpers/updateInputState';

import { Button, ButtonIcon } from 'design-system';
import {
    AddLineIcon,
    ArrowGoBackLineIcon,
    ErrorWarningLineIcon,
    FlashlightFillIcon,
    CloseCircleLineIcon,
    More2FillIcon,
    InformationLineIcon,
} from 'design-system/Icons';
import { SvgIcon } from '../../../design-system';
import NewRunContainer from 'components/NewRunContainer/NewRunContainer';
import Alert from 'design-system/Alert/Alert';
import PromptSettingsBox from 'components/PromptSettingsBox/PromptSettingsBox';
import PromptViewPageHeader from 'components/PromptViewPageHeader/PromptViewPageHeader';
import NewTextAreaBox from 'design-system/NewTextAreaBox/NewTextAreaBox';
import CollapsableContainer from 'components/CollapsableContainer/CollapsableContainer';
import CheckLineIcon from 'design-system/Icons/CheckLineIcon';
import ActionModal from 'pages/LibraryPage/ActionModal/ActionModal';
import OutputContainer from 'components/OutputContainer/OutputContainer';
import DeleteTaskConfirmModal from 'components/DeleteTaskConfirmModal/DeleteTaskConfirmModal';
import TaskOptions from 'components/TaskOptions/TaskOptions';
import Loading from 'components/Loading';
import ToolTip from 'design-system/ToolTip/ToolTip';
import CompassIcon from '../../../design-system/Icons/CompassIcon';
import ShareTaskModal from '../ShareTaskModal/ShareTaskModal';
import ReactRouterPrompt from 'react-router-prompt';
import BlockingNavigationModal from '../BlockingNavigationModal/BlockingNavigationModal';
import DuplicateTaskSuccessModal from '../../../components/DuplicateTaskSuccessModal/DuplicateTaskSuccessModal';

const PromptViewPage = () => {
    const { id: promptId } = useParams();
    const { user } = useUser();

    const urlParams = new URLSearchParams(window.location.search);
    const [resultId, setResultId] = useState(urlParams.get('result') || null);
    const fillId = urlParams.get('fill') || null;

    const navigate = useNavigate();
    const location = useLocation();
    const libraryLocation = location.state?.libraryLocation || '';
    const [prompt, setPrompt] = useState(null);
    const [defaultVersion, setDefaultVersion] = useState(null);
    const [promptVersion, setPromptVersion] = useState({ name: null, id: null });
    const [promptText, setPromptText] = useState('');
    const [promptTextData, setPromptTextData] = useState([
        { value: '', state: 'default', label: 'Prompt' },
    ]);
    const [temporaryPromptInputs, setTemporaryPromptInputs] = useState([]);
    const [promptInputs, setPromptInputs] = useState([]);
    const [promptOutputs, setPromptOutputs] = useState([]);
    const modelOptions = getModelOptions(user);
    const [promptSettings, setPromptSettings] = useState({
        model: modelOptions[0],
        maxTokens: 1000,
        temperature: TemperatureOptions[0],
    });
    const [collections, setCollections] = useState([]);
    const [areCollectionsLoading, setAreCollectionsLoading] = useState(false);
    const inputRef = useRef(null);
    const [runPromptState, setRunPromptState] = useState({
        isLoading: false,
        errorMessage: null,
        statusCode: null,
    });
    let websocket = WebSocketInstance.getSingleton();
    const [generationId, setGenerationId] = useState(null);
    const taskId = prompt?.task?.id || null;
    const [isRunning, setIsRunning] = useState(false);
    const [isExpanded, setIsExpanded] = useState({
        promptSettings: false,
        inputs: true,
    });
    const [editPromptData, setEditPromptData] = useState({});
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [statusAlert, setStatusAlert] = useState(null);
    const [actionModal, setActionModal] = useState({ isOpened: false });
    const [outputRating, setOutputRating] = useState(null);
    const [hasLoggedStreamTokenForGenId, setHasLoggedStreamTokenForGenId] = useState(false);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [showDeleteTaskConfirmModal, setShowDeleteTaskConfirmModal] = useState(false);
    const mainContainerRef = useRef(null);
    const leftContainerRef = useRef(null);
    const [leftContainerWidth, setLeftContainerWidth] = useState('50%');
    const [rightContainerWidth, setRightContainerWidth] = useState('50%');
    const [isResizing, setIsResizing] = useState(false);
    const [showResizeTooltip, setShowResizeTooltip] = useState(false);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const { width: screenWidth } = useWindowSize();
    const [isShareTaskModalOpened, setIsShareTaskModalOpened] = useState(false);
    const [isDuplicateTaskLoading, setIsDuplicateTaskLoading] = useState(false);
    const [duplicatedTaskSuccessModal, setDuplicatedTaskSuccessModal] = useState({
        opened: false,
        id: null,
        taskName: null,
        taskType: 'prompt',
    });
    const organizationMembership = getOrganizationMembership(user);
    // if the task is private or shared
    const [accessType, setAccessType] = useState(null);
    // what access the specific user has for the task
    const [accessLevel, setAccessLevel] = useState(null);

    const [protectedMode, setProtectedMode] = useState(false);
    const chainCount = prompt && prompt.task?.chain_count;

    const [arePromptAndDefaultVersionsDifferent, setArePromptAndDefaultVersionsDifferent] =
        useState(promptVersion.id && promptVersion.id !== defaultVersion?.id);

    const [arePromptChanges, setArePromptChanges] = useState(false);
    const [areInputEdits, setAreInputEdits] = useState(false);
    const { isNavigationRestricted, setIsNavigationRestricted } = useNavigationRestrictionContext();

    const { setIsWrongOrg, setRedirectPath } = useWrongOrgOrViewTypeNavBlocker();

    const isOwnerOrAdmin = accessLevel === USER_ROLE.owner || accessLevel === USER_ROLE.admin;

    // const arePromptAndDefaultVersionsDifferent =
    //     promptVersion.id && promptVersion.id !== defaultVersion?.id;

    // when default version changes, update arePromptAndDefaultVersionsDifferent
    useEffect(() => {
        if (defaultVersion?.id) {
            setArePromptAndDefaultVersionsDifferent(
                promptVersion.id && promptVersion.id !== defaultVersion?.id
            );
        }
    }, [defaultVersion]);

    useDocumentTitle((prompt && prompt?.task?.name) || '');
    useFetchCollections(setCollections, setAreCollectionsLoading);

    useEffect(() => {
        const areChanges = arePromptChanges || areInputEdits;

        // save should Navigation be restricted or not in context
        if (areChanges !== isNavigationRestricted) {
            setIsNavigationRestricted(areChanges);
        }
    }, [arePromptChanges, areInputEdits]);

    useEffect(() => {
        // clear context after page unmount
        return () => {
            setIsNavigationRestricted(false);
        };
    }, []);

    useEffect(() => {
        WebSocketInstance.connect();
        waitForSocketConnection(() => {
            WebSocketInstance.addCallbacks(
                onStreamStart,
                onMessageStream,
                onStreamEnd,
                onStreamError
            );
        });
    }, []);

    useEffect(() => {
        WebSocketInstance.setCurrentPageGenId(generationId);
    }, [generationId]);

    const waitForSocketConnection = (callback) => {
        const checkConnection = () => {
            if (WebSocketInstance.state() === 1) {
                console.log('connection is secure');
                callback();
            } else {
                setTimeout(checkConnection, 300);
            }
        };

        checkConnection();
    };

    const handleNav = (pageName) => {
        navigate(`/${pageName}/`);
    };

    useEffect(() => {
        if (prompt) {
            resetPromptState();
        }
        fetchPromptData();

        if (resultId || fillId) {
            fetchResultData();
        }
    }, [promptId]);

    useEffect(() => {
        if (promptText) {
            setPromptTextData([{ value: promptText, state: 'default', label: 'Prompt' }]);
        }
    }, [promptText]);

    const fetchPromptData = async () => {
        try {
            const authToken = localStorage.getItem('authKey');
            if (!authToken || !authToken.length) {
                throw new Error('User not logged in.'); // TODO: handle more gracefully
            }
            const options = {
                headers: {
                    Authorization: `Token ${authToken}`,
                },
            };
            const { data } = await client.get(`${API.ROUTES.library.prompt}${promptId}/`, options);
            const accessLevel = data.access_level;
            if (accessLevel === 'viewer') {
                if (resultId) {
                    navigate(`/library/prompt/${promptId}/view?result=${resultId}`, {
                        state: { libraryLocation },
                    });
                } else if (fillId) {
                    navigate(`/library/prompt/${promptId}/view?fill=${fillId}`, {
                        state: { libraryLocation },
                    });
                } else {
                    navigate(`/library/prompt/${promptId}/view`, {
                        state: { libraryLocation },
                    });
                }
                return;
            }
            const defaultVersion = data.default_prompt_version || null;
            setPrompt(data);
            handlePromptLocalStorageSave(data, data?.default_prompt_version?.organization);
            setDefaultVersion(defaultVersion);
            setAccessLevel(accessLevel);
            setAccessType(data.task.access_type);
            setProtectedMode(!!data.task.chain_count);

            if (defaultVersion) {
                if (!resultId) {
                    const { messages, settings } = defaultVersion;
                    const promptText = messages?.[0]?.content || '';
                    const { inputs, outputs } = data.task;
                    setPromptOutputs(updateOutputState(outputs));
                    setPromptText(promptText);
                    setPromptSettings(getPromptSettings(settings));

                    if (fillId) {
                        setTemporaryPromptInputs((prevState) => [...prevState, ...inputs]);
                    } else {
                        setPromptInputs(updateInputState(inputs, promptText));
                    }

                    if (!settings.model) {
                        setEditPromptData((prevData) => ({
                            ...prevData,
                            settings: {
                                provider: promptSettings?.model.provider,
                                temperature: promptSettings?.temperature.value || 0.0,
                                max_tokens: promptSettings?.maxTokens,
                                type: promptSettings?.model.type,
                                model: promptSettings?.model.model,
                            },
                        }));
                        setArePromptChanges(true);
                    }
                } else {
                    return;
                }
            } else {
                resetPromptState();
            }
        } catch (error) {
            resetPromptState();
            const backLinkHref = location.state?.libraryLocation ?? '/library';
            handlePageDataLoadError({
                e: error,
                setIsWrongOrg,
                setRedirectPath,
                redirectPath: '/library',
                generalErrorHandler: () => navigate(backLinkHref),
            });
        }
    };

    const fetchResultData = async () => {
        try {
            const requestId = resultId || fillId;
            const { data } = await operateClient.get(`${API.ROUTES.operate.result}${requestId}/`);
            const {
                inputs,
                outputs,
                prompt_version: { id, name, messages, settings },
                rating,
            } = data;
            const promptText = messages?.[0]?.content || '';
            if (fillId) {
                setTemporaryPromptInputs((prevState) => [
                    ...prevState,
                    ...inputs.map((item) => ({ ...item, fromResultData: true })),
                ]);
            }
            if (resultId) {
                setPromptInputs(updateInputState(inputs, promptText));
                setPromptOutputs(updateOutputState(outputs));
                setPromptText(promptText);
                setPromptSettings(getPromptSettings(settings));
                setOutputRating(rating);
                if (!settings.model) {
                    setArePromptChanges(true);
                }
                setPromptVersion({ id: id, name: formatPromptVersionName(name) });
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const inputs = filterInputsWithFillQuerystring(temporaryPromptInputs);
        setPromptInputs(updateInputState(inputs, promptText));
    }, [temporaryPromptInputs]);

    const resetPromptState = () => {
        setPrompt(null);
        setDefaultVersion(null);
        setPromptText('');
        setPromptSettings({
            model: modelOptions[0],
            maxTokens: 1000,
            temperature: TemperatureOptions[0],
        });
        setPromptInputs([]);
        setPromptOutputs([]);
        setOutputRating(null);
        setAreInputEdits(false);
        setArePromptChanges(false);
    };

    const updateInputMapping = (newInputs, existingInputs) => {
        // Create a map of existing inputs for faster lookup
        const existingInputMap = new Map(existingInputs.map((input) => [input.id, input]));
        const promptText = promptTextData[0]?.value || '';

        // Merge new inputs into existing inputs
        const updatedInputs = newInputs.map((newInput) => {
            const existingInput = existingInputMap.get(newInput.id);

            if (existingInput) {
                // If the input with the same id exists, merge the new data into it
                return {
                    ...existingInput,
                    ...newInput,
                    value: existingInput.value,
                    state: 'default',
                    isRequired: true,
                };
            } else {
                // If it doesn't exist in the existing inputs, add it to the result
                return {
                    ...newInput,
                    state: 'default',
                    isRequired: true,
                    isMissedInPrompt: !promptText?.includes(`[${newInput.key}]`),
                };
            }
        });

        return updatedInputs;
    };

    const onMessageStream = (data) => {
        if (isExpanded.inputs === true) {
            setIsExpanded({ inputs: false, promptSettings: false });
        }
        setPromptOutputs((prevPromptOutputs) => {
            const updatedPromptOutputs = prevPromptOutputs.map((output) => {
                const currentValue = output.value || '';
                return { ...output, value: currentValue + data.message.text };
            });
            return updatedPromptOutputs;
        });
        clearTimeout(llmStartTimeout);
    };

    const onStreamEnd = (data) => {
        setRunPromptState({ isLoading: false, errorMessage: null, statusCode: null });
        datadogRum.addAction('PromptStreamEnd', {
            generationId,
            promptSettings,
            promptInputs,
        });
        setIsRunning(false);
        // updateQueryParameters({ resultId: data.message.gen_id });
        setOutputRating(0);
    };

    const onStreamError = () => {
        setRunPromptState({
            isLoading: false,
            errorMessage: 'Oops! Something went wrong while running your prompt, please try again.',
        });
        datadogRum.addAction('PromptStreamError', {
            generationId,
            promptSettings,
            promptInputs,
        });
        setIsRunning(false);
    };

    let llmStartTimeout;

    const onStreamStart = () => {
        setIsExpanded({ inputs: false, promptSettings: false });
        setIsRunning(true);
        llmStartTimeout = setTimeout(() => {
            console.log('llm timeout');
            onStreamError();
        }, 5000);
        datadogRum.addAction('PromptStreamStart', {
            generationId,
            promptSettings,
            promptInputs,
        });
    };

    const updatePromptTextData = (newValue, index) => {
        setPromptTextData((prevData) =>
            prevData.map((data, i) => {
                if (i === index) {
                    return { ...data, value: newValue, state: 'default' };
                } else {
                    return data;
                }
            })
        );
        setEditPromptData((prevData) => ({
            ...prevData,
            messages: [
                {
                    role: 'user',
                    content: newValue,
                },
            ],
        }));
        //to update the field isMissedInPrompt
        setPromptInputs(updateInputState(promptInputs, newValue));
        if (!arePromptChanges) {
            setArePromptChanges(true);
        }
    };

    const updatePromptInputs = (newValue, index) => {
        const updatedPromptInputs = promptInputs.map((input, i) => {
            if (i === index) {
                return { ...input, value: newValue, state: 'default' };
            } else {
                return input;
            }
        });
        setPromptInputs(updatedPromptInputs);
        setAreInputEdits(true);
    };

    const updatePromptOutputs = (newValue, index) => {
        const updatedPromptOutputs = promptOutputs.map((output, i) => {
            if (i === index) {
                const oldValue = output.value ? output.value : '';
                return { ...output, value: oldValue + newValue };
            } else {
                return output;
            }
        });
        setPromptOutputs(updatedPromptOutputs);
    };

    const resetPromptOutputs = (newValue) => {
        setPromptOutputs((prevPromptOutputs) => {
            const updatedPromptOutputs = prevPromptOutputs.map((output) => {
                return { ...output, value: newValue };
            });
            return updatedPromptOutputs;
        });
    };

    const updateVersions = (version) => {
        setPromptVersion({ id: version.id, name: formatPromptVersionName(version.name) });
        setDefaultVersion(version);
        // setArePromptAndDefaultVersionsDifferent(false);
    };

    const runPrompt = () => {
        setRunPromptState({ isLoading: true, errorMessage: null, statusCode: null });
        updateQueryParameters({ resultId: null, clearFillId: true });
        setOutputRating(null);

        // Add the patch call if arePromptChanges isn't null, user has models, and promptSettings.model is in modelOptions
        if (
            arePromptChanges &&
            modelOptions.length &&
            modelOptions.some((option) => option.model === promptSettings.model.model)
        ) {
            client
                .patch(`${API.ROUTES.library.prompt}${promptId}/`, editPromptData)
                .then((response) => {
                    updateVersions(response.data?.default_prompt_version);
                    executePrompt();
                    setArePromptChanges(false);
                })
                .catch((patchError) => {
                    setRunPromptState({
                        isLoading: false,
                        errorMessage: 'You have unsaved prompt edits. Please try again.',
                    });
                });
            // if arePromptChanges is true, but user has no models, we want to save the changes, but set to error
        } else if (arePromptChanges && !modelOptions.length) {
            client
                .patch(`${API.ROUTES.library.prompt}${promptId}/`, editPromptData)
                .then((response) => {
                    updateVersions(response.data?.default_prompt_version);
                    setRunPromptState({
                        isLoading: false,
                        errorMessage: 'Please add an API key and select a model',
                    });
                    setArePromptChanges(false);
                })
                .catch((patchError) => {
                    setRunPromptState({
                        isLoading: false,
                        errorMessage: 'You have unsaved prompt edits. Please try again.',
                    });
                });

            // if prompt settings model is not in modelOptions, set state to error; regardless of arePromptChanges
        } else if (
            modelOptions.length &&
            !modelOptions.some((option) => option.model === promptSettings.model.model)
        ) {
            setRunPromptState({
                isLoading: false,
                errorMessage: 'Please select a model you have access to.',
            });
        } else {
            executePrompt();
        }
    };

    const executePrompt = () => {
        setRunPromptState({ isLoading: true, errorMessage: null, statusCode: null });
        updateQueryParameters({ resultId: null });
        // check if each promptInput has a value
        const promptInputsValid = promptInputs.every((input) => input.value);
        if (promptInputsValid) {
            // refresh PromptOutputs
            resetPromptOutputs('');
            // update promptinputs to remove isRequired and state keys
            const updatedPromptInputs = promptInputs.map((input) => {
                const { isRequired, state, errorMessage, isMissedInPrompt, ...rest } = input;
                return rest;
            });
            let run_data = { inputs: updatedPromptInputs, sync: false, task_id: taskId };
            if (arePromptAndDefaultVersionsDifferent) {
                run_data.prompt_version_id = promptVersion.id;
            }

            if (!websocket.isOpen()) {
                if (!websocket.attempt_reconnect()) {
                    console.log('websocket reconnect failed / using sync mode');
                    run_data = { sync: true, ...run_data };
                    setIsExpanded({ inputs: false, promptSettings: false });
                }
            }

            // execute task
            operateClient
                .post(API.ROUTES.operate.result, run_data)
                .then((res) => {
                    if (!websocket.isOpen()) {
                        console.log('websocket not open / using sync response mode');
                        resetPromptOutputs(res.data.final_output);
                        setIsRunning(false);
                        setRunPromptState({
                            isLoading: false,
                            errorMessage: null,
                            statusCode: null,
                        });
                        const resultId = res.data.id;
                        updateQueryParameters({ resultId });
                    } else {
                        console.log('streaming response');
                        setIsRunning(true);
                        setRunPromptState({
                            isLoading: false,
                            errorMessage: null,
                            statusCode: null,
                        });
                        // setting run ID here because that's what will come through the websocket messages
                        const generationId = res.data.run;
                        setGenerationId(generationId);
                        setResultId(res.data.id);
                        setHasLoggedStreamTokenForGenId(false);
                    }
                })
                .then(() => {
                    setAreInputEdits(false);
                })
                .catch((error) => {
                    setIsRunning(false);
                    if (error.response.status === 400) {
                        if (
                            error.response.data?.error &&
                            error.response.data.error.includes("You don't have API credentials")
                        ) {
                            setRunPromptState({
                                isLoading: false,
                                errorMessage: `Add a valid API key for ${promptSettings.model.provider} to be able to run this prompt.`,
                            });
                        } else if (
                            error.response.data?.error &&
                            error.response.data.error.includes(
                                'Total token exceeds model token limit'
                            )
                        ) {
                            setRunPromptState({
                                isLoading: false,
                                errorMessage:
                                    'Oops! Your request is over the token limit for this model. Please try again with less tokens or a different model.',
                            });
                        } else {
                            setRunPromptState({
                                isLoading: false,
                                errorMessage:
                                    'Oops! Something went wrong while running your prompt, please try again.',
                                statusCode: error.response.status,
                            });
                        }
                    } else {
                        setRunPromptState({
                            isLoading: false,
                            errorMessage:
                                'Oops! Something went wrong while running your prompt, please try again.',
                            statusCode: error.response.status,
                        });
                    }
                });
        } else {
            // if promptInputs are not valid, set state to error
            setIsExpanded({ inputs: true, promptSettings: false });
            handleScrollToInputs();
            const updatedPromptInputs = promptInputs.map((input) => {
                if (!input.value) {
                    return {
                        ...input,
                        state: 'error',
                        errorMessage: 'Please fill in this field.',
                    };
                } else {
                    return input;
                }
            });
            setPromptInputs(updatedPromptInputs);
            setRunPromptState({ isLoading: false, errorMessage: null, statusCode: null });
        }
    };

    const cancelStream = () => {
        if (websocket) {
            // reset prompt state
            setRunPromptState({ isLoading: false, errorMessage: null, statusCode: null });
            setIsRunning(false);
            setGenerationId(null);
        }
    };

    // const handleCmdEnterPress = (event) => {
    //     if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
    //         runPrompt();
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('keydown', handleCmdEnterPress);

    //     return () => {
    //         window.removeEventListener('keydown', handleCmdEnterPress);
    //     };
    // }, [promptInputs]);

    const handleBackPressed = () => {
        navigate(location.state?.libraryLocation ?? '/library');
    };

    const toggleCollapsed = (field) => {
        setIsExpanded((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const handleSavePrompt = async () => {
        try {
            if (arePromptChanges) {
                setIsEditLoading(true);
                const { data } = await client.patch(
                    `${API.ROUTES.library.prompt}${promptId}/`,
                    editPromptData
                );
                // setDefaultVersion(data?.default_prompt_version);
                updateVersions(data?.default_prompt_version);
                setArePromptChanges(false);
                setEditPromptData({});
                setIsEditLoading(false);
            }
        } catch (error) {
            setIsEditLoading(false);
            setStatusAlert({
                status: 'critical',
                message: defaultErrorMessage,
                statusCode: error.response.status,
                icon: ErrorWarningLineIcon,
            });
        }
    };

    const updateInputVariable = async (newData, alertMessage) => {
        try {
            setActionModal((prevState) => ({ ...prevState, isLoading: true }));
            const { data } = await client.patch(`${API.ROUTES.library.task}${taskId}/`, {
                inputs: newData,
            });
            setActionModal((prevState) => ({ ...prevState, isLoading: false }));

            // merge the new data into the existing inputs
            const updatedInputs = updateInputMapping(data.inputs, promptInputs);
            setPromptInputs(updatedInputs);

            setActionModal({ isOpened: false });
            setStatusAlert({
                status: 'positive',
                message: alertMessage,
                icon: CheckLineIcon,
            });
        } catch (error) {
            setActionModal((prevState) => ({ ...prevState, isLoading: false }));
            setStatusAlert({
                status: 'critical',
                message: defaultErrorMessage,
                statusCode: error.response.status,
                icon: ErrorWarningLineIcon,
            });
        }
    };

    const onDeleteInputButtonClick = (id) => {
        const handleDeleteInput = async () => {
            const newData = promptInputs.reduce(
                (acc, input) =>
                    input.id === id
                        ? acc
                        : [
                              ...acc,
                              {
                                  id: input.id,
                                  key: input.key,
                                  type: input.type,
                                  label: input.label,
                                  value: '',
                                  is_required: input.is_required,
                              },
                          ],
                []
            );
            await updateInputVariable(newData, 'Variable successfully deleted');
        };

        const label = promptInputs.find((input) => input.id === id)?.label;

        setActionModal({
            isOpened: true,
            type: 'delete',
            value: label,
            handleModalAction: handleDeleteInput,
        });
    };

    const onCreateInputButtonClick = () => {
        const handleCreateInput = async (value) => {
            const currentData = promptInputs.map((input) => {
                const { isRequired, state, errorMessage, isMissedInPrompt, ...rest } = input;
                return rest;
            });
            const newInput = {
                label: value,
                type: 'string',
                key: formatToKey(value),
                value: '',
                is_required: true,
            };
            currentData.push(newInput);

            await updateInputVariable(currentData, 'Variable successfully added');
        };

        setActionModal({
            isOpened: true,
            type: 'add',
            handleModalAction: handleCreateInput,
        });
    };

    const handleSetAsCurrentVersion = async () => {
        try {
            setIsEditLoading(true);
            const { data } = await client.patch(
                `${API.ROUTES.library.promptVersion}${promptVersion.id}/`,
                { is_default: true }
            );
            setDefaultVersion(data);
            setIsEditLoading(false);
        } catch (e) {
            setStatusAlert({
                status: 'critical',
                message: defaultErrorMessage,
                statusCode: e.response.status,
                icon: ErrorWarningLineIcon,
            });
            setIsEditLoading(false);
        }
    };

    const handleScrollToInputs = () => {
        if (inputRef.current) {
            inputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleClickMoreButton = () => {
        setShowMoreOptions(true);
    };

    const openDeleteTaskConfirmModal = () => {
        setShowMoreOptions(false);
        setShowDeleteTaskConfirmModal(true);
    };

    const startResizing = () => {
        setIsResizing(true);
    };

    const stopResizing = () => {
        setIsResizing(false);
    };

    const resizeContainers = (e) => {
        if (!isResizing) return;

        const containerRect = mainContainerRef.current?.getBoundingClientRect();

        const position = e.clientX - containerRect.left;
        const width =
            position < 340
                ? 340
                : position > containerRect.width - 340
                ? containerRect.width - 340
                : position;
        const newLeftWidth = (width / containerRect.width) * 100;
        setLeftContainerWidth(`${newLeftWidth}%`);
        setRightContainerWidth(`${100 - newLeftWidth}%`);
    };

    const showToolTip = () => {
        if (!isResizing) {
            setShowResizeTooltip(true);
        }
    };

    const getCursorPosition = (e) => {
        const { clientX: x, clientY: y } = e;
        setCursorPosition({ x, y });
    };

    useEffect(() => {
        window.addEventListener('mousemove', resizeContainers);
        window.addEventListener('mouseup', stopResizing);
        return () => {
            window.removeEventListener('mousemove', resizeContainers);
            window.removeEventListener('mouseup', stopResizing);
        };
    }, [resizeContainers, stopResizing]);

    useEffect(() => {
        document.addEventListener('mousemove', getCursorPosition);

        return () => {
            document.removeEventListener('mousemove', getCursorPosition);
        };
    }, []);

    const handleDuplicateToMyLibrary = async () => {
        try {
            await duplicateTask({
                taskId: prompt.task?.id,
                promptId: prompt.id,
                promptVersionId: defaultVersion.id,
                taskType: TASK_TYPES.prompt,
                setAlert: setStatusAlert,
                setIsLoading: setIsDuplicateTaskLoading,
                setSuccessModal: setDuplicatedTaskSuccessModal,
            });
            setShowMoreOptions(false);
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <div
            className={`
        fixed top-[60px] sm:top-0 bottom-0 left-0 sm:left-[68px] right-0 bg-white overflow-auto lg:overflow-hidden flex flex-col lg:flex-row ${
            isResizing && 'cursorResize select-none'
        }
      `}
            ref={mainContainerRef}
        >
            {prompt ? (
                <>
                    <div
                        className="h-auto lg:h-full lg:min-w-[340px] bg-white pb-[40px] relative"
                        style={{ width: screenWidth < 1024 ? '' : leftContainerWidth }}
                        ref={leftContainerRef}
                    >
                        <div className="h-full flex flex-col px-[24px] overflow-y-auto">
                            <div className="bg-white sticky top-0 z-5 py-[20px] w-full">
                                <div className="flex items-center justify-between">
                                    <Button
                                        type="link"
                                        size="sm"
                                        text="Back to Library"
                                        onClick={handleBackPressed}
                                        leadingIcon={ArrowGoBackLineIcon}
                                    />
                                    {!arePromptAndDefaultVersionsDifferent && (
                                        <Button
                                            type="link"
                                            size="sm"
                                            text="Share"
                                            onClick={() => setIsShareTaskModalOpened(true)}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="py-5 px-4">
                                <PromptViewPageHeader
                                    taskId={taskId}
                                    headerText={prompt.task.name}
                                    emojiCode={prompt.task.icon_text}
                                    subHeaderText={prompt.task.description}
                                    setPrompt={setPrompt}
                                    currentTaskCollectionsIds={prompt.task?.collections}
                                    allCollections={collections}
                                    setAllCollections={setCollections}
                                    areCollectionsLoading={areCollectionsLoading}
                                    collectionsEditable={isOwnerOrAdmin}
                                    leftContainerRef={leftContainerRef}
                                />
                            </div>
                            {arePromptAndDefaultVersionsDifferent && (
                                <div className="bg-blue-100 mx-4 mb-4 flex items-start gap-[12px] rounded-2 p-[8px]">
                                    <div className="w-9 h-9 flex justify-center items-center bg-white rounded-2">
                                        <SvgIcon
                                            color="#17B4E5"
                                            icon={InformationLineIcon}
                                            size="large"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="font-body text-body-bold-s">
                                            You are viewing and running Version {promptVersion.name}{' '}
                                            of this prompt
                                        </p>
                                        <p className="font-body text-body-regular-s">
                                            Set as current version to make edits.
                                        </p>
                                    </div>
                                </div>
                            )}
                            {promptTextData &&
                                promptTextData.map((item, index) => (
                                    <div
                                        className="flex flex-col px-[16px] flex-grow max-lg:min-h-[240px]"
                                        key={index}
                                    >
                                        <NewTextAreaBox
                                            name={item.label}
                                            value={item.value}
                                            onChange={(e) =>
                                                updatePromptTextData(e.target.value, index)
                                            }
                                            label={item.label}
                                            placeholder="Write your prompt here"
                                            state={
                                                arePromptAndDefaultVersionsDifferent
                                                    ? 'disabled'
                                                    : item.state
                                            }
                                            badge={
                                                arePromptAndDefaultVersionsDifferent && {
                                                    text: `Version ${promptVersion.name}`,
                                                    color: 'peach',
                                                    leadingIcon: CompassIcon,
                                                    leadingIconColor: '#000000',
                                                }
                                            }
                                            isExpanded
                                            errorMessage={item.errorMessage}
                                            withCopyButton
                                            fullHeight
                                            scrollInsideTextArea={screenWidth >= 1024}
                                            darkBg={arePromptAndDefaultVersionsDifferent}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div
                        className="h-auto lg:h-full lg:min-w-[340px] bg-neutral-50 lg:overflow-y-auto pt-[20px] px-[32px] max-lg:flex-grow relative pb-[8px]"
                        style={{ width: screenWidth < 1024 ? '' : rightContainerWidth }}
                    >
                        {screenWidth >= 1024 && (
                            <div
                                className="absolute top-0 bottom-0 left-0 bg-transparent w-[4px] z-10 cursorResize"
                                onMouseDown={startResizing}
                                onMouseEnter={showToolTip}
                                onMouseLeave={() => {
                                    setShowResizeTooltip(false);
                                }}
                            >
                                {showResizeTooltip && (
                                    <div
                                        className="absolute"
                                        style={{ top: `${cursorPosition.y + 15}px`, left: '20px' }}
                                    >
                                        <ToolTip
                                            text={'Drag to resize'}
                                            position="center-left"
                                            isShown
                                        >
                                            <div></div>
                                        </ToolTip>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="w-full flex flex-col gap-[12px] h-full">
                            <CollapsableContainer
                                title="Prompt Settings"
                                isExpanded={isExpanded.promptSettings}
                                toggleExpand={() => toggleCollapsed('promptSettings')}
                            >
                                <PromptSettingsBox
                                    editable={!arePromptAndDefaultVersionsDifferent}
                                    promptSettings={promptSettings}
                                    setPromptSettings={setPromptSettings}
                                    setEditPromptData={setEditPromptData}
                                    setArePromptChanges={setArePromptChanges}
                                />
                            </CollapsableContainer>
                            <CollapsableContainer
                                title="Inputs"
                                isExpanded={isExpanded.inputs}
                                toggleExpand={() => toggleCollapsed('inputs')}
                                taskId={taskId}
                                showProtectedModeBadge={chainCount > 0}
                                protectedMode={protectedMode}
                                setProtectedMode={setProtectedMode}
                            >
                                <NewRunContainer
                                    description="Add input variables to your prompt. Use them by adding single brackets like [example-variable]."
                                    textAreaData={promptInputs}
                                    updateTextAreaData={updatePromptInputs}
                                    ref={inputRef}
                                    withCopyButton
                                    withDeleteButton={!arePromptAndDefaultVersionsDifferent}
                                    onDeleteButtonClick={(id) =>
                                        onDeleteInputButtonClick(id, 'input')
                                    }
                                    useKeyAsALabel
                                    showErrorInfo
                                    protectedMode={protectedMode}
                                />

                                <div className="mt-[16px] flex flex-col min-[400px]:flex-row gap-2 xs:gap-4 items-start">
                                    <Button
                                        type="ghost"
                                        size="xs"
                                        text="Add New Input"
                                        leadingIcon={AddLineIcon}
                                        onClick={onCreateInputButtonClick}
                                        state={
                                            arePromptAndDefaultVersionsDifferent || protectedMode
                                                ? 'disabled'
                                                : 'default'
                                        }
                                    />
                                </div>
                            </CollapsableContainer>

                            <OutputContainer
                                promptId={promptId}
                                promptOutputs={promptOutputs}
                                updatePromptOutputs={updatePromptOutputs}
                                withLinkIcon={outputRating !== null && resultId} // output is generated and resultId exists
                                isRunning={isRunning}
                                scrollInsideTextArea={screenWidth >= 1024}
                                outputRating={outputRating}
                                updateOutputRating={setOutputRating}
                                resultId={resultId}
                            />
                        </div>
                        <div className="justify-end fixed right-[10px] min-[380px]:right-[20px] bottom-[5px] lg:bottom-[14px] z-28 flex gap-[4px] xs:gap-[10px] items-center p-[7px]">
                            <div className="relative">
                                <ButtonIcon
                                    type="secondary"
                                    size="sm"
                                    icon={More2FillIcon}
                                    onClick={handleClickMoreButton}
                                />
                                {showMoreOptions && (
                                    <TaskOptions
                                        onModalClose={() => setShowMoreOptions(false)}
                                        handleTaskDeleteSelected={openDeleteTaskConfirmModal}
                                        handleTaskDuplicate={handleDuplicateToMyLibrary}
                                        isDuplicateTaskLoading={isDuplicateTaskLoading}
                                        promptId={promptId}
                                        libraryLocation={libraryLocation}
                                        task={prompt.task}
                                        inputs={promptInputs}
                                        outputs={promptOutputs}
                                        promptText={promptTextData?.[0]?.value || ''}
                                        setAlert={setStatusAlert}
                                    />
                                )}
                            </div>
                            {arePromptAndDefaultVersionsDifferent ? (
                                <Button
                                    type="secondary"
                                    size="sm"
                                    text="Set as Current Version"
                                    state={isEditLoading ? 'loading' : 'default'}
                                    onClick={handleSetAsCurrentVersion}
                                />
                            ) : (
                                <Button
                                    type="secondary"
                                    size="sm"
                                    text={
                                        arePromptChanges === null || arePromptChanges
                                            ? 'Save Prompt'
                                            : 'Prompt Saved'
                                    }
                                    trailingIcon={
                                        arePromptChanges === null || arePromptChanges
                                            ? null
                                            : CheckLineIcon
                                    }
                                    onClick={handleSavePrompt}
                                    state={
                                        isEditLoading
                                            ? 'loading'
                                            : arePromptChanges === null || arePromptChanges
                                            ? 'default'
                                            : 'disabled'
                                    }
                                />
                            )}
                            <Button
                                type="primary"
                                size="sm"
                                text={
                                    isRunning
                                        ? 'Cancel Stream'
                                        : arePromptAndDefaultVersionsDifferent
                                        ? `Run Prompt ${promptVersion.name}`
                                        : 'Run Prompt'
                                }
                                leadingIcon={isRunning ? CloseCircleLineIcon : FlashlightFillIcon}
                                // helperText={isRunning ? null : '⌘Enter'}
                                onClick={isRunning ? cancelStream : runPrompt}
                                state={runPromptState.isLoading ? 'loading' : 'default'}
                            />
                        </div>
                    </div>
                    {runPromptState.errorMessage && (
                        <Alert
                            status="critical"
                            message={runPromptState.errorMessage}
                            statusCode={runPromptState.statusCode}
                            icon={ErrorWarningLineIcon}
                            handleClose={() =>
                                setRunPromptState({
                                    isLoading: false,
                                    errorMessage: null,
                                    statusCode: null,
                                })
                            }
                        />
                    )}
                    {statusAlert && (
                        <Alert
                            status={statusAlert.status}
                            message={statusAlert.message}
                            icon={statusAlert.icon || CheckLineIcon}
                            statusCode={statusAlert.statusCode}
                            handleClose={() => setStatusAlert(null)}
                        />
                    )}
                    {actionModal.isOpened && (
                        <ActionModal
                            type={actionModal.type}
                            onModalClose={() => setActionModal({ isOpened: false })}
                            handleModalAction={actionModal.handleModalAction}
                            value={actionModal.value}
                            isLoading={actionModal.isLoading}
                        />
                    )}
                    {showDeleteTaskConfirmModal && (
                        <DeleteTaskConfirmModal
                            id={taskId}
                            onClose={() => setShowDeleteTaskConfirmModal(false)}
                            taskType="prompt"
                        />
                    )}
                    {isShareTaskModalOpened && (
                        <ShareTaskModal onClose={() => setIsShareTaskModalOpened(false)} />
                    )}
                    {duplicatedTaskSuccessModal.opened && (
                        <DuplicateTaskSuccessModal
                            handleClose={() => {
                                setDuplicatedTaskSuccessModal({
                                    opened: false,
                                    id: null,
                                    taskName: null,
                                    taskType: null,
                                });
                            }}
                            id={duplicatedTaskSuccessModal.id}
                            taskName={duplicatedTaskSuccessModal.taskName}
                            taskType={duplicatedTaskSuccessModal.taskType}
                        />
                    )}
                    <ReactRouterPrompt when={isNavigationRestricted}>
                        {({ onConfirm, onCancel }) => (
                            <BlockingNavigationModal
                                edits={areInputEdits ? `input` : 'prompt'}
                                onCancel={onCancel}
                                onConfirm={onConfirm}
                                runPrompt={runPrompt}
                                savePrompt={handleSavePrompt}
                            />
                        )}
                    </ReactRouterPrompt>
                </>
            ) : (
                <div className="fixed inset-0 flex justify-center items-center">
                    <div className="z-6 bg-white p-4 rounded-lg">
                        <Loading text="" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PromptViewPage;
