import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'design-system/SvgIcon/SvgIcon';
import ErrorWarningLineIcon from 'design-system/Icons/ErrorWarningLineIcon';
import { Badge, ButtonIcon } from '../index';
import { FileCopyLineIcon } from '../Icons';
import Alert from '../Alert/Alert';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import CheckLineIcon from '../Icons/CheckLineIcon';

TextArea.propTypes = {
    state: PropTypes.oneOf(['default', 'error', 'disabled']),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    displayLabelAsBadge: PropTypes.bool,
    labelBadgeColor: PropTypes.oneOf(['neutral', 'blue', 'purple', 'peach', 'lime']),
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    withCopyButton: PropTypes.bool,
    lightBg: PropTypes.bool,
    height: PropTypes.string,
    tipText: PropTypes.string,
};

function TextArea(props) {
    const {
        state = 'default',
        label,
        displayLabelAsBadge = false,
        labelBadgeColor,
        name,
        value,
        placeholder,
        isRequired = false,
        errorMessage,
        onChange,
        withCopyButton = false,
        height: _height,
        lightBg = false,
        tipText = '',
        ...otherProps
    } = props;
    const [copySuccessAlert, setCopySuccessAlert] = useState(false);

    let textAreaClassName = classNames(
        'w-full resize-y py-17 px-11 rounded-2 text-base text-neutral-500 font-normal disabled:text-neutral-300 leading-1.25 placeholder-neutral-300 focus:placeholder-transparent caret-neutral-500 border-1 focus:border-1 focus:outline-0 focus:shadow-l3 transition-all ease-in',
        {
            'bg-white': lightBg || state !== 'disabled',
            'bg-neutral-50': state === 'disabled' && !lightBg,
            'border-neutral-300 focus:shadow-neutral-300': state !== 'error' && !lightBg,
            'border-neutral-200': lightBg,
            'border-red-500 focus:shadow-red-500': state === 'error',
        }
    );

    const handleCopy = () => {
        navigator.clipboard.writeText(value).then(() => {
            setCopySuccessAlert(true);
        });
    };

    const height = _height ? _height : '120px';

    return (
        <div className="flex flex-col gap-1 font-inter w-full">
            <div className="flex flex-col">
                <div className="mb-1 flex justify-between items-center">
                    <div
                        className="text-small font-bold leading-1.25 font-body-bold"
                        style={{ width: withCopyButton ? 'calc(100% - 42px)' : '100%' }}
                    >
                        {displayLabelAsBadge ? (
                            <Badge text={label} color={labelBadgeColor || 'blue'} />
                        ) : (
                            label
                        )}
                        {isRequired && <sup className="text-red-500 leading-1 ml-0.5">*</sup>}
                    </div>
                    {withCopyButton && (
                        <ButtonIcon
                            type="link"
                            size="xs"
                            state={!value ? 'disabled' : 'default'}
                            icon={FileCopyLineIcon}
                            onClick={handleCopy}
                        />
                    )}
                </div>

                <textarea
                    className={textAreaClassName}
                    required={isRequired}
                    value={value}
                    disabled={state === 'disabled'}
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    style={{ height, minHeight: height }}
                    {...otherProps}
                />
            </div>

            {state === 'error' && errorMessage && <ErrorMessage message={errorMessage} />}

            {tipText && (state !== 'error' || !errorMessage) && (
                <div className="flex items-center gap-1">
                    <SvgIcon icon={ErrorWarningLineIcon} color="#5E6470" size="medium" />
                    <p className="text-extraSmall text-neutral-300 leading-1.25">{tipText}</p>
                </div>
            )}
            {copySuccessAlert && (
                <Alert
                    status="positive"
                    message={`Copied to clipboard!`}
                    icon={CheckLineIcon}
                    autoCloseInMS={3000}
                    handleClose={() => setCopySuccessAlert(false)}
                />
            )}
        </div>
    );
}

export default TextArea;
