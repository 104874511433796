import React from 'react';

import { API } from 'constants';
import client from '../../../../services/inbox-api';

import { useFetchDataOnLoad } from '../../../../hooks/useFetchDataOnLoad';
import { useResponsiveBreakpoints } from '../../../../hooks/useResponsiveBreakpoints';

import Modal from '../../../../design-system/Modal/Modal';
import Loading from '../../../../components/Loading';
import ViewSuggestionModalIndex from '../ViewSuggestionModalIndex/ViewSuggestionModalIndex';
import { ButtonIcon } from '../../../../design-system';
import { CloseLineIcon } from '../../../../design-system/Icons';

const ViewSuggestionModal = ({ id, onSuccess, onClose }) => {
    const { data: suggestionData } = useFetchDataOnLoad({
        client,
        route: API.ROUTES.inbox.suggestion + id + '/',
        onError: onClose,
    });

    const { isMobile } = useResponsiveBreakpoints();
    const maxHeight = isMobile ? '90%' : '80%';

    return (
        <Modal size="large" resetPadding maxHeight={maxHeight} onClose={onClose}>
            <div className="relative p-5 md:p-6 lg:p-8 flex flex-col gap-5">
                <div className="absolute top-3.5 right-3.5 md:top-4 md:right-4">
                    <ButtonIcon type="link" size="sm" icon={CloseLineIcon} onClick={onClose} />
                </div>
                <div className="pr-9 pb-5 border-b-1 border-neutral-200">
                    <h2 className="font-heading-bold text-heading-bold-m text-black mb-2">
                        New Agent Suggestion
                    </h2>
                    <p className="font-body text-body-regular-s text-neutral-400">
                        Review the agent suggestion below and approve, reject, or resubmit
                    </p>
                </div>

                <div className="min-h-[50px] flex flex-col gap-5">
                    {suggestionData && (
                        <ViewSuggestionModalIndex
                            suggestion={suggestionData}
                            onSuccess={onSuccess}
                            onClose={onClose}
                        />
                    )}

                    {!suggestionData && (
                        <div className="pt-3">
                            <Loading withText={false} />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ViewSuggestionModal;
