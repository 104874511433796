import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../constants/organization';

export const threadsRoutes = [
    {
        path: '/threads',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ThreadsPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
    {
        path: '/thread/:threadId',
        indexPath: '/threads',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.ThreadDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin],
                },
            ],
        },
    },
];
