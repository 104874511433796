import React from 'react';

import { JOB_TASK_STATUS_TO_BUCKET_MAPPING } from '../../../../constants/jobs';

import MilestoneCard from '../MilestoneCard/MilestoneCard';
import CardIndicatorLineLayout from '../../../../components/CardIndicatorLineLayout/CardIndicatorLineLayout';

const MilestonesBoardRowView = ({ milestones, pageType, entityName }) => {
    const areMilestones = !!milestones?.length;

    return (
        <div className="flex flex-col gap-2.5">
            {areMilestones &&
                milestones.map((milestone, index) => {
                    const { status } = milestone;
                    const bucketStatus = JOB_TASK_STATUS_TO_BUCKET_MAPPING[status];

                    return (
                        <CardIndicatorLineLayout key={index}>
                            <MilestoneCard
                                milestone={milestone}
                                bucketStatus={bucketStatus}
                                view="row"
                                pageType={pageType}
                            />
                        </CardIndicatorLineLayout>
                    );
                })}

            {!areMilestones && (
                <p className="font-body text-body-regular-m text-neutral-500">
                    No {entityName?.toLowerCase()} found
                </p>
            )}
        </div>
    );
};

export default MilestonesBoardRowView;
