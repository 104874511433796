import React from 'react';
import classNames from 'classnames';

import ToolTip from '../../../design-system/ToolTip/ToolTip';
import UserSharedLineIcon from '../../../design-system/Icons/UserSharedLineIcon';
import LogFeedbackTrigger from '../../../components/LogFeedbackTrigger/LogFeedbackTrigger';
import { ButtonIcon } from '../../../design-system';
import { CloseLineIcon } from '../../../design-system/Icons';

const MobileVersionAdditionalControls = ({ message, handleCloseExpandedMessageDetailView }) => {
    const assignedFrom = message?.assigned_from;
    const isShareFeedbackButtonDisplayed = message.feedback_allowed;

    const tooltipText = (
        <>
            Reassigned from <strong>{assignedFrom}</strong>
        </>
    );

    const containerClassName = classNames(
        'min-[1160px]:hidden w-full flex px-4 pt-3 pb-2 bg-white border-b-1 border-neutral-200',
        assignedFrom || isShareFeedbackButtonDisplayed ? 'justify-between' : 'justify-end'
    );

    return (
        <div className={containerClassName}>
            <div className="flex items-center gap-2">
                {assignedFrom && (
                    <ToolTip
                        text={tooltipText}
                        position="top-left"
                        shift="3px"
                        useInternalHoverStateToShowToolTip
                    >
                        <ButtonIcon
                            type="link"
                            size="sm"
                            icon={UserSharedLineIcon}
                            onClick={() => {}}
                        />
                    </ToolTip>
                )}

                {isShareFeedbackButtonDisplayed && (
                    <LogFeedbackTrigger target="inbox_message" targetId={message.id} />
                )}
            </div>

            <ButtonIcon
                type="link"
                size="sm"
                icon={CloseLineIcon}
                onClick={handleCloseExpandedMessageDetailView}
            />
        </div>
    );
};

export default MobileVersionAdditionalControls;
