import React from 'react';
import { Navigate } from 'react-router-dom';

import useUser from 'hooks/useUser';
import { useViewType } from '../hooks/useViewType';
import { VIEW_TYPES_REDIRECT_PATH } from '../constants/viewTypes';

//route just for not loggedIn users
const RestrictedRoute = ({ component: Component }) => {
    const useUserResult = useUser();
    const { user } = useUserResult;

    const { viewType } = useViewType();

    const redirectPath = VIEW_TYPES_REDIRECT_PATH[viewType];

    return user ? <Navigate to={redirectPath} /> : Component;
};

export default RestrictedRoute;
