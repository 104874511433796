import React, { useRef, useState } from 'react';
import { DateTime } from 'luxon';

import { useResponsiveBreakpoints } from '../../../hooks/useResponsiveBreakpoints';

import { getTaskTypeBadge } from '../../../helpers/getTaskTypeBadge';
import { formatOutputValue } from '../../../helpers/formatOutputValue';

import { Badge, ButtonIcon } from '../../../design-system';
import ErrorMessage from '../../../design-system/ErrorMessage/ErrorMessage';
import NewTextAreaBox from '../../../design-system/NewTextAreaBox/NewTextAreaBox';
import MoreVerticalIcon from '../../../design-system/Icons/MoreVerticalIcon';
import CompiledPromptModal from '../CompiledPromptModal/CompiledPromptModal';
import OutputRatingControls from '../../../components/OutputRatingControls/OutputRatingControls';
import TaskRunMoreOptionsPopup from '../TaskRunMoreOptionsPopup/TaskRunMoreOptionsPopup';
import AdditionalResultDataModal from '../../LibraryPage/TaskPlaygroundPage/AdditionalResultDataModal/AdditionalResultDataModal';
import CollapsibleStatusCardContainer from '../CollapsibleStatusCardContainer/CollapsibleStatusCardContainer';

const TaskRunCard = ({ step, workerId, setThread, setFeedbackModal, scrollableContainerRef }) => {
    const { task, status, order, errors, inputs, outputs, result, updated_at, result_data } = step;

    const formattedTime = DateTime.fromISO(updated_at).toFormat('MM/dd/yyyy - hh:mm a');

    const [moreOptionsPopupOpened, setMoreOptionsPopupOpened] = useState(false);
    const [activeModal, setActiveModal] = useState({ type: null });

    const badge = getTaskTypeBadge({
        taskType: task.task_type,
        usePromptLabelInsteadOfModel: true,
    });

    const bottomControlsRef = useRef(null);
    const { isMobile } = useResponsiveBreakpoints({ maxMobileWidth: 640 });

    const openThreadFeedbackModal = (data) => setFeedbackModal({ initialRating: data.rating });

    const updateRating = (newRating) => {
        // in threads state find a corresponded worker and step and then replace rating
        setThread((prevData) => ({
            ...prevData,
            workers: prevData.workers.map((worker) => {
                if (worker.id !== workerId) {
                    return worker;
                }
                return {
                    ...worker,
                    config: worker.config.map((step) => {
                        if (step.order !== order) {
                            return step;
                        }
                        return { ...step, result: { ...step.result, rating: newRating } };
                    }),
                };
            }),
        }));
    };

    const handleScrollToTaskCardBottom = () => {
        if (bottomControlsRef.current && scrollableContainerRef.current) {
            const container = scrollableContainerRef.current;
            const cardBottomElement = bottomControlsRef.current;

            const screenHeight = window.innerHeight;

            const mobileOffset = 240;
            const desktopOffset = screenHeight > 800 ? 200 : 180; // amount of pixels to scroll up from the bottom of the screen

            const offset = isMobile ? mobileOffset : desktopOffset;
            const elementTop = cardBottomElement.offsetTop;
            const scrollPosition = elementTop - screenHeight + offset;

            container.scrollTo({ top: scrollPosition, behavior: 'smooth' });
        }
    };

    // header of the card (collapsed view)
    const stepInfoBlock = (
        <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1.5">
                <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase">
                    Task {order}
                </p>
                {badge && (
                    <div className="sm:hidden">
                        <Badge
                            text={<span className="text-body-bold-xs">{badge.label}</span>}
                            color={badge.color}
                            leadingIcon={badge.icon}
                            leadingIconColor={badge.iconColor}
                        />
                    </div>
                )}
            </div>

            <div className="flex items-center gap-2">
                {badge && (
                    <div className="hidden sm:block">
                        <Badge
                            text={<span className="text-body-bold-xs">{badge.label}</span>}
                            color={badge.color}
                            leadingIcon={badge.icon}
                            leadingIconColor={badge.iconColor}
                        />
                    </div>
                )}
                <p className="font-body text-body-regular-s text-neutral-500 flex-grow line-clamp-1">
                    {task.name}
                </p>
            </div>

            <p className="font-body text-body-regular-xs text-neutral-300 line-clamp-2">
                {task.description}
            </p>

            {!!errors?.length && (
                <>
                    {errors.map((error, index) => (
                        <ErrorMessage
                            key={index}
                            message={error.message}
                            highlightedStart={error.type}
                        />
                    ))}
                </>
            )}
        </div>
    );

    // main card content (task inputs, outputs and rating) (expanded view)
    const expandedTaskBlock = (
        <div className="px-3 sm:px-4 pb-3 bg-white rounded-b-2">
            <div className="py-4 sm:border-t-1 border-[#D9D9D9] flex flex-col gap-4">
                <div>
                    <button
                        type="button"
                        onClick={handleScrollToTaskCardBottom}
                        className="block font-body text-body-regular-s text-purple-500 underline underline-offset-[3px]"
                    >
                        Scroll to bottom
                    </button>
                </div>

                <div className="flex flex-col gap-3">
                    {!!inputs.length &&
                        inputs.map((input) => {
                            const badge = {
                                text: (
                                    <>
                                        <span className="font-body text-body-bold-xs">Input: </span>
                                        {input.label}
                                    </>
                                ),
                                color: 'peach',
                            };
                            return (
                                <div key={input.id} className="flex flex-col gap-1.5 items-start">
                                    <NewTextAreaBox
                                        name={input.id}
                                        value={input.value}
                                        size="sm"
                                        state={input?.error ? 'error' : 'disabled'}
                                        readOnlyWhileError
                                        errorMessage={input?.error?.message}
                                        errorMessageHighlightedStart={input?.error?.type}
                                        withCopyButton={true}
                                        labelBadge={badge}
                                        lightBorder
                                    />
                                </div>
                            );
                        })}
                    {!!outputs.length &&
                        outputs.map((output) => {
                            const badge = {
                                text: (
                                    <>
                                        <span className="font-body text-body-bold-xs">
                                            Output:{' '}
                                        </span>
                                        {output.label}
                                    </>
                                ),
                                color: 'blue',
                            };
                            return (
                                <div key={output.id} className="flex flex-col gap-1.5 items-start">
                                    <NewTextAreaBox
                                        name={output.id}
                                        value={formatOutputValue(output.value)}
                                        size="sm"
                                        state="disabled"
                                        withCopyButton={true}
                                        labelBadge={badge}
                                        lightBorder
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
            <div
                className="relative pt-3 border-t-1 border-neutral-200 flex gap-2 justify-between sm:justify-end"
                ref={bottomControlsRef}
            >
                <OutputRatingControls
                    resultId={result.id}
                    outputRating={result.rating}
                    view="buttonIcon"
                    updateOutputRating={updateRating}
                    onSuccessRatingUpdate={openThreadFeedbackModal}
                />
                <ButtonIcon
                    type="link"
                    size="sm"
                    icon={MoreVerticalIcon}
                    onClick={() => setMoreOptionsPopupOpened(true)}
                />

                {moreOptionsPopupOpened && (
                    <TaskRunMoreOptionsPopup
                        task={task}
                        setActiveModal={setActiveModal}
                        onClose={() => setMoreOptionsPopupOpened(false)}
                    />
                )}

                {activeModal.type === 'viewRunData' && (
                    <AdditionalResultDataModal
                        resultId={result.id}
                        data={result_data}
                        onClose={() => setActiveModal({ type: null })}
                    />
                )}
                {activeModal.type === 'compiledPrompt' && (
                    <CompiledPromptModal
                        promptId={task.default_prompt_id}
                        resultId={result.id}
                        resultData={result_data}
                        onClose={() => setActiveModal({ type: null })}
                    />
                )}
            </div>
        </div>
    );

    return (
        <CollapsibleStatusCardContainer
            status={status}
            lastUpdatedInfo={formattedTime}
            collapsedCardBlock={stepInfoBlock}
            expandedCardBlock={expandedTaskBlock}
        />
    );
};

export default TaskRunCard;
