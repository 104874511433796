import React, { useState } from 'react';

import { Badge, ButtonIcon } from 'design-system';
import { ArrowDownSLineIcon, ArrowUpSLineIcon } from 'design-system/Icons';
import RefreshIcon from '../../design-system/Icons/RefreshIcon';
import ProtectedModeBadge from '../ProtectedModeBadge/ProtectedModeBadge';

const CollapsableContainer = ({
    title = '',
    isExpanded: externalIsExpanded = true,
    toggleExpand,
    isDisabled = false, // disable toggle expand / collapse
    badge,
    children,
    withRefreshIcon = false,
    refreshLoading = false,
    handleRefresh = () => {},
    taskId,
    showProtectedModeBadge = false,
    protectedMode,
    setProtectedMode,
    useInternalExpandState = false,
    internalDefaultExpandState = false,
    topRightContent = null,
}) => {
    const [internalIsExpanded, setInternalIsExpanded] = useState(internalDefaultExpandState);
    const isExpanded = useInternalExpandState ? internalIsExpanded : externalIsExpanded;

    const onRefreshClick = async (e) => {
        e?.stopPropagation();
        await handleRefresh();
    };

    const handleToggleExpand = (e) => {
        e?.stopPropagation();
        if (!isDisabled) {
            if (useInternalExpandState) {
                setInternalIsExpanded((prev) => !prev);
            } else {
                toggleExpand();
            }
        }
    };

    return (
        <div className="w-full border-b border-neutral-200 flex flex-col gap-4 pb-2">
            <div
                className={`flex items-center justify-between ${!isDisabled && 'cursor-pointer'}`}
                onClick={handleToggleExpand}
            >
                <div className="flex items-center gap-2 flex-wrap">
                    <h3 className="font-body text-body-bold-m text-neutral-500 uppercase select-none">
                        {title}
                    </h3>
                    {badge && (
                        <Badge
                            text={badge.text}
                            color={badge.color}
                            leadingIcon={badge.leadingIcon}
                            leadingIconColor={badge.leadingIconColor}
                        />
                    )}
                </div>
                <div className="flex items-center">
                    {showProtectedModeBadge && (
                        <ProtectedModeBadge
                            taskId={taskId}
                            protectedMode={protectedMode}
                            setProtectedMode={setProtectedMode}
                        />
                    )}
                    {withRefreshIcon && (
                        <ButtonIcon
                            type="link"
                            size="xs"
                            state={refreshLoading ? 'loading' : 'default'}
                            icon={RefreshIcon}
                            onClick={onRefreshClick}
                            spinOnLoading
                        />
                    )}
                    {topRightContent}
                    {!isExpanded ? (
                        <ButtonIcon
                            type="link"
                            size="sm"
                            state={isDisabled ? 'disabled' : 'default'}
                            icon={ArrowDownSLineIcon}
                            onClick={handleToggleExpand}
                        />
                    ) : (
                        <ButtonIcon
                            type="link"
                            size="sm"
                            state={isDisabled ? 'disabled' : 'default'}
                            icon={ArrowUpSLineIcon}
                            onClick={handleToggleExpand}
                        />
                    )}
                </div>
            </div>
            {isExpanded && <div className="pb-3">{children}</div>}
        </div>
    );
};

export default CollapsableContainer;
