import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';
import { ORGANIZATION_PLAN, USER_ROLE } from '../../constants/organization';

export const basesRoutes = [
    {
        path: '/bases',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.BasesPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.member],
                },
            ],
        },
    },
    {
        path: '/base/:baseId',
        indexPath: '/bases',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.BaseDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
            needsSpecialPermission: true,
            allowedPlansAndRoles: [
                {
                    plan: ORGANIZATION_PLAN.agency,
                    roles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.member],
                },
            ],
        },
    },
    // {
    //     path: '/base/new',
    //     route_access_type: ROUTE_ACCESS_TYPE.private,
    //     component: Pages.BaseBuilderPage,
    //     permissionRules: {
    //         availableViewTypes: [VIEW_TYPES.admin, VIEW_TYPES.client],
    //         needsSpecialPermission: true,
    //         allowedPlansAndRoles: [
    //             {
    //                 plan: ORGANIZATION_PLAN.agency,
    //                 roles: [USER_ROLE.owner, USER_ROLE.admin, USER_ROLE.member],
    //             },
    //         ],
    //     },
    // },
];
