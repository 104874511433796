import React from 'react';
import PropTypes from 'prop-types';

import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';

import MobilePopup from '../MobilePopup/MobilePopup';
import { ListOptionGroup } from '../index';

MultiActionPopup.propTypes = {
    actionOptions: PropTypes.node.isRequired,
    onClose: PropTypes.func,
};

function MultiActionPopup({ actionOptions, onClose }) {
    const { isMobile } = useResponsiveBreakpoints();

    if (isMobile) {
        return <MobilePopup onClose={onClose}>{actionOptions}</MobilePopup>;
    }

    return (
        <div className="absolute bottom-[calc(100%+4px)] right-0 z-30">
            <ListOptionGroup
                fixedWidth={297}
                listOptions={actionOptions}
                handleClose={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    onClose();
                }}
            ></ListOptionGroup>
        </div>
    );
}

export default MultiActionPopup;
