import * as Pages from '../../pages';

import { VIEW_TYPES } from '../../constants/viewTypes';
import { ROUTE_ACCESS_TYPE } from '../../constants/routeAccessType';

export const jobsRoutes = [
    {
        path: '/jobs',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.JobsAndProjectsPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
    {
        path: '/job/:jobId',
        indexPath: '/jobs',
        route_access_type: ROUTE_ACCESS_TYPE.private,
        component: Pages.JobDetailPage,
        permissionRules: {
            availableViewTypes: [VIEW_TYPES.client],
        },
    },
];
