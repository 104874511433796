import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useViewType } from '../../../hooks/useViewType';

import { API } from 'constants';
import client from '../../../services/operate-api';
import { defaultErrorMessage } from '../../../constants/errorMessages';

import { handleCopy } from '../../../helpers/handleCopy';
import { openLinkInNewTab } from '../../../helpers/openLinkInNewTab';

import { ListOption, ListOptionGroup } from '../../../design-system';

const ThreadMoreOptionsPopup = ({
    onClose,
    threadStatus,
    executionId,
    setCopyAlert,
    setThreadEditModal,
    setRestartSidePanel,
    setCancelConfirmationModal,
    setIsConfirmStatusChangeModalOpened,
    setFeedbackPanelOpened,
    setIsReloadReportModalOpened,
}) => {
    const navigate = useNavigate();
    const { toggleViewType } = useViewType();

    const { threadId } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const withArchivedWorkers = Boolean(searchParams.get('archived'));

    const [isCopyLoading, setIsCopyLoading] = useState(false);

    const handleAction = (action) => {
        action();
        onClose();
    };

    const handleEditName = () => setThreadEditModal({ open: true });

    const handleRestart = () => setRestartSidePanel({ restartTarget: 'thread', opened: true });

    const handleCancelThread = () =>
        setCancelConfirmationModal({ opened: true, cancelTarget: 'thread' });

    const handleReloadReport = () => setIsReloadReportModalOpened(true);

    const handleMarkAsComplete = () => setIsConfirmStatusChangeModalOpened(true);

    const handleViewExecution = () => openLinkInNewTab(`/execution/${executionId}`);

    const showArchivedWorkers = () => {
        setFeedbackPanelOpened(false);
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.append('archived', 'True');
        setSearchParams(updatedSearchParams);
        onClose();
    };

    const hideArchivedWorkers = () => {
        setFeedbackPanelOpened(false);
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('archived');
        setSearchParams(updatedSearchParams);
        onClose();
    };

    const navigateToJobDetailPage = () => {
        toggleViewType();
        navigate(`/job/${threadId}`);
    };

    const handleCopySettings = async () => {
        try {
            setIsCopyLoading(true);
            const { data } = await client.get(`${API.ROUTES.operate.thread}${threadId}/settings/`);
            const settings = data.settings;

            handleCopy(settings, setCopyAlert, 'Current thread settings copied to clipboard!');
            onClose();
        } catch (e) {
            setIsCopyLoading(false);
            setCopyAlert({ status: 'critical', message: defaultErrorMessage });
        }
    };

    const isViewJobDetailButtonDisplayed =
        threadStatus !== 'canceled' && threadStatus !== 'archived';

    const isThreadCompleted = threadStatus === 'success';

    return (
        <div className="absolute top-[34px] right-[4px] z-55">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="editIcon"
                            text="Edit Name"
                            onClick={() => handleAction(handleEditName)}
                        />
                        {withArchivedWorkers ? (
                            <ListOption
                                leadingIconName="eyeOffIcon"
                                text="Hide Archived Workers"
                                onClick={hideArchivedWorkers}
                            />
                        ) : (
                            <ListOption
                                leadingIconName="eyeIcon"
                                text="Show Archived Workers"
                                onClick={showArchivedWorkers}
                            />
                        )}
                        <ListOption
                            leadingIconName="refreshCcwIcon"
                            text="Reload Report"
                            onClick={() => handleAction(handleReloadReport)}
                        />
                        <ListOption
                            leadingIconName="skipBackIcon"
                            text="Restart Thread"
                            onClick={() => handleAction(handleRestart)}
                        />
                        <ListOption
                            leadingIconName="squareIcon"
                            text="Cancel Thread"
                            iconColor="#E95B69"
                            textColor="#E95B69"
                            onClick={() => handleAction(handleCancelThread)}
                        />
                        {isViewJobDetailButtonDisplayed && (
                            <ListOption
                                leadingIconName="playCircleIcon"
                                text="View Job Details"
                                onClick={navigateToJobDetailPage}
                            />
                        )}
                        {!isThreadCompleted && (
                            <ListOption
                                leadingIconName="checkboxLineIcon"
                                text="Mark as Complete"
                                onClick={() => handleAction(handleMarkAsComplete)}
                            />
                        )}
                        <ListOption
                            leadingIconName="fileCopyLineIcon"
                            text="Copy Current Settings"
                            isLoading={isCopyLoading}
                            onClick={handleCopySettings}
                        />
                        {!!executionId && (
                            <ListOption
                                leadingIconName="shareBoxLineIcon"
                                text="View Execution"
                                onClick={() => handleAction(handleViewExecution)}
                            />
                        )}
                    </>
                }
                handleClose={(e) => {
                    e.stopPropagation();
                    onClose();
                }}
            />
        </div>
    );
};

export default ThreadMoreOptionsPopup;
