import React, { useEffect, useState } from 'react';

import useUser from '../../../hooks/useUser';
import { useViewType } from '../../../hooks/useViewType';

import {
    decorationLineColor,
    multiOrgStyles,
    navItemIconColor,
    navItemLabelColor,
    orgBlockStyles,
} from '../../../constants/sideBar';
import { VIEW_TYPES_REDIRECT_PATH } from '../../../constants/viewTypes';
import { ORGANIZATION_DATA_LS_KEY } from '../../../constants/organization';
import { navigateUserAfterOrganizationChange } from '../../../helpers/changeOrganizationUtils';

import { SvgIcon } from '../../../design-system';
import ToolTip from '../../../design-system/ToolTip/ToolTip';
import ArrowRightSLineIcon from '../../../design-system/Icons/ArrowRightSLineIcon';
import OrganizationSwitcher from '../OrganizationSwitcher/OrganizationSwitcher';

const OrganizationIconBlock = ({ mobileVersion = false }) => {
    const { viewType, oppositeViewType, toggleViewType } = useViewType();
    const { user, userRole, orgPlan } = useUser();
    const organization = user?.organization;
    // organizationState can be "single" or "multi"
    const organizationState =
        organization && organization.organization_count > 1 ? 'multi' : 'single';

    const [showToolTip, setShowToolTip] = useState(false);
    const [showOrganizationSwitcher, setShowOrganizationSwitcher] = useState(false);

    useEffect(() => {
        // This useEffect hook sets up a listener for changes in the 'localStorage' (specifically when organization is changed)
        // If a change is detected in the storage key in any tab except the current one, it will reload all other tabs where the same application is open.
        const handleOrganizationChange = async (e) => {
            if (e.key === ORGANIZATION_DATA_LS_KEY) {
                try {
                    const newOrgData = JSON.parse(localStorage.getItem(ORGANIZATION_DATA_LS_KEY));

                    if (newOrgData) {
                        navigateUserAfterOrganizationChange({
                            newOrgData,
                            user,
                            viewType,
                            oppositeViewType,
                            toggleViewType,
                        });
                    } else {
                        window.location.href = VIEW_TYPES_REDIRECT_PATH[viewType];
                    }
                } catch (e) {
                    window.location.href = VIEW_TYPES_REDIRECT_PATH[viewType];
                }
            }
        };

        window.addEventListener('storage', handleOrganizationChange);
        return () => {
            window.removeEventListener('storage', handleOrganizationChange);
        };
    }, []);

    useEffect(() => {
        if (organization?.id) {
            try {
                const orgData = {
                    id: organization.id,
                    userRole,
                    orgPlan,
                    isStaff: user.is_staff,
                };
                const storedOrgData = localStorage.getItem(ORGANIZATION_DATA_LS_KEY);
                if (!storedOrgData) {
                    localStorage.setItem(ORGANIZATION_DATA_LS_KEY, JSON.stringify(orgData));
                }
                if (storedOrgData) {
                    const parsedStoredOrgData = JSON.parse(storedOrgData);
                    if (parsedStoredOrgData.id !== organization.id) {
                        localStorage.setItem(ORGANIZATION_DATA_LS_KEY, JSON.stringify(orgData));
                    }
                }
            } catch (e) {}
        }
    }, [organization?.id]);

    const openOrganizationSwitcher = () => {
        if (organizationState === 'single') {
            return;
        }
        setShowOrganizationSwitcher(true);
    };

    const orgIconBlockStyles = `w-[36px] h-[36px] rounded-2 absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] z-[14] flex justify-center items-center uppercase font-body font-semibold text-[20px] ${
        orgBlockStyles[viewType]
    } ${organizationState === 'multi' && 'cursor-pointer'}`;

    if (!organization) {
        return <></>;
    }

    return (
        <>
            {mobileVersion ? (
                <div
                    className={`w-full flex items-center justify-between gap-2 pb-2 border-b-1 ${
                        decorationLineColor[viewType]
                    } ${organizationState === 'multi' && 'cursor-pointer'}`}
                    onClick={openOrganizationSwitcher}
                >
                    <div
                        className={`flex items-center gap-2 ${
                            organizationState === 'multi' ? 'w-[calc(100%-32px)]' : 'w-full'
                        } `}
                    >
                        <div className="w-[52px] h-[52px] relative z-[-1]">
                            <div className={orgIconBlockStyles}>{organization.name[0]}</div>
                            <div
                                className={`w-[28px] h-[28px] absolute z-[8] rounded left-1/2 transform translate-x-[-50%] bottom-[2px] before:content-[''] before:block before:w-[32px] before:h-[32px] before:absolute before:z-[9] before:rounded-[6px] before:left-1/2 before:transform before:translate-x-[-50%] before:bottom-[3px] ${multiOrgStyles[viewType]}`}
                            ></div>
                        </div>
                        <p
                            className={`font-body text-body-bold-m w-[calc(100%-60px)] truncate ${navItemLabelColor[viewType]}`}
                        >
                            {organization.name}
                        </p>
                    </div>
                    {organizationState === 'multi' && (
                        <button className="">
                            <SvgIcon
                                color={navItemIconColor[viewType]}
                                icon={ArrowRightSLineIcon}
                                size="large"
                            />
                        </button>
                    )}
                </div>
            ) : (
                <ToolTip
                    text="Organization"
                    position="center-left"
                    shift="63px"
                    isShown={showToolTip}
                >
                    <li
                        className="w-[52px] h-[52px] relative"
                        onMouseEnter={() => setShowToolTip(true)}
                        onMouseLeave={() => setShowToolTip(false)}
                    >
                        <div className={orgIconBlockStyles} onClick={openOrganizationSwitcher}>
                            {organization.name[0]}
                        </div>
                        {organizationState === 'multi' && (
                            <div
                                className={`w-[28px] h-[28px] absolute z-[8] rounded left-1/2 transform translate-x-[-50%] bottom-[2px] before:content-[''] before:block before:w-[32px] before:h-[32px] before:absolute before:z-[9] before:rounded-[6px] before:left-1/2 before:transform before:translate-x-[-50%] before:bottom-[3px] ${multiOrgStyles[viewType]}`}
                            ></div>
                        )}
                    </li>
                </ToolTip>
            )}
            {showOrganizationSwitcher && (
                <OrganizationSwitcher
                    mobileVersion={mobileVersion}
                    onClose={(e) => {
                        e?.stopPropagation();
                        setShowOrganizationSwitcher(false);
                    }}
                />
            )}
        </>
    );
};

export default OrganizationIconBlock;
