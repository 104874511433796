import React from 'react';

import { API } from 'constants';
import client from '../../services/library-api';
import reportClient from '../../services/report-api';

import useUser from '../../hooks/useUser';
import useCustomPagination from '../../hooks/useCustomPagination';
import { useFetchOptionsForPaginatedSelect } from '../../hooks/useFetchOptionsForPaginatedSelect';
import { useFetchOptionsForPaginatedSelectWithSWR } from '../../hooks/useFetchOptionsForPaginatedSelectWithSWR';

import { SORT_TYPES } from '../../constants/sort';

import YourTopProcessesBlock from './YourTopProcessesBlock/YourTopProcessesBlock';
import RecentJobsBlock from './RecentJobsBlock/RecentJobsBlock';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Loading from '../../components/Loading';
import ClientPageTitleBlock from '../../components/ClientPageTitleBlock/ClientPageTitleBlock';
import NewAgentSuggestionsBlock from './NewAgentSuggestionsBlock/NewAgentSuggestionsBlock';

const DashboardPage = () => {
    const { user } = useUser();

    const {
        options: processes,
        optionsLoading: processesLoading,
        setPage: setProcessRequestPage,
        canLoadMoreOptions: canLoadMoreProcesses,
        isFirstRequestCompleted: areProcessesLoaded,
    } = useFetchOptionsForPaginatedSelectWithSWR({
        client,
        route: API.ROUTES.library.process,
        searchParams: { shared: true, ordering: SORT_TYPES.lastRun },
        limit: 8,
        autoFetchNextPage: true,
    });

    const { data: jobs, key } = useCustomPagination({
        client: reportClient,
        route: API.ROUTES.report.job,
        pageIndex: 0,
        searchParams: { ordering: SORT_TYPES.created },
        limit: 10,
    });

    const {
        options: agentsWithSuggestions,
        optionsLoading: suggestionsLoading,
        setPage: setAgentsWithSuggestionsPage,
        canLoadMoreOptions: canLoadMoreAgentsWithSuggestions,
    } = useFetchOptionsForPaginatedSelect({
        client,
        route: API.ROUTES.library.processSuggestions,
    });

    useDocumentTitle('Dashboard');

    const loadNextProcessesPage = () => {
        setProcessRequestPage((page) => page + 1);
    };

    const pageTitle = user?.first_name ? `Welcome, ${user.first_name}!` : 'Hello there!';

    const isPageDisplayed = user && areProcessesLoaded;

    return (
        <div className="page-position bg-neutral-50 overflow-y-auto flex flex-col py-5 px-5 md:px-6 lg:p-8">
            {isPageDisplayed && (
                <>
                    <ClientPageTitleBlock title={pageTitle} />

                    <div className="flex-1 lg:overflow-hidden flex flex-col lg:relative">
                        <div className="lg:absolute lg:inset-0 lg:h-full w-full max-w-full grid grid-rows-[auto_auto] grid-cols-1 lg:grid-rows-1 lg:grid-cols-[minmax(200px,0.54fr)_minmax(200px,0.46fr)] l:grid-cols-[minmax(200px,0.56fr)_minmax(200px,0.44fr)] lg:items-start gap-y-5 gap-x-4 xl:gap-x-5">
                            <div className="flex flex-col gap-5 max-w-full lg:h-full lg:overflow-y-auto lg:hide-scrollbar">
                                <NewAgentSuggestionsBlock
                                    agentsWithSuggestions={agentsWithSuggestions}
                                    loading={suggestionsLoading}
                                    hasNextPage={canLoadMoreAgentsWithSuggestions}
                                    setNextPage={setAgentsWithSuggestionsPage}
                                />

                                <YourTopProcessesBlock
                                    processes={processes}
                                    hasNextPage={canLoadMoreProcesses}
                                    loading={processesLoading}
                                    loadNextProcessesPage={loadNextProcessesPage}
                                />
                            </div>

                            <div className="lg:h-full h-fit lg:overflow-y-auto lg:hide-scrollbar">
                                <RecentJobsBlock jobs={jobs} jobsMutateKey={key} />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {!isPageDisplayed && (
                <div className="flex-grow flex items-center justify-center">
                    <Loading />
                </div>
            )}
        </div>
    );
};

export default DashboardPage;
