import PublicRoute from '../router/PublicRoute';
import PrivateRoute from '../router/PrivateRoute';
import RestrictedRoute from '../router/RestrictedRoute';

import { ROUTE_ACCESS_TYPE } from '../constants/routeAccessType';

export const getRouteAccessComponent = (route_access_type) => {
    switch (route_access_type) {
        case ROUTE_ACCESS_TYPE.private:
            return PrivateRoute;
        case ROUTE_ACCESS_TYPE.restricted:
            return RestrictedRoute;
        case ROUTE_ACCESS_TYPE.public:
            return PublicRoute;
        default:
            return PrivateRoute;
    }
};
