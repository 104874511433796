import React, { memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { useOverlayState } from '../../../hooks/useOverlayState';

import { ButtonIcon } from '../../../design-system';
import { FlashlightFillIcon, More2FillIcon } from '../../../design-system/Icons';
import JobCardActions from '../../JobsAndProjectsPage/JobCardActions/JobCardActions';
import HighlightedIcon from '../../../design-system/HighlightedIcon/HighlightedIcon';
import TimestampDisplay from '../../../design-system/TimestampDisplay/TimestampDisplay';
import JobCardMoreOptionsPopup from '../../JobsAndProjectsPage/JobCardMoreOptionsPopup/JobCardMoreOptionsPopup';

const JobCard = ({ job, jobsMutateKey }) => {
    const { id, name, process, created_at } = job;
    const location = useLocation();

    const { isOpened, open: openPopup, close: closePopup } = useOverlayState();
    const [actionModal, setActionModal] = useState({ action: null });

    const cardContainerClassName = classNames(
        'px-4 py-3 max-w-full w-full rounded-2 bg-neutral-50',
        'grid grid-rows-[auto_auto] md:grid-rows-1 grid-cols-[32px_minmax(100px,_1fr)_28px] md:grid-cols-[32px_minmax(80px,_1fr)_117px_28px] lg:grid-rows-[auto_auto] xl:grid-rows-1 lg:grid-cols-[32px_minmax(100px,_1fr)_28px] xl:grid-cols-[32px_minmax(80px,_1fr)_117px_28px] gap-3 2xl:gap-4 items-center'
    );

    return (
        <>
            <Link to={`/job/${id}`} state={{ from: location }} className={cardContainerClassName}>
                <div className="h-full place-content-center">
                    <HighlightedIcon icon={FlashlightFillIcon} variant="light_purple" />
                </div>

                <div className="flex flex-col gap-0.5 overflow-hidden">
                    <p className="font-body-bold text-body-bold-s text-black truncate">{name}</p>
                    <p className="font-body text-body-regular-xs text-neutral-500 truncate">
                        {process.name}
                    </p>
                </div>

                <div className="pt-2 md:p-0 lg:pt-2 xl:p-0 border-t-1 md:border-none lg:border-t-1 xl:border-none border-neutral-200 row-start-2 row-end-2 col-span-full md:row-start-auto md:row-end-auto md:col-span-1 lg:row-start-2 lg:row-end-2 lg:col-span-full xl:row-start-auto xl:row-end-auto xl:col-span-1">
                    <TimestampDisplay
                        label="Run on"
                        timestamp={created_at}
                        dateTimeFormat="MM/dd/yyyy"
                    />
                </div>

                <div className="relative">
                    <ButtonIcon icon={More2FillIcon} onClick={openPopup} type="link" size="xs" />

                    {isOpened && (
                        <JobCardMoreOptionsPopup
                            setActionModal={setActionModal}
                            onClose={closePopup}
                        />
                    )}
                </div>
            </Link>

            <JobCardActions
                id={id}
                name={name}
                actionModal={actionModal}
                setActionModal={setActionModal}
                requestKeysToMutate={[jobsMutateKey]}
            />
        </>
    );
};

export default memo(JobCard);
