import React from 'react';

import { openLinkInNewTab } from '../../../../helpers/openLinkInNewTab';
import { STATUS, statusBadge as _statusBadge } from '../../../../constants/statuses';

import FormFieldWrapper from '../../../../design-system/FormFieldWrapper/FormFieldWrapper';
import MarkdownPreviewWithAdditionalActions from '../../../../design-system/MarkdownPreviewWithAdditionalActions/MarkdownPreviewWithAdditionalActions';
import { Badge, Button } from '../../../../design-system';
import { FlashlightFillIcon, ShareBoxLineIcon } from '../../../../design-system/Icons';

const AgentContentPieceForProject = ({ content }) => {
    const { data, is_filled, label } = content;
    const { name, process, status, thread_id, outputs } = data || {};

    const processName = is_filled ? process?.name : label;
    const processTagText = (
        <>
            <span className="font-body-bold text-body-bold-xs uppercase">Agent: </span>
            {processName}
        </>
    );
    const processTag = (
        <Badge
            color="purple"
            text={processTagText}
            leadingIcon={FlashlightFillIcon}
            leadingIconColor="#754DCF"
        ></Badge>
    );
    const statusBadgeData = _statusBadge[status || STATUS.pending];
    const statusBadge = <Badge text={statusBadgeData?.text} color={statusBadgeData?.color} />;

    const containerClassName =
        'w-full p-2.5 rounded-2 border-1 border-neutral-200 flex flex-col gap-1.5 xs:gap-2.5';

    if (!is_filled) {
        return (
            <div className={containerClassName}>
                <div className="flex items-center justify-between gap-3">
                    <div className="flex-grow overflow-hidden">{processTag}</div>
                    {statusBadge}
                </div>
            </div>
        );
    }

    return (
        <div className={containerClassName}>
            <div>{processTag}</div>
            <div className="flex flex-col-reverse gap-1.5 xs:flex-row xs:gap-3 justify-between">
                <p className="font-body-bold text-body-bold-l text-neutral-500 line-clamp-2 xs:pt-[2px]">
                    {name}
                </p>
                <div>{statusBadge}</div>
            </div>

            {!!outputs?.length &&
                outputs.map(({ id, label, value }) => {
                    if (!value) {
                        return (
                            <FormFieldWrapper key={id} label={label} gap={6}>
                                <div className="px-4 py-8 rounded-2 border-1 border-neutral-200 border-dashed flex justify-center font-body text-body-regular-m text-neutral-200 italic text-center">
                                    Not filled
                                </div>
                            </FormFieldWrapper>
                        );
                    }

                    return (
                        <MarkdownPreviewWithAdditionalActions
                            key={id}
                            value={value}
                            label={label}
                        />
                    );
                })}

            <div className="flex justify-end">
                <Button
                    type="link"
                    size="xs"
                    text="View Job"
                    trailingIcon={ShareBoxLineIcon}
                    onClick={() => openLinkInNewTab(`/job/${thread_id}`)}
                />
            </div>
        </div>
    );
};

export default AgentContentPieceForProject;
