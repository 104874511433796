import React from 'react';

import { TASK_TYPES } from '../../../constants/library';
import { openLinkInNewTab } from '../../../helpers/openLinkInNewTab';

import { ListOption, ListOptionGroup } from '../../../design-system';

const TaskRunMoreOptionsPopup = ({ onClose, task, setActiveModal }) => {
    const taskType = task.task_type;

    const handleViewNav = () => {
        if (task.task_type === TASK_TYPES.prompt) {
            openLinkInNewTab(`/library/prompt/${task.default_prompt_id}`);
        } else {
            openLinkInNewTab(`/library/task/${task.id}`);
        }
    };

    const openModal = (type) => {
        setActiveModal({ type });
        onClose();
    };

    return (
        <div className="absolute top-[110%] right-0 z-20 pb-5">
            <ListOptionGroup
                fixedWidth={300}
                listOptions={
                    <>
                        <ListOption
                            leadingIconName="shareBoxLineIcon"
                            text="View Task"
                            onClick={handleViewNav}
                        />
                        <ListOption
                            leadingIconName="informationLineIcon"
                            text="View Data"
                            onClick={() => openModal('viewRunData')}
                        />
                        {taskType === TASK_TYPES.prompt && (
                            <ListOption
                                leadingIconName="fileList3LineIcon"
                                text="View Prompt"
                                onClick={() => openModal('compiledPrompt')}
                            />
                        )}
                    </>
                }
                handleClose={(e) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    onClose();
                }}
            />
        </div>
    );
};

export default TaskRunMoreOptionsPopup;
